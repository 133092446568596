import React, {Suspense, useRef, useState} from 'react';

import { BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux"
import {createStore} from "redux"
import ebaseApp from "../../redux/reducers"
import auth from "../../utils/auth"
import Routes from "../common/Routes";
import SessionManager from "../../utils/SessionManager";
import Spinner from "../common/Spinner";
import { Toast, ToastContainer } from 'react-bootstrap';

require("froala-editor/js/froala_editor.min.js");
require("froala-editor/css/froala_editor.min.css");
require('font-awesome/css/font-awesome.css');

const store = createStore(ebaseApp);
// eslint-disable-next-line
const sessionManager = new SessionManager(store); //It's not used here but this initialises the static SessionManager

const SuspenseLoading = () => {
    return (
        <div>
            <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                    <Spinner/>
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                    Please wait while we load the live preview examples
                    <span className="font-size-lg d-block text-dark">
              This live preview instance can be slower than a real production
              build!
            </span>
                </div>
            </div>
        </div>
    );
};

const AppContainer = props => {

    // const location = useLocation();
    // const history = useHistory();
    //
    // console.log("location ",useLocation);
    // console.log("history ",history);

    let isLite = window.location.host.includes('lite');
    auth.setIsListe(isLite);

    let container = useRef(null)

    const showLoadError = (message, error) => {
        console.log("ShowLoad error ", message, "|" , error, container);

        let error_message = message;
        addToast("Error", error_message);
        if (error != null && error.message != null)
        {
            error_message = <span>{message}<br/>{error.message}</span>
        }
        showLoadErrorCheck(error_message);
    }

    const showLoadErrorCheck = (error_message) =>  {
       if (container.current) {
           showLoadErrorImpl(error_message);
       } else {
           setTimeout(()=>showLoadErrorCheck(error_message), 500);
       }
    }

    const showLoadErrorImpl = (error_message) =>  {
        container.current.error(error_message, "Error", {
            timeOut: 5000,
            extendedTimeOut: 10000,
            closeButton:true
        });
    }

    SessionManager.shared().setErrorHandler(showLoadError);

    const [toasts, setToasts] = useState([]);

    const removeToast = (id) => {
        setToasts(toasts.filter(toast => toast.id !== id));
    };

    const addToast = (title, message) => {
         const newToast = {
                id: Date.now(),  // Unique ID for each toast
                title: title,
                message: message,
            };
         setToasts([...toasts, newToast]);  // Update the state with the new toast
    };

    let toastContainer = <ToastContainer position="top-end" className="p-3">
        {toasts.map((toast) => (
            <Toast
                key={toast.id}
                onClose={() => removeToast(toast.id)}
                delay={5000}
                autohide={true}
                bg="danger"
            >
                <Toast.Header>
                    <strong className="me-auto">{toast.title}</strong>
                </Toast.Header>
                <Toast.Body  className={'text-white'}>{toast.message}</Toast.Body>
            </Toast>
        ))}
    </ToastContainer>

    return (
        <Provider store={store}>
            <Suspense fallback={<SuspenseLoading/>}>
                <BrowserRouter>
                    <Routes/>
                </BrowserRouter>
                {toastContainer}
            </Suspense>
        </Provider>
    )

}

export default AppContainer

/*

class AppContainer extends React.Component {

    constructor() {
        super();
    }

    render() {
        console.log("React version: ",React.version);
        let isLite = window.location.host.includes('lite');
        auth.setIsListe(isLite);

        if(isLite){
            return (
                <Provider store={store}>
                    <Router history={hashHistory}>
                        <Router history={browserHistory}>
                            <Route path="/" component={Main}>
                            <IndexRedirect to="/impactsummary" />
                            <Route path="impactsummary" component={LiteImpactSummaryView}/>
                            </Route>
                        </Router>
                    </Router>
                </Provider>
            );
        }


        return (
            <Provider store={store}>
                <Router history={hashHistory}>
                    <Router history={browserHistory}>
                        <Route path="/" component={Main}>
                            <IndexRedirect to="/select" />
                            <Route path="select" component={SelectView}> </Route>
                            <Route path="clients" component={ClientsContainer}> </Route>
                            <Route path="client/:clientId" component={ClientManageContainer}> </Route>
                            <Route path="libraries" component={ClientLibraryHomeContainer}></Route>
                            <Route path="projects" component={ProjectsHomeContainer}>
                                <IndexRedirect to="/projects/list" />
                                <Route path="list" component={ProjectsView}/>
                                <Route path="project/:objectId/:tabName" component={ProjectContainer}/>
                            </Route>
                            <Route path="users" component={UsersHomeContainer}>
                                <IndexRedirect to="/users/list" />
                                <Route path="list" component={UsersView}/>
                                <Route path="manage/:userId" component={UserManageContainer}/>
                            </Route>

                            <Route path="help" component={HelpView}> </Route>
                            <Route path="enviddata" component={DataHomeContainer}>
                                <IndexRedirect to="/enviddata/objects" />
                                <Route path="objects" component={DataHomeView}/>
                                    <Route path="object/:objectId/:tabName" component={DataObjectView}/>
                            </Route>
                            <Route path="report-defn/:projectId" component={ReportHomeContainer}>
                                <IndexRedirect to="summary/0" />
                                <Route path="summary/:tabIndex" component={ReportHomeView}/>
                                <Route path="task/:task" component={ReportTaskContainer}/>
                            </Route>
                            <Route path="report-controlssummary/:projectId" component={ControlsSummaryContainer}>
                                <IndexRedirect to="summary/0" />
                                <Route path="summary/:tabIndex" component={ControlsSummaryView}/>
                                <Route path="task" component={ActivityTaskView}/>
                            </Route>
                            <Route path="compliance/:projectId" component={ComplianceHomeContainer}>
                                <IndexRedirect to="home/0" />
                                <Route path="home/:tabIndex" component={ComplianceHomeView}/>
                                <Route path="manage/:filter/:controlId/:tabIndex" component={ComplianceMgrContainer}/>
                            </Route>
                            <Route path="stakeholder/:projectId" component={StakeholderHomeContainer}>
                                <IndexRedirect to="home/0" />
                                <Route path="home/:tabIndex" component={StakeholderHomeView}/>
                                <Route path="manage/:filter/:stakeholderId/:tabIndex" component={StakeholderMgrContainer}/>
                                <Route path="comms/:listType/:filter/:objectId" component={StakeholderCommsContainer}/>
                                <Route path="objections" component={StakeholderHomeView}/>
                                <Route path="actions" component={StakeholderHomeView}/>
                            </Route>
                            <Route path="controlscatalogue" component={ControlsCatalogueContainer}/>
                            <Route path="knowledgebase" component={KnowledgeBaseContainer}/>
                            <Route path="stakeholdersummary" component={StakeholderSummaryContainer}/>
                            <Route path="impactsummary" component={ImpactSummaryView}/>
                            <Route path="regionsummary" component={RegionSummaryView}/>
                            <Route path="webmap" component={MapTest} />

                        </Route>
                    </Router>
                </Router>
            </Provider>
        )
    }
}

export default AppContainer
*/

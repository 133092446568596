import React from 'react';
import {useTranslation} from "react-i18next";


const ObjectionStatusLabel = function(props)
{
    const { t } = useTranslation();

    let name = "Unknown";
    let label = "";

    switch (props.status)
    {
        case "OPEN":
            name = "Open";
            label = "success"
            break;
        case "ASSESSING":
            name = "Assessing";
            label = "warning"
            break;
        case "MERIT":
            name = "Has Merit";
            label = "success"
            break;
        case "NO_MERIT":
            name = "No Merit";
            label = "success"
            break;
        case "ACTION_COMPLETED":
            name = "Action Completed";
            label = "primary"
            break;
        case "SATISFIED":
            name = "Satisfied";
            label = "primary"
            break;
    }

    if (props.plain === true)
    {
        return <span>{t(name)}</span>
    }
    else
    {
        return <span className={"label label-"+label}>{t(name)}</span>
    }

}

const EventSectionTitle = function(props)
{
    return <h3 style={{marginTop:-10, marginBottom:20}}>{props.children}</h3>
}

export {ObjectionStatusLabel, EventSectionTitle}

/**
 * Created by craig on 5/12/17.
 */
/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import ControlsCatalogueView from '../../views/ControlsCatalogueView'
import { connect } from 'react-redux'

import {loadTreeStart, loadTreeDone, loadBranchStart, loadBranchDone} from '../../redux/actions/actions'
import {loadSummaryObjectDone, loadSummaryObjectStart} from '../../redux/actions/actions'
import SessionManager from "../../utils/SessionManager";

const mapStateToProps = (state, ownProps) =>
{
    return {
        isLoading: state.summary.isLoading,
        children: state.summary.children,
        objects: state.summary.objects,
        loadState: state.summary.loadState,
        selectedIsLoading:state.summary.selectedIsLoading,
        selectedData:state.summary.selectedData,
        dimensions:state.session.dimensions,
    }
}


const loadTree = function(props, dispatch)
{
    dispatch(loadTreeStart());

    ebase.ajax({
        url:"/ebase/controls/read-controls-summary",
        data:{object_id : null, summaryType:"ControlsSummary"},
        success:(result) => {
            if (result.success) {
                dispatch(loadTreeDone(result.data));
            } else {
                props.showLoadError("Error Loading Summary", result);
            }
        },
        error :(result) => {
            props.showLoadError("Error Loading Summary", result);
        }

    });

}

const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {
        loadSummary : () => {

            loadTree(ownProps, dispatch);
        },

        loadObject : (object) => {
            if (!object) {
                return;
            }
            let object_id = object.object_id;
            let displayed_object_id = (object.displayed_object_id == null ) ? object.object_id : object.displayed_object_id;
            dispatch(loadSummaryObjectStart(object_id, displayed_object_id));

            ebase.ajax({
                url:"/ebase/controls/read-controls-object",
                data:{object_id : displayed_object_id},
                success:(result) => {
                    if (result.success) {
                        dispatch(loadSummaryObjectDone(displayed_object_id, result.data));
                    } else {
                        ownProps.showLoadError("Error Loading Summary", result);
                    }
                },
                error :(result) => {
                    ownProps.showLoadError("Error Loading Summary", result);
                }

            });


        },

        loadBranch : (parent) =>{
            dispatch(loadBranchStart(parent));
            ebase.ajax({
                url:"/ebase/controls/read-controls-summary",
                data:{object_id : parent, summaryType:"ControlsSummary"},
                success:(result) => {
                    if (result.success) {
                        dispatch(loadBranchDone(parent,result.data));
                    } else {
                        ownProps.showLoadError("Error Loading Summary", result);
                    }
                },
                error :(result) => {
                    ownProps.showLoadError("Error Loading Summary", result);
                }

            });
        }


    }
}

class ControlsCatalogueVw extends React.Component{
    render() {


        return  <div>
                    {React.cloneElement(<ControlsCatalogueView/>, this.props)}
                </div>

    }
    componentWillMount()
    {
        this.props.loadSummary();

        //this.timer = setInterval(this.tick, 5000);
    }

    componentWillUnmount()
    {
        //clearInterval(this.timer);
    }

};

const ControlsCatalogueContainer = connect(mapStateToProps, mapDispatchToProps)(ControlsCatalogueVw)

export default ControlsCatalogueContainer

import jq from "../../utils/jq";
import {createRef} from "react";

var React = require('react');

class FroalaEditor extends React.Component{

  constructor(props) {
    super(props);

    // Tag on which the editor is initialized.
    this.tag = null;
    this.defaultTag = 'div';
    this.listeningEvents = []

    // Jquery wrapped element.
    this.$element = null;

    // Editor element.
    this.$editor = null;
    this.elRef = createRef();

    // Editor options config
    this.config = {
      immediateReactModelUpdate: false,
      reactIgnoreAttrs: null
    }

    this.editorInitialized = false;

    this.SPECIAL_TAGS = ['img', 'button', 'input', 'a'];
    this.INNER_HTML_ATTR = 'innerHTML';
    this.hasSpecialTag = false;
    this.oldModel = null;

  }

  // Before first time render.
  componentWillMount() {
    this.tag = this.props.tag || this.defaultTag;
  }

  // After first time render.
  componentDidMount() {
    const tagName = this.elRef.current.tagName;
    if (this.SPECIAL_TAGS.indexOf(tagName) !== -1) {

      this.tag = tagName;
      this.hasSpecialTag = true;
    }

    if (this.props.onManualControllerReady) {
      this.generateManualController();
    } else {
      this.createEditor();
    }
  }

  componentWillUnmount() {
    this.destroyEditor();
  }

  createEditor() {

    if (this.editorInitialized) {
      return;
    }

    this.config = this.props.config || this.config;

    this.$element = jq.find(this.elRef.current);

    this.setContent(true);

    this.registerEvents();
    this.$editor = this.$element.froalaEditor(this.config).data('froala.editor').$el;
    this.initListeners();

    this.editorInitialized = true;
  }

  setContent(firstTime) {

    if (!this.editorInitialized && !firstTime) {
      return;
    }

    if (this.props.model || this.props.model === '') {

      this.oldModel = this.props.model;

      if (this.hasSpecialTag) {
        this.setSpecialTagContent();
      } else {
        this.setNormalTagContent(firstTime);
      }
    }
  }

  setNormalTagContent(firstTime) {

    var self = this;

    function htmlSet() {

      self.$element.froalaEditor('html.set', self.props.model || '', true);
      //This will reset the undo stack everytime the model changes externally. Can we fix this?
      self.$element.froalaEditor('undo.reset');
      self.$element.froalaEditor('undo.saveStep');
    }

    if (firstTime) {
      this.registerEvent(this.$element, 'froalaEditor.initialized', function () {
        htmlSet();
      });
    } else {
      htmlSet();
    }

  }

  setSpecialTagContent() {

    var tags = this.props.model;

    // add tags on element
    if (tags) {

      for (var attr in tags) {
        if (tags.hasOwnProperty(attr) && attr !== this.INNER_HTML_ATTR) {
          this.$element.attr(attr, tags[attr]);
        }
      }

      if (tags.hasOwnProperty(this.INNER_HTML_ATTR)) {
        this.$element[0].innerHTML = tags[this.INNER_HTML_ATTR];
      }
    }
  }

  destroyEditor() {

    if (this.$element) {

      this.listeningEvents && this.$element.off(this.listeningEvents.join(" "));
      this.$editor.off('keyup');
      this.$element.froalaEditor('destroy');
      this.listeningEvents.length = 0;
      this.$element = null;
      this.editorInitialized = false;
    }
  }

  getEditor() {

    if (this.$element) {
      return this.$element.froalaEditor.bind(this.$element);
    }
    return null;
  }

  generateManualController() {
    var controls = {
      initialize: this.createEditor,
      destroy: this.destroyEditor,
      getEditor: this.getEditor,
    };

    this.props.onManualControllerReady(controls);
  }

  updateModel() {

    if (!this.props.onModelChange) {
      return;
    }

    var modelContent = '';

    if (this.hasSpecialTag) {

      var attributeNodes = this.$element[0].attributes;
      var attrs = {};

      for (var i = 0; i < attributeNodes.length; i++ ) {

        var attrName = attributeNodes[i].name;
        if (this.config.reactIgnoreAttrs && this.config.reactIgnoreAttrs.indexOf(attrName) !== -1) {
          continue;
        }
        attrs[attrName] = attributeNodes[i].value;
      }

      if (this.$element[0].innerHTML) {
        attrs[this.INNER_HTML_ATTR] = this.$element[0].innerHTML;
      }

      modelContent = attrs;
    } else {

      var returnedHtml = this.$element.froalaEditor('html.get');
      if (typeof returnedHtml === 'string') {
        modelContent = returnedHtml;
      }
    }

    this.oldModel = modelContent;
    this.props.onModelChange(modelContent);
  }

  initListeners() {
    var self = this;

    // bind contentChange and keyup event to froalaModel
    this.registerEvent(this.$element, 'froalaEditor.contentChanged',function () {
      self.updateModel();
    });
    if (this.config.immediateReactModelUpdate) {
      this.registerEvent(this.$editor, 'keyup', function () {
        self.updateModel();
      });
    }
  }

  // register event on jquery editor element
  registerEvent(element, eventName, callback) {

    if (!element || !eventName || !callback) {
      return;
    }

    this.listeningEvents.push(eventName);
    element.on(eventName, callback);
  }

  registerEvents() {

    var events = this.config.events;
    if (!events) {
      return;
    }

    for (var event in events) {
      if (events.hasOwnProperty(event)) {
        this.registerEvent(this.$element, event, events[event]);
      }
    }
  }

  render(){
    // return <this.tag ref="el">{this.props.children}</this.tag>;
    return <this.tag ref={this.elRef}>{this.props.children}</this.tag>;

  }
};

export default FroalaEditor;

/**
 * Created by craig on 9/8/17.
 */

import React from 'react';
import {SummaryTree, SummaryItem} from './SummaryTree'
import EnvidObjectIcon from "./EnvidObjectIcon"
import {withTranslation} from "react-i18next";

class SummaryFull extends React.Component {

    constructor(props)
    {
        super();

        this.renderObject = this.renderObject.bind(this);
    }

    renderObject(object, ctx)
    {
        const {t} = this.props;

        let style_small_space = {marginRight:"5px"};

        let ret = [];

        if (object === undefined){
            return <span className="tree-item" style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>&nbsp;{t("Loading")} </span>
        }

        let type = object.type;
        if (object.displayed_object_type != null) type = object.displayed_object_type;
        ret.push(<EnvidObjectIcon key='0' type={type} showName={false}/>);
        ret.push(<span key='1' style={style_small_space}/>);
        ret.push(<span key='2'>{object.name}</span>);
        // - for debugging:
        // ret.push(object.name + " ("+object.object_id+")");

        return <span className="tree-item" style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>&nbsp;{ret}</span>
    }

    sortObjectIds(object_ids, objects)
    {
        // sort the child_object_ids
        if (object_ids === undefined){
            return;
        }
        object_ids.sort((a, b) => {
            //console.log("sort child ids");

            let oA = objects[this.extractObjectId(a)];
            let oB = objects[this.extractObjectId(b)];
            // console.log(oA);
            // console.log(oB);
            if(oA === undefined && oB !== undefined){
                return 1;
            }
            if(oA !== undefined && oB === undefined){
                return -1;
            }
            if(oA === undefined && oB === undefined){
                return -1;
            }
            let nameA = oA.name;
            let nameB = oB.name;
            if (oA.type === "Control") nameA = "b"+nameA;
            if (oA.type === "Receptor") nameA = "a"+nameA;
            if (oB.type === "Control") nameB = "b"+nameB;
            if (oB.type === "Receptor") nameB = "a"+nameB;
            return nameA.localeCompare(nameB);
            //return (nameB < nameA) ? 1 : -1
        });

    }

    extractObjectId(contexted)
    {
        let splitted = contexted.split("::");
        let object_id = splitted[splitted.length-1];
        return object_id;

    }

    renderChildren(object_ids, parent)
    {
        if (object_ids == null || object_ids.length === 0) {
            return null;
        }

        let children = [];
        for (let i=0; i<object_ids.length; ++i) {
            let contexted = object_ids[i];

            // remove any context stuff
            let object_id = this.extractObjectId(contexted);

            let object = this.props.objects[object_id];

            // console.log("renderChildren:: ",this.props);
            // console.log("object_id: ",object_id);
            // console.log("object: ",object);
            // console.log(object);

            let child_object_ids = this.props.children[contexted];

            // if (object && object.name === 'Emissions - Light') {
            //     console.log("contexted: ", contexted, ' ', object);
            //     console.log("childe ids: ", child_object_ids);
            // }
            // console.log(child_object_ids);

            // if (object !== undefined) {
            //
            //     if (object.type === "Impact") {
            //
            //         console.log("SummaryFull:: RenderChildren");
            //         console.log("object_ids: ");
            //         console.log(object_ids);
            //
            //         console.log("child ids:");
            //         console.log(child_object_ids);
            //     }
            // }

            this.sortObjectIds(child_object_ids, this.props.objects);

            // if (object && object.name === 'Emissions - Light') {
            //     console.log("CHILD OBJECT IDS ", child_object_ids, ' ', parent);
            // }

            let child = <SummaryItem key={contexted} context={contexted} object={object} parent={parent}>{this.renderChildren(child_object_ids,object)}</SummaryItem>;
            children.push(child);
        }


        return children;
    }

    loadBranch(base, context){

        //console.log("LOAD BRANCH ",base, ' ', context);

        // console.log("SummaryFUll:: load branch: "+base);
        //Stakeholder Summary doesn't use loadbranch
        if (this.props.loadBranch === undefined){
            return;
        }

        //Stops loading if you've already loaded objects
        let children = this.props.children[context];
        let doLoad = true;
        if(children !== undefined) {
            doLoad = false;
            for(let child in children) {
                let bits = child.split("::");
                let childObjId = bits.pop();
                let childObj = this.props.objects[childObjId];
                if(childObj === undefined){
                    doLoad = true;
                }
            }
            // if(children.length > 0){
            //
            //     let objid = children[0];
            //     let bits = objid.split("::");
            //     objid = bits.pop();
            //
            //     let obj = this.props.objects[objid];
            //     //let nextChildren = this.props.children[objid];
            //     //console.log('NEXT CH', nextChildren);
            //     if(obj !== undefined){
            //         doLoad = false;
            //     }
            // }
        }
        if(doLoad) {
            // for (var ss in this.props.children) {
            //     if (ss.endsWith("::" + base)) {
            //         base = ss;
            //     }
            // }
            this.props.loadBranch(context);
        }
    }

    render() {

        // console.log("SummaryFull:: render");
        // console.log(this.props);
        // console.log("SummaryFull: top type: '"+this.props.topType+"'");

        let top = [];

        //let topType = "ProjectCategory";
        let topType = this.props.topType;
        if(topType === null || topType === undefined){
            topType = "ProjectCategory";
        }

        if (this.props.top != null) {
            topType = this.props.top;
        }

        for (var object_id in this.props.objects) {
            let obj = this.props.objects[object_id];
            if (obj.type === topType) {
                top.push(object_id);
            }
        }

        this.sortObjectIds(top, this.props.objects);
        let overrideTypes = [];
        if (top.length > 1)
        {
            overrideTypes = ["ProjectCategory"];
        }

        return (
            <SummaryTree renderObject={this.renderObject} callback={this.props.onclick} defaultOpen={true} overrideTypes={overrideTypes} loadBranch={this.loadBranch.bind(this)}>
                {this.renderChildren(top)}
            </SummaryTree>);
    }
}



export default withTranslation()(SummaryFull)


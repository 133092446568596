import React, {Component, createRef} from 'react';
import { Link } from 'react-router-dom';
import sitedata from '../../utils/sitedata'
import jq from "../../utils/jq";
import {withTranslation} from "react-i18next";
import {NavAppMainImage, NavAppSubImage} from "../../img/images";

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.menuRef = createRef();
    }

    componentDidMount() {
        //console.log("COMPONENT DID MOUNT", this.refs);
        // const { menu } = this.refs;
        // jq.find(menu).metisMenu();
        jq.find(this.menuRef.current).metisMenu();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log("COMPONENT DID UPDATE", this.refs);
        // const { menu } = this.refs;
        // jq.find(menu).metisMenu('dispose').metisMenu();
        jq.find(this.menuRef.current).metisMenu('dispose').metisMenu();
    }

    activeRoute(routeName) {

        // console.log("active route ",routeName, this.props);

        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    secondLevelActive(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    }

    renderMenuDefs(menuDefs, level) {
        let menuItems = [];
        if (!!menuDefs) {
            for (let i = 0; i < menuDefs.length; i++) {
                let menuItem = this.renderMenuDef(menuDefs[i], level);
                if (menuItem) {
                    menuItems.push(menuItem)
                }
            }
        }
        return menuItems;
    }

    renderMenuDef(menuDef, level) {

        let readonly = false;
        let hasPermission = false;
        let showNew = false;
        let clientLibraries = sitedata.getClientLibraries(this.props);

        if (!menuDef.perm && !menuDef.link) {
            hasPermission = true;
        } else if (menuDef.perm === 'client') {
            if (menuDef.link === '/webmap') {
                hasPermission = sitedata.checkWriteAccess(this.props,menuDef.perm);
            } else {
                hasPermission = !!clientLibraries && clientLibraries.length > 1;
            }
        } else if (menuDef.perm === 'enviddata') {
            let writePerm = sitedata.checkWriteAccess(this.props,menuDef.perm);
            let readPerm = sitedata.checkReadAccess(this.props,menuDef.perm);
            hasPermission =  readPerm || writePerm;
            readonly = readPerm && !writePerm;
        } else {
            hasPermission = menuDef.perm === 'help' || sitedata.checkReadAccess(this.props,menuDef.perm);
        }

        let menuItem;

        if (hasPermission) {
            let menuItemContent;
            let menuItemLink;
            let active = false;

            let submenuItems = this.renderMenuDefs(menuDef.defs, level+1);

            let title;
            if (menuDef.title) {
                title = menuDef.title;
            } else {
                title = sitedata.lookupLinkName(menuDef.link + (readonly ? '-readOnly':''));
            }

            if (menuDef.titleType === 'subtitle') {
                return <li className="nav-subtitle">
                    {title}
                </li>
            }

            menuItemContent = (
              <>
              <span className="nav-label">{title}</span>
                  {showNew && (
                    <>
                      <span>&nbsp;&nbsp;</span>
                      <span className="label" style={{backgroundColor:'#ad1f8d', color:'white'}}>{this.props.t("NEW")}</span>
                    </>
                   )}
                  {submenuItems.length > 0 &&
                      <span className="fa arrow"/>
                  }
              </>
            );

            let key;
            if (menuDef.link === undefined) {
                key = menuDef.title;
                active = "";
                menuItemLink =  (
                  <Link style={(submenuItems.length > 0 ? {display: 'block' } : {})} to={{hash: '#'}}>{sitedata.lookupLinkIcon(
                    menuDef.link)}{menuItemContent}</Link>);
            } else {
                key = menuDef.perm + "-" + menuDef.link;
                active = this.activeRoute(menuDef.link);
                menuItemLink = (
                  <Link to={menuDef.link}>{sitedata.lookupLinkIcon(
                    menuDef.link)}{menuItemContent}</Link>);
            }

            let submenu;
            if (submenuItems.length > 0) {
                let levelName = '';
                if (level === 1) {
                    levelName = 'second';
                } else {
                    levelName = 'third';
                }
                let paddingPx = (level * 20) + 'px'
                submenu = (
                <ul className={'nav metismenu ' + (levelName ? 'nav-'+levelName+'-level ' : '') +  'collapse'} style={{paddingLeft: paddingPx}}>
                    {submenuItems}
                </ul>
              );
            }

            menuItem = (
              <li className={`${active}`} key={key}>
                  {menuItemLink}
                  {submenu}
              </li>
            );

        }

        return menuItem;
    }

    render() {

        //const {t} = this.props;

        // console.log("Navigation:: ",this.props);
        // console.log("");
        //<img alt="image" className="img-circle" src="img/greenlight-logo.png" style={{width:48, height:48}}/>

        let selectedDataset = false;
        if(this.props.siteData != null){
            selectedDataset = this.props.siteData.dataset != null;
        }

        if(!selectedDataset){

            return (
                <nav className="navbar-default navbar-static-side" role="navigation">
                    <div className="slimScrollDiv" style={{position: "relative", overflow: "hidden", width:"auto", height:"100%"}}>
                        <div className="sidebar-collapse" style={{overflow: "hidden", width: "auto", height:"100%"}}>
                            <ul className="nav metismenu" id="side-menu" ref={this.menuRef}>
                                <li className="nav-header" style={{padding: '8px 8px 0 20px'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        rowGap: '8px',
                                        alignItems: 'flex-start'
                                    }}>
                                        <img style={{maxWidth: '70%', maxHeight: '100%', objectFit: 'contain'}}
                                             alt={'Xamin eBase'} src={NavAppMainImage}/>
                                        <img style={{maxWidth: '50%', maxHeight: '100%', objectFit: 'contain'}}
                                             alt={'Xamin environmental management'} src={NavAppSubImage}/>
                                    </div>
                                </li>
                                <li className="nav-header">
                                    <div className="dropdown profile-element"> <span>
                                        <img alt="Logo" className="img-circle" src={sitedata.getClientLogo(this.props)}
                                             style={{width: 48, height: 48}}/>
                                    </span>
                                    <span className="clear"> <span className="block m-t-xs"> <strong
                                        className="font-bold" style={{color: 'white'}}>{sitedata.getUserFullName(this.props)}</strong>
                                    </span> <span
                                        className="text-muted text-xs block">{sitedata.getUserPosition(this.props)}</span> </span>
                                        {/*<a data-toggle="dropdown" className="dropdown-toggle" href="#">*/}
                                        {/*</a>*/}
                                    </div>
                                    <div className="logo-element">
                                        eBase
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            )

        }


        // console.log("Navigation: have sitedata");

        //var position = "User";

        //var navs = [];

        let menuDefs = [
            {link: '/select', perm: 'enviddata'},
            {link: '/libraries', perm: 'client'},
            {link: '/projects', perm: 'project'},
            {link: '/enviddata', perm: 'enviddata'},


            {
                title: 'Browse Library', defs: [
                    {link: '/impactsummary', perm: 'summary'},
                    {link: '/receptorsummary', perm: 'summary'},
                    {link: '/knowledgebase', perm: 'knowledgebase'},
                    {link: '/controlscatalogue', perm: 'controls'},
                    {link: '/regionsummary', perm: 'summary'},
                    {link: '/stakeholdersummary', perm: 'knowledgebase'},
            ]},
        ];
        let botMenuDefs = [
            {title: 'Support', titleType: 'subtitle', perm: 'enviddata'},
                {link: 'https://www.xodusgroup.com/', title: 'User Guidance', perm: 'enviddata'},
            {title: 'Admin', titleType: 'subtitle', perm: 'user'},
                {link: '/users', perm: 'user'},
                {link: '/templates', perm: 'user'},
        ];

        //let perms = ["client","user", "project", "enviddata", "enviddata", "controls", "knowledgebase","knowledgebase","summary","summary","summary", "client"];//, "help"];
        //let links = ["/libraries","/users", "/projects", "/enviddata", "/templates", "/controlscatalogue", "/knowledgebase","/stakeholdersummary","/impactsummary","/regionsummary","/receptorsummary","/webmap"];//, "/help"];

        //let isFullSystem = sitedata.checkWriteAccess(this.props,"system") && sitedata.checkReadAccess(this.props,"system") && sitedata.checkGrantAccess(this.props,"system");

        //
        // let clientLibraries = sitedata.getClientLibraries(this.props);
        //
        // for (let i = 0; i < menudefn.length; ++i) {
        //
        //     let menudef = menudefn[i];
        //     let readonly = false;
        //     let hasPermission = false;
        //     let showNew = menudef.link === '/webmap' || menudef.link === '/receptorsummary';
        //
        //     if (menudef.perm === 'client') {
        //         if (menudef.link === '/webmap') {
        //             hasPermission = sitedata.checkWriteAccess(this.props,menudef.perm);
        //         } else {
        //             hasPermission = !!clientLibraries && clientLibraries.length > 1;
        //         }
        //     } else if (menudef.perm === 'enviddata') {
        //         let writePerm = sitedata.checkWriteAccess(this.props,menudef.perm);
        //         let readPerm = sitedata.checkReadAccess(this.props,menudef.perm);
        //         hasPermission =  readPerm || writePerm;
        //         readonly = readPerm && !writePerm;
        //     } else {
        //         hasPermission = menudef.perm === 'help' || sitedata.checkReadAccess(this.props,menudef.perm);
        //     }
        //
        //     if (hasPermission) {
        //         navs.push(
        //           (<li className={this.activeRoute(menudef.link)} key={menudef.perm+"-"+i}>
        //               <Link to={menudef.link}>{sitedata.lookupLinkIcon(menudef.link)}
        //                   <span className="nav-label">{sitedata.lookupLinkName(menudef.link + (readonly ? '-readOnly':''))}</span>
        //                   {showNew && (
        //                     <>
        //                         <span>&nbsp;&nbsp;</span>
        //                         <span className="label" style={{backgroundColor:'#ad1f8d', color:'white'}}>{t("NEW")}</span>
        //                     </>
        //                   )}
        //               </Link>
        //           </li>
        //         ));
        //     }

            // if(perms[i] === "client"){
            //     let clientLibraries = sitedata.getClientLibraries(this.props);
            //     let link = links[i];
            //
            //     //webmap should be visible to company user, company admin and super user. corresponds to write-client.
            //     if (link === '/webmap') {
            //         if (sitedata.checkWriteAccess(this.props,perms[i])) {
            //             navs.push(
            //                 (
            //                     <li className={this.activeRoute(link)} key={perms[i] + "-" + i}>
            //                         <Link to={link}>{sitedata.lookupLinkIcon(link)}<span
            //                             className="nav-label">{sitedata.lookupLinkName(link)}</span>
            //                             &nbsp;&nbsp;
            //                             <span className="label" style={{backgroundColor:'#ad1f8d', color:'white'}}>{t("NEW")}</span>
            //                         </Link>
            //
            //                     </li>
            //                 )
            //             )
            //         }
            //     } else {
            //         if (clientLibraries !== null && clientLibraries !== undefined) {
            //             if (clientLibraries.length > 1) {
            //                 navs.push(
            //                     (
            //                         <li className={this.activeRoute(link)} key={perms[i] + "-" + i}>
            //                             <Link to={link}>{sitedata.lookupLinkIcon(link)}<span
            //                                 className="nav-label">{sitedata.lookupLinkName(link)}</span></Link>
            //                         </li>
            //                     )
            //                 );
            //             }
            //             if (clientLibraries.length === 1) {
            //                 let cl = clientLibraries[0];
            //                 if (cl.libraries.length > 1) {
            //                     navs.push(
            //                         (
            //                             <li className={this.activeRoute(link)} key={perms[i] + "-" + i}>
            //                                 <Link to={link}>{sitedata.lookupLinkIcon(link)}<span
            //                                     className="nav-label">{sitedata.lookupLinkName(link)}</span></Link>
            //                             </li>
            //                         )
            //                     )
            //                 }
            //             }
            //         }
            //     }
            // }
            // else if(perms[i] === "enviddata")
            // {
            //
            //     let readOnly = sitedata.checkReadAccess(this.props, perms[i]) && !sitedata.checkWriteAccess(this.props,perms[i]);
            //     if(readOnly){
            //         let link = links[i];
            //         navs.push(
            //             (
            //                 <li className={this.activeRoute(link)} key={perms[i] + "-" + i}>
            //                     <Link to={link}>{sitedata.lookupLinkIcon(link)}<span
            //                         className="nav-label">{sitedata.lookupLinkName(link + "-readOnly")}</span></Link>
            //                 </li>
            //             )
            //         );
            //     }else if(sitedata.checkWriteAccess(this.props,perms[i])) {
            //         let link = links[i];
            //         navs.push(
            //             (
            //                 <li className={this.activeRoute(link)} key={perms[i]+"-"+i}>
            //                     <Link to={link}>{sitedata.lookupLinkIcon(link)}<span
            //                         className="nav-label">{sitedata.lookupLinkName(link)}</span></Link>
            //                 </li>
            //             )
            //         );
            //     }
            // }
            // else {
            //
            //     if (perms[i] === "help" || sitedata.checkReadAccess(this.props, perms[i])) {
            //         var link = links[i];
            //
            //         navs.push(
            //             (
            //                 <li className={this.activeRoute(link)} key={perms[i]+"-"+i}>
            //                     <Link to={link}>{sitedata.lookupLinkIcon(link)}
            //                         <span className="nav-label">{sitedata.lookupLinkName(link)}</span>
            //                         {link === '/receptorsummary' && (
            //                           <>
            //                         <span>&nbsp;&nbsp;</span>
            //                         <span className="label" style={{backgroundColor:'#ad1f8d', color:'white'}}>{t("NEW")}</span>
            //                           </>
            //                             )}
            //                     </Link>
            //                 </li>
            //             )
            //         );
            //     }
            // }

       // }

        return (
            <nav className="navbar-default navbar-static-side" role="navigation">
                <div className="slimScrollDiv" style={{position: "relative", overflow: "hidden", width:"auto", height:"100%"}}>
                    <div className="sidebar-collapse" style={{overflow: "hidden", width: "auto", height:"100%"}}>
                        <ul className="nav d-flex flex-column metismenu full-height" id="side-menu" ref={this.menuRef} style={{paddingBottom: '20px'}}>
                            <li className="nav-header" style={{padding: '8px 8px 0 20px'}}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', rowGap:'8px', alignItems: 'flex-start'}}>
                                    <img style={{maxWidth: '70%', maxHeight: '100%', objectFit: 'contain'}}
                                         alt={'Xamin eBase'} src={NavAppMainImage}/>
                                    <img style={{maxWidth: '50%', maxHeight: '100%', objectFit: 'contain'}}
                                         alt={'Xamin environmental management'} src={NavAppSubImage}/>
                                </div>
                            </li>
                            <li className="nav-header" style={{paddingBottom: '20px'}}>
                                <div className="dropdown profile-element">
                                    <div style={{width: 48, height: 48}}>
                                        <img alt="image" className="img-circle" src={sitedata.getClientLogo(this.props)}
                                             style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain'}}/>
                                    </div>
                                    <a className="text-decoration-none" href="#">
                                         <span className="clear">
                                             <span className="block m-t-xs">
                                                <strong className="font-bold">{sitedata.getUserFullName(this.props)}</strong>
                                             </span>
                                             <span className="text-xs block">{sitedata.getUserPosition(this.props)}</span>
                                        </span>
                                    </a>
                                </div>
                                <div className="logo-element">
                                    eBase
                                </div>

                            </li>
                            {this.renderMenuDefs(menuDefs, 1)}
                            <li style={{flexGrow: 1}}></li>
                            {this.renderMenuDefs(botMenuDefs, 1)}
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default withTranslation()(Navigation)

import React from 'react';
import {Row,Col} from 'react-bootstrap'
import details from '../utils/details'
import EButton from "../components/common/EButton"
import envid from "../utils/envid"
import moment from 'moment';
import {ObjectionStatusLabel} from '../components/common/StakeholderUtils'
import {EventSectionTitle} from '../components/common/StakeholderUtils'
import {getEventTypeIcon} from '../components/common/StakeholderEventType'
import {withTranslation} from "react-i18next";

class StakeholderObjectionView extends React.Component {

    gotoEvent(event_id)
    {
        this.props.onClick(event_id);
    }

    render() {

        const {t} = this.props;

        let objection = this.props.objection;
        if (objection == null)
        {
            return <div>{t("Objection not found")}</div>
        }

        let name = envid.getObjectField(objection, 'name');
        let desc = envid.getObjectField(objection, 'desc');
        let status = envid.getObjectField(objection, 'status');
        let created_date = envid.getObjectField(objection, 'creation_time');

        if (created_date != null)
        {
            created_date = moment(created_date).format('h:mma D MMM YYYY');
        }

        let timelineItems = [];
        let objectionLinks = this.props.objectionLinks;

        for (let i = 0; i<objectionLinks.length; ++i)
        {

            let is_first = (i === 0);

            let link = objectionLinks[i];
            let event = envid.getObjectFromLink(this.props.objectLists, link, "StakeholderEvent");

            let icon = getEventTypeIcon(envid.getObjectField(event, "event_type"));
            let style = {background: "#21A884", color: "white"};


            let complete_date = moment(envid.getObjectField(link, "completion_date"));

            let extra = null;
            if (i===0 && status === "SATISFIED")
            {
                timelineItems.push(
                    <Row className="timeline-item">
                        <Col xs={4} className="date">
                            <i className="fa fa-check" style={style}></i>
                            {complete_date.format("D MMM YYYY")}
                            <br/>
                            <small className="text-navy">{complete_date.fromNow()}</small>
                        </Col>


                        <Col xs={8} className={"content no-top-border"}>
                            <Row style={{marginTop: -7}}>
                                <Col md={4}>
                                    <ObjectionStatusLabel status={status}/>
                                </Col>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Col md={12}>
                                    {t("Automatically became Satisfied on {date}", {date:complete_date.format("D MMM YYYY")})}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                );
                is_first = false;
            }
            else if (i === 0 && (status === "NO_MERIT" || status === "ACTION_COMPLETED"))
            {
                extra = <span><i>({t("Will become Satisfied on {date}", {date:complete_date.format("D MMM YYYY")})}</i></span>

                // extra =  (
                //     <Row style={{marginTop: 5, marginBottom:10}}>
                //         <Col md={12}>
                //             <i>Will become Satisfied on {complete_date.format("D MMM YYYY")}</i>
                //         </Col>
                //      </Row>);

            }

            let date = moment(envid.getObjectField(link, "creation_time"));
            let item_left = (
                <Col xs={4} className="date">
                    <i className={" fa " + icon} style={style}></i>
                    {date.format("D MMM YYYY")}
                    <br/>
                    <small className="text-navy">{date.fromNow()}</small>
                </Col>
            );

            let stakeholders_short = details.getStakeholderList(event.details.stakeholders, this.props.objectLists["Stakeholder"], "short");

            let item_right = null;
            item_right = (
                <Col xs={8} className={"content " + (is_first ? "no-top-border" : "")} style={{cursor:"pointer"}} onClick={this.gotoEvent.bind(this, event.object_id)}>
                    <Row style={{marginTop: -7}}>
                        <Col md={12}>
                            <ObjectionStatusLabel status={envid.getObjectField(link, "new_status")}/> {extra}
                        </Col>
                    </Row>
                    <Row style={{marginTop: 10}}>
                        <Col md={12}>
                            Stakeholders: {stakeholders_short}
                        </Col>
                    </Row>
                    <Row style={{marginTop: 5}}>
                        <Col md={12}>
                            Subject: {envid.getObjectField(event, "name")}
                        </Col>
                    </Row>
                </Col>
            );

            timelineItems.push(
                <Row className="timeline-item">
                        {item_left}
                        {item_right}
                </Row>
            );

        }

        return (
            <div>
                <div className="mail-box-header">

                    <div className="pull-right tooltip-demo">
                        <EButton type="back" isTable={true}
                                 onClick={this.props.back}>{t("Back")}</EButton>
                    </div>

                    <h2>
                        {name}
                    </h2>
                    <span className="font-normal font-noraml">{t("Current Status")}: <ObjectionStatusLabel status={status}/></span>
                    <span className="font-normal font-noraml pull-right">{created_date}</span>

                </div>

                <div className="mail-box">
                    <div className="mail-body">
                        <EventSectionTitle><i className="fa fa-file-text-o"></i> {t("Description")}</EventSectionTitle>
                        <p dangerouslySetInnerHTML={{__html: (desc == null) ? t("No Description") : desc.text}}/>
                    </div>

                    <div className="mail-body">
                        <EventSectionTitle><i className="fa fa-clock-o"></i> {t("Timeline")}</EventSectionTitle>
                        <div className="inspinia-timeline">
                            {timelineItems}
                        </div>
                    </div>

                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(StakeholderObjectionView)

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import rest_backend from './i18n/rest-backend'

//import LanguageDetector from 'i18next-browser-languagedetector';
//import resources from 'strings/index';
import moment from 'moment';

i18n
//  .use(LanguageDetector)
  .use(rest_backend)
  .use(initReactI18next)
  .init({
    detection:{
      excludeCacheFor:['cimode'],
    },
    fallbackLng: 'en',
    lng:'en',
    debug: true,
    defaultNS:'translation',
    fallbackNS:'translation',
    //resources: resources,
    saveMissing: true,
    keySeparator: false,

    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if(value instanceof Date) {
          if (format === "fromNow") {
            return moment(value).locale(lng).fromNow();
          }
          if (format === "fullDateTime"){
            return moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a");
          }
        }
        if (format === 'uppercase') return value.toLocaleUpperCase(lng);
        return value;
      },
    },
}, (error => console.log('i18n error', error)));

export default i18n;

import React, {useEffect, useState} from 'react';
import ContainerHeader from '../components/common/ContainerHeader';
import PageWrapper from '../components/common/PageWrapper';
import EbasePanel from '../components/common/EbasePanel';
import {Table, Form} from 'react-bootstrap';
import Spinner from '../components/common/Spinner';
import EButton from '../components/common/EButton';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {TemplateManager} from '../utils/TemplateManager';
import SessionManager from '../utils/SessionManager';

const TemplatesAssignView = (props) => {

  const {t} = useTranslation();

  const templates = useSelector((state) => state.templates.templates);
  const isLoading = useSelector((state) => state.templates.isLoading);
  const dimensions = useSelector((state) => state.session.dimensions);
  const siteData = useSelector((state)=> state.session.siteData);

  const [assigned, setAssigned] = useState({});
  const [saving, setSaving] = useState();
  const [client, setClient] = useState();
  const [library, setLibrary] = useState();
  const [libraryConfig, setLibraryConfig] = useState();

  const clientId = props.match?.params?.clientId;
  const dataSetId = props.match?.params?.dataSetId;

  const backLink = '/client/'+clientId;

  const assignTemplate = (template_id, assign) => {
    let newAssigned = {...assigned};
    newAssigned[template_id] = assign;
    setAssigned(newAssigned);
  }

  const assignAll = (use) => {
    let newAssigned = {...assigned};
    Object.keys(assigned).forEach(tid=>newAssigned[tid]=use);
    setAssigned(newAssigned);
  }

  const saveAssignments = () => {
    let tids = Object.keys(assigned).filter(tid=>assigned[tid]);

    setSaving(true);
      TemplateManager.updateClientTemplates(clientId, dataSetId, tids).then(()=>{
        SessionManager.shared().checkSession();
        SessionManager.shared().goto(backLink);
    }).catch(error => {
      SessionManager.shared().errorHandler(t('Error Saving Template Assignments') +" - " + error, error);
    }).finally(()=>{
      setSaving(false);
    })
  }

  const cancelAssignments = () => {
    SessionManager.shared().goto(backLink);
  }

  useEffect(() => {
    if (!templates && !isLoading) {
      TemplateManager.loadReportTemplates();
    }
  }, [templates, isLoading]);

  useEffect(()=> {
    let client;
    if (siteData) {
      client = siteData?.clientLibraries?.find(cl => {
        return cl.client?.client_id === clientId;
      })
    }
    setClient(client);
  }, [siteData]);

  useEffect(()=> {
    if (client) {
      setLibrary(client.libraries.find(l=>l.dataset_id === dataSetId));
      setLibraryConfig(client.libraryConfig[dataSetId] ?? {});
    } else {
      setLibrary(undefined);
      setLibraryConfig(undefined);
    }
  }, [client]);

  useEffect( () => {
    let newAssigned = {};

    if (libraryConfig) {
      let templateIds = libraryConfig?.templateIds;

      if (templates) {
        templates.forEach(template => {
          let tid = template.template_id;
          let curAssign = assigned[tid];
          let isAssigned = !templateIds ? template.built_in : templateIds.find(
            i => i === tid);
          newAssigned[tid] = curAssign ?? isAssigned;
        });
      }

    }

    setAssigned(newAssigned);
  }, [templates, libraryConfig]);

  let body;


  // console.log("USersView;:");
  // console.log(this.props);

  if (isLoading || saving) {
    body = <Spinner/>;
  } else {

    if (!templates || !templates.length) {
      body = <tbody>
      <tr>
        <td>{t('No Data')}</td>
      </tr>
      </tbody>;
    } else {

      templates.sort((a, b) => {

        let ret = a.report_type.localeCompare(b.report_type);
        if (ret === 0) {
          ret = a.name.localeCompare(b.name);
        }
        if (ret === 0) {
          ret = a.description.localeCompare(b.description);
        }
        if (ret === 0) {
          ret = a.type.localeCompare(b.type);
        }
        if (ret === 0) {
          ret = a.template_id.localeCompare(b.template_id);
        }
        return ret;

      });

      const rows = templates.map((template) =>

        <tr key={template.template_id}>
          <td style={{textAlign:'center'}}>
            <Form.Check id={template.template_id+'_use'}
                      className="icheckbox_minimal-green"
                      checked={!!assigned[template?.template_id]}
                      onChange={(ev, checked) => assignTemplate(template.template_id, checked)}>
            </Form.Check>
          </td>
          <td>{template.report_type}</td>
          <td>{template.name}</td>
          <td>{template.type}</td>
          <td style={{textAlign:'center'}}>{template.default && (<i className="fa fa-check"/>)}</td>
          <td style={{textAlign:'center'}}>{template.built_in && (<i className="fa fa-check"/>)}</td>
        </tr>,
      );
      body = <tbody>{rows}</tbody>;
    }

    body = (
      <Table className="table table-hover">
        <thead>
        <tr>
          <th style={{textAlign:'center'}}>{t('Use')}</th>
          <th>{t('Report')}</th>
          <th>{t('Name')}</th>
          <th>{t('Type')}</th>
          <th style={{textAlign:'center'}}>{t('Default')}</th>
          <th style={{textAlign:'center'}}>{t('Built In')}</th>
        </tr>
        </thead>
        {body}
      </Table>
    );
  }

  let title = t('Assign Templates') + ' - ' + library?.desc ?? '';

  return (
    <div>
      <ContainerHeader title={title} crumbs={['/', backLink, t('Assign Templates')]}
                       dimensions={dimensions}/>
      <PageWrapper dimensions={dimensions}>
        <EbasePanel>
          <div style={{ display: 'flex', flexDirection: 'row',paddingTop:10, borderTop:'1px solid #eaeaea'}}>
            <EButton onClick={()=>assignAll(true)} type="done">{t('Select All')}</EButton>
            <EButton style={{marginLeft:25}} onClick={()=>{assignAll(false)}} type="cancel">{t('Deselect All')}</EButton>
          </div>
          {body}
          <div style={{ display: 'flex', flexDirection: 'row',paddingTop:10, borderTop:'1px solid #eaeaea'}}>
            <EButton onClick={cancelAssignments} type="cancel">{t('Cancel')}</EButton>
            <EButton style={{marginLeft:25}} onClick={saveAssignments} type="done">{t('Done')}</EButton>
          </div>
        </EbasePanel>
      </PageWrapper>
    </div>
  );

};

export {TemplatesAssignView};

import React from 'react';

class TaskProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const radius = this.props.radius - this.props.strokeWidth / 2;
        const width = this.props.radius * 2;
        const height = this.props.radius * 2;
        const viewBox = "0 0 " + width + " " + height;
        const dashArray = radius * Math.PI * 2 ;
        const dashOffset = dashArray - dashArray * this.props.percentage / 100;

        let colour = "#1ab394";
        let bg = {stroke: "#ddd", fill:"none"};
        let fg = {transition: "stroke-dashoffset .5s ease-in-out",
                  stroke: colour,
                  strokeDasharray: dashArray,
                  strokeDashoffset: dashOffset,
                  fill:"none"
        };

        return (
            <svg
                className="TaskProgress"
                width={this.props.radius * 2}
                height={this.props.radius * 2}
                viewBox={viewBox}>
                <circle
                    style = {bg}
                    cx={this.props.radius}
                    cy={this.props.radius}
                    r={radius}
                    strokeWidth={this.props.strokeWidth+"px"} />
                <circle
                    style = {fg}
                    cx={this.props.radius}
                    cy={this.props.radius}
                    r={radius}
                    transform="rotate(-90) translate(-70, 0)"
                    strokeWidth={this.props.strokeWidth+"px"}
                    />
                <text
                    className="TaskProgress-Text"
                    style={{fill: colour}}
                    x={this.props.radius}
                    y={this.props.radius}
                    dy=".4em"
                    textAnchor="middle">
                    {`${this.props.percentage}%`}
                </text>
            </svg>
        );
    }
}

TaskProgress.defaultProps = {
    strokeWidth: 20,
    radius: 40,
    percentage: 60,
};
export default TaskProgress
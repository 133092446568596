import React from 'react'
import ebase from '../../utils/ajax'
import DataDetails from '../../views/DataDetails'
import sitedata from '../../utils/sitedata'
import details from '../../utils/details'
import update from 'immutability-helper';
import ReactTimeout from 'react-timeout'

/**
 * This compnent is the container for the details, these will be rendered from a template defined in details.js
 *
 * props
 *   sitedata
 *   updateObject : function(object) - call to update the object in the state, pass up a new object
 *   updateSaveStatus : function(status) - updates the saved status of this object. The parent uses this to display a saved status
 *   currentObject - the current object
 *   fields - fields from the template
 *
 */
class DataDetailsContainer extends React.Component{


    constructor(){
        super();

        this.state = {
            changedFields: {},
            savingFields: {},
            internalIsSaving: false,
            savedStatus: "unchanged",
            last_update_time: 0
        };
        this.updateField = this.updateField.bind(this);
        this.saveFields = this.saveFields.bind(this);
        this.saveNextField = this.saveNextField.bind(this);
        this.saverTimeout = this.saverTimeout.bind(this);
        this.mounted = true;
        this.updateArrayField = this.updateArrayField.bind(this);
    }

    componentDidMount()
    {
        this.props.clearInterval();
        this.props.setInterval(this.saverTimeout, 1000);
    }

    componentWillUnmount()
    {
        // make sure eveything is saving, before we get out of here.
        this.saveFields();
        this.mounted = false;
    }


    /*******************************************************************************
     *
     * SAVING DETAILS METHODS
     *
     *******************************************************************************/

    saverTimeout()
    {
        var changedFields = this.state.changedFields;
        let isNoChangedFields = Object.keys(changedFields).length === 0 && changedFields.constructor === Object;

        // if there are changes and it has been 5 secons since our last change, then save it
        if (!isNoChangedFields && !this.state.internalIsSaving)
        {
            if (this.state.last_update_time + 2500 < Date.now())
            {
                this.saveFields();
            }
        }
    }

    saveSuccess(key, result)
    {
        if (!this.mounted)
        {
            // the problem with this, is we might have more things to save
            console.log("----------- Unmounted ignoring ----------------");
            if (this.state.savingFields.length > 1) {
                console.log("----------- Trouble, too many things saving ----------------");
                console.log(this.state.savingFields);
            }

            // perhaps assume that the first one has saved, then save the rest
            return;
        }

        // the result will contain any updates we need to apply
        var updates = result.data;

        var savingFields = Object.assign({}, this.state.savingFields)
        delete savingFields[key];

        //update last edit time and saving fields
        var updatedObject = update(this.props.currentObject, {object: {documentInfo: {last_update_time:{$set: new Date().getTime()}}}});

        if (updates != null) {
            for (var i in updates)
            {
                let fu = updates[i];

                if (updatedObject.object.object_id === fu.object_id) {
                    let u = fu.field;
                    //console.log("  fu.field: "+JSON.stringify(fu.field,null,"  "));
                    let v = details.getValue(updatedObject.object, u.name);
                    //console.log("   v: "+v);
                    if (v != null && (typeof v === 'string' || v instanceof String) && v.startsWith("create:")) {
                        // we need to add a new item into the list for this object
                        var s = v.split(":");

                        let list = updatedObject.objectLists[s[1]];
                        if (list != null) {
                            list[u.value] = {name: s[2], object_id: u.value};
                        }
                        else{
                            updatedObject.objectLists[s[1]] = {key:{name: s[2], object_id: u.value}};
                        }
                    }

                    updatedObject = update(updatedObject, details.getUpdate(u.name, u.value));
                }
                else
                {
                    let u = fu.field;

                    //console.log("Found Dependant Object " + fu.type);
                    // this is another object, if it's in our list update it.
                    let list = updatedObject.objectLists[fu.type];
                    if (list != null) {
                        let obj = list[fu.object_id];
                        if (obj != null)
                        {
                            //console.log("  Updating " + u.name + " = " + u.value);
                            try {
                                update(obj, details.getUpdate(u.name, u.value));
                            } catch (e) {

                            }
                        }
                    }

                }
            }
        }
        updatedObject.last_update_user = sitedata.getUser(this.props);
        updatedObject.object.documentInfo.last_update_user = updatedObject.last_update_user.user_id;

        this.props.updateObject(updatedObject);
        this.setState({savingFields: savingFields});
        this.state.internalIsSaving = false;
        this.saveNextField();

        if(result.success)
        {
            //console.log("Saved - " + key);
        }
        else
        {
            console.log(result.error);
        }

    }

    saveError(request, textStatus, errorThrown, original_request)
    {

        this.props.updateSavedStatus(this.props.currentObject.object.object_id, "error");
        this.props.siteData.ajaxSaveError(request, textStatus, errorThrown, original_request);
        console.log(request);

        if (!this.mounted)
        {
            console.log("----------- Unmounted ignoring ----------------");

            return;
        }

        this.state.internalIsSaving = false;
        // don't remove it, and show an error
        this.setState({savedStatus:"error"});
    }

    saveNextField()
    {
        if (this.state.internalIsSaving)
        {
            console.log("Already Saving");
            return ;
        }

        this.state.internalIsSaving = true;

        var fields = this.state.savingFields;

        var fieldKey = null;
        for (var key in fields) {
            if (key != null) {
                fieldKey = key;
            }
        }

        if (fieldKey == null) {
            this.setState({savedStatus: "saved"});
            this.props.updateSavedStatus(this.props.currentObject.object.object_id, "saved");

            this.state.internalIsSaving = false;
            return;
        }

        var field = fields[key];

        ebase.ajax({
            url:"/ebase/envid-admin/write-enviddata-updatefield",
            data:{object_id:field.object_id, field: {name:field.field, value:field.value, list:field.list}},
            success: this.saveSuccess.bind(this, key),
            error:   this.saveError.bind(this)
        });

    }

    saveFields()
    {
        // save all the fields that have changed
        var changedFields = this.state.changedFields;
        var savingFields = update(this.state.savingFields, {$merge : changedFields});


        this.state.savingFields = savingFields;
        this.setState({changedFields:{}, savingFields:savingFields, savedStatus:"saving"});
        this.props.updateSavedStatus(this.props.currentObject.object.object_id, "saving");


        //console.log("saveFields changedFields = ");
        //console.log(this.state.changedFields);
        // console.log("saveFields savingFields = ");
        // console.log(this.state.savingFields);

        this.saveNextField();
    }

    updateField(fieldName, value)
    {
        if (this.props.currentObject == null) {
            console.log("---------- no currentObject ----------");
            return;
        }

        // the object maybe one of the linkInfo objects, in which case we need to
        // save this rather then the main object

        //console.log("Update Field " + fieldName);
        let object_id = this.props.currentObject.object.object_id;
        let oldValue = details.getValue(this.props.currentObject.object, fieldName);
        if (oldValue === value) {
            // console.log("Unchanged Field " + fieldName);
            return;
        }

        //console.log("Update Field " + fieldName);

        // no need to remder for this guy
        this.state.last_update_time = Date.now();

        var changes = {savedStatus:"changed"};
        // let names = this.props.currentObject.objectLists["ControlName"];
        // if (names !== null && names !== undefined){
        //     console.log(JSON.stringify(names,null,"  "));
        // }else{
        //     console.log("    names is null")
        // }

        let currentObject = update(this.props.currentObject, details.getUpdate(fieldName, value));

        // names = this.props.currentObject.objectLists["ControlName"];
        // if (names !== null && names !== undefined){
        //     console.log(JSON.stringify(names,null,"  "));
        // }else{
        //     console.log("    names is null")
        // }

        try {
            if (value != null && (typeof value === 'string' || value instanceof String) && value.startsWith("change:")) {
                let object_id = value.split(":")[1];
                let new_name = value.split(":")[2];

                //we're changing a name, we need to change the optoins list
                let lists = currentObject.objectLists;
                for (var list in lists) {
                    for (var id in lists[list]) {
                        if (object_id === id) {
                            lists[list][id].name = new_name;
                        }
                    }
                }
            }
        } catch (e) {
            console.log("Error checking for change: in value");
            console.log(e);
        }

        var fchanges = {};
        if( Object.prototype.toString.call( value ) === '[object Array]' ) {
            fchanges[details.makeId(object_id, fieldName)] = {object_id:object_id, field:fieldName, list:value};
        } else {
            fchanges[details.makeId(object_id, fieldName)] = {object_id:object_id, field:fieldName, value:value};
        }
        changes.changedFields = update(this.state.changedFields, {$merge: fchanges});

        // this will call the parent container to update the actual object. This will re render the object,
        // so perhaps we should change the state without a rerender
        if (fieldName === "details.impact_id")
        {
            // if they change the impact id, then we need to reaload the links for this object
            console.log("Updating Link Change Time");
            this.props.updateObject(currentObject, {changeLinkTime:(new Date()).getTime()});

        }
        else {
            this.props.updateObject(currentObject);
        }
        this.props.updateSavedStatus(this.props.currentObject.object.object_id, "changed");
        this.setState(changes);
        this.state.changedFields = changes.changedFields;

    }


    /**
     * Update a field where the value of that field is an array of objects.
     arrayName = name of array in object
     index     = index in array of object want to update
     objfield  = field of object in array want to update
     newVale   = the value we are setting for the objField
     objUpdate = a whole new object of the same structure that makes up the array we're updating.
                 Useful for adding/replacing a whole object in the array.
     isRemove  = boolean, if true the object at index is removed from the array.
     */
    updateArrayField(arrayName,index,objField,newValue,objUpdate = null,isRemove = false){

        // console.log("   ~~~~~ UpdateArrayField ~~~~~~");
        // console.log("    arrayName: "+arrayName);
        // console.log("    index    : "+index);
        // console.log("    objField : "+objField);
        // console.log("    newValue : "+newValue);
        // console.log("    objUpdate: "+JSON.stringify(objUpdate,null," "));
        // console.log("    isRemove : "+isRemove);
        // console.log("   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~");

        if (this.props.currentObject == null) {
            console.log("---------- no currentObject ----------");
            return;
        }

        //console.log("Update array " + arrayName);
        let object_id = this.props.currentObject.object.object_id;
        let oldArray = details.getValue(this.props.currentObject.object, arrayName);


        let currentObject = null;
        let updateValue = [];

        if(isRemove === true){

            if(oldArray !== null && oldArray !== undefined){

                oldArray.splice(index,1);

                updateValue = oldArray;

                currentObject = update(this.props.currentObject, details.getUpdate(arrayName, oldArray));

                this.props.updateObject(currentObject);

            }


        }else if(objUpdate !== undefined && objUpdate !== null){

            //Adding a whole new object into the array.

            if(oldArray === null || oldArray === undefined){

                currentObject = update(this.props.currentObject, details.getUpdate(arrayName, [objUpdate]));

                // console.log("current object after objUpdate");
                // console.log(currentObject);

                updateValue = [objUpdate];


            }else{
                if(index < 0){
                    //add onto the end
                    oldArray.push(objUpdate);
                    currentObject = update(this.props.currentObject, details.getUpdate(arrayName, oldArray));

                    // console.log("current object after objUpdate");
                    // console.log(currentObject);

                    updateValue = oldArray;

                    this.props.updateObject(currentObject);

                }else {

                    updateValue = oldArray.splice(index,0,objUpdate);
                    currentObject = update(this.props.currentObject, details.getUpdate(arrayName, updateValue));

                    // console.log("current object after objUpdate");
                    // console.log(currentObject);

                    this.props.updateObject(currentObject);
                }
            }
        }else {

            //update the field of obj at index in oldArray with new valu

            oldArray[index][objField] = newValue;
            currentObject = update(this.props.currentObject, details.getUpdate(arrayName, oldArray));

            // console.log("current object after filed update");
            // console.log(currentObject);

            updateValue = oldArray;

            this.props.updateObject(currentObject);
        }


        var fchanges = {};
        var changes = {savedStatus: "changed"};

        // console.log("updateValue");
        // console.log(updateValue);

        fchanges[details.makeId(object_id, arrayName)] = {
            object_id: object_id,
            field: arrayName,
            value: JSON.stringify(updateValue)
        };
        changes.changedFields = update(this.state.changedFields, {$merge: fchanges});

        this.props.updateSavedStatus(this.props.currentObject.object.object_id, "changed");
        this.setState(changes);
        this.state.changedFields = changes.changedFields;

    }



    /*******************************************************************************
     *
     * RENDER METHOD
     *
     *******************************************************************************/
    render()
    {
        return (
            <DataDetails updateField={this.updateField}
                         saveFields={this.saveFields}
                         currentObject={this.props.currentObject}
                         fields={this.props.fields}
                         updateContactList={this.updateArrayField}/>
        )
    }


}

// DataDetailsContainer.contextTypes = {
//     router: function contextType() {
//         return PropTypes.func.isRequired;
//     }
// };

export default ReactTimeout(DataDetailsContainer)



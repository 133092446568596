
import details from '../../utils/details'
import update from 'immutability-helper';
import envid from '../../utils/envid';

const sort_events = (a, b) => { return b.details.timestamp - a.details.timestamp;}

const checkActionValid = function(action)
{

    if (action.details.role_id == null ) return false;

    if (action.details.name == null) return false;
    if (action.details.name.length === 0) return false;
    //if (action.details.name == "New Action") return false;

    return true;


}

const changeEventActionCount = function(state_events, action, count)
{
    let events = state_events.slice();

    for (let i = 0; i < events.length; ++i) {
        if (events[i].object_id === action.details.event_id) {
            events[i] = {...events[i], details:{...events[i].details, action_count:events[i].details.action_count + count}};
            break;
        }
    }

    console.log(events);
    return events;


}

export default function comms(state = {isLoading:true, isObjectLoading:false, events:[],actions:[],allActions:[],eventSearchTerm:"",eventSearchResults:[], changes:{savedStatus:"unchanged", changedFields:{}}}, action) {

    switch (action.type)
    {
        case "LOAD_SHEVENTS_START": {
            // console.group(" -->> LOAD_SHEVENTS_START LIST");
            // console.groupEnd();
            return {...state, isLoading:true};
        }

        case "LOAD_SHEVENTS_DONE": {

            let data = action.result.data;
            let events = [];
            let eventMap = data.objectLists["StakeholderEvent"];
            if (eventMap != null)
            {
                events = Object.values(eventMap);
                events = events.sort(sort_events)
            }
            // console.group(" -->> LOAD_SHEVENTS_DONE LIST");
            // console.groupEnd();
            return {...state, events: events, objectLists:data.objectLists, isLoading: false, isObjectLoading:false};
        }

        case "LOAD_SHEVENTS_FAILED": {

            return {...state, error:action.error, isLoading: false, isObjectLoading:false};
        }

        case "LOAD_SHEVENT_START": {
            // console.group("  LOAD_SHEVENT_START SINGLE");
            // console.groupEnd();
            return {...state, isObjectLoading:true, selectedObject:null, actions:null};
        }

        case "LOAD_OBJECTION_START": {

            return {...state, isObjectLoading:true, selectedObject:null};
        }

        case "LOAD_OBJECTION_DONE": {

            let data = action.result.data;

            let objectionLinkMap = data.objectLists["StakeholderEventObjectionLink"];
            let objectionLinks = [];
            if (objectionLinkMap != null)
            {
                objectionLinks = Object.values(objectionLinkMap).sort((a, b) => envid.getObjectField(b, 'creation_time') - (envid.getObjectField(a, 'creation_time')));
            }

            return {...state, selectedObject: data.object, isObjectLoading: false, objectionLinks:objectionLinks, objectLists:data.objectLists};
        }

        case "LOAD_SHEVENT_DONE": {

            let data = action.result.data;
            let actionsMap = data.objectLists["StakeholderAction"];
            let actions = [];
            if (actionsMap != null)
            {
                actions = Object.values(actionsMap).sort((a, b) => envid.getObjectField(a, 'name').localeCompare(envid.getObjectField(b, 'name')));
            }

            let objectionLinkMap = data.objectLists["StakeholderEventObjectionLink"];
            let objectionLinks = [];
            if (objectionLinkMap != null)
            {
                objectionLinks = Object.values(objectionLinkMap).sort((a, b) => envid.getObjectField(a, 'name').localeCompare(envid.getObjectField(b, 'name')));
            }
            // console.group("LOAD_SHEVENT_DONE SINGLE")
            // console.log("result objectLists",action.result.data.objectLists);
            // console.log("state objectLists: ",state.objectLists);


            let lists = {...state.objectLists};
            let types = Object.keys(data.objectLists)
            for (var k in types){
                let type = types[k];
                lists[type] = data.objectLists[type];
            }

            // console.log("new list: ",lists);
            // console.groupEnd()

            return {...state, objectLists: lists, selectedObject: data.object, isObjectLoading: false, actions:actions, selectedAction:null, objectionLinks:objectionLinks};
        }

        case "EDIT_SHACTION_DONE": {
            return {...state, selectedAction:null, selectedActionValid:null};
        }

        case "NEW_SHACTION_DONE" : {

            let new_action = action.result.data.object;

            let actions = state.actions.slice();
            actions.splice(0, 0, new_action);

            let events = changeEventActionCount(state.events, new_action, +1);

            return {...state, actions: actions, selectedAction: new_action.object_id, selectedActionValid: false, events:events};


        }

        case "REMOVE_SHACTION_DONE":
        {
            let action_id = action.action_id;

            // check the actions
            let events = state.events;
            let actions = state.actions.slice();

            for (let i = 0; i < actions.length; ++i) {
                if (actions[i].object_id === action_id) {
                    console.log("SPLICING");
                    events = changeEventActionCount(state.events, actions[i], -1);

                    actions.splice(i, 1);
                    break;
                }
            }


            return {...state, actions: actions, events:events};

        }

        case "NEW_SHEVENT_START": {
            return {...state, isObjectLoading:true};
        }

        case "NEW_SHEVENT_DONE": {
            // slot the new object into the various stuff
            console.log("NEW_SHEVENT_DONE");
            console.log(action);

            let new_object = action.result.data.object;

            let events = [];
            if(state.events != null) events = state.events.slice();

            events.splice(0, 0, new_object);
            events = events.sort(sort_events)

            console.log({...state, events:events, selectedObject:new_object, isObjectLoading: false});
            return {...state, events:events, selectedObject:new_object, isObjectLoading: false}

        }

        case "NEW_SHEVENT_FAILED": {
            return {...state, isObjectLoading:false};
        }


        case "UPDATE_SHEVENTS_FIELD":
        {
            // console.log("UPDATE_SHEVENTS_FIELD");
            // console.log(action);

            let object_id = action.object_id;
            let field = action.field;
            let value = action.value;

            let updateObject = details.getUpdate(field, value);

            let events = state.events;
            let event = state.selectedObject;
            let actions = state.actions;
            let selectedActionValid = state.selectedActionValid;

            if(event != null) {
                // could be the event changing, or an action
                if (event.object_id === object_id) {
                    events = [];
                    if (state.events != null) {
                        events = state.events.slice();
                    }
                    event = update({object: state.selectedObject}, updateObject).object;

                    for (let i = 0; i < state.events.length; ++i) {
                        if (state.events[i].object_id === object_id) {
                            events[i] = update({object: events[i]}, updateObject).object;
                            break;
                        }
                    }
                }
                else {
                    // check the actions
                    actions = state.actions.slice();
                    for (let i = 0; i < actions.length; ++i) {
                        if (actions[i].object_id === object_id) {
                            actions[i] = update({object: actions[i]}, updateObject).object;
                            selectedActionValid = checkActionValid(actions[i]);
                            break;
                        }
                    }

                }
            }
            //update the action in the list of all actions as well.
            let updateAll = null;
            if(state.allActions.length > 0){

                if(field === "details.status") {
                    let updateObject = details.getUpdate(field, value);
                    let all = state.allActions.slice();
                    for (let i = 0; i < all.length; ++i) {
                        if (all[i].object_id === object_id) {
                            all[i] = update({object: all[i]}, updateObject).object;
                            break;
                        }
                    }
                    updateAll = all;
                }
            }


            // now update the changed list
            let changes = details.updateChanges(state.changes, object_id, field, value);
            if(updateAll !== null){
                return {...state, events: events, selectedObject: event, changes: changes, actions: actions, selectedActionValid: selectedActionValid,allActions:updateAll};
            }else {
                return {...state, events: events, selectedObject: event, changes: changes, actions: actions, selectedActionValid: selectedActionValid};
            }

        }

        case "SAVING_SHEVENTS_START":
        {
            return state;
        }

        case "SAVING_SHEVENTS_DONE":
        {
            return state;
        }

        case "SAVING_SHEVENTS_FAILED":
        {
            return state;
        }

        case "UPLOAD_COMMSEVENT_ATTACHMENT_START":
        {
            let event_id = action.event_id;
            let filename = action.file;

            if(state.selectedObject === null || state.selectedObject === undefined){
                return state;
            }

            if(state.selectedObject.object_id === event_id){

                let selected = {...state.selectedObject};

                let attachments = selected.details.attachments;
                if (attachments == null){
                    attachments = [];
                }

                attachments.push("/uploading/"+filename);

                selected.details.attachments = attachments;

                return {...state ,selectedObject:selected};
            }else{
                return state;
            }

        }
        case "UPLOAD_COMMSEVENT_ATTACHMENT_DONE":
        {
            let event_id = action.event_id;
            let filename = action.filename;
            let path = action.path;

            if(state.selectedObject === null || state.selectedObject === undefined){
                return state;
            }

            if(state.selectedObject.object_id === event_id){

                let selected = {...state.selectedObject};

                let attachments = selected.details.attachments;
                if (attachments == null){
                    attachments = [];
                }

                for (let j=attachments.length-1; j>=0; --j)
                {
                    if (attachments[j] === "/uploading/"+filename)
                    {
                        attachments = attachments.slice();
                        attachments[j] = path;
                        selected.details.attachments = attachments;
                    }
                }

                let events = state.events;
                for(let i=0;i<state.events.length;i++){

                    let ev = events[i];
                    if(ev.object_id === event_id){
                        events = events.slice();

                        ev.details.attachment_count = attachments.length;

                        events[i] = ev;

                        break;
                    }
                }

                return {...state ,selectedObject:selected,events:events};
            }else{
                return state;
            }
        }

        case "UPLOAD_COMMSEVENT_ATTACHMENT_ERROR":
        {
            let event_id = action.event_id;
            let filename = action.file;

            if(state.selectedObject === null || state.selectedObject === undefined){
                return state;
            }

            if(state.selectedObject.object_id === event_id){

                let selected = {...state.selectedObject};

                let attachments = selected.details.attachments;
                if (attachments == null){
                    return state;
                }

                let path = "/uploading/"+filename;
                let index = attachments.indexOf(path);
                if(index >= 0){
                    path = "/error/"+filename;
                    attachments[index] = path;
                }

                selected.details.attachments = attachments;

                return {...state ,selectedObject:selected};
            }else{
                return state;
            }
        }
        case "DELETE_COMMSEVENT_ATTACHMENT_START":
        {

            let event_id = action.event_id;
            let path = action.path;

            if(state.selectedObject === null || state.selectedObject === undefined){
                return state;
            }

            if(state.selectedObject.object_id === event_id){

                let selected = {...state.selectedObject};

                let attachments = selected.details.attachments;
                if(attachments === null || attachments === undefined){
                    return state;
                }

                let current = attachments.indexOf(path);
                let currentPath = attachments[attachments.indexOf(path)];

                currentPath = currentPath.replace("attachment","deleting"); //UI will disable the delete button if it sees 'deleting'
                attachments[current] = currentPath;

                selected.details.attachments = attachments;

                return {...state ,selectedObject:selected};
            }else{
                return state;
            }
        }
        case "DELETE_COMMSEVENT_ATTACHMENT_DONE":
        {
            let event_id = action.event_id;
            let path = action.path;

            path = path.replace("attachment","deleting");
            // find the action and remove the path

            if(state.selectedObject === null || state.selectedObject === undefined){
                return state;
            }

            if(state.selectedObject.object_id === event_id){

                let selected = {...state.selectedObject};

                let index = selected.details.attachments.indexOf(path);
                if(index >= 0){
                    selected.details.attachments.splice(index,1);
                }

                let events = state.events;
                for(let i=0;i<state.events.length;i++){

                    let ev = events[i];
                    if(ev.object_id === event_id){
                        events = events.slice();

                        ev.details.attachment_count = selected.details.attachments.length;

                        events[i] = ev;

                        break;
                    }
                }

                return {...state ,selectedObject:selected,events:events};
            }
        }
        case "DELETE_COMMSEVENT_ATTACHMENT_ERROR":
        {
            let event_id = action.event_id;
            let path = action.path;

            path = path.replace("attachment","deleting");
            // find the action and remove the path

            if(state.selectedObject === null || state.selectedObject === undefined){
                return state;
            }

            if(state.selectedObject.object_id === event_id){

                let selected = {...state.selectedObject};

                let index = selected.details.attachments.indexOf(path);
                if(index >= 0){
                    path = path.replace("deleting","error");
                    selected.details.attachments[index] = path;
                }

                return {...state ,selectedObject:selected};
            }
        }
        case "LOAD_SHACTIONS_START":
        {
            return {...state, isLoading:true}
        }
        case "LOAD_SHACTIONS_DONE":
        {
            let data = action.result.data;
            let actionsMap = data.objectLists["StakeholderAction"];
            let actions = [];
            if (actionsMap != null)
            {
                actions = Object.values(actionsMap).sort((a, b) => a.details.status.localeCompare(b.details.status));
            }

            return {...state, isLoading:false,allActions:actions,objectLists:data.objectLists}
        }
        case "LOAD_SHACTIONS_FAIL":
        {
            return state;
        }

        case "LOAD_OBJECTIONS_START":
        {
            return {...state, isLoading:true}
        }
        case "LOAD_OBJECTIONS_DONE":
        {
            let data = action.result.data;
            let objectionsMap = data.objectLists["StakeholderObjection"];
            let objections = [];
            if (objectionsMap != null)
            {
                objections = Object.values(objectionsMap).sort((a, b) => a.created - b.created);
            }

            return {...state, isLoading:false,allObjections:objections}
        }
        case "LOAD_OBJECTIONS_FAIL": {
            return state;
        }

        case "COMMS_SEARCH_START":
        {
            return {...state, isSearching:true,eventSearchResults:[],eventSearchTerm:""}
        }
        case "COMMS_SEARCH_DONE":
        {

            let results = action.result.data.results;
            if(results === undefined || results === null){
                results = [];
            }


            return {...state, isSearching:false,eventSearchResults:results,eventSearchTerm:action.result.data.term}
        }
        case "COMMS_SEARCH_ERROR":
        {
            return {...state, isSearching:false,eventSearchResults:[],eventSearchTerm:""}
        }
        case "COMMS_SEARCH_CLEAR":
        {
            return {...state, isSearching:false,eventSearchResults:[],eventSearchTerm:""}
        }

        case "NEW_OBJECTION_DONE":
        {
            let data = action.result.data;

            let objection = data.objection.object;
            let eventObjectionLink = data.eventObjectionLink.object;

            let objectionLinks = state.objectionLinks.slice();
            objectionLinks.push(eventObjectionLink);
            objectionLinks = objectionLinks.sort((a, b) => envid.getObjectField(a, 'name').localeCompare(envid.getObjectField(b, 'name')));

            let objections = state.objectLists["StakeholderObjection"];
            if (objections == null)
            {
                objections = {};
            }
            objections = {...objections};
            objections[objection.object_id] = objection;

            return {...state, objectionLinks:objectionLinks, objectLists:{...state.objectLists, "StakeholderObjection":objections}};

        }

        case "UPDATE_OBJECTION_DONE":
        {
            // update the StakeholderObjection in the object lists.
            let updated = action.result.object;

            let objections = state.objectLists?.["StakeholderObjection"];
            if (objections == null)
            {
                objections = {};
            }
            objections = {...objections};
            objections[updated.object_id] = updated;

            return {...state, objectLists:{...state.objectLists, "StakeholderObjection":objections}};

        }

        case "REMOVE_OBJECTION_DONE":
        {
            let objection_id = action.objection_id;

            // check the actions
            let objectionLinks = state.objectionLinks.slice();
            for (let i=0; i<objectionLinks.length;++i)
            {
                if (objectionLinks[i].object_id === objection_id)
                {
                    objectionLinks.splice(i, 1);
                    break;
                }
            }

            return {...state, objectionLinks: objectionLinks};

        }

        case "COMMS_CLEAR_LISTS":
        {
            //return {...state, events:null, actions:null}
            return state;
        }

        default:{}


    }
    return state;
}

/**
 * Created by craig on 9/8/17.
 */

import React from 'react';
import StakeholderFilter from "../../utils/StakeholderFilter"
import {withTranslation} from "react-i18next";

class StakeholderTree extends React.Component {

    constructor(props)
    {
        super();

        this.checkFilter = this.checkFilter.bind(this);
    }

    onclick(stakeholder)
    {
        if (stakeholder.type === "Stakeholder") {
            this.props.onclick(stakeholder);
        }
    }


    checkFilter(stakeholderStatus)
    {
        let filterString = this.props.filter;
        if (filterString == null)
        {
            return true;
        }

        let filter = new StakeholderFilter(filterString);

        if(filter.showAll() === true){
            return true;
        }

        return filter.showByStatus(stakeholderStatus);
    }

    render() {

        const {t} = this.props;

        if (this.props.object == null || this.props.object.structure == null)
        {
            return <div>{t('No Stakeholders')}</div>
        }

        // properties will include the structure at the moment
        let structure = this.props.object.structure;

        let li_style = {overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"};

        let rows = [];

        for (var i = 0; i < structure.list.length; ++i)
        {
            let item = structure.list[i];

            let project_stakeholder_link = this.props.objectLists["ProjectStakeholderLink"][item.object_id];

            if(this.checkFilter(project_stakeholder_link?.details?.status)){
                let stakeholder = this.props.objectLists["Stakeholder"][item.link];
                if (stakeholder) {
                    rows.push({stakeholder: stakeholder, status: project_stakeholder_link?.details?.status});
                }
            }
        }

        console.log("ROWS BEFORE ", rows);
        rows = rows.sort((a,b) => {
           let res = a.stakeholder.details.name.localeCompare(b.stakeholder.details.name);
           if (res === 0) {
               res = res = a.stakeholder.object_id.localeCompare(b.stakeholder.object_id);
           }
           return res;

        });
        console.log("ROWS AFTER ", rows);

        return <ul className="list-group clear-list m-t">{
            rows.map( (row,idx) => {
                let stakeholder = row.stakeholder;
                let status = row.status;

                let name = stakeholder.details.name;

                let displayStatus = '';

                let labelClass = 'label ';

                if (status == null)
                {
                    displayStatus = "Unassigned";
                }
                if (status === "IN_PROGRESS"){
                    displayStatus = "In Progress";
                    labelClass = labelClass + "label-warning";
                }
                if (status === "COMPLETE"){
                    displayStatus = "Complete"
                    labelClass = labelClass + "label-primary";
                }
                if (status === "COMPLETE_WITH_CONSULTATION"){
                    displayStatus = "Consultation";
                    labelClass = labelClass + "label-success";
                }
                if (status === "NA"){
                    displayStatus = "N/A";
                    labelClass = labelClass + "label-default";
                }


                let first = "";
                if (idx === 0)
                {
                    first = " first-item";
                }
                let selected = "";
                if (this.props.selected === stakeholder.object_id) {
                    selected = " stakeholder-item-selected";
                }

                return (<li key={stakeholder.object_id} className={"stakeholder-item list-group-item" + first + selected} style={li_style} onClick={this.onclick.bind(this, stakeholder)}>
                    <div style={{display:"inline-block",width:85}}><span className={labelClass}>{t(displayStatus)}</span></div>
                    {name}
                </li>);
        })}
        </ul>
    }
}



export default withTranslation()(StakeholderTree)


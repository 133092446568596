import React from 'react';

class PageWrapper extends React.Component {
    render() {
        // use the dimensions to set the height
        var dim = this.props.dimensions;
        var style = (this.props.style == null)?{} : this.props.style;
        if (dim != null)
        {
            style.height = dim.window.height - dim.extras.height;
            style.maxHeight = dim.window.height - dim.extras.height;
            style.overflowX = "hidden";

            if (this.props.noScroll === true)
            {
                style.overflowY = "hidden";
            } else {
                style.overflowY = "scroll";
            }
        } else {
            console.log("PageWrapper : Dimnesions Not Found");
        }

        return (
            <div className="wrapper wrapper-content animated fadeIn" style={style}>
                {this.props.children}
            </div>
        )
    }
}

export default PageWrapper

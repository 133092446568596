import i18n from 'i18next';

class StakeholderFilter extends Object
{
    constructor(filter) {
        super();

        if (filter == null)
        {
            filter = "0-0000-0";
        }
        this.filter = filter.toString();

        this.toString = function () {
            return this.filter.toString();
        }


        this.showAll = function () {
            if (this.filter === "0" || this.filter === "0-0000-0")
            {
                return true;
            }
            return false;
        }

        this.setStatus = function(status, b)
        {
            let pos = this.statusIndex(status);

            this.filter = this.filter.slice(0, pos) + (b?"1":"0") + this.filter.slice(pos+1);
        }

        this.statusIndex = function(status)
        {
            if (status === "IN_PROGRESS")
            {
                return 2;
            }
            else if (status === "COMPLETE")
            {
                return 3;
            }
            else if (status === "COMPLETE_WITH_CONSULTATION")
            {
                return 4;
            }
            else if (status === "NA")
            {
                return 5;
            }

        }

        this.checkStatus = function(status)
        {
            let bStatusCheck = 0;
            let pos = this.statusIndex(status);

            bStatusCheck = this.filter.toString().charAt(pos) === '1';

            return bStatusCheck;
        }

        this.showByStatus = function(status)
        {
            let bStatusCheck = false;

            if (this.showAll()) return true;

            if((status === null || status === undefined) && this.showAll()){
                return true;
            }

            bStatusCheck = this.checkStatus(status);

            return bStatusCheck;
        }


        this.getDisplayMessage = function()
        {
            let message = null;


            if (this.checkStatus("IN_PROGRESS"))
            {
                message = i18n.t("In Progress");
            }
            else if (this.checkStatus("COMPLETE"))
            {
                message = i18n.t("Complete");
            }
            else if (this.checkStatus("COMPLETE_WITH_CONSULTATION"))
            {
                message = i18n.t("Complete With Consultation");
            }
            else if (this.checkStatus("NA"))
            {
                message = i18n.t("Not Relevant");
            }

            message += " " + i18n.t('Stakeholders');

            return message;
        }
    }
}

export default StakeholderFilter;


import ebase from "../../utils/ajax";
import React from "react";
import {connect} from "react-redux";
import AlertsView from "../../views/AlertsView"
import {
    loadAlertsDone,
    loadAlertsStart,
} from "../../redux/actions/actions";






const mapStateToProps = (state, ownProps) =>
{


    return {
        alertList : state.users.alerts,
        alertUpdateTime:state.users.lastAlertUpdate,
        complianceUpdateTime: state.compliance.lastComplianceUpdateTime,
    }
}



const loadAlerts = (dispatch) =>
{
    dispatch(loadAlertsStart());
    ebase.ajax({
        url: "/ebase/core/" +
          "read-client-alerts",
        data: {},
        success: (result) => {
            // console.log(result);
            // console.log("-~-~-~-~-~-~-~-~-~-~-");
            dispatch(loadAlertsDone(result));
        },
        error:   (result) => {
            dispatch(loadAlertsDone(result));
        }
    });


}

const alertClicked = (ownProps, path) =>
{
    // console.log("Alerts container, go to path "+path);
    // console.log(ownProps);

    ownProps.routerPush(path);
}

const mapDispatchToProps = (dispatch, ownProps) =>
{
    return {

        loadAlerts : () => loadAlerts(dispatch),
        alertClicked : (path) => alertClicked(ownProps,path),

    }
}

class TheAlertsView extends React.Component{
    render() {

        return (

                React.cloneElement(<AlertsView/>, this.props)

        )
    }
    componentWillReceiveProps(nextProps)
    {
        // console.log("TheAlertsView will receive props");
        // console.log(nextProps);
        // console.log("=--------------------------");

        if(nextProps.alertUpdateTime !== null && nextProps.alertUpdateTime !== undefined && nextProps.complianceUpdateTime !== null && nextProps.complianceUpdateTime !== undefined){


            if (nextProps.complianceUpdateTime > nextProps.alertUpdateTime){
                // console.log("");
                // console.log("   >> will update status");
                // console.log("");
                nextProps.loadAlerts();
            }
        }

    }
    componentWillMount()
    {
        // console.log("TheAlertsView will mount");
        // console.log(this.props);
        // console.log("=--------------------------");

        this.props.loadAlerts();
    }

};


const AlertsContainer = connect(mapStateToProps, mapDispatchToProps)(TheAlertsView)


export default AlertsContainer

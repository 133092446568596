import update from 'immutability-helper';
import i18n from '../i18n'

// const t = i18n.t;

export default {

    getObjectTemplate(type) {

        switch (type) {
            case "ProjectCategory":
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [{field: "details.name", display:i18n.t("Project Type Name"), type: "text"},
                            {field: "details.desc", display:i18n.t("Description"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Activities"),
                        tabType: "link",
                        linkObject: "ProjectActivityLink",
                        showObject: "Activity",
                        fields: [{field: "details.desc", display:i18n.t("Project Type Link to Activity"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Summary"),
                        tabType: "sum"
                    },
                    {
                        tabName:i18n.t("References"),
                        tabType: "ref"
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ];

            case "Activity"    :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [{field: "details.name", display:i18n.t("Activity Name"), type: "text"},
                            {field: "details.short_desc", display:i18n.t("Short Description"), type: "text"},
                            {field: "details.desc", display:i18n.t("Long Description"), type: "ftext"},
                            {
                                field: "details.use_long_desc",
                                display:i18n.t("Use \"Long Description\" instead"),
                                type: "bool-next"
                            },
                        ]
                    },

                    {
                        tabName:i18n.t("Aspects"),
                        tabType: "link",
                        linkObject: "AspectActivityLink",
                        showObject: "Aspect",
                        fields: [{field: "details.name", display:i18n.t("Aspect/Activity Link Name"), type: "readonlytext"},
                            {
                                field: "details.short_desc",
                                display:i18n.t("Aspect/Activity Link Short Description"),
                                type: "text"
                            },
                            {field: "details.desc", display:i18n.t("Aspect/Activity Link Long Description"), type: "ftext"},
                            {field: "details.technicalInputs", display:i18n.t("Technical Inputs"), type: "TechnicalInput"},
                        ]
                    },
                    {
                        tabName:i18n.t("Project Categories"),
                        tabType: "link-1",
                        linkObject: "ProjectActivityLink",
                        showObject: "ProjectCategory",
                        fields: [{field: "details.desc", display:i18n.t("Project Type Link to Activity"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Controls"),
                        tabType: "link-2",
                        linkObject: "ControlActivityLink",
                        showObject: "Control",
                        fields: []
                    },
                    {
                        tabName:i18n.t("References"),
                        tabType: "ref"
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ];

            case "Aspect"    :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [{field: "details.name", display:i18n.t("Aspect Name"), type: "text"},
                            {field: "details.desc", display:i18n.t("Description"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Activities"),
                        tabType: "link",
                        linkObject: "AspectActivityLink",
                        showObject: "Activity",
                        fields: [{field: "details.name", display:i18n.t("Aspect/Activity Link Name"), type: "readonlytext"},
                            {
                                field: "details.short_desc",
                                display:i18n.t("Aspect/Activity Link Short Description"),
                                type: "text"
                            },
                            {field: "details.desc", display:i18n.t("Aspect/Activity Link Long Description"), type: "ftext"},
                            {field: "details.technicalInputs", display:i18n.t("Technical Inputs"), type: "TechnicalInput"}
                        ]
                    },
                    {
                        tabName:i18n.t("References"),
                        tabType: "ref"
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ];


            case "Receptor"  :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [{field: "details.name", display:i18n.t("Receptor Name"), type: "text"},
                            {
                                field: "details.receptor_type",
                                display:i18n.t("Classification"),
                                type: "ReceptorClassification"
                            },
                            {field: "details.desc", display:i18n.t("Receptor Description - General"), type: "ftext"},
                            {
                                field: "details.use_long_desc",
                                display:i18n.t("Use \"Receptor Description - General\" instead"),
                                type: "bool-next"
                            },
                            {
                                field: "details.environmentalInputs",
                                display:i18n.t("Environmental Inputs"),
                                type: "EnvironmentalInput"
                            },

                        ]
                    },
                    {
                        tabName:i18n.t("Regions"),
                        tabType: "link",
                        linkObject: "ReceptorRegionLink",
                        showObject: "Region",
                        fields: [{field: "details.desc", display:i18n.t("Regional Description"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Impacts"),
                        tabType: "link-1",
                        linkObject: "ImpactReceptorLink",
                        showObject: "Impact",
                        fields: [{field: "details.name", display:i18n.t("Impact Receptor Link Name"), type: "readonlytext"},
                            {field: "details.desc", display:i18n.t("Description"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("References"),
                        tabType: "ref"
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ]


            case "Control"    :
                let bHideExtra = i18n.t("hide_control_extra_values") === "yes";
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [
                            {field: "details.name", display:i18n.t("Control Name"), type: "readonlytext"},
                            {field: "details.impact_id", display:i18n.t("Impact"), type: "Impact"},
                            {field: "details.control_name_id", display:i18n.t("Control"), type: "ControlName"},
                            {field: "details.control_type_id", display:i18n.t("Control Type"), type: "ControlType"},
                            {field: "details.ranking", display:i18n.t("Control Ranking"), type: "ControlRanking"},
                            {field: "details.desc", display:i18n.t("Control Purpose"), type: "ftext"},
                            {field: "details.control_source_id", display:i18n.t("Control Source"), type: "ControlSource"},
                            {field: "details.epo", display:i18n.t("Environmental Performance Outcome"), type: "text", ignore:bHideExtra},
                            {field: "details.eps", display:i18n.t("Environmental Performance Standard"), type: "ftext", ignore:bHideExtra},
                            {field: "details.criteria", display:i18n.t("Measurement Criteria"), type: "ftext", ignore:bHideExtra}]
                    },
                    {
                        tabName:i18n.t("Receptors"),
                        tabType: "link",
                        linkObject: "ControlReceptorLink",
                        showObject: "Receptor",
                        fields: [{
                            field: "details.name",
                            display:i18n.t("Control Receptor Link Name"),
                            type: "readonlytext"
                        },
                            {field: "details.desc", display:i18n.t("Control Purpose on Receptor"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Activities"),
                        tabType: "link-1",
                        linkObject: "ControlActivityLink",
                        showObject: "Activity",
                        fields: []
                    },
                    {
                        tabName:i18n.t("References"),
                        tabType: "ref"
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ];


            case "TechnicalInput"    :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [{field: "details.name", display:i18n.t("Technical Input Name"), type: "text"},
                            {field: "details.desc", display:i18n.t("Description"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ];


            case "EnvironmentalInput"    :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [{field: "details.name", display:i18n.t("Environmental Input Name"), type: "text"},
                            {field: "details.desc", display:i18n.t("Description"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }

                ];

            case "Impact"    :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [
                            {field: "details.name", display:i18n.t("Name"), type: "readonlytext"},
                            {field: "details.impact_type_id", display:i18n.t("Impact Name"), type: "ImpactType"},
                            {field: "details.aspect_id", display:i18n.t("Aspect"), type: "Aspect"},
                            {field: "details.desc", display:i18n.t("Impact Description"), type: "ftext"},
                            {
                                field: "details.use_long_desc",
                                display:i18n.t("Use \"Impact Description\" instead"),
                                type: "bool-next"
                            },
                        ]
                    },
                    {
                        tabName:i18n.t("Receptors"),
                        tabType: "link",
                        linkObject: "ImpactReceptorLink",
                        showObject: "Receptor",
                        fields: [{field: "details.name", display:i18n.t("Impact Receptor Link Name"), type: "readonlytext"},
                            {field: "details.desc", display:i18n.t("Description"), type: "ftext"}]
                    },

                    {
                        tabName:i18n.t("References"),
                        tabType: "ref"
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }

                ];

            case "Region"    :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [{field: "details.name", display:i18n.t("Region Name"), type: "text"},
                            {field: "details.timezone", display:i18n.t("TimeZone"), type: "timezone"},
                            {field: "details.desc", display:i18n.t("Description"), type: "ftext"}]
                    },
                    {
                        tabName:i18n.t("Receptors"),
                        tabType: "link",
                        linkObject: "ReceptorRegionLink",
                        showObject: "Receptor",
                        fields: [{field: "details.desc", display:i18n.t("Regional Description"), type: "ftext"}]
                    },

                    {
                        tabName:i18n.t("Stakeholders"),
                        tabType: "link-1",
                        linkObject: "StakeholderRegionLink",
                        showObject: "Stakeholder",
                        fields: [{field: "details.name", display: i18n.t("Stakeholder/Region Link Name"), type: "readonlytext"},
                            {field: "details.desc", display: i18n.t("Stakeholder/Region Link Long Description"), type: "ftext"}
                        ]
                    },

                    {
                        tabName:i18n.t("References"),
                        tabType: "ref"
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ];

            case "Reference" :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [{field: "details.reference_info", display:i18n.t("Reference"), type: "text"}]
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ];

            case "Stakeholder" :
                return [
                    {
                        tabName:i18n.t("Details"),
                        tabType: "det",
                        fields: [
                            {field: "details.name", display:i18n.t("Name"), type: "text"},
                            {field: "details.short_desc", display:i18n.t("Short Description"), type: "text"},
                            {field: "details.website", display:i18n.t("Website"), type: "text"},
                            {field: "details.desc", display: i18n.t("Functions, Interest or Activities"), type: "ftext"},
                            {field: "details.contacts", display:i18n.t("Contacts"), type: "ContactList"}
                        ]
                    },
                    {
                        tabName:i18n.t("Regions"),
                        tabType: "link",
                        linkObject: "StakeholderRegionLink",
                        showObject: "Region",
                        fields: [{field: "details.name", display: i18n.t("Stakeholder/Region Link Name"), type: "readonlytext"},
                            {field: "details.desc", display: i18n.t("Stakeholder/Region Link Long Description"), type: "ftext"}
                        ]
                    },
                    {
                        tabName:i18n.t("Manage"),
                        tabType: "man"
                    },
                    {
                        tabName:i18n.t("Publishing"),
                        tabType: "pub"
                    }
                ];
        }

        return {};
    },

    getObjectReadOnlyTemplate(type) {
        switch (type)
        {
            case "ProjectCategory": return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields: [{field: "details.name", display:i18n.t("Project Type Name"), type: "text"},
                        {field: "details.desc", display: "", type: "ftext"}]
                },
            ];


            case "Activity"    : return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields: [{field: "details.name", display:i18n.t("Activity Name"), type: "text"},
                        {field: "details.short_desc", display: "", type: "text"},
                        {field: "details.use_long_desc", display:i18n.t("Use \"Long Description\" instead"), type: "bool-next"},
                        {field: "details.desc", display:i18n.t("Detailed Description"), type: "ftext"},
                    ]
                },

            ];

            case "ProjectActivityLink": return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields: [{field: "(Activity).details.name", display:i18n.t("Activity Name"), type: "text"},
                        // {field: "(Activity).details.short_desc", display: "", type: "text"},
                        // {field: "(Activity).details.use_long_desc", display:i18n.t("Use \"Long Description\" instead"), type: "bool-next"},
                        {field: "(Activity).details.desc", display: "", type: "ftext"},
                        {field : "(ProjectCategory).details.name", display:"", type : "link-to"},
                        {field: "details.desc", display: "", type: "ftext"},
                    ]
                }
            ];

            case "Aspect"    : return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields : [{field : "details.name", display:i18n.t("Aspect Name"), type : "text"},
                        {field : "details.desc", display : "", type : "ftext"}]
                },
            ];

            case "AspectActivityLink"    : return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields : [{field : "(Aspect).details.name", display:i18n.t("Aspect Name"), type : "text"},
                        {field : "(Aspect).details.desc", display : "", type : "ftext"},
                        {field : "(Activity).details.name", display:"", type : "link-to"},
                        {field : "details.desc", display : "", type : "ftext"}
                        ]
                },
            ];


            case "Receptor"  :   return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields : [{field : "details.name", display:i18n.t("Receptor Name"), type : "text"},
                        {field : "details.receptor_type", display:i18n.t("Classification"), type : "ReceptorClassification"},
                        {field: "details.use_long_desc", display:i18n.t("Use \"Receptor Description - General\" instead"), type: "bool-next"},
                        {field : "details.desc", display:i18n.t("About"), type : "ftext"},

                    ]
                },
            ];


            case "Control"    : {
                let bHideExtra = i18n.t("hide_control_extra_values") === "yes";

                return [
                    {
                        tabName: i18n.t("Details"),
                        tabType: "det",
                        fields: [
                            {field: "details.name", display: i18n.t("Control Name"), type: "readonlytext"},
                            //{field : "details.impact_id", display:i18n.t("Impact"), type : "Impact"},

                            {field: "details.control_type_id", display: i18n.t("Control Type"), type: "ControlType"},
                            {field: "details.ranking", display: i18n.t("Control Ranking"), type: "ControlRanking"},
                            {field: "details.control_name_id", display: i18n.t("Control"), type: "control-tree"},
                            {field: "details.desc", display: i18n.t("Control Purpose"), type: "ftext"},
                            {
                                field: "details.control_source_id",
                                display: i18n.t("Control Source"),
                                type: "ControlSource"
                            },
                            {field: "details.epo", display: i18n.t("Environmental Performance Outcome"), type: "text", ignore:bHideExtra},
                            {
                                field: "details.eps",
                                display: i18n.t("Environmental Performance Standard"),
                                type: "ftext",
                                ignore:bHideExtra
                            },
                            {field: "details.criteria", display: i18n.t("Measurement Criteria"), type: "ftext", ignore:bHideExtra}]
                    },

                ];
            }

            case "TechnicalInput"    : return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields : [{field : "details.name", display:i18n.t("Technical Input Name"), type : "text"},
                        {field : "details.desc", display : "", type : "ftext"}]
                }
            ];


            case "EnvironmentalInput"    : return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields : [{field : "details.name", display:i18n.t("Environmental Input Name"), type : "text"},
                        {field : "details.desc", display : "", type : "ftext"}]
                },
            ];

            case "Impact"    : return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields : [
                        {field : "details.name", display:i18n.t("Impact Name"), type : "readonlytext"},
                        {field : "details.name", display:i18n.t("Impact"), type : "impact-tree"},
                        {field : "details.impact_type_id", display:i18n.t("Impact Type"), type : "ImpactType"},
                        {field : "details.use_long_desc", display:i18n.t("Use \"Impact Description\" instead"), type: "bool-next"},
                        {field : "details.desc", display:i18n.t("About"), type : "ftext"},
                        {field : "details.environmentalInputs", display:i18n.t("Environmental Inputs"), type : "EnvironmentalInput"},
                    ]
                },
            ];

            case "ImpactType"    : return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields : [
                        {field : "details.name", display:i18n.t("Impact Type"), type : "readonlytext"},
                    ]
                },
            ];

            case "Region"    : return [
                {
                    tabName:i18n.t("Details"),
                    tabType: "det",
                    fields : [{field : "details.name", display:i18n.t("Region Name"), type : "text"},
                        {field : "details.desc", display : "", type : "ftext"}]
                },
            ];

            case "Reference" : return [
                {
                    tabName:i18n.t("Details"),
                    tabType:"det",
                    fields:[]
                },
            ];

            case "Stakeholder" : return [
                {
                    tabName:i18n.t("Details"),
                    tabType:"det",
                    fields:[
                        {field:"details.name",display:i18n.t("Name"),type:"text"},
                        {field: "details.short_desc", display:i18n.t("Short Description"), type: "text"},
                        {field: "details.website", display:i18n.t("Website"), type: "text"},
                        {field : "details.desc", display:i18n.t("Stakeholder Region Description"), type:"StakeholderRegionLink"},
                        {field : "details.desc", display : i18n.t("Functions, Interest or Activities"), type : "ftext"},
                        {field : "details.contacts", display:i18n.t("Contacts"), type : "ContactList"}
                    ]
                },

            ];

            case "ImpactReceptorLink": return [
                {
                    tabName:i18n.t("Details"),
                    tabType:"det",
                    fields:[
                        {field:"details.name",display:i18n.t("Name"),type:"text"}, //ordering is important - should be first
                        {field : "(Receptor).details.desc", display : "", type : "ftext"}, //ordering is important - should be second
                        {field : "details.desc", display:i18n.t("Description"), type:"ImpactReceptorLinkDescription"},
                        ]
                }
            ];

            case "ReceptorRegionLink": return [
                {
                    tabName:i18n.t("Details"),
                    tabType:"det",
                    fields:[
                        {field:"details.name",display:i18n.t("Name"),type:"text"},
                        {field : "details.desc", display:i18n.t("Description"), type:"ftext"},
                    ]
                }
            ];


        }

        return {};
    },

    extractText(textthing)
    {
        if (textthing == null)
        {
            return "";
        }
        else if (textthing.text != null)
        {
            return textthing.text;
        }
        else
        {
            return textthing;
        }

    },

    getExtendedValue(object, field)
    {
        if (field.startsWith("("))
        {
            let pos = field.indexOf(")");
            let type = field.substring(1, pos);
            let new_field = field.substring(pos+2);

            // console.log("getExtendedValue = " + type + " . " + new_field);
            let list = object.objectLists[type];
            let obj = Object.values(list)[0];
            return this.getValue(obj, new_field);
        }
        else
        {
            return this.getValue(object.object, field);
        }


    },

    getValue(object, field)
    {
        var splt = field.split(".");

        if (splt.length === 1)
        {
            return this.extractText(object[field]);
        }
        else if (splt.length === 2 && splt[0] === "details")
        {
            let textthing = object.details[splt[1]];
            return this.extractText(textthing);
            // if (splt[1] == "desc" || splt[1] == "eps" || splt[1] == "criteria" || splt[1] == "public_desc") {
            //     if (object.details[splt[1]] == null) return "";
            //     return object.details[splt[1]].text;
            // }
            //
            // return ;
        }
        else if (field === "publishing.published"){
            return object.publishingInfo.published;
        }

        console.log("Returning Nothing for " + field);
        return "";
    },

    updateChanges(stateChanges, object_id, field, value)
    {
        var changes = {savedStatus:"saving"};

        // now fix up the changes stuff
        var fchanges = {};
        if( Object.prototype.toString.call( value ) === '[object Array]' ) {
            fchanges[this.makeId(object_id, field)] = {object_id:object_id, field:field, list:value};
        } else {
            fchanges[this.makeId(object_id, field)] = {object_id:object_id, field:field, value:value};
        }
        changes.changedFields = update(stateChanges.changedFields, {$merge: fchanges});

        // console.log("Changes = ");
        // console.log(changes);

        return changes;
    },

    getStakeholderList(stakeholders, stakeholdersMap, type)
    {
        let stakeholders_short = i18n.t("None");
        let stakeholders_long = i18n.t("None");
        if (stakeholders != null && stakeholders.length > 0 && stakeholdersMap != null)
        {
            stakeholders_long = stakeholders_short = "";
            for (let i=0; i<stakeholders.length; ++i)
            {
                let sh_id = stakeholders[i];
                let sh = stakeholdersMap[sh_id];
                if (sh != null) {

                    if (i > 0) {
                        stakeholders_long += ", ";
                    }
                    stakeholders_long += sh.name;

                    if (i >= 2) {
                        if (i === 2) {
                            stakeholders_short += " + " + (stakeholders.length - 2) + " more."
                        }

                    } else {
                        if (i > 0) stakeholders_short += ", ";
                        stakeholders_short += sh.name;
                    }
                }else{
                    stakeholders_long = stakeholders_short = "NOT FOUND";
                }
            }
        }
        return (type==="short")?stakeholders_short:stakeholders_long;
    },

    getUpdate(field, value)
    {
        if (field === "publishing.published"){
            return {object : {publishingInfo : {published : {$set : value}}}};
        }
        else {
            var splt = field.split(".");

            if (splt.length === 1)
            {
                var obj = {object : {}};
                obj.object[field] = {$set : value};
                return obj;
            }
            else if (splt.length === 2 && splt[0] === "details")
            {
                var obj = {object : {details : {}}};
                if (splt[1] === "desc" || splt[1] === "eps" || splt[1] === "criteria" || splt[1] === "public_desc") {
                    obj.object.details[splt[1]] = {$set : {text : value}};
                } else {
                    obj.object.details[splt[1]] = {$set : value};
                }
                return obj;
            }
        }



        return {};

    },

    makeId(object_id, fieldName)
    {
        return object_id + "-" + fieldName.replace(".", "-");
    },

    isObjectEmpty(obj)
    {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
}

import i18n from '../i18n'

export default {
    allNames : {
        "activity"           : "Activity",
        "aspect"             : "Aspect",
        "receptor"           : "Receptor",
        "environmentalinput" : "Environmental Input",
        "technicalinput"     : "Technical Input",
        "control"            : "Control",
        "impact"             : "Impact",
        "projectcategory"    : "Project Type",
        "region"             : "Region",
        "location"           : "Region",
        "aspectactivitylink" : "Aspect/Activity",
        "reference"          : "Reference",
        "stakeholder"        : "Stakeholder",
        "communication"      : "Communication",
        "objection"          : "Objection",
        "action"             : "Action",
        "projectactivitylink": "Project/Activity"
    },

    allColours : {
        "context"            : ["#ed5565"],
        "risk"               : ["#f7ac59"],
        "report"             : ["#ad1f8d"],
        "activity"           : ["#ed5565"],
        "aspect"             : ["#f7ac59"],
        "receptor"           : ["#35bfc3"],
        "environmentalinput" : ["#2284c6"],
        "technicalinput"     : ["#f7ac59"],
        "control"            : ["#12b394"],
        "impact"             : ["#2284c6"],
        "projectcategory"    : ["#ad1f8d"],
        "region"             : ["#ed5565"],
        "location"           : ["#ed5565"],
        //"aspectactivitylink" : ["#f7ac59", "#ed5565"],
        "aspectactivitylink" : ["#f7ac59"],
        "reference"          : ["#ad1f8d"],
        "stakeholder"        : ["#23c6c8"],
        "communication"        : ["#1c84c6"],
        "objection"          : ["#ed5565"],
        "action"             : ["#ad1f8d"],
        "projectactivitylink": ["#ed5565"],
        "ebaseGreen"         : ["#12b394"],

    },

    allIcons : {
        "context"            : ["fa-puzzle-piece"],
        "risk"               : ["fa-exclamation-triangle"],
        "report"             : ["fa-sign-out"],
        "activity"           : ["fa-wrench"],
        "aspect"             : ["fa-fire"],
        "receptor"           : ["fa-tree"],
        "environmentalinput" : ["fa-sign-in"],
        "technicalinput"     : ["fa-sign-in"],
        "control"            : ["fa-umbrella"],
        "impact"             : ["fa-compress"],
        "projectcategory"    : ["fa-folder-open"],
        "region"             : ["fa-map-marker"],
        "location"           : ["fa-map-marker"],
        //"aspectactivitylink" : ["fa-fire", "fa-wrench"],
        "aspectactivitylink" : ["fa-fire"],
        "reference"          : ["fa-book"],
        "stakeholder"        : ["fa-child"],
        "communication"      : ["fa-envelope"],
        "objection"          : ["fa-shield"],
        "action"             : ["fa-coffee"],
        "projectactivitylink": ["fa-wrench"],
    },

    publishStates : {
        Draft:  {colour: "#ed5565", textColour: "white"},
        Submitted: {colour: "#ad1f8d", textColour: "white"},
        Reviewed: {colour: "#f7ac59", textColour: "white"},
        Pending:  {colour: "#1c84c6", textColour: "white"},
        Approved: {colour: "#12b394", textColour: "white"},
    },

    getPublishStates() {
        return Object.keys(this.publishStates);
    },

    getPublishStateColour(state) {
        const stateProps = this.publishStates[state];
        return stateProps?.colour ?? this.publishStates.Draft.colour;
    },

    getPublishTextColour(state) {
        const stateProps = this.publishStates[state];
        return stateProps?.textColour ?? this.publishStates.Draft.textColour;
    },

    getPublishTextStyle(state) {
        return {backgroundColor: this.getPublishStateColour(state), color: this.getPublishTextColour(state)}
    },

    getObjectName(type) {
        return i18n.t(this.allNames[type.toLowerCase()]);

    },

    getObjectColours(type) {
        return this.allColours[type.toLowerCase()];
    },

    getEbaseGreen(){
        return this.allColours["ebaseGreen"];
    },

    getObjectIcons(type) {
        return this.allIcons[type.toLowerCase()];

    },
    getObjectSingleName(type)
    {
        // i18n should do a better job of this, if we use it properly

        if (type.toLowerCase() === "activity" ||
            type.toLowerCase() === "aspect" ||
            type.toLowerCase() === "environmentalinput" ||
            type.toLowerCase() === "impact")
        {
            return i18n.t("an " + this.allNames[type.toLowerCase()]);
        }
        return i18n.t("a " + this.allNames[type.toLowerCase()]);
    },
    getObjectPluralName(type)
    {
        let result = "";
        if (type.toLowerCase() === "activity")
        {
            result = "Activities";
        }
        else if (type.toLowerCase() === "projectcategory")
        {
            result = "Project Categories";
        }
        else {
            result = this.getObjectName(type) + "s";
        }

        // should be able to do this with pluralisation. To do it proberly, probably need to
        // know the number. A single form of pluralisation is very english specific
        return i18n.t(result);
    },
    getObjectField(object, field)
    {
        // console.log('getObjectField ',object, field);
        if (object == null)
        {
            return null;
        }
        if (object[field] != null)
        {
            return object[field];
        }
        else if (object.details != null && object.details[field] != null)
        {
            return object.details[field];
        }
        else if (object.documentInfo != null && object.documentInfo[field] != null)
        {
            return object.documentInfo[field];
        }
        return null;
    },
    getFromObjectLists(objectLists, type, object_id)
    {
        if (objectLists == null || objectLists[type] == null || objectLists[type][object_id]==null)
        {
            return {object_id:object_id, type:type, details:{name:"Unknown Object"}};
        }
        return objectLists[type][object_id];
    },
    getObjectFromLink(objectLists, linkObject, type)
    {
        if (linkObject == null) return null;

        let new_object_id = null;
        let link = linkObject.link;
        if (link == null) return null;

        new_object_id = (link.left_type === type) ? link.left_object_id : link.right_object_id;
        if (new_object_id == null) return null;

        return this.getFromObjectLists(objectLists, type, new_object_id);
    },

    getLiteContactEmail(){
        return "mailto:ebase@xodusgroup.com";
    },

    getcontactWebsite(){
        return "https://www.ebase.com.au/";
    },

    //Styles used for the multi select options in the react-select component.
    //Makes the options blue. Default is grey.
    getMultiSelectStyle(){
        return {
            multiValue: (styles, { data }) => {
                return {
                    ...styles,
                    backgroundColor: "rgb(0,126,255,0.1)",
                    border: "1px solid rgb(0,126,255,0.2)",
                    color: "#007eff",
                }
            },
            multiValueLabel: (styles, { data }) => {
                return {
                    ...styles,
                    color: "#007eff",
                }
            },
            multiValueRemove: (styles, { data }) => {
                return {
                    ...styles,
                    borderLeft: '1px solid rgb(0,126,255,0.2)',
                    ":hover":{
                        backgroundColor: "rgb(0,126,255,0.15)",
                        cursor:'pointer'
                    }
                }
            }
        }
    },

    formatNumberToPlaces(number, places){
        if (typeof number === 'string'){
            number = parseInt(number);
            if (isNaN(number)){
                return "-"
            }
        }
        let formatter = new Intl.NumberFormat('en',{maximumFractionDigits:places});
        return formatter.format(number);
    },

    getAppVersion(){
        return "1.1.0 (48)";
    }


}

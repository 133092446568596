import React from 'react';
import Spinner from "../components/common/Spinner"
import envid from "../utils/envid"
import {withTranslation} from "react-i18next";


const fontSize = 13;//"medium";
const badgeHeight = 24;
const badgeWidth = 60;

class StakeholderStatusView extends React.Component {

    constructor(props)
    {
        super(props);

        this.makeRows = this.makeRows.bind(this);

        this.makeStakeholderRows = this.makeStakeholderRows.bind(this);
        this.makeObjectionRows = this.makeObjectionRows.bind(this);
        this.makeActionRows = this.makeActionRows.bind(this);
        this.totalStatus = this.totalStatus.bind(this);

        this.rowClick = this.rowClick.bind(this);

        this.clickID = this.clickID.bind(this);

    }

    rowClick(rowKey){
        this.props.statusClicked(rowKey);
    }

    clickID(status){

        return status;
    }

    makeObjectionRows(statusInfo){

    }

    makeActionRows(statusInfo){

    }

    makeStakeholderRows(statusInfo){

        const {t} = this.props;

        if(statusInfo === null || statusInfo === undefined){
            return [];
        }

        let ordering = {"IN_PROGRESS": t("In Progress"),"COMPLETE": t("Complete"),"COMPLETE_WITH_CONSULTATION": t("Complete With Consultation"),"NA": t("Not Relevant")};
        let keys = Object.keys(ordering);

        var rows = [];

        for (var i =0; i<keys.length;i++){

            let statusKey = keys[i];

            var num = statusInfo[statusKey];
            if( num !== undefined){

                if(num === 0){
                    continue;
                }

                var info = ordering[statusKey];
                var icon = "fa ";
                var badgeColour = "";
                var border = "";
                var textColour = 'white';

                let clickKey = this.clickID(statusKey);

                if(statusKey === "IN_PROGRESS"){
                    icon = icon + "fa-clock-o";
                    // badgeColour = "#ed5565";
                    badgeColour = "#f8ac59";
                }else if (statusKey === "COMPLETE"){

                    badgeColour = "#1ab395";
                    icon = icon + "fa-check";
                }else if (statusKey === "COMPLETE_WITH_CONSULTATION"){
                    icon = icon + "fa-check";
                    badgeColour = "#2284c6";

                }else{
                    icon = icon +  "fa-ban";
                    textColour = "";
                    border = "1px solid #676a6c";
                }

                let badge = <div style={{backgroundColor:badgeColour,border:border, borderRadius:4,color:textColour,height:badgeHeight,width:badgeWidth,display:'flex',flexDirection:"row",justifyContent:"center",alignContent:"center"}}>
                    <div style={{width:60, display:"flex",alignSelf:'center'}}>
                        <div style={{height:20,width:26,textAlign:'center'}}><i className={icon} style={{fontSize:fontSize,marginTop:1}}></i></div>
                        <div style={{height:20,width:26,display:'flex',justifyContent:'flex-end'}}><div style={{display:"flex",alignSelf:'center',fontSize:fontSize}}>{num}</div></div>
                    </div>
                </div>;


                let row = <tr key={statusKey} onClick={() => this.rowClick(clickKey)} style={{cursor:"pointer"}}>
                    <td style={{width:85, padding:4}}>
                        {badge}
                    </td>
                    <td style={{verticalAlign:"middle", padding:4}}>
                        {t(info)}
                    </td>
                    <td style={{textAlign:"right", padding:4}}><i className="fa fa-angle-right" style={{fontSize:"2em",color:"#636363"}}></i></td>
                </tr>;

                rows.push(row);

            }
        }

        return rows;

    }

    makeRows(type){
        if(type === "stakeholder") {
            return this.makeStakeholderRows(this.props.statusInfo["stakeholder"]);
        }
        if(type === "objection"){
            return this.makeStakeholderRows(this.props.statusInfo["stakeholder"]);
        }
        if(type === "action"){
            return this.makeStakeholderRows(this.props.statusInfo["stakeholder"]);
        }
    }

    totalStatus(type){
        let statusInfo = this.props.statusInfo[type];
        if(statusInfo === null || statusInfo === undefined){
            return 0;
        }


    }

    render() {
        //
        // console.log("StakeholderStatusView  RENDER");
        // console.log(this.props);
        // console.log("--------------------");
        const {t} = this.props;

        if(this.props.isStatusLoading){
            return <Spinner/>;
        }

        let statusInfo = this.props.statusInfo;
        if (statusInfo === null || statusInfo === undefined){
            return <div>
                <h4>{t("No Status Information Found")}</h4>
            </div>
        }

        let totalStakeholders = statusInfo["stakeholder"].total;

        let stakeholderRows = this.makeRows("stakeholder");
        let stakeholderTable = <div></div>;
        if(stakeholderRows.length > 0){
            stakeholderTable =
                <div>
                    <h4>{t("Stakeholder Status")}</h4>
                    <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                        {stakeholderRows}
                        </tbody>
                    </table>
                </div>
        }

        let manageTitle = t("Manage all Stakeholders");
        if (this.props.readOnly === true){
            manageTitle = t("View all Stakeholders");
        }

        return <div>

            <h4>{t("All Stakeholders")}</h4>
            <table className="table table-hover">
                <thead></thead>
                <tbody>
                <tr onClick={() => this.rowClick("edit_all")} style={{cursor:"pointer"}}>
                    <td style={{width:85, padding:4}}>
                        <div style={{backgroundColor:"",border:"1px solid #676a6c", borderRadius:4,height:badgeHeight,width:badgeWidth,display:'flex',flexDirection:"row",justifyContent:"center",alignContent:"center"}}>
                            <div style={{width:60, display:'flex',alignSelf:'center'}}>
                                <div style={{height:20,width:26,textAlign:'center'}}><i className="fa fa-child" style={{fontSize:fontSize,marginTop:1, color:envid.getObjectColours("stakeholder")}}></i></div>
                                <div style={{height:20,width:26,display:'flex',justifyContent:'flex-end'}}><div style={{display:"flex",alignSelf:'center',fontSize:fontSize}}>{totalStakeholders}</div></div>
                            </div>
                        </div>
                    </td>
                    <td style={{verticalAlign:"middle", padding:4}}>
                        {manageTitle}
                    </td>
                    <td style={{textAlign:"right", padding:4}}><i className="fa fa-angle-right" style={{fontSize:"2em",color:"#636363"}}></i></td>
                </tr>
                </tbody>
            </table>
            {stakeholderTable}
        </div>

    }

}

export default withTranslation()(StakeholderStatusView)

import React from 'react';
import {connect} from 'react-redux';
import sitedata from '../utils/sitedata'
import {Link} from 'react-router-dom';
import {Card} from 'react-bootstrap'
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EbasePanel from '../components/common/EbasePanel'
import {Trans, withTranslation} from "react-i18next";

class SelectView extends React.Component {

  render() {

    const {t} = this.props;

    // console.log("Select view render");
    // console.log(this.props);
    // console.log("---");

    let panelStyle = {
      width: "150px",
      height: "170px",
      padding: "5px",
      fontWeight: "bold",
      fontSize: "30px",
      margin: "10px",
      border: 'unset'
    };

    var navs = [];

    let items = [{perm: "client", link: "/libraries"},
      {perm: "project", link: "/projects"},
      {perm: "enviddata", link: "/enviddata"},
      {perm: "controls", link: "/controlscatalogue"},
      {perm: "knowledgebase", link: "/knowledgebase"},
      {perm: "stakeholder", link: "/stakeholdersummary"},
      {perm: "summary", link: "/impactsummary"},
      {perm: "summary", link: "/regionsummary"},
      //{perm:"help",link:"/help"},
    ];

    var navs1 = items.filter((item) => {

      if (item.perm === "client") {
        return sitedata.checkWriteAccess(this.props, item.perm);
      } else {
        return sitedata.checkReadAccess(this.props, item.perm)
      }


    }).map((item, index) => {
        var link = item.link;
        let linkName = sitedata.lookupLinkName(link)

        if (item.perm === "enviddata") {
          let readOnly = sitedata.checkReadAccess(this.props, item.perm) && !sitedata.checkWriteAccess(this.props, item.perm);
          if (readOnly) {
            linkName = sitedata.lookupLinkName(link + "-readOnly");
          }
        }

        return (
          <Link to={link} key={link + " " + index}>
            <Card className="widget navy-bg p-lg text-center" style={panelStyle}>
              <div className="vertical-align">
                <div style={{fontSize: "50px"}}>{sitedata.lookupLinkIcon(link)}</div>
                <br/>
                <div style={{fontSize: "16px", fontWeight: 600, marginTop: "10px"}}>{linkName}</div>
              </div>
            </Card>
          </Link>
        )
      }
    );


    let remaining = navs1.length;
    let remainder = remaining % 3;
    let navColClass = "col-lg-4";
    let containerColClass = "col-lg-6";
    let leftSpaceColClass = "col-lg-3";
    if (navs1.length > 6) {
      navColClass = "col-lg-3";
      containerColClass = "col-lg-8";
      remainder = remaining % 4;
      leftSpaceColClass = "col-lg-2";
    }


    for (var i = 0; i < navs1.length; ++i) {
      if (remaining === remainder) {
        if (remainder === 3) navs.push(<div key={"rmdr3" + i} className="col-lg-2"></div>);
        if (remainder === 2) navs.push(<div key={"rmdr2" + i} className="col-lg-2"></div>);
        if (remainder === 1) navs.push(<div key={"rmdr1" + i} className="col-lg-4"></div>);
      }
      navs.push(<div key={"" + i} className={navColClass}>{navs1[i]}</div>);
      --remaining;
    }

    let selectContent = (
      <EbasePanel>
        <div className="row">
          <div className={leftSpaceColClass}>
          </div>
          <div className={containerColClass}>
            <div className="row">
              {navs}
            </div>
          </div>
        </div>
      </EbasePanel>
    );


    return (
      <div>
        <ContainerHeader title={t("Home")} dimensions={this.props.dimensions}/>
        <PageWrapper dimensions={this.props.dimensions}>
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-content text-center p-md">
                  <Trans i18nKey="welcome-message">
                    <h2><span className="text-navy">Welcome to eBase</span> your environment impact<br/> identification
                      and assessment platform.</h2>
                    <p>eBase follows the logic and thread of environmental impact assessment and will guide you
                      through<br/> the process. This means you
                      can perform robust environmental impact and risk assessments 24/7<br/> and at a fraction of the
                      time and cost.</p>
                  </Trans>
                </div>
              </div>
            </div>
          </div>
          {selectContent}
        </PageWrapper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    siteData: state.session.siteData,
    dimensions: state.session.dimensions,
  };

};

export default withTranslation()(connect(mapStateToProps)(SelectView));

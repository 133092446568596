/**
 * Created by lewellyn on 16/10/18.
 */
import { connect } from 'react-redux'

import ProjectManageView from "../../views/ProjectManageView";

const mapStateToProps = (state, ownProps) =>{

    return {};
};

const mapDispatchToProps = (dispatch, ownProps) =>{

    return {};
};

// var ProjectMngrView = React.createClass({
//
//     render: function () {
//
//         return (
//
//             <div>
//                 {React.cloneElement(<ProjectManageView/>,this.props)}
//             </div>
//         );
//     },
//     componentWillMount : function(){
//
//         console.log("ProjectMngrView:: will mount");
//         console.log(this.props);
//
//         ebase.ajax({
//             url:"/ebase/envid-admin/read-enviddata-deletecheck",
//             data:{object_id:this.props.params.objectId},
//             success: (result) => {console.log("ProjectMngrView:: delete check done"); console.log(result)},
//             error: (result) => {console.log("ProjectMngrView:: delete check error")}
//         });
//
//     }
//
// });

const ProjectManageContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectManageView);
export default ProjectManageContainer

/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientLibraryConfig } from './ClientLibraryConfig';
import {
    ClientLibraryConfigFromJSON,
    ClientLibraryConfigFromJSONTyped,
    ClientLibraryConfigToJSON,
} from './ClientLibraryConfig';
import type { ClientResult } from './ClientResult';
import {
    ClientResultFromJSON,
    ClientResultFromJSONTyped,
    ClientResultToJSON,
} from './ClientResult';
import type { DatasetResult } from './DatasetResult';
import {
    DatasetResultFromJSON,
    DatasetResultFromJSONTyped,
    DatasetResultToJSON,
} from './DatasetResult';

/**
 * 
 * @export
 * @interface ClientLibraryResult
 */
export interface ClientLibraryResult {
    /**
     * 
     * @type {ClientResult}
     * @memberof ClientLibraryResult
     */
    client?: ClientResult;
    /**
     * 
     * @type {Array<DatasetResult>}
     * @memberof ClientLibraryResult
     */
    libraries?: Array<DatasetResult>;
    /**
     * 
     * @type {{ [key: string]: ClientLibraryConfig; }}
     * @memberof ClientLibraryResult
     */
    libraryConfig?: { [key: string]: ClientLibraryConfig; };
    /**
     * 
     * @type {string}
     * @memberof ClientLibraryResult
     */
    role?: string;
}

/**
 * Check if a given object implements the ClientLibraryResult interface.
 */
export function instanceOfClientLibraryResult(value: object): value is ClientLibraryResult {
    return true;
}

export function ClientLibraryResultFromJSON(json: any): ClientLibraryResult {
    return ClientLibraryResultFromJSONTyped(json, false);
}

export function ClientLibraryResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientLibraryResult {
    if (json == null) {
        return json;
    }
    return {
        
        'client': json['client'] == null ? undefined : ClientResultFromJSON(json['client']),
        'libraries': json['libraries'] == null ? undefined : ((json['libraries'] as Array<any>).map(DatasetResultFromJSON)),
        'libraryConfig': json['libraryConfig'] == null ? undefined : (mapValues(json['libraryConfig'], ClientLibraryConfigFromJSON)),
        'role': json['role'] == null ? undefined : json['role'],
    };
}

export function ClientLibraryResultToJSON(value?: ClientLibraryResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'client': ClientResultToJSON(value['client']),
        'libraries': value['libraries'] == null ? undefined : ((value['libraries'] as Array<any>).map(DatasetResultToJSON)),
        'libraryConfig': value['libraryConfig'] == null ? undefined : (mapValues(value['libraryConfig'], ClientLibraryConfigToJSON)),
        'role': value['role'],
    };
}


import React from 'react';
import envid from "../utils/envid";
import {Trans, withTranslation} from "react-i18next";

class LiteDetailsLandingView extends React.Component {

    render(){

        const {t} = this.props;

        const email = envid.getLiteContactEmail();
        const website = envid.getcontactWebsite();

        return(
<div>
        <div className="row" style={{display:'flex'}}>
            <div className="col-md-4" style={{minWidth:400}}>
                <div className="ibox ">
                    <div className="ibox-title">
                        <h5>{t("What is eBase Lite?")}</h5>
                    </div>
                    <div>
                        <div className="ibox-content no-padding border-left-right" style={{minHeight:250}}>
                            <iframe title="Intro" width="100%" height="250" src="https://www.youtube.com/embed/k0ZYeAPT6TY"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </div>
                        <div className="ibox-content profile-content">
                            <p>
                                <Trans i18nKey="lite-find-out-more">To find out more about <span><strong>eBase Lite</strong></span> watch the video and <a href={{email}}>contact us</a> if you like to know more.</Trans>
                            </p>

                            <div className="user-button">
                                <div className="row">
                                    <div className="col-md-6">
                                        <a href={envid.getLiteContactEmail()}>
                                        <button type="button" className="btn btn-primary btn-sm btn-block"><i
                                            className="fa fa-envelope"></i> {t("Send Message")}
                                        </button>
                                        </a>
                                    </div>
                                    <div className="col-md-6">
                                        <a href={envid.getcontactWebsite()} target="_blank" rel="noopener noreferrer">
                                        <button type="button" className="btn btn-default btn-sm btn-block"><i
                                            className="fa fa-external-link"></i> {t("Go to website")}
                                        </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  style={{displaly:'flex'}}>
                <div className="ibox ">
                    <div className="ibox-title">
                        <h5>{t("eBase Lite")}</h5>
                    </div>
                    <div className="ibox-content">

                        <Trans i18nKey="lite-full-message">
                            <p>
                                eBase is Digital Environmental Impact Assessment software,
                                that allows you to manage knowledge associated with Impact Assessment
                                and then use that knowledge to create projects and automate the EIA workflow.
                            </p>
                            <p>
                                We have created <span><strong>eBase Lite</strong></span>, to allow you to browse a lighter version of our
                                <span style={{fontStyle:'italic'}}> Australian Offshore Oil and Gas Knowledge Library</span>. At Xodus Group we keep this
                                library of knowledge up to date and use it to streamline our approvals work so
                                that we are accessing the most accurate data available.
                            </p>
                            <p>
                                If you like what you see, <a href={{email}}>contact us</a> to access the full version,
                                which comes with a larger, more complete data set and functionality
                                the allows you to create projects and reports using this knowledge.
                            </p>
                            <p>
                                You can subscribe to eBase to access our existing data or use eBase to
                                create your companies own Knowledge Library.
                                Head over to our <a href={{website}} target={'_blank'} rel="noopener noreferrer">website</a> or <a href={{email}}>contact us</a> to find out more.
                            </p>
                        </Trans>
                    </div>
                </div>
            </div>
        </div>
</div>
        )
    }

}
//className="col-sm-8"
export default withTranslation()(LiteDetailsLandingView)

import React from 'react';
import Spinner from "../components/common/Spinner"
import {withTranslation} from "react-i18next";

const fontSize = 13;//"medium";
const badgeHeight = 24;
const badgeWidth = 60;

class ComplianceStatusView extends React.Component {

    constructor(props)
    {
        super(props);

        this.makeRows = this.makeRows.bind(this);

        this.rowClick = this.rowClick.bind(this);

        this.clickID = this.clickID.bind(this);

    }

    rowClick(rowKey, isProjectStatus){
        this.props.statusClicked(rowKey, isProjectStatus);
    }

    clickID(status){

        return status;
    }

    makeRows(status, days, isProjectStatus){

        const {t} = this.props;

        let includeZeros = isProjectStatus;

        let ordering = {"NOT_COMPLIANT": t("Not Compliant"),"DUE_SOON": t("Due Soon"),"COMPLIANT": t("Compliant"),"IN_PROGRESS": t("In Progress"),"INCOMPLETE": t("Incomplete")};

        let keys = Object.keys(ordering);

        var rows = [];

        for (var i =0; i<keys.length;i++){

            let statusKey = keys[i];

            var num = status[statusKey];
            if( num !== undefined){

                if(num === "0" && !includeZeros){
                    continue;
                }

                var info = ordering[statusKey];
                var icon = "fa ";
                var badgeColour = "";
                var border = "";
                var textColour = 'white';

                let clickKey = this.clickID(statusKey);

                if(statusKey === "NOT_COMPLIANT"){
                    icon = icon + "fa-warning";
                    badgeColour = "#ed5565";
                }else if (statusKey === "DUE_SOON"){
                    info = t("Due in the next days", {days});
                    badgeColour = "#2284c6";
                    icon = icon + "fa-calendar";
                }else if (statusKey === "COMPLIANT"){
                    icon = icon + "fa-check";
                    badgeColour = "#1ab395";
                }else if (statusKey === "IN_PROGRESS"){
                    icon = icon + "fa-clock-o";
                    badgeColour = "#f8ac59";
                }else{
                    icon = icon +  "fa-edit";
                    textColour = "";
                    border = "1px solid #676a6c";
                }


                let badge = <div style={{backgroundColor:badgeColour,border:border, borderRadius:4,color:textColour,height:badgeHeight,width:badgeWidth,display:'flex',flexDirection:"row",justifyContent:"center",alignContent:"center"}}>
                    <div style={{width:60, display:'flex',alignSelf:'center'}}>
                        <div style={{height:20,width:26,textAlign:'center'}}><i className={icon} style={{fontSize:fontSize,marginTop:1}}></i></div>
                        <div style={{height:20,width:26,display:'flex',justifyContent:'flex-end'}}><div style={{display:"flex",alignSelf:'center',fontSize:fontSize}}>{num}</div></div>
                    </div>
                    </div>;

                let row = <tr onClick={() => this.rowClick(clickKey, isProjectStatus)} style={{cursor:"pointer"}}>
                    <td style={{width:85, padding:4}}>
                        {badge}
                    </td>
                    <td style={{verticalAlign:"middle", padding:4}}>
                        {info}
                    </td>
                    <td style={{textAlign:"right", padding:4}}><i className="fa fa-angle-right" style={{fontSize:"2em",color:"#636363"}}></i></td>
                </tr>;

                rows.push(row);
            }
        }

        return rows;

    }

    render() {
        //
        // console.log("ComplianceStatusView RENDER");
        // console.log(this.props);
        // console.log("--------------------");
        const {t} = this.props;

        if(this.props.isStatusLoading){
            return <Spinner/>;
        }

        if (this.props.statusInfo === null || this.props.statusInfo === undefined){
            return <div>
                <h2>{t("Compliance Status For My Roles")}</h2>
                <h2>{t("Compliance Status for Project")}</h2>
            </div>
        }

        let user = "Me";
        if(this.props.siteData !== null && this.props.siteData !== undefined){
            if(this.props.siteData.user !== null && this.props.siteData.user !== undefined){
                if(this.props.siteData.user.firstname !== null && this.props.siteData.user.firstname !== undefined){
                    user = this.props.siteData.user.firstname;
                }
            }
        }

        let projectStatus = this.makeRows(this.props.statusInfo.project_status,this.props.statusInfo.soon_days,true);

        let userStatus = this.makeRows(this.props.statusInfo.user_status,this.props.statusInfo.soon_days,false);

        let projectTable = <div></div>;
        let userTable = <div></div>;
        if(projectStatus.length > 0){
            projectTable =
                <div>
                    <h4>{t('Compliance Status for Project')}</h4>
                    <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                        {projectStatus}
                        </tbody>
                    </table>
                </div>
        }

        if(userStatus.length > 0){
            userTable =
                <div>
                    <h4>{t('Compliance Status For', {user})}</h4>
                    <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                        {userStatus}
                        </tbody>
                    </table>
                </div>
        }

        let total = 0;
        total += parseInt(this.props.statusInfo.project_status["IN_PROGRESS"]);
        total += parseInt(this.props.statusInfo.project_status["INCOMPLETE"]);
        total += parseInt(this.props.statusInfo.project_status["NOT_COMPLIANT"]);
        total += parseInt(this.props.statusInfo.project_status["COMPLIANT"]);

        return <div>

            <h4>{t("All Controls and Compliance")}</h4>
            <table className="table table-hover">
                <tbody>
                <thead></thead>
                <tr onClick={() => this.rowClick("edit_all", true)} style={{cursor:"pointer"}}>
                    <td style={{width:85, padding:4}}>
                        <div style={{backgroundColor:"",border:"1px solid #676a6c", borderRadius:4,height:badgeHeight,width:badgeWidth,display:'flex',flexDirection:"row",justifyContent:"center",alignContent:"center"}}>
                            <div style={{width:60, display:'flex',alignSelf:'center'}}>
                                <div style={{height:20,width:26,textAlign:'center'}}><i className="fa fa-umbrella" style={{fontSize:fontSize,marginTop:1, color:"#1ab395"}}></i></div>
                                <div style={{height:20,width:26,display:'flex',justifyContent:'flex-end'}}><div style={{display:"flex",alignSelf:'center',fontSize:fontSize}}>{total}</div></div>
                            </div>
                        </div>
                    </td>
                    <td style={{verticalAlign:"middle", padding:4}}>
                        {t("Manage Compliance for all controls")}
                    </td>
                    <td style={{textAlign:"right", padding:4}}><i className="fa fa-angle-right" style={{fontSize:"2em",color:"#636363"}}></i></td>
                </tr>
                </tbody>
            </table>


            {userTable}
            {projectTable}
        </div>
    }

}

export default withTranslation()(ComplianceStatusView)

import React, {useState, useEffect, useRef} from 'react';
import Measure from 'react-measure';
import { connect } from 'react-redux';
import TopHeader from "../common/TopHeader";
import auth from "../../utils/auth";
import SessionManager from "../../utils/SessionManager";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import sitedata from "../../utils/sitedata";
import { withRouter } from 'react-router-dom';
import {Button, Modal} from "react-bootstrap";
import Spinner from "../common/Spinner";
import jq from "../../utils/jq";

const NavigationLayout = props =>{

    // console.log("NavigationLayout:: render",props);

    //connect resize event
    useEffect( () => {
        jq.find(window).resize(SessionManager.shared().updateDimensions);

        return () => {
            //unsubscribe from resize event.
            jq.find(window).off("resize");
        }

    },[])

    const [hideOverlay, setHideOverlay] = useState(false);
    const gotoSelectDataset = () => {

        console.log("gotoSelectDataset does nothing!");

        // this.routerPush("/");
        // let change = Object.assign({},sitedata.getSiteData(this.state));
        // change.access_id = null;
        // change.access = [];
        //
        // this.setState({siteData : change});
    };

    let version_display = null;
    let devOverlay = sitedata.getDevOverlayName(props);
    if (devOverlay != null && !hideOverlay) {
        version_display = <div
            onClick={()=> setHideOverlay(true)}
            style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                background: "yellow",
                padding: 10,
                fontSize: "20pt",
                zIndex: 10000,
                border: "1pt solid black",
                color: "black",
                width:280,
                textAlign:"center"
            }}>{devOverlay}</div>
    }

    let wrapperClass = "gray-bg ";// + this.props.location.pathname;

    let sData = props.siteData;
    let datasetName = "";
    if(sData !== null && sData !== undefined) {
        let currentDataset = sitedata.getCurrentDataset(sData);
        if (currentDataset !== undefined && currentDataset !== null) {
            if (currentDataset.name !== null && currentDataset.name !== undefined) {
                if (sitedata.getClientLibraries(sData).length > 1) {
                    datasetName = currentDataset.name;
                }
            }
        }
    }

    return (
        <div id="wrapper">

            <Navigation location={props.location} siteData={props.siteData} selectDatasetAccess={gotoSelectDataset}/>

            <div id="page-wrapper" className={wrapperClass} style={{minHeight:"300px"}}>

                <Measure bounds onResize={(dimensions)=> SessionManager.shared().updateDimensions({item:"topheader", height:dimensions.bounds.height})} whitelist={["height"]}>
                    { ({measureRef}) => (
                      <TopHeader ref={measureRef} onLogout={props.doLogout}  title={datasetName} isLite={auth.getIsLite()} />
                    )}
                </Measure>

                    {props.children}

                <Measure bounds onResize={(dimensions)=> SessionManager.shared().updateDimensions({item:"footer", height:dimensions.bounds.height})} whitelist={["height"]}>
                  { ({measureRef}) => (
                      <Footer ref={measureRef} copyRight={auth.getIsLite() ? "Xodus Green Light © 2020 Patented AU2017100353" : null} />
                    )}
                </Measure>

            </div>

            <div className="static-modal">
                <Modal show={props.retrying!==true && props.saveErrors != null && props.saveErrors.length > 0}>
                    <Modal.Header>
                        <Modal.Title>Internet Connection Error</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Unable to save your latest changes, please check your Internet Connection.</p>
                        <p>You can fix the connection and try again, or just cancel, but make sure to check your lastest updates.</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={() => SessionManager.shared().cancelRetry()}>Cancel</Button>
                        <Button bsstyle="primary" onClick={ () => SessionManager.shared().performRetry}>Retry</Button>
                    </Modal.Footer>

                </Modal>

            </div>
            <div className="static-modal">
                <Modal show={props.retrying===true}>
                    <Modal.Body style={{textAlign:"center"}}>
                        <h2>Retrying Changes...</h2>
                        <Spinner/>
                    </Modal.Body>
                </Modal>

            </div>

            {version_display}
        </div>
    )

}

const mapStateToProps = (state, ownProps) => ({
    siteData: state.session.siteData,
    retrying: state.session.retrying,
    saveErrors: state.session.saveErrors,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    doLogout: () =>{
        SessionManager.shared().logout();
    }
});

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(NavigationLayout));

import {
    LOAD_TREE_START,
    LOAD_BRANCH_START,
    LOAD_TREE_DONE,
    LOAD_BRANCH_DONE,
    LOAD_BRANCH_ERROR,
    LOAD_TREE_ERROR,
    CLEAR_SUMMARY_OBJECT
} from "../actions/actions"
import {LOAD_SUMMARY_OBJECT_DONE, LOAD_SUMMARY_OBJECT_START} from "../actions/actions"

export default function summary(state = {children:null, objects:null, loadState:{}, isLoading:false, publishSummary:null}, action) {

    switch (action.type)
    {
        case LOAD_TREE_START:
            return {tree:null,children:null, objects:null, isLoading:true};

        case LOAD_BRANCH_START: {
            let object_id = action.object_id;
            let loadState = {...state.loadState};
            loadState[object_id] = "loading";

            return {...state, loadState: loadState};
        }

        case LOAD_TREE_DONE: {
            let data = action.data;
            if (state.objects == null){
                return {children: data.children, objects: data.objects, loadState: {}, isLoading: false};
            }
            let update = {};
            Object.assign(update,state.objects,data.objects);

            let childUpdate = {};
            Object.assign(childUpdate,state.children,data.children);

            return {children: childUpdate, objects: update, loadState: {}, isLoading: false};
        }

        case LOAD_BRANCH_DONE: {
            let data = action.data;
            // console.log("Load_branch_done ",data);
            // console.log(data);
            let object_id = action.object_id;

            // replace the child & add new objects
            // console.log("children before;");
            // console.log(children);
            let children = {...state.children};

            // console.log("children after:");
            // console.log(children);

            let objects = {...state.objects};

            Object.keys(data.children).forEach(key => children[key] = data.children[key]);
            Object.keys(data.objects).forEach(key => objects[key] = data.objects[key]);

            let loadState = {...state.loadState};
            loadState[object_id] = "loaded";

            return {...state, children: children, objects: objects, loadState: loadState};
            // return {...state, children: children, objects: objects};
        }
        case LOAD_BRANCH_ERROR: {
            let object_id = action.object_id;
            let loadState = {...state.loadState};
            loadState[object_id] = "error";

            return {...state, loadState: loadState};
        }
        case LOAD_TREE_ERROR:
            return {...state};

        case LOAD_SUMMARY_OBJECT_START:
        {
            let displayed_object_id = action.displayed_object_id;

            return {...state, selectedObjectId:displayed_object_id, selectedIsLoading:true};

        }

        case LOAD_SUMMARY_OBJECT_DONE:
        {
            let object_id = action.object_id;
            let data = action.data;

            if (object_id === state.selectedObjectId) {
                return {...state, selectedData: data, selectedIsLoading:false};
            }
            break;
        }

        case CLEAR_SUMMARY_OBJECT:
        {
            return {...state,selectedObjectId:null, selectedData:null}
        }

        case "LOAD_LIBRARY_PUBLISH_SUMMARY_START":
        {
            return {...state,publishSummary:null,isLoading: true};
        }
        case "LOAD_LIBRARY_PUBLISH_SUMMARY_DONE":
        {
            return {...state,publishSummary:action.data,isLoading: false};
        }
        default:{}

    }
    return state;
}

/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import sitedata from '../../utils/sitedata'
import { connect } from 'react-redux'
import {updateAnswer, saveAnswersStart, saveAnswersSuccess, saveAnswersFailed} from '../../redux/actions/actions'
import {loadReportStart, loadReportDone} from '../../redux/actions/actions'
import {loadSummaryObjectDone, loadSummaryObjectStart} from '../../redux/actions/actions'
import values from "object.values"
import ComplianceMgrContainer from "./ComplianceMgrContainer";
import ComplianceHomeView from "../../views/ComplianceHomeView";
import SessionManager from "../../utils/SessionManager";

const mapStateToProps = (state, ownProps) =>
{
    let currentObject = state.reports.currentObject;

    return {
        report: (currentObject==null)? null : currentObject,
        project: (currentObject==null)? null : currentObject.project,
        isLoading: state.reports.isLoading,
        task: state.reports.currentTask,
        unsavedAnswers:state.reports.unsavedAnswers,
        selectedIsLoading:state.summary.selectedIsLoading,
        selectedData:state.summary.selectedData,
        siteData: state.session.siteData,
        dimensions: state.session.dimensions,

    }
}



const saveAnswers = function(dispatch, unsavedAnswers)
{

    console.log("Calling Save Answers - " + unsavedAnswers.answers);
    if (unsavedAnswers.answers == null || unsavedAnswers.savingAnswers != null) return;
    let answers = unsavedAnswers.answers;

    dispatch(saveAnswersStart());

    if (!Object.values) values.shim();

    // save the answer
    ebase.ajax({
        url:"/ebase/envid-client/write-report-saveanswers",
        data:Object.values(answers),
        success:(result) => {
            // once saved it will return all the changes, we'll then apply the changes to the model
            if (result.success) {
                dispatch(saveAnswersSuccess(result.data));
            } else {
                dispatch(saveAnswersFailed(result.error));
            }
        },
        error:() => dispatch(saveAnswersFailed("Couldn't contact server"))
    });

}

const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {
        updateAnswer: (answer) => dispatch(updateAnswer(answer)),
        saveAnswers: (unsavedAnswers) => saveAnswers(dispatch, unsavedAnswers),

        loadReport: (project_id) => {

            dispatch(loadReportStart());
            ebase.ajax({
                url: "/ebase/envid-client/read-report-load",
                data: {project_id: project_id, report_type: "Compliance"},
                success: (result) => {
                    if (result.success) {
                        dispatch(loadReportDone(result));
                    } else {
                        ownProps.showLoadError("Error Loading Project", result);
                    }
                },
                error: (result) => {
                    ownProps.showLoadError("Error Loading Project", result);
                }
            })

        },


        makeTitle(project, page_name)
        {
            if (project == null) return page_name;
            return project.name + ": " + page_name;
        },

        loadObject: (object) => {
            let object_id = object.object_id;
            let displayed_object_id = (object.displayed_object_id == null ) ? object.object_id : object.displayed_object_id;
            dispatch(loadSummaryObjectStart(object_id, displayed_object_id));

            ebase.ajax({
                url: "/ebase/envid-client/read-report-object",
                data: {object_id: displayed_object_id},
                success: (result) => {
                    if (result.success) {
                        dispatch(loadSummaryObjectDone(displayed_object_id, result.data));
                    } else {
                        ownProps.showLoadError("Error Loading Summary", result);
                    }
                },
                error: (result) => {
                    ownProps.showLoadError("Error Loading Summary", result);
                }

            });
        }
    }
}

class ReportChildrenView extends React.Component{

    constructor(props) {
        super(props);
        let params = sitedata.getParams(this.props);
        let object_id = params.projectId;
        this.props.loadReport(object_id);
    }

    render() {

        console.log("ComplianceHomeContainer render");
        console.log(this.props);
        console.log("");

        let params = sitedata.getParams(this.props);

        if (params.controlId == null){
            return (
                <div>
                    {React.cloneElement(<ComplianceHomeView />, this.props)}
                </div>
            )
        }else{
            return (
                <div>
                    {React.cloneElement(<ComplianceMgrContainer/>, this.props)}
                </div>
            )
        }



    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('ComplianceHomeContainer componentDidUpdate snapshot', snapshot);
        console.log('ComplianceHomeContainer componentDidUpdate prevProps', prevProps);
        // if (prevProps.unsavedAnswers != null)
        // {
        //     this.props.saveAnswers(prevProps.unsavedAnswers);
        // }

        // let nextParams = sitedata.getParams(prevProps);
        // let params = sitedata.getParams(this.props);
        // console.log(' params', params);
        // if(nextParams.projectId !== params.projectId){
        //     this.props.loadReport(prevProps.params.projectId);
        // }

        // let params = sitedata.getParams(this.props);
        // this.props.loadReport(params.projectId);
    }

    // componentWillReceiveProps(nextProps)
    // {
    //
    //     if (nextProps.unsavedAnswers != null)
    //     {
    //         this.props.saveAnswers(nextProps.unsavedAnswers);
    //     }
    //
    //     let nextParams = sitedata.getParams(nextProps);
    //     let params = sitedata.getParams(this.props);
    //     if(nextParams.projectId !== params.projectId){
    //         this.props.loadReport(nextProps.params.projectId);
    //     }
    // }

    // componentWillMount()
    // {
    //     let params = sitedata.getParams(this.props);
    //     let object_id = params.projectId;
    //     this.props.loadReport(object_id);
    // }

};

const ComplianceHomeContainer = connect(mapStateToProps, mapDispatchToProps)(ReportChildrenView)

export default ComplianceHomeContainer

import React  from 'react';
import Measure from 'react-measure';
import { connect } from 'react-redux';
import TopHeader from "../common/TopHeader";
import auth from "../../utils/auth";
import SessionManager from "../../utils/SessionManager";
import Footer from "../common/Footer";
import {withTranslation} from "react-i18next";

const LiteLayout = props =>{

    const {t} = props;

    let wrapperClass = "gray-bg";

    // console.log('LiteLayout:: ',props);

    let datasetName = "";

    return (
        <div style={{height:"100%", overflow:"hidden"}}>
            <div id="wrapper">
                <div id="page-wrapper" className={wrapperClass} style={{minHeight:"300px"}}>
                    <Measure bounds onResize={(dimensions)=> SessionManager.shared().updateDimensions({item:"topheader", height:dimensions.bounds.height})} whitelist={["height"]}>
                        { ({measureRef}) => (
                            <TopHeader ref={measureRef} onLogout={props.doLogout} title={datasetName} isLite={auth.getIsLite()} />
                        )}
                    </Measure>

                        {props.children}

                    <Measure bounds onResize={(dimensions)=> SessionManager.shared().updateDimensions({item:"footer", height:dimensions.bounds.height})} whitelist={["height"]}>
                        { ({measureRef}) => (
                        <Footer ref={measureRef} copyRight={auth.getIsLite() ? t("Xodus Green Light © 2022 Patented AU2017100353") : null} />
                        )}
                    </Measure>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    siteData: state.session.siteData
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    doLogout: () =>{
        SessionManager.shared().logout();
    }
});
export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(LiteLayout));

/**
 * Created by lewellyn on 13/5/19.
 */
import React from 'react';
import {connect} from "react-redux";
import ContainerHeader from "../components/common/ContainerHeader";
import PageWrapper from "../components/common/PageWrapper";
import SummaryFull from "../components/common/SummaryFull";
import SplitPane from 'react-split-pane'
import details from "../utils/details";
import Spinner from "../components/common/Spinner";
import DataDetailsReadOnly from "./DataDetailsReadOnly";
import {withTranslation} from "react-i18next";

class DataSummaryTreeView extends React.Component {

    selectObject(object,parent)
    {
        // load the object from the server

        // console.log("DataSummaryTreeView:: select object ");
        // console.log(object);
        // console.log("parent:");
        // console.log(parent);

        let pathname = this.props.location.pathname;

        if (pathname === "/impactsummary"){

            //receptors need the parent since the info needs
            //date from the impact-recptor link object
            if(object.type === "Receptor" && parent !== null){
                this.props.loadObject(object,"ImpactSummary",parent);
            }else{
                this.props.loadObject(object,"ImpactSummary",null);
            }


        } else if (pathname === "/regionsummary"){

            //receptors need the parent since the info needs
            //date from the impact-recptor link object
            if(object.type === "Receptor" && parent !== null){
                this.props.loadObject(object,"RegionSummary",parent);
            }else{
                this.props.loadObject(object,"RegionSummary",null);
            }
        } else if (pathname === "/receptorsummary"){

            this.props.loadObject(object,"ReceptorSummary",null);
        }

    }

    loadBranch(object_id){

        // console.log("DataSummaryTreeView:: load branch - "+object_id);
        let pathname = this.props.location.pathname;
        if (pathname === "/impactsummary"){
            this.props.loadBranch(object_id,"ImpactSummary");
        }
        if (pathname === "/regionsummary"){
            this.props.loadBranch(object_id,"RegionSummary");
        }
        if (pathname === "/receptorsummary"){
            this.props.loadBranch(object_id,"ReceptorSummary");
        }

    }

    render() {

        const {t} = this.props;

        // console.log("DataSummaryTreeView :: render ",this.props);
        // console.log(this.props);

        let pathname = this.props.location.pathname;

        if (pathname === null || pathname === undefined){
            return (<div>{t("No Summary Data for this type")}</div>);
        }

        var page_title = "";

        var topType = "";

        if (pathname === "/impactsummary"){
            page_title = t("Impact Summary");
            topType = "ProjectCategory";
        } else if (pathname === "/regionsummary"){
            page_title = t("Region Summary");
            topType = "Region";
        } else if (pathname === "/receptorsummary"){
            page_title = t("Receptor Summary");
            topType = "Receptor";
        }

        // console.log("DataSummaryTree:: top type: '"+topType+"'");

        let selected = <div>{t("Select an object")}</div>;

        let downloadButton =[];

        let pane_style = {padding:20, overflow:"scroll"};
        pane_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height;
        pane_style.overflowY = "scroll";
        pane_style.overflowX = "hidden";
        pane_style.textOverflow = "ellipsis";

        if (this.props.isLoading)
        {
            return <div>
                <ContainerHeader title={page_title} crumbs={['/', page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <Spinner/>
                </PageWrapper>
            </div>
        }

        if (this.props.selectedIsLoading === true)
        {
            selected = <Spinner/>
        }
        else if (this.props.selectedData != null)
        {
            let template = details.getObjectReadOnlyTemplate(this.props.selectedData.object.type);
            if (template != null){
                selected = <DataDetailsReadOnly fields={template[0].fields} currentObject={this.props.selectedData}/>
            }else {
                selected = <div>Not Template for {this.props.selectedData.object.type}</div>
            }



            // console.log("DataSummaryTreeView :: template ",template);


            // if(this.props.selectedData.object.type === "Region"){
            //     downloadButton = (
            //         <div style={{marginBottom:10,textAlign:'right'}}>
            //             <EButton onClick={() => this.getCSVClick(this.props.selectedData.object.object_id)}>Get Stakeholder CSV</EB>
            //         </div>
            //     );
            // }

        }

        return (
            <div>
                <ContainerHeader title={page_title} crumbs={['/', page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions} style={{padding:0, margin:0, position:"relative"}}>
                    <SplitPane split="vertical" minSize={350}>
                        <div style={pane_style}>
                            <SummaryFull topType={topType} children={this.props.children} objects={this.props.objects} onclick={this.selectObject.bind(this)} loadBranch={this.loadBranch.bind(this)} loadState={this.props.loadState}/>
                        </div>
                        <div style={pane_style}>
                            {downloadButton}
                            {selected}
                        </div>
                    </SplitPane>
                </PageWrapper>
            </div>

        );
    }

}

const mapStateToProps = (state, ownProps) =>{

    return {
        dimensions:state.session.dimensions,
    }

};


export default withTranslation()(connect(mapStateToProps)(DataSummaryTreeView));

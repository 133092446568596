import React from 'react';
import {withTranslation} from "react-i18next";
import envid from "../../utils/envid"

class LibraryPublishSummary extends React.PureComponent{

    constructor(props) {
        super(props);

        this.rowClicked = this.rowClicked.bind(this);
        this.getCount = this.getCount.bind(this);
        this.getPcnt = this.getPcnt.bind(this);
    }

    rowClicked(state){
        // console.log('clicked',state);
        this.props.viewObjects(state);
    }

    getCount(state){
        if (this.props.summary == null){
            return '-'
        }

        let count = this.props.summary[state];
        if (count == null){
            return '-';
        }

        return count;
    }

    getPcnt(state){
        if (this.props.summary == null){
            return ''
        }

        let count = this.props.summary[state];
        let total = this.props.summary["Total"];
        if (count == null || total == null){
            return '';
        }
        return envid.formatNumberToPlaces((count / total*100),0) + "%";
    }

    render(){

        // console.log('LibraryPublishSummary::',this.props);

        const {t} = this.props;

        let rows = [];

        let tds ={verticalAlign:"middle"};
        let disclosure = <i className="fa fa-angle-right" style={{fontSize:"2em",color:"#636363"}}></i>;
        let ebaseGreen = envid.getEbaseGreen();

        rows = envid.getPublishStates().map((state,i) => {
            const count = this.getCount(state);

           return(
            <tr key={state+''+i} style={{cursor:(count !== "0" && count !== "-") ? "pointer":"default"}} onClick={() => this.rowClicked(state)}>
                <td style={tds}>
                    <span  className="label" style={envid.getPublishTextStyle(state)}>{state}</span>
                </td>
                <td style={{verticalAlign:"middle",textAlign:'right'}}>
                    <div style={{textAlign:'end',marginRight:0}}>{count}</div>
                </td>
                <td style={{verticalAlign:"middle", fontStyle:"italic"}}><div style={{textAlign:'end',marginRight:0, color:ebaseGreen}}>{this.getPcnt(state)}</div></td>
                <td style={{textAlign:'right'}}>
                    {disclosure}
                </td>
            </tr>
           );
        })

        return (
            <div>
                <div className="ibox-title">
                    <h3>{t("Library Summary")}</h3>
                </div>
                <div className="ibox-content">
                    <table className="table table-hover no-margins" style={{width: '100%', tableLayout:'fixed'}}>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

}
export default withTranslation()(LibraryPublishSummary)

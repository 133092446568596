import {Store} from "redux";
import {ConfigurationParameters, EbaseAuthenticateWSApi, Configuration, SiteDataResult} from "../fetchapi";
class EbaseDS {

  private baseUrl: string;
  private store;

  private authWs: EbaseAuthenticateWSApi;

  constructor(appBase: string, store: Store<any>) {
    console.log(' appBase', appBase);
    this.baseUrl = appBase;
    this.store = store;
    const parameters: ConfigurationParameters = {};
    parameters.basePath = appBase;
    const config: Configuration = new Configuration(parameters);

    this.authWs = new EbaseAuthenticateWSApi(config);

  }

  public loginSession(email: string, password: string, callback: (res?: SiteDataResult | null, error?: string | null) => void): void{
    this.authWs.authenticate({username: email, password: password}).then(result => {
      if (result.success){
        callback(result.data);
      }else{
        callback(null, result.error);
      }
    }).catch(error => {
      console.log(' auth catch', error);
      let msg = "Connection Error";
      if (error != null){
        msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
      }
      callback(null, msg);
      this.handleResponseError(error);
    });

  }

  // public loginSessionPromise(email: string, password: string): Promise<SiteDataResult>{
  //
  //   this.authWs.authenticate({username: email, password: password}).then(result => {
  //     if (result.success){
  //       return Promise.resolve(result.data);
  //     }else{
  //       return Promise.reject(result.error);
  //     }
  //   }).catch(error => {
  //     console.log(' auth catch', error);
  //     let msg = "Connection Error";
  //     if (error != null){
  //       msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
  //     }
  //     this.handleResponseError(error);
  //     return Promise.reject(msg);
  //   });
  //
  // }

  private handleResponseError(error: any): void {
    if (error?.response?.status === 401) {
      window.location.reload();
    }
  }
}

export default EbaseDS;

import React from 'react';
import {Table} from 'react-bootstrap'
import envid from "../utils/envid";
import StakeholderEventActionsView from "./StakeholderEventActionsView"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import {withTranslation} from "react-i18next";

class StakeholderActionsListView extends React.Component {

    rowClick(event_id){
        this.props.actionClick(event_id);
    }

    render() {

        // console.log("StakeholderActionsListView RENDER");
        // console.log(this.props);
        // console.log("---------");
        const {t} = this.props;

        let actions = this.props.actions;
        if (actions == null) actions = [];

        let body = <span>Hello</span>;


        if (actions.length === 0)
        {
            body = <span>{t("No Actions")} </span>;
        }
        else
        {
            let rows = [];

            rows = actions.map((action) => {
                let role_id = envid.getObjectField(action, 'role_id');
                let description = envid.getObjectField(action, 'description');

                let role_name = t("Unknown Role");
                let user_name = t("Unknown User");
                try {
                    let role = this.props.objectLists["ProjectRole"][role_id];
                    role_name = envid.getObjectField(role, 'name');

                    let user_id = envid.getObjectField(role, 'user_id');
                    let user = this.props.objectLists["User"][user_id];
                    user_name = envid.getObjectField(user, 'name');
                } catch (e) {
                    console.log(e);
                }

                let complete = action.details.status === "COMPLETE";
                let status = (complete) ? <span className="label label-primary">{t("Completed")}</span> :
                    <span className="label label-warning">{t("In Progress")}</span>

                let the_role = <span>{role_name}<br/><small><i className="fa fa-user"></i> {user_name}</small></span>

                return (
                    <tr style={{cursor:"pointer"}} onClick={this.rowClick.bind(this,action.details.event_id)}>
                        <td>{status}</td>
                        <td>{the_role}</td>
                        <td>{description}</td>
                        <td><i className="fa fa-angle-right" style={{fontSize:"2em",color:"#636363"}}></i></td>
                    </tr>);
            })

            body = (
                <Table className="table table-hover">
                    <thead>
                    <tr>
                        <th>Status</th>
                        <th><i className="fa fa-address-card-o" style={{marginRight:5,fontSize:'1.1em'}}></i>{t("Role")}</th>
                        <th>{t("Description")}</th>
                        <th style={{width:1}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </Table>
            );
        }


        return (
            <div>

                <div className="mail-box-header">
                    <h2>
                        <EnvidObjectIcon type="Action"/>&nbsp;&nbsp;&nbsp;&nbsp;{t("Actions")}
                        {/*&nbsp;&nbsp;<span className="label label-success" style={{fontSize:16}}>{this.props.actions.length}</span>*/}
                    </h2>

                    <div className="mail-body">
                        <StakeholderEventActionsView readOnly={this.props.readOnly}
                                                     showTitle={false}
                                                     actions={this.props.actions}
                                                     objectLists={this.props.objectLists}
                                                     navigate={true}
                                                     navigateClick={this.rowClick.bind(this)}
                                                     updateField={this.props.updateField}
                                                     saveFields={this.props.saveFields}


                        />
                    </div>
                </div>

            </div>
        );


    }

}

export default withTranslation()(StakeholderActionsListView)

/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientLibraryConfig
 */
export interface ClientLibraryConfig {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientLibraryConfig
     */
    templateIds?: Array<string>;
}

/**
 * Check if a given object implements the ClientLibraryConfig interface.
 */
export function instanceOfClientLibraryConfig(value: object): value is ClientLibraryConfig {
    return true;
}

export function ClientLibraryConfigFromJSON(json: any): ClientLibraryConfig {
    return ClientLibraryConfigFromJSONTyped(json, false);
}

export function ClientLibraryConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientLibraryConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'templateIds': json['templateIds'] == null ? undefined : json['templateIds'],
    };
}

export function ClientLibraryConfigToJSON(value?: ClientLibraryConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'templateIds': value['templateIds'],
    };
}


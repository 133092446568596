import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ebase_logo from "../../img/ebase-logo.png";
import envid from "../../utils/envid";
import {useSelector} from "react-redux";
import {FooterLogo} from "../../img/images";

const Footer = React.forwardRef((props, ref) => {

    const {t} = useTranslation();
    const [showVersion, setShowVersion] = useState(false);
    const serverVersion = useSelector((state) => state.session.siteData?.serverVersion);

    // let rightText = t("Xodus Green Light © 2020 Patented AU2017100353");
  let rightText = t("© 2005 - 2024 Xodus Group Ltd | If you require support, please email ");

    if (props.copyRight != null){
        rightText = props.copyRight;
    }
    return (
      <div ref={ref} className="footer fixed">
        <div className="pull-right">
          <span style={{fontFamily:'Montserrat, sans-serif'}}>powered By&nbsp;&nbsp;</span>
          <img alt="Ebase Logo" src={FooterLogo}
               style={{width: "91px", height: "25px", marginTop: "-14px", marginBottom: "-10px"}}/>
          <span onMouseEnter={() => setShowVersion(true)} onMouseLeave={() => setShowVersion(false)}
                className={showVersion === true ? "text-muted" : "text-white"}
                style={{fontSize: 8, marginLeft: 20}}>v{serverVersion} | v{envid.getAppVersion()}</span>
        </div>
        <div className="text-center">
          {rightText} <a href={'mailto:xaminSupport@xodusgroup.com'}>Xodus Support</a>
        </div>

      </div>
    )

});

export default Footer;

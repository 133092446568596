import React from 'react';
import sitedata from '../utils/sitedata'
import {Row,Col} from 'react-bootstrap'
import details from '../utils/details'

import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import SplitPane from 'react-split-pane'
import DataDetailsReadOnly from "./DataDetailsReadOnly"
import ProjectStakeholderLinkSetupView from './ProjectStakeholderLinkSetupView'
import {Tab,Tabs} from 'react-bootstrap'
import StakeholderTree from "../components/common/StakeholderTree";
import StakeholderFilter from "../utils/StakeholderFilter";
import SessionManager from "../utils/SessionManager";
import {withTranslation} from "react-i18next";

class StakeholderMgrView extends React.Component {

    constructor()
    {
        super();

        this.selectTab = this.selectTab.bind(this);
    }

    selectObject(object)
    {
        let object_id = "none";
        if (object != null)
        {
            object_id = object.object_id;
        }

        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let tabIndex = params.tabIndex;

        let filter = new StakeholderFilter(params.filter);
        SessionManager.shared().goto('/stakeholder/'+project_id+'/manage/'+filter.toString()+'/'+object_id+'/'+tabIndex);

    }

    selectTab(tabKey)
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let object_id = params.stakeholderId;
        let tabIndex = tabKey;

        let filter = new StakeholderFilter(params.filter);
        SessionManager.shared().goto('/stakeholder/'+project_id+'/manage/'+filter.toString()+'/'+object_id+'/'+tabIndex);
    }

    saveFields()
    {
        this.props.saveFields(this.props.changes);
    }

    showAll()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        // let control_id = params.controlId;
        // let tabIndex = params.tabIndex;

        let filter = new StakeholderFilter();
        SessionManager.shared().goto('/stakeholder/'+project_id+'/manage/'+filter.toString()+'/none/details');
    }

    render() {

        const {t} = this.props;

        // console.group("StakeholderMgrView");
        // console.log(this.props);
        // console.groupEnd("");
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let tabIndex = params.tabIndex;
        let stakeholderId = params.stakeholderId;
        let filter = new StakeholderFilter(params.filter);

        // the filter gives us some idea of what to show


        let page_title = t("Stakeholder Management");
        let crumbs = ['/', '/projects', '/projects/project/'+project_id+'/info', '/stakeholder/'+project_id+'/home/0', page_title];


        if (this.props.isLoading) {
            return <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)}
                                 crumbs={crumbs}
                                 dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <Spinner/>;
                </PageWrapper>
            </div>
        }

        let subtitle = "";
        if(this.props.changes !== null && this.props.changes !== undefined) {
            if (this.props.changes.savedStatus === "unchanged") {
                subtitle = "";
            }
            else if (this.props.changes.savedStatus === "saved") {
                subtitle = t("All Changes Saved");
            }
            else if (this.props.changes.savedStatus === "saving") {
                subtitle = t("Saving") + "...";
            }
            else {
                subtitle = t("Saving") + "...";
            }
        }

        let pane_style = {padding:20, overflow:"scroll"};
        pane_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height;
        pane_style.overflowY = "scroll";
        pane_style.overflowX = "hidden";
        pane_style.textOverflow = "ellipsis";

        let left_side = (
            <StakeholderTree selected={stakeholderId}
                             object={this.props.object}
                             objectLists={this.props.objectLists}
                             filter={filter}
                             user={this.props.siteData.user}
                             onclick={this.selectObject.bind(this)}/>);

        let selected = <div></div>
        if (stakeholderId === "none") {
            selected = <div>{t('Select a Stakeholder')}</div>
        } else if (this.props.isStakeholderLoading || this.props.stakeholder == null) {
            selected = <div><Spinner/></div>
        } else {

            let stakeholder = this.props.stakeholder;
            let stakeholderLink = this.props.stakeholderLink;

            let name = stakeholder.details.name;

            let template = details.getObjectReadOnlyTemplate("Stakeholder");

            let tabs = [];
            tabs.push(
                <Tab title={t('Stakeholder')} key="setup" eventKey="setup">
                    <ProjectStakeholderLinkSetupView
                        projectStakeholderLink={stakeholderLink}
                        saveFields={this.saveFields.bind(this)}
                        updateField={this.props.updateField}
                        readOnly={this.props.readOnly}
                    />
                </Tab>);

            tabs.push(
                <Tab title={t('Details')} key="details" eventKey="details">
                    <DataDetailsReadOnly fields={template[0].fields} currentObject={{object:stakeholder, objectLists:this.props.objectLists}}/>
                </Tab>);

            selected = <div>

                <Row style={{fontSize:18, marginBottom:10}}>
                    <Col md={7}>
                        <EnvidObjectIcon type="Stakeholder"/>&nbsp;&nbsp;{name}
                    </Col>
                    <Col md={2}>
                        {/*<StakeholderLabel status={link.details.status}/>*/}
                    </Col>
                </Row>

                <div style={{background:"white", padding:10}}>
                    <div className="tabs-container">
                        <Tabs id="object-tabs" activeKey={tabIndex}
                              onSelect={this.selectTab}>
                            {tabs}
                        </Tabs>
                    </div>
                </div>

            </div>


        }


        let filterMessage = null;
        if (!filter.showAll())
        {
            filterMessage = (
            <div className="alert alert-info" style={{marginBottom: 0}}>
                <Row style={{height: 15}}>
                    <Col md={10} style={{marginTop: -2}}>
                        <i className="fa"></i> <b>{filter.getDisplayMessage()}</b>
                    </Col>
                    <Col md={2} style={{textAlign: "right", marginTop: -7}}>
                        <EButton isTable={true} type="clear" onClick={this.showAll.bind(this)}>{t("Show All")}</EButton>
                    </Col>
                </Row>
            </div>)

        }

        return (
            <div>
            <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)} crumbs={crumbs} dimensions={this.props.dimensions} subtitle={subtitle}/>
                <PageWrapper dimensions={this.props.dimensions} style={{padding:0, margin:0, position:"relative"}}>

                    {filterMessage}

                <SplitPane split="vertical" minSize={350} defaultSize={400}>
                    <div style={pane_style}>
                        {left_side}
                    </div>
                    <div style={pane_style}>
                        {selected}
                    </div>
                </SplitPane>


            </PageWrapper>
        </div>);

    }

}

export default withTranslation()(StakeholderMgrView)

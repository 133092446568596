import {LOAD_REPORT_START, LOAD_REPORT_DONE, LOAD_TASK} from "../actions/actions"
import {UPDATE_ANSWER, SAVE_ANSWERS, GOTO_ANSWER} from "../actions/actions"
import {LOAD_REPORT_TEMPLATES, SAVE_REPORT_TEMPLATES, UPDATE_REPORT_TEMPLATE, CHECK_REPORT_STATUS} from "../actions/actions"
import taskUtil from '../../utils/taskUtil'
import update from 'immutability-helper';

const incrementPosition = function (task, taskPosition) {

    let bFirst = true;
    if (taskPosition == null) {
        taskPosition = [0];
        bFirst = false;
    }

    let newTaskPosition = taskPosition.slice();


    while (true) {

        let item = taskUtil.getStructureItem(task, newTaskPosition);
        if (item == null) {
            return null;
        }
        let answer = taskUtil.getAnswer(task, item.object_id);

        if (bFirst) {
            bFirst = false;
        } else {
            if (answer != null) return newTaskPosition;
        }

        // if there is a sub list, then go to the first on the list.
        if (item.list != null && item.list.length > 0 && (answer == null || answer.answer === "Yes")) {
            newTaskPosition.push(0);
        }
        else
        {
            newTaskPosition[newTaskPosition.length - 1]++;

            // now check that what we have is a valid position
            let bFinished = false;
            while (!bFinished) {

                if (newTaskPosition.length === 0) {
                    return null;
                }
                else {
                    let parentPosition = newTaskPosition.slice(0, newTaskPosition.length - 1);

                    let parent = taskUtil.getStructureItem(task, parentPosition);
                    if (parent == null) {
                        return null;
                    }
                    else if (newTaskPosition[newTaskPosition.length - 1] < parent.list.length) {
                        // we're good, just quit check
                        bFinished = true;
                    } else {
                        newTaskPosition.pop();
                        if (newTaskPosition.length > 0) {
                            newTaskPosition[newTaskPosition.length - 1]++;
                        }
                    }
                }
            }


        }
    }

}

const decrementPosition = function (task, taskPosition)
{

    let previousPosition = null;
    let currentPosition = incrementPosition(task, null);

    console.log("Checking = " + taskPosition);
    while (currentPosition != null)
    {
        previousPosition = currentPosition;
        currentPosition = incrementPosition(task, currentPosition);
        console.log("currentPosition = " + currentPosition);

        if (currentPosition.length===taskPosition.length && currentPosition.every(function(v,i) { return v === taskPosition[i]})) {
            console.log("Returning " + previousPosition);
            return previousPosition;
        }
    }

    return null;
}

export default function reports(state = {isLoading:false, currentObject:null, unsavedAnswers:{}}, action) {

    let stateChanges = {};

    switch (action.type)
    {
        case LOAD_REPORT_START:
            return Object.assign({}, state, {isLoading: true, currentObject:null});

        case LOAD_REPORT_DONE:
            if (action.result.success)
            {
                return Object.assign({}, state, {isLoading: false, currentObject:action.result.data});
            }
            return Object.assign({}, state, {isLoading: false, currentObject:null, error:action.result.error});
        case LOAD_TASK:
        {
            if (action.error != null)
            {
                return Object.assign({}, state, {isLoading: false, error:action.error});
            }
            else if (action.result != null)
            {
                let task = action.result.data;
                let newTaskPosition = null;
                if (task.object.status === "New")
                {
                    newTaskPosition = incrementPosition(task, null);
                }
                return Object.assign({}, state, {isLoading: false, currentTask:action.result.data, taskPosition:newTaskPosition});
            }
            else
            {
                return Object.assign({}, state, {isLoading: true, currentTask:null});
            }
        }
        case CHECK_REPORT_STATUS:
        {
            // find the report
            let instance_id = (action.reportSummary == null) ? action.instance_id : action.reportSummary.instance_id;

            let reports = state.currentObject.reports;
            let report = reports.find((r) => r.instance_id === instance_id);
            if (report == null) {
                console.log("Report == null");
                console.log(reports);
                return state;
            }

            let newReports = reports.filter((r) => r.instance_id !== instance_id);

            if (action.reportSummary == null)
            {
                report = Object.assign({}, report);
                report.nextCheck = (Date.now()) + 5*1000;
                report.force = null;
                newReports.push(report);
            }
            else
            {
                newReports.push(report = action.reportSummary);
                if (action.reportSummary.status === "Generating")
                {
                    report.nextCheck = (Date.now()) + 5*1000;
                    report.force = null;
                }
            }

            if (newReports != null)
            {
                let newState = update(state, {currentObject : {reports : {$set : newReports}}});
                return newState;
            }
            return state;
        }
        case LOAD_REPORT_TEMPLATES:
        {
            if (action.error != null)
            {
                return Object.assign({}, state, {templates:null, templateError:action.error});
            }
            else if (action.result != null)
            {
                let templates = action.result;

                // sort out the templates, so we know which we already use
                let currentReports = state.currentObject.reports;
                for (let i=0; i<templates.length; ++i) {
                    // check if it is active
                    let instance = currentReports.find((r) => r.template_id === templates[i].template_id);
                    templates[i].activated = (instance != null);
                }

                templates = templates.sort((t1, t2) => {if (t1.activated !== t2.activated) return t1.activated?-1:1; return (t1.name >= t2.name)?1:-1});

                return Object.assign({}, state, {templates:templates});
            }
            else
            {
                return Object.assign({}, state, {templates:null, templateError:null});
            }
        }
        case UPDATE_REPORT_TEMPLATE :
        {
            let template_id = action.template_id;
            let activate = action.activate;

            let templates = state.templates.slice();
            for (let i=0; i<templates.length; ++i)
            {
                if (templates[i].template_id === template_id)
                {
                    templates[i] = Object.assign({}, templates[i], {activated:activate});
                }
            }
            return Object.assign({}, state, {templates:templates});

        }
        case SAVE_REPORT_TEMPLATES:
        {
            if (action.error != null)
            {
                return Object.assign({}, state, {templates:null, templateError:action.error});
            }
            else if (action.result != null)
            {
                // these are the reports, so replace our reports with these ones
                let newReports = action.result;
                let currentObject = Object.assign({}, state.currentObject, {reports:newReports});
                return Object.assign({}, state, {currentObject:currentObject});
            }
            else
            {
                return Object.assign({}, state, {templates:null, templateError:null});
            }

        }
        case UPDATE_ANSWER:
        {
            console.log("UPDATE ANSWER : " + JSON.stringify(action.answer));
            // this will update the state with the new answer, and add a list of answers that need to be saved.
            // when save answer gets called it will save any unsaved answers.
            // if there is an error, it will tell the user who can choose to try again, or just cancel the changes.
            // if they cancel they'll have to reload
            let answer = action.answer;
            let object_id = answer.object_id;

            let newTask = Object.assign({}, state.currentTask);
            newTask.object.answers[object_id] = answer;

            let currentAnswer = state.currentTask.object.answers[object_id];

            let changedAnswers = {};
            let extra = {reason:null, type:currentAnswer.type};
            let cAnswer = Object.assign({}, extra, answer);
            changedAnswers[cAnswer.object_id] = cAnswer;
            console.log("CHANGED ANSWER : " + JSON.stringify(cAnswer));
            // now we see if any of the other guys needs to be set based on this
            // find the item we have just saved, and see if it has any sub items
            // don't fo the ControlsSummary Task
            let taskType = state.currentTask.object.type;
            console.log("Task Type == " + taskType);


            if (taskType === "AspectActivity") {

                console.log("Checking Children");

                let taskPosition = state.taskPosition;

                let shouldUpdateChildren = answer.answer !== "Yes" || currentAnswer.answer !== "Yes";
                let childAnswer = (answer.answer !== "Yes") ? answer.answer : "New";
                if (shouldUpdateChildren) {

                    let item = taskUtil.getStructureItem(state.currentTask, taskPosition);
                    if (item.list != null) {
                        for (var i in item.list) {
                            let oid = item.list[i].object_id;
                            let newAnswer = Object.assign({}, {object_id: oid, answer: childAnswer}, extra);
                            newTask.object.answers[oid] = newAnswer;
                            changedAnswers[oid] = newAnswer;
                        }
                    }
                }
            }

            // now we need to add the changes to the list of saved changes
            let unsavedAnswers = Object.assign({}, state.unsavedAnswers);
            if (unsavedAnswers.answers == null) {
                unsavedAnswers.answers = changedAnswers;
            } else {
                unsavedAnswers.answers = Object.assign({}, unsavedAnswers.answers, changedAnswers);
            }

            console.log("UNSAVED ANSWERS : " + JSON.stringify(unsavedAnswers.answers));

            return Object.assign({}, state, {currentTask : newTask, unsavedAnswers:unsavedAnswers});

        }

        case SAVE_ANSWERS:
        {
            let unsavedAnswers = Object.assign({}, state.unsavedAnswers);
            let newReports = null;

            // this is either starting, finished, or errored
            if (action.error != null)
            {
                // we have an error, we'll have to add the changes back into the list
                if (unsavedAnswers.answers == null) {
                    unsavedAnswers.answers = unsavedAnswers.savingAnswers;
                } else {
                    unsavedAnswers.answers = Object.assign({}, unsavedAnswers.answers, unsavedAnswers.savingAnswers);
                }
                unsavedAnswers.error = action.error;

            } else if (action.result != null)
            {
                // we've saved the answers, and now we can remove them
                unsavedAnswers.savingAnswers = null;
                unsavedAnswers.error = null;

                // we can also update the task summarys
                let taskSummaryList = action.result;
                if (taskSummaryList != null && taskSummaryList.length > 0)
                {
                    for (let i=0; i<taskSummaryList.length; ++i)
                    {
                        let s = taskSummaryList[i];
                        if (state.currentTask != null && s.type === state.currentTask.object.type)
                        {
                            stateChanges.currentTask = Object.assign({}, state.currentTask);
                            stateChanges.currentTask.object = Object.assign({}, state.currentTask.object);
                            stateChanges.currentTask.object.status = s.status;
                            stateChanges.currentTask.object.percentage = s.percentage;
                            stateChanges.currentTask.object.unknown_answers = s.unknown_answers;
                        }

                        if (stateChanges.currentObject == null)
                        {
                            stateChanges.currentObject = Object.assign({}, state.currentObject);
                            stateChanges.currentObject.summary = Object.assign({}, state.currentObject.summary);
                        }
                        stateChanges.currentObject.summary[s.type] = s;
                    }
                }

                // now we can trigger the report statuses again
                let reports = state.currentObject.reports;

                if (reports != null) {
                    newReports = reports.map((r) => {
                        let report = Object.assign({}, r);
                        report.nextCheck = Date.now();
                        report.force = true;
                        return report;
                    });
                }
            } else {
                unsavedAnswers.savingAnswers = unsavedAnswers.answers;
                unsavedAnswers.answers = null;
                unsavedAnswers.error = null;
            }

            stateChanges.unsavedAnswers = unsavedAnswers;
            let newState = Object.assign({}, state, stateChanges);

            if (newReports != null) {
                newState = update(newState, {currentObject : {reports : {$set : newReports}}});
            }
            return newState;


        }

        case GOTO_ANSWER:
        {

            let taskPosition = state.taskPosition;
            let newTaskPosition = null;
            let task = state.currentTask;

            if (action.taskPosition != null)
            {
                return Object.assign({}, state, {taskPosition : action.taskPosition});
            }

            switch (action.direction)
            {
                case "NEXT":
                {
                    let newTaskPosition = incrementPosition(task, taskPosition);

                    return Object.assign({}, state, {taskPosition : newTaskPosition});
                }
                case "PREV": {
                    let newTaskPosition = decrementPosition(task, taskPosition);

                    return Object.assign({}, state, {taskPosition : newTaskPosition});
                }
                case "HOME": break;

            }
            return Object.assign({}, state, {taskPosition : newTaskPosition});

        }

    }
    return state;
}

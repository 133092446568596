import React from 'react';
import {Container,Row,Col} from 'react-bootstrap'

class AlertsView extends React.Component {

    constructor(props){
        super(props);

        this.clicked = this.clicked.bind(this);
        this.clear = this.clear.bind(this);
        this.rowClick = this.rowClick.bind(this);


        this.state = {showOptions:false};
    }


    clicked(){
        this.setState({showOptions:!this.state.showOptions});
    }

    clear(){
        this.setState({showOptions:false});
    }

    rowClick(path){
        this.props.alertClicked(path);
    }

    render(){

        // console.log("");
        // console.log("AlertsView Render");
        // console.log(this.props);
        // console.log("");

        let alerts = this.props.alertList;


        if(alerts === null || alerts === undefined){
            return <li></li>;
        }
        if(alerts.length === 0){
            return <li></li>;
        }


        let dropDownClass = "dropdown";
        if(this.state.showOptions){
            dropDownClass = "dropdown open";
        }


        let rows = [];

        let total = 0;

        for (var i=0; i< alerts.length; i++){
            let alert = alerts[i];
            total = total + alert.count;
            let margin = 0;
            if(i !== 0){
                margin = 8

            }

            let row = <Row style={{maxWidth:290, cursor:"pointer",borderBottom:"1px solid lightgray",marginTop:margin,paddingBottom:8,paddingTop:5}} key={i} onClick={() => this.rowClick(alert.path)}>
                <Col sm={1}>
                    <span className="label label-warning">{alert.count}</span>
                </Col>
                <Col sm={10}>
                    {alert.message}
                </Col>
            </Row>;

            rows.push(row);
        }

        let alertContent = <Container>
            {rows}
        </Container>


        return(
                <li className={dropDownClass} onClick={this.clicked} onBlur={this.clear}>

                    <a className="dropdown-toggle count-info" data-toggle="dropdown">
                        <i className="fa fa-bell"></i>  <span className="label label-warning">{total}</span>
                    </a>

                    <ul className="dropdown-menu dropdown-messages">
                        <li>{alertContent}</li>
                    </ul>

                </li>
        )



    }

}

export default AlertsView

import React from 'react';
import details from '../utils/details'
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import SummaryFull from '../components/common/SummaryFull'
import Spinner from "../components/common/Spinner"
import DataDetailsReadOnly from "./DataDetailsReadOnly"

import SplitPane from 'react-split-pane'
import {withTranslation} from "react-i18next";

class KnowledgeBaseView extends React.Component {

    constructor()
    {
        super();
        this.state = {showHelp:true, showReportTemplates:false};
    }


    selectObject(object)
    {
        // load the object from the server
        this.props.loadObject(object);
        // console.log(object);

    }

    render() {

        const {t} = this.props;

        let page_title = t("Knowledge Base");
        if (this.props.isLoading)
        {
            return <div>
                        <ContainerHeader title={page_title} crumbs={['/', page_title]} dimensions={this.props.dimensions}/>
                        <PageWrapper dimensions={this.props.dimensions}>
                            <Spinner/>
                        </PageWrapper>
                    </div>
        }

        // let children = this.props.children;
        // let objects = this.props.objects;
        // let loadState = this.props.loadState;
        //
        // let summaryElement = null;
        if (this.props.children == null)
        {
            return <div>{t("Empty")}</div>
        }
        else
        {
            let pane_style = {padding:20, overflow:"scroll"};
            pane_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height;
            pane_style.overflowY = "scroll";
            pane_style.overflowX = "hidden";
            pane_style.textOverflow = "ellipsis";

            let selected = <div>{t("Select an object")}</div>
            if (this.props.selectedIsLoading === true)
            {
                selected = <Spinner/>
            }
            else if (this.props.selectedData != null)
            {
                let template = details.getObjectReadOnlyTemplate(this.props.selectedData.object.type);
                selected = <DataDetailsReadOnly fields={template[0].fields} currentObject={this.props.selectedData}/>
            }

            return (
                <div>
                    <ContainerHeader title={page_title} crumbs={['/', page_title]} dimensions={this.props.dimensions}/>
                    <PageWrapper dimensions={this.props.dimensions} style={{padding:0, margin:0, position:"relative"}}>
                        <SplitPane split="vertical" minSize={350}>
                            <div style={pane_style}>
                                <SummaryFull children={this.props.children} objects={this.props.objects} onclick={this.selectObject.bind(this)} loadBranch={this.props.loadBranch} loadState={this.props.loadState}/>
                            </div>
                            <div style={pane_style}>
                                {selected}
                            </div>
                        </SplitPane>
                    </PageWrapper>
                </div>);
        }
    }
}

export default withTranslation()(KnowledgeBaseView)

import React from 'react';
import taskUtil from '../../utils/taskUtil'
import {Table} from 'react-bootstrap'
import {withTranslation} from 'react-i18next';

class RiskAnswerControl extends React.Component{

    constructor() {
        super();

        this.state = {hoverCol:null, hoverRow:null};
    }

    render() {
        const {t} = this.props;

        // let offStyle = {
        //     marginLeft: "10px",
        //     marginRight: "10px",
        //     padding: "5px",
        //     border: "2pt #DDD solid",
        //     cursor: "pointer"
        // };
        // let onStyle = {
        //     marginLeft: "10px",
        //     marginRight: "10px",
        //     padding: "5px",
        //     border: "2pt green solid",
        //     cursor: "pointer"
        // };
        // let style = {width: 100};
        let matrix = this.props.matrix;
        let cellWidth = 95;
        let cellHeight = 35;

        //let btns = [{name:"Yes", text:"Yes"}, {name:"No", text:"No"}, {name:"DontKnow", text:"Don't Know"}];
        // let btns = [{name: "Yes", text: "Yes"}, {name: "No", text: "No"}];

        //let colours = ["#5DBDC2", "#3584C1", "#EEAE68", "#A1328B", "#DF6069"];
        let colours = taskUtil.matrix_colours;
        let header_style = {fontSize: 10};
        let hheader_style = Object.assign({}, header_style, {
            textAlign: "center",
            maxWidth: cellWidth,
            width: cellWidth,
            whiteSpacing: "nowrap",
            border: "none"
        });
        let vheader_style = Object.assign({}, header_style, {
            textAlign: "left",
            maxWidth: cellWidth,
            width: cellWidth,
            border: "none",
            whiteSpacing: "nowrap"
        });
        let cell_style = Object.assign({}, {
            fontSize: 11,
            textAlign: "center",
            maxWidth: cellWidth,
            width: cellWidth,
            height: cellHeight,
            margin: "2px",
            background: "red",
            border: "2px solid white",
            color: "white",
            cursor: "pointer",
            userSelect: "none"
        });
        if(this.props.readOnly){
            cell_style.cursor = "default";
        }
        let row_style = {border: "none"};

        let rows = [];

        rows.push(<tr>
            <td style={{border: "none", height: 10, maxHeight: 10}}></td>
            <td style={{border: "none", height: 10, maxHeight: 10, width: 20}}></td>
            <td style={{border: "none", height: 10, maxHeight: 10}}></td>
            <td style={{border: "none", height: 10, maxHeight: 10, padding: 0}} colSpan="6">{t('Likelihood')}</td>
        </tr>);

        let hiIndexH = (this.state.hoverCol != null) ? this.state.hoverCol : this.props.value.likelihood;
        let hiIndexV = (this.state.hoverRow != null) ? this.state.hoverRow : this.props.value.consequence;
        let hiStyle = {fontWeight: "normal"};
        let nhiStyle = {fontWeight: "normal", color: "#AAA"};

        // start with a header row
        rows.push(<tr style={row_style}>
            <td style={{border: "none"}}></td>
            <td rowSpan="7" style={{border: "none", width: 30, maxWidth: 30}}>
                <div style={{textAlign: "right", marginTop: 175, transform: "rotate(-90deg)"}}>{t('Consequences')}</div>
            </td>
            <td style={vheader_style}></td>
            {matrix.likelihood.map((elem, i) => <td style={(i === hiIndexH) ? Object.assign({}, hheader_style, {background:"#DDD"}) : hheader_style} title={t(elem.desc)}><span
                style={(i === hiIndexH) ? hiStyle : nhiStyle}>{elem.code}<br/>{t(elem.name)}</span></td>)}
            <td style={{border: "none"}}></td>
        </tr>);

        for (let i = 0; i < matrix.consequence.length; ++i) {
            let consequense = matrix.consequence[i];

            rows.push(<tr style={row_style}>
                <td style={{border: "none"}}></td>
                <td style={(i === hiIndexV) ? Object.assign({}, vheader_style, {background:"#DDD"}) : vheader_style} title={t(consequense.desc)}><span
                    style={(i === hiIndexV) ? hiStyle : nhiStyle}>{consequense.code}&nbsp;&nbsp;&nbsp;{t(consequense.name)}</span>
                </td>
                {matrix.likelihood.map((elem, j) => {
                    let selected = this.props.value != null && (this.props.value.likelihood === j && this.props.value.consequence === i);
                    let highlighted = hiIndexV === i && hiIndexH === j;
                    let text = null;//consequense.code + elem.code;
                    let severity = matrix.matrix[i][j];
                    let cstyle = Object.assign({}, cell_style, {background: colours[severity]});

                    if (selected) {
                        text = <span><i className="fa fa-check"></i> {t(matrix.residual_risk[severity].name)}</span>
                        //cstyle.border = "4px solid white";
                        cstyle.fontWeight = "bold";
                        //cstyle.color = "black";
                        //cstyle.zIndex = -2;
                    }
                    else if (highlighted) {
                        text = <span>{t(matrix.residual_risk[severity].name)}</span>
                        //cstyle.border = "4px solid white";
                        cstyle.fontWeight = "normal";
                        cstyle.color = "#EEE";
                        //cstyle.color = "black";
                        //cstyle.zIndex = -2;
                    }
                    if(this.props.readOnly){
                        return <td style={cstyle}>{text}</td>
                    }else {
                        return <td style={cstyle}
                                   onMouseEnter={() => this.setState({hoverRow: i, hoverCol: j})}
                                   onMouseLeave={() => this.setState({hoverRow: null, hoverCol: null})}
                                   onClick={() => this.props.onChanged(i, j, matrix.matrix[i][j])}
                                   onDoubleClick={(e) => {
                                       e.stopPropagation();
                                       this.props.onChangedAndNext(i, j, matrix.matrix[i][j]);
                                   }}
                        >
                            {text}
                        </td>
                    }
                })}
            </tr>)
        }

        return (
            <Table style={{borderCollapse: ""}}>
                <tbody>
                {rows}
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(RiskAnswerControl);

/**
 * Created by lewellyn on 15/5/19.
 */
import React from 'react';
import DataSummaryTreeContainer from "../components/containers/DataSummaryTreeContainer";

class ImpactSummaryView extends React.Component {

    render() {

        return (
            <div>
                {React.cloneElement(<DataSummaryTreeContainer/>,this.props)}
            </div>
        );
    }

}


export default ImpactSummaryView
import jq from "../utils/jq";

const rest_backend = {
    type: 'backend',
        init: function(services, backendOptions, i18nextOptions) {
        console.log("---- i18next rest-backend init", services, backendOptions, i18nextOptions)
    /* use services and options */
    },
    read: function(language, namespace, callback) {
        /* return resources */
        console.log("---- i18next rest-backend read", language, namespace)

        let args = {};
        args.type = "POST";
        args.data = JSON.stringify({languageCode:language, namespaceCode:namespace});
        args.contentType = "application/json; charset=utf-8";
        args.url = "/ebase/translation/read-translation-namespace";

        args.error = (err) => {
            console.log("---- i18next rest-backend read error", err);
            callback(false, null);
        }
        args.success = (result) => {
            console.log("---- i18next rest-backend read success", result);
            callback(null, result);
        }

        jq.ajax(args);

        /* if method fails/returns an error, call this: */
        /* callback(truthyValue, null); */
    },

    // only used in backends acting as cache layer
    save: function(language, namespace, data) {
        console.log("---- SAVING TRANSLATION : ", language, namespace, data);
        // store the translations
    },

    create: function(languages, namespace, key, fallbackValue) {
        console.log("---- CREATE TRANSLATION : ", languages, namespace, key, fallbackValue);
        let args = {};
        args.type = "POST";
        args.data = JSON.stringify({languageCode:languages[0], namespaceCode:namespace, key:key, value:fallbackValue});
        args.contentType = "application/json; charset=utf-8";
        args.url = "/ebase/translation/write-translation-namespace";

        args.error = (err) => {
            console.log("---- i18next rest-backend write error", err);
        }
        args.success = (result) => {
            console.log("---- i18next rest-backend write success", result);
        }

        jq.ajax(args);
    }
}

export default rest_backend;

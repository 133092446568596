import React from 'react';
import sitedata from '../utils/sitedata'
import details from '../utils/details'
import PropTypes from 'prop-types';

import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import taskUtil from "../utils/taskUtil"
import {SummaryTree, SummaryItem} from '../components/common/SummaryTree'
import SplitPane from 'react-split-pane'
import DataDetailsReadOnly from "./DataDetailsReadOnly"
import SessionManager from "../utils/SessionManager";
import {withTranslation} from "react-i18next";

class ActivityTaskView extends React.Component {

    constructor()
    {
        super();
        this.state = {showHelp:true, showReportTemplates:false};

        this.manageReports = this.manageReports.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.selectNone = this.selectNone.bind(this);
        this.changeAll = this.changeAll.bind(this);
        this.changeAnswer = this.changeAnswer.bind(this);
        this.renderObject = this.renderObject.bind(this);
    }

    showTask(type)
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        SessionManager.shared().goto("/report-controlssummary/" + project_id + "/task/" + type);
    }

    manageReports()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        this.props.loadReportTemplates(project_id);
        this.setState({showReportTemplates:true});
    }

    updateReports()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        this.props.saveReportTemplates(project_id, this.props.templates);
        this.setState({showReportTemplates:false});
    }

    editProject()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        SessionManager.shared().goto("/projects/project/" + project_id + "/info");
    }

    // value param represents whether all or no checkboxes are to be selected
    changeAll(value)
    {
        let task = this.props.task;
        for (let i=0; i<task.object.structure.list.length; ++i)
        {

            let object_id = task.object.structure.list[i].object_id;
            // let object = taskUtil.getObject(task, "Activity", object_id);
            let answer = taskUtil.getAnswer(task, object_id);

            // if change has occured
            if (answer.answer !== value)
            {
                this.changeAnswer(answer);
            }

        }

    }

    selectObject(object)
    {
        // load the object from the server
        this.props.loadObject(object);
    }

    selectAll()
    {
        this.changeAll("Yes");
    }

    selectNone()
    {
        this.changeAll("No");
    }

    changeAnswer(answer)
    {
        let params = sitedata.getParams(this.props);
        // if they answer no to a control question, we have to ask the for a justification
        let changes = {project_id:params.projectId, taskType:"Activity"};
        changes.answer = (answer.answer === "Yes") ? "No" : "Yes";
        changes.reason = null;

        let newAnswer = Object.assign({}, answer, changes);
        this.props.updateAnswer(newAnswer);

        console.log("Changing The Answer Test - ");
        console.log(newAnswer)
    }

    renderObject(task, object)
    {
        let style_small_space = {marginRight:"5px"};

        let ret = [];

        let type = object.type;

        // lookup the answers
        let answer = taskUtil.getAnswer(task, object.object_id);

        let check = null;

        if (answer != null)
        {
            let checked = null;

            if (answer.answer === "Yes")
            {
                checked = true;
            }
            else
            {
                checked = false;
            }

            if(this.props.readOnly){
                check = <input type="checkbox" checked={checked} style={{marginLeft: 3, marginTop: 2, fontSize: 18, width: 20}} />
            }else {
                check = <input type="checkbox" checked={checked} style={{marginLeft: 3, marginTop: 2, fontSize: 18, width: 20}}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.changeAnswer(answer)
                    }}/>
            }
        }

        ret.push(<EnvidObjectIcon type={type} showName={false}/>);
        ret.push(<span style={style_small_space}/>);

        if (type === "Control")
        {
            let name = object.details.name;

            //let index = name.lastIndexOf(" from ");
            let index = name.indexOf(" for ");
            if (index > 0) {
                name = name.substring(0, index);
            }
            ret.push(name);
        }
        else
        {
            ret.push(object.details.name);
        }

        return <div style={{whiteSpace:"nowrap"}}>{check}<span className="tree-item" style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>&nbsp;{ret}</span></div>
    }

    renderChildren(task)
    {
        let children = [];
        for (let i=0; i<task.object.structure.list.length; ++i)
        {
            let activity_structure = task.object.structure.list[i];
            let object_id = activity_structure.object_id;
            let object =  taskUtil.getObject(task, "Activity", object_id);

            let controls = [];

            if (activity_structure.list!=null) {
                for (let j = 0; j < activity_structure.list.length; ++j) {
                    let control_id = activity_structure.list[j].object_id;
                    let control = taskUtil.getObject(task, "Control", control_id);
                    controls.push(<SummaryItem key={"control-" + control_id} object={control}/>);
                }
            }

            let child = <SummaryItem key={"activity-"+object_id} object={object}>{controls}</SummaryItem>
            children.push(child);
        }

        return children;
    }

    render() {

        const {t} = this.props;

        let page_title = t("Select Activities");
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;

        if (this.props.isLoading || this.props.report == null)
        {
            let p = null;
            if (this.props.projectName != null)
            {
                p = {name:this.props.projectName};
            }


            return <div>
                        <ContainerHeader title={this.props.makeTitle(p, page_title)} crumbs={['/', '/projects', '/projects/project/'+project_id+"/info", '/report-controlssummary/'+project_id+"/task", page_title]} dimensions={this.props.dimensions}/>
                        <PageWrapper dimensions={this.props.dimensions}>
                            <Spinner/>;
                        </PageWrapper>
                    </div>
        }

        let report = this.props.report;
        let project = this.props.project;

        // var navs = [];
        // var total_percentage = 0;
        // navs = [];
        // total_percentage = Math.round(total_percentage);
        // var help = null;
        // let project_title = project.name;
        // let lastUpdate = (docInfo) => (docInfo==null) ? <span></span> : <span>Last Updated {new Date(docInfo.last_update_time).toLocaleDateString()}<br/>by {docInfo.last_update_user_name}</span>;
        // let names = [t("Select Actvities"), t("Generate Reports")];

        let status = [];
        // Work out the status for each hub box

        // Provide Context Status
        status.push(taskUtil.combineStatus([report.summary["Activity"]]));

        // Reports
        status.push("Ready");

        let selected_block = null;
        {
            let task = this.props.task;
            let activity_list = null;

            if (task == null)
            {
                activity_list = <div><Spinner/></div>
            }
            else {

                let pane_style = {padding:20, overflow:"scroll"};
                pane_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height;
                pane_style.overflowY = "scroll";
                pane_style.overflowX = "hidden";
                pane_style.textOverflow = "ellipsis";

                let selected = <div>{t('Select an object')}</div>
                if (this.props.selectedIsLoading === true)
                {
                    selected = <Spinner/>
                }
                else if (this.props.selectedData != null)
                {
                    let template = details.getObjectReadOnlyTemplate(this.props.selectedData.object.type);
                    selected = <DataDetailsReadOnly fields={template[0].fields} currentObject={this.props.selectedData}/>
                }

                activity_list = (
                        <SplitPane split="vertical" minSize={400}>
                            <div style={pane_style}>
                                <SummaryTree renderObject={this.renderObject.bind(this, task)} callback={this.selectObject.bind(this)} defaultOpen={false}>
                                    {this.renderChildren(task)}
                                </SummaryTree>
                            </div>
                            <div style={pane_style}>
                                {selected}
                            </div>
                        </SplitPane>);

            }

            let secondTitleHeight = 50;
            let titleStyle = {fontSize:"larger",
                              fontWeight:"normal",
                              border:"1px solid #DDD",
                              marginTop:-10,
                              backgroundColor:"white",
                              padding:7,
                              height:secondTitleHeight}; // borderBottom:"2px solid black"

            selected_block = (
                    <div className="ibox float-e-margins">
                        <div className="ibox-contentx">

                            <div style={titleStyle}>
                                <span style={{paddingBottom:5, paddingTop:20, paddingRight:20}}>{t("Choose the activities in your project")}</span>
                                <EButton type="selectall" isTable={true} onClick={this.selectAll.bind(this)} disabled={this.props.readOnly}>{t("Select All")}&nbsp;</EButton>&nbsp;
                                <EButton type="selectnone" isTable={true} onClick={this.selectNone.bind(this)} disabled={this.props.readOnly}>{t("Select None")}</EButton>
                                <div className="pull-right"
                                     onClick={() => SessionManager.shared().goto('/report-controlssummary/' + project.object_id + "/summary/0" )}>
                                    <EButton>{t("Done")}</EButton></div>
                                <br/>
                            </div>
                            <div className="project-list" style={{height:this.props.dimensions.window.height - this.props.dimensions.extras.height - secondTitleHeight - 80}}>
                                {activity_list}
                            </div>

                        </div>

                    </div>);

        }
        return (
            <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)} crumbs={['/', '/projects', '/projects/project/'+project_id+'/info', '/report-controlssummary/'+project_id+"/summary/0",  page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>

                    <div className="row">

                        <div className="col-lg-12">
                            {selected_block}
                        </div>

                    </div>
                </PageWrapper>
            </div>
        )
    }

}

ActivityTaskView.propTypes = {
    report : PropTypes.object,
    project : PropTypes.object,
    isLoading : PropTypes.bool,
};


export default withTranslation()(ActivityTaskView)

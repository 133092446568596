class EnvidPeriod extends Object
{
    constructor(speriod) {
        super();

        this.is_one_off = speriod == null || speriod === "ONE_OFF";


        this.count = 0;
        this.type = "days";

        if (speriod != null)
        {
            let s = speriod.split(" ");
            try {
                this.count = s[0];
                this.type = s[1];
            } catch (e)
            {

            }

        }

        this.toString = function () {
            return this.count + " " + this.type;
        }

        this.isOneOff = function()
        {
            return this.is_one_off;
        }

        this.setOneOff = function(b)
        {
            this.is_one_off = b;
        }

        this.getCount = function()
        {
            return this.count;
        }

        this.getType = function () {
            return this.type;
        }

        this.setCount = function(count)
        {
            this.count = count;
        }

        this.setType = function(type)
        {
            this.type = type;
        }

        this.set = function(count, type)
        {
            this.count = count;
            this.type = type;
        }
    }

}

export default EnvidPeriod;

import {LOAD_USERS_START, LOAD_USERS_DONE, LOAD_ALERTS_START, LOAD_ALERTS_DONE} from "../actions/actions"


export default function users(state = {userList:null, isLoading:false,alerts:[]}, action) {

    switch (action.type)
    {
        case LOAD_USERS_START:
            return {isLoading:true,alerts:state.alerts};

        case LOAD_USERS_DONE:
            if (action.result.success)
            {
                return {isLoaded: true, userList: action.result.data,alerts:state.alerts};
            }
            return {isLoaded:true, error:action.result.error,alerts:state.alerts};

        case LOAD_ALERTS_START:
        {
            return state;
        }

        case LOAD_ALERTS_DONE:
        {
            if(action.result.success){
                return {alerts:action.result.data,lastAlertUpdate:new Date()};
            }
            return state;
        }

        case "UPDATE_USER_START":
        {

            return {...state,savingUser:true}
        }

        case "UPDATE_USER_DONE":
        {
            return {...state,savingUser:false,savedUser:action.result};
        }

        case "LOAD_USERLIBRARIES_START":
        {
            return {isLoading:true};
        }

        case "LOAD_USERLIBRARIES_DONE":
        {
            if(action.result.success) {
                return {...state, isLoading: false, manageUserLibraries: action.result.data};
            }
            return {...state, isLoading: false};
        }
        default:{}
    }
    return state;
}

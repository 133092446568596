import React from 'react';
import {Form} from 'react-bootstrap'
import envid from '../../utils/envid'
import {withTranslation} from "react-i18next";

class ProjectRoleSelect extends React.Component {

    render() {

        const {t} = this.props;

        console.log('ProjectRoleSelect:: ',this.props);

        let roleList = Object.values(this.props.roles).sort((a, b) => {return envid.getObjectField(a, 'name').localeCompare(envid.getObjectField(b, 'name'))});
        let users = this.props.users;

        return (
            <Form.Select placeholder="select"
                         value={this.props.value}
                         onChange={this.props.onChange}>
                {(this.props.value == null) ? <option>{t("Select")}...</option> : null}
                {roleList.map((role) => {
                    // console.log(users);
                    // console.log(role);
                    let user = users[envid.getObjectField(role, 'user_id')];
                    let username = t("Empty")
                    if (user != null) {
                        username = user.name;
                    }

                    return <option value={role.object_id}>{envid.getObjectField(role, 'name')} ({username})</option>
                })}

            </Form.Select>
        )
    }
}

export default withTranslation()(ProjectRoleSelect)

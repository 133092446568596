/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import { connect } from 'react-redux'
import sitedata from "../../utils/sitedata"
import {saveAnswersSuccess} from '../../redux/actions/actions'
import {loadReportStart, loadReportDone} from '../../redux/actions/actions'
import siteDataUtil from "../../utils/sitedata";
import ReportTaskContainer from "./ReportTaskContainer";
import ReportHomeView from "../../views/ReportHomeView";
import SessionManager from "../../utils/SessionManager";

const mapStateToProps = (state, ownProps) =>
{
    let currentObject = state.reports.currentObject;
    let sData = state.session.siteData;

    return {
        report: (currentObject==null)? null : currentObject,
        project: (currentObject==null)? null : currentObject.project,
        isLoading: state.reports.isLoading,
        templates:state.reports.templates,
        templateError:state.reports.templateError,
        projectName : state.projects.projectName,
        readOnly:(!siteDataUtil.checkWriteAccess(sData,"project")),
        dimensions:state.session.dimensions,
        siteData:sData,
    }
}


// const loadReport = function(props, dispatch, project_id)
// {
//     dispatch(loadProjectStart());
//
//     ebase.ajax({
//         url:"/ebase/core/read-project-load",
//         data:{object_id : project_id},
//         success:(result) => {
//             if (result.success) {
//                 dispatch(loadProjectDone(result));
//             } else {
//                 props.showLoadError("Error Loading Project", result);
//             }
//         },
//         error :(result) => {
//             props.showLoadError("Error Loading Project", result);
//         }
//
//     });
//
// }

const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {
        loadReport : (project_id) => {
            dispatch(loadReportStart());
            ebase.ajax({
                url: "/ebase/envid-client/read-report-load",
                data:{project_id:project_id, report_type:"ImpactAssessment"},
                success: (result) => {
                    if (result.success) {
                        dispatch(loadReportDone(result))
                    } else {
                        ownProps.showLoadError("Error Loading Project", result);
                    }
                },
                error :(result) => {
                    ownProps.showLoadError("Error Loading Project", result);
                }
            })

        },

        performTaskAction : (project_id, type, action, whenfinished) => {

            //dispatch(loadReportTemplatesStart());
            return ebase.ajax({
                url: "/ebase/envid-client/write-report-taskaction",
                data:{project_id:project_id, report_type:"ImpactAssessment", type:type, action:action},
                success: (result) => {
                    if (result.success) {
                        dispatch(saveAnswersSuccess(result.data));
                    }
                    whenfinished();

                },
                error :() => {
                    whenfinished();
                }
            })

        },

        makeTitle(project, page_name)
        {
            if (project == null) return page_name;
            return project.name + ": " + page_name;
        }


    }
}

class ReportChildrenView extends React.Component{

    constructor(props) {
        super(props);
        // console.log('ReportChildrenView constructor params', sitedata.getParams(this.props));
        let params = sitedata.getParams(this.props);
        let object_id = params.projectId;
        this.props.loadReport(object_id);
    }

    render() {

        // console.log("ReportHomeContainer-ReportChildrenView:: ",this.props);

        let params = sitedata.getParams(this.props);

        if (params.task == null){
            return (
                <div>
                    {React.cloneElement(<ReportHomeView/>, this.props)}
                </div>
            )
        }else{
            return (
                <div>
                    {React.cloneElement(<ReportTaskContainer/>, this.props)}
                </div>
            )
        }
    }
}

const ReportHomeContainer = connect(mapStateToProps, mapDispatchToProps)(ReportChildrenView);

export default ReportHomeContainer

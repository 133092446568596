class CommsFilter extends Object
{
    constructor(filter) {
        super();

        if (filter == null)
        {
            filter = "0-0000-0";
        }
        this.filter = filter;

        this.toString = function () {
            return this.filter;
        }


        this.showAll = function () {
            if (filter === "0" || filter === "0-0000-0")
            {
                return true;
            }
            return false;
        }


        this.getDisplayMessage = function()
        {
            return "Not Implemented";

        }
    }
}

export default CommsFilter;

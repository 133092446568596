import React from 'react';
import EbasePanel from "../components/common/EbasePanel"
import EButton from "../components/common/EButton"
import "../ladda.min.css"
import Spinner from '../components/common/Spinner'
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import {Modal, ModalBody, ModalHeader, ModalTitle, ModalFooter} from 'react-bootstrap'
import IntersectionVisible    from 'react-intersection-visible'
import {Trans, withTranslation} from "react-i18next";



class DataManage extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {asking : false};

        //don't store loadOnShow in a state becuase don't want to trigger a render when changing it.
        //null at start so initial show/hide doesn't trigger a reload.
        this.loadOnShow = null;

        this.onDelete = this.onDelete.bind(this);
        this.onRealDelete = this.onRealDelete.bind(this);

        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onDelete()
    {
        this.setState({asking:true});
    }

    onRealDelete()
    {
        // could double check here, with a popup.
        this.props.doDelete();
    }

    onClose()
    {
        this.setState({asking:false});
    }

    onHide(entries){

        if(this.loadOnShow !== null) {
            this.loadOnShow = true;
        }
    }
    onShow(entires){
        if(this.loadOnShow === null){
           this.loadOnShow = true;
        }else if (this.loadOnShow){
            this.loadOnShow = false;
            this.props.reload();
        }
    }

    render() {

        const {t} = this.props;

        if (this.props.loading)
        {
            return <Spinner/>
        }

        let deleteCheckInfo = this.props.deleteCheckInfo;
        if (deleteCheckInfo == null)
        {
            return <div>{t("No Information Found")}</div>;
        }

        if (deleteCheckInfo.error != null)
        {
            return <div className="label-error">{deleteCheckInfo.error}</div>
        }

        let canDelete = deleteCheckInfo.canDelete;

        let modal = (

            <Modal show={this.state.asking} onHide={this.onClose.bind(this)}>
                <ModalHeader closeButon>
                    <ModalTitle>
                        {t("Delete Object")}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>

                    <p>{t("Are you sure you want to delete this object")}</p>

                </ModalBody>
                <ModalFooter>
                    <EButton isTable={true} type="cancel" onClick={this.onClose.bind(this)}>{t("Cancel")}</EButton>
                    <EButton isTable={true} type="delete" onClick={this.onRealDelete}>{t("Delete")}</EButton>
                </ModalFooter>

            </Modal>

        );

        let links = "";
        if(deleteCheckInfo != null && deleteCheckInfo.linked != null){

            links = deleteCheckInfo.linked.map( (linkedObject) =>
                <li key={linkedObject.object_id}><EnvidObjectIcon type={linkedObject.type} showName={false}/> &nbsp;{linkedObject.name}</li>

            );
        }

        if (canDelete) {

            let message = [<Trans i18nKey="delete-warning-one"><p>You can delete this object. This will remove it permanently from the database.</p><p>Please only delete this if you know what you are doing.</p></Trans>]

            let type = this.props.currentObject.object.type;
            if (type !== "ProjectCategory" && type !== "Region" && type !== "Stakeholder"){
                message = [<Trans i18nKey="delete-warning-two"><p>You can delete this object. This will remove it permanently from the database and may effect projects associated with this Library.</p><p>Please only delete this if you sure it needs to be deleted.</p></Trans>]
            }

            return (
                <IntersectionVisible onShow={(e) => this.onShow(e)} onHide={(e) => this.onHide(e)}>
                    <div>
                        {modal}

                        <div className="col-lg-6">
                            <div className={"card card-default"}>
                                <div className="card-header"><h3>{t("Delete Object")}</h3></div>
                                <div className="card-body p-lg text-left">
                                    {message}
                                    <div className="text-right">
                                        <EButton type="delete" onClick={this.onDelete}>{t("Delete Object")}</EButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IntersectionVisible>
                    )
        }

        return (
            <IntersectionVisible onShow={(e) => this.onShow(e)} onHide={(e) => this.onHide(e)}>
            <EbasePanel>
                <div style={{height:this.props.height-40,overflow:'scroll'}}>
                    <Trans i18nKey="cannot-delete-message">
                        <h3>Cannot Delete Object</h3>
                        <p>You cannot delete this object, it has connections to other objects.</p>
                        <p>Remove all it's connections if you really want to delete it.</p>
                    </Trans>

                    <ul>{links}</ul>


                </div>
            </EbasePanel>
            </IntersectionVisible>
        );
    }

}

export default withTranslation()(DataManage)

import React from 'react';
import Spinner from "../components/common/Spinner"
import EButton from "../components/common/EButton"
import {Table} from 'react-bootstrap'
import { connect } from 'react-redux'
import ebase from '../utils/ajax'

import {Modal,ModalBody,ModalHeader,ModalTitle,ModalFooter} from 'react-bootstrap'
import {loadReportTemplatesStart, loadReportTemplatesSuccess, loadReportTemplatesFailed, checkReportStatusStart,
    checkReportStatusSuccess, saveReportTemplatesSuccess, saveReportTemplatesStart, saveReportTemplatesFailed,
    updateReportTemplate
} from "../redux/actions/actions";
import {withTranslation} from "react-i18next";


/**
 * Created by craig on 23/3/18.
 */

class ManageReportsModal extends React.Component
{
    constructor()
    {
        super();

        this.updateReports = this.updateReports.bind(this);
    }

    updateReports()
    {
        console.log("saveReportTemplates");
        let project_id = this.props.project_id;
        this.props.saveReportTemplates(project_id, this.props.report.type,  this.props.templates);
        this.props.showReportTemplates(false);
    }

    reportIcon(reportType) {
        let icon = "fa-";
        if (reportType === "WORD_DOCX") {
            icon = icon + "file-word-o";
        }
        else if (reportType === "ZIP") {
            icon = icon + "file-archive-o";
        } else {
            icon = icon + "file-excel-o";
        }
        return icon;
    }
    iconColour(reportType){
        let colour = "";
        if (reportType === "WORD_DOCX") {
            colour = "#376198";
        }
        else if (reportType === "ZIP") {
            colour = "#f7ac59";
        } else {
            colour = "#3C744C"
        }
        return colour;
    }


    render() {
        const {t} = this.props;

        let modal_body = <Spinner/>
        if (this.props.templateError !=  null)
        {
            modal_body = <p>Error loading Report Templates : {this.props.templateError}</p>
        }
        else if (this.props.templates !=  null)
        {
            var tbody;

            if(this.props.templates === 0){
                tbody = <tr><td>{t("No report templates available")}</td></tr>
            }else{

                const rows = this.props.templates
                //.sort((t1, t2) => (t1.activated != t2.activated) ? ((t1.activated)?1:0) : ((t1.name >= t2.name)?1:0))
                    .map((tpt, index) =>

                            <tr key={index}>
                                <td className="project-status" style={{width:70}}><span className={"label label-" + ((tpt.activated)?"info":"")}>{(tpt.activated?t("Include"):t("Exclude"))}</span></td>
                                <td className="project-title left" style={{textAlign:"left"}}><a
                                    style={{fontSize: "normal", fontWeight: "bold"}}><i className={"fa " + this.reportIcon(tpt.type)} style={{color: this.iconColour(tpt.type)}}></i> {tpt.name}</a>
                                    <br/>
                                    {tpt.description}
                                </td>
                                <td className="project-actions" style={{width:70}}>
                                    {
                                        (!tpt.activated)?
                                            <EButton type="add" isTable={true} onClick={() => this.props.updateReportTemplate(tpt.template_id, true)}>{t("Add")}</EButton>
                                            :
                                            <EButton type="remove" isTable={true} onClick={()=>this.props.updateReportTemplate(tpt.template_id, false)}>{t("Remove")}</EButton>
                                    }
                                </td>
                            </tr>
                        , this);

                tbody = <tbody>{rows}</tbody>;
            }

            modal_body = <Table>{tbody}</Table>
        }

        return (
            <Modal show={this.props.show} onHide={()=>this.props.showReportTemplates(false)} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("Choose Your Reports")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {modal_body}

                </Modal.Body>
                <Modal.Footer>
                    <EButton isTable={true} type="cancel" onClick={()=>this.props.showReportTemplates(false)}>{t("Cancel")}</EButton>
                    <EButton isTable={true} type="save" onClick={this.updateReports}>{t("Save")}</EButton>
                </Modal.Footer>

            </Modal>
        )
    }
}

class ManageReportsView extends React.Component {

    constructor()
    {
        super();
        this.manageReports = this.manageReports.bind(this);

    }

    manageReports()
    {
        console.log(this.props.report);
        let project_id = this.props.project_id;
        this.props.loadReportTemplates(project_id, this.props.report.type);
        this.props.showReportTemplates(true);
    }

    reportIcon(reportType) {
        let icon = "fa-";
        if (reportType === "WORD_DOCX") {
            icon = icon + "file-word-o";
        }
        else if (reportType === "ZIP") {
            icon = icon + "file-archive-o";
        } else {
            icon = icon + "file-excel-o";
        }
        return icon;
    }

    iconColour(reportType){
        let colour;
        if (reportType === "WORD_DOCX") {
            colour = "#376198";
        }
        else if (reportType === "ZIP") {
            colour = "#f7ac59";
        } else {
            colour = "#3C744C"
        }
        return colour;
    }


    render() {
        const {t} = this.props;

        let titleStyleReport = {fontSize:"larger", fontWeight:"normal", marginTop:-10, paddingBottom:15}; // borderBottom:"2px solid black"

        let report = this.props.report;

        let lastUpdate = (docInfo) => (docInfo==null) ? <span></span> : <span>{t("Last Updated")} {new Date(docInfo.last_update_time).toLocaleDateString()}<br/>{t("by")} {docInfo.last_update_user_name}</span>;

        let report_rows = report.reports.map((report,index) => {
            let label = "label-primary";
            let text = lastUpdate(report.documentInfo);
            if (report.status === "Error") {
                label = "label-danger";
                text = <span>Error Generating Report</span>
            } else if (report.status === "Generating") {
                label = "label-default";
            } else if (report.status === "Pending") {
                label = "label-warning";
                text = <span>{t("Waiting for Completion of Impact & Risk Assessment")}</span>;
            }

            return (
                <tr key={index}>
                    <td className="project-status"><span className={"label " + label}>{t(report.status)}</span></td>
                    <td className="project-title left" style={{textAlign:"left"}}><span
                        style={{fontSize: "normal", fontWeight: "bold"}}><i className={"fa " + this.reportIcon(report.document_type)} style={{color:this.iconColour(report.document_type)}}></i> {report.name}</span>
                    </td>
                    <td className="project-text" style={{whiteSpace:"nowrap"}}>{text}</td>
                    <td className="project-actions">
                        <EButton type="edit" isTable={true} disabled={report.status !== "Ready"}
                                 onClick={() => window.location.assign("/ebase/envid-client/read-report-downoadreport/" + report.instance_id)}>{t("Download")}</EButton>&nbsp;
                    </td>
                </tr>);
        });

        let manageBtn = (
            <div className="pull-right">
                <EButton type="add" onClick={this.manageReports}>{t("Manage Reports")}</EButton>
            </div>
        );
        if(this.props.readOnly === true){
            manageBtn = [];
        }
        return (
            <div className="ibox">
                <div className="ibox-content p-md">

                    <div style={titleStyleReport}><span style={{paddingBottom:5, paddingTop:20}}>{t("Generate Reports")}</span>
                        {manageBtn}
                    </div>

                    <Table className="table table-hover">
                        <tbody>

                        {report_rows}

                        </tbody>
                    </Table>


                </div>
            </div>);




    }

}


const loadReportTemplates = function(project_id, report_type, dispatch, ownProps)
{
    dispatch(loadReportTemplatesStart());
    ebase.ajax({
        url: "/ebase/envid-client/read-report-templates",
        data: {project_id: project_id, report_type: report_type},
        success: (result) => {
            if (result.success) {
                dispatch(loadReportTemplatesSuccess(result.data));
            } else {
                dispatch(loadReportTemplatesFailed(result.error));
            }

        },
        error: () => dispatch(loadReportTemplatesFailed("Error contacting server"))
    })


}
const updateTheReportTemplate = function(template_id, activate, dispatch, ownProps) {
    dispatch(updateReportTemplate(template_id, activate));
}

const checkReportStatus = function(instance_id, dispatch, ownProps) {
    dispatch(checkReportStatusStart(instance_id));

    ebase.ajax({
        url: "/ebase/envid-client/read-report-instancestatus",
        data: {instance_id: instance_id},
        success: (result) => {
            if (result.success) {
                dispatch(checkReportStatusSuccess(result.data));
            }
        }
    })

}

const saveReportTemplates = function(project_id, report_type, templates, dispatch, ownProps) {

    dispatch(saveReportTemplatesStart());

    ebase.ajax({
        url: "/ebase/envid-client/write-report-manageinstances",
        data: {
            project_id: project_id,
            report_type: report_type,
            template_ids: templates.filter((t) => t.activated).map((t) => t.template_id)
        },
        success: (result) => {
            if (result.success) {
                dispatch(saveReportTemplatesSuccess(result.data));
            } else {
                dispatch(saveReportTemplatesFailed(result.error));
            }
        },
        error: () => dispatch(saveReportTemplatesFailed("Error contacting server"))
    })
}

const mapStateToProps = (state, ownProps) =>
{
    let currentReport = state.reports.currentObject;


    return {
        report: (currentReport==null)? null : currentReport,
        project_id: (currentReport==null)? null : currentReport.project.object_id,
        templates:state.reports.templates,
        templateError:state.reports.templateError,
    }
}



const mapDispatchToProps = (dispatch, ownProps) =>
{
    return {
        loadReportTemplates:
            (project_id, report_type) => {loadReportTemplates(project_id, report_type, dispatch, ownProps);},
        updateReportTemplate:
            (template_id, activate) => {updateTheReportTemplate(template_id, activate, dispatch, ownProps)},
        checkReportStatus:
            (instance_id) => {checkReportStatus(instance_id, dispatch, ownProps)},
        saveReportTemplates:
            (project_id, report_type, templates) => {saveReportTemplates(project_id, report_type, templates, dispatch, ownProps)},
    }
}


class ManageReportContainerHelper extends React.Component{

    render() {

        return (React.cloneElement(<ManageReportsView/>, this.props))
    }
    componentWillMount()
    {
        this.timer = setInterval(this.tick.bind(this), 5000);
    }

    componentWillUnmount()
    {
        clearInterval(this.timer);
    }

    tick () {
        // check the props, see if there are any reports that need checking
        if (this.props.report == null) return;
        if (this.props.report.reports == null) return;

        let reports = this.props.report.reports;

        for (let i in reports)
        {
            let r = reports[i];
            if ((r.status === "Generating" || r.force===true) && (r.nextCheck==null || r.nextCheck < Date.now()))
            {
                this.props.checkReportStatus(r.instance_id);
            }
        }
    }


};

const ManageReportsViewContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageReportContainerHelper))
const ManageReportsModalContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ManageReportsModal))

export {ManageReportsViewContainer, ManageReportsModalContainer}

import React from 'react';
import {withTranslation} from "react-i18next";


class ProjectActionStatus extends React.Component {


    constructor(props){
        super(props);

        // console.log("---- ProjectActionStatus -----");
        // console.log(props);
        // console.log("-------------------");
        this.statusLabel = this.statusLabel.bind(this);
        this.clickID = this.clickID.bind(this);
        this.actionDisplayName = this.actionDisplayName.bind(this);
    }

    statusLabel(status){

        const {t} = this.props;

        //{ImpactRiskAssessment: "COMPLETE", Stakeholder: "NEW", ControlSummary: "INPROGRESS", Compliance: "INPROGRESS"}
        if(status === "COMPLETE"){
            return <span className="label label-primary">{t("Completed")}</span>;

        }else if(status === "NEW"){
            return <span className="label label-info">{t("New")}</span>

        }else if(status === "INPROGRESS"){
            return <span className="label label-warning">{t("In Progress")}</span>

        }else if(status === "UNAVAILABLE"){
            return <span  className="label label-default">{t("Waiting")}</span>
        }
    }

    clickID(action){
        if(action === "ImpactRiskAssessment"){
            return "ira_report";
        }else if(action === "ControlSummary"){
            return "control_report";
        }else if(action === "Compliance"){
            return "compliance";
        }else{
            //Stakeholder
            return "stakeholder";
        }
    }

    actionDisplayName(action){

        const {t} = this.props;

        let name = "";
        if(action === "ImpactRiskAssessment"){
            name = "Impact & Risk Assessment";
        }else if(action === "ControlSummary"){
            name =  "Controls Summary";
        }else if(action === "Compliance"){
            name =  "Compliance Management";
        }else{
            //Stakeholder
            name = "Stakeholder Consultation";
        }
        return t(name);
    }

    render(){

        const {t} = this.props;

        let loading = "";
        if(this.props.loading){
            loading = <span style={{fontSize:"x-small", color:"#AAA",fontWeight:100,marginLeft:3}}>{t("updating")}...</span>;
        }



        var rows = [];
        let keys = ["ImpactRiskAssessment","ControlSummary","Compliance","Stakeholder"];

        if(this.props.readOnly){
            keys = ["ImpactRiskAssessment","ControlSummary","Stakeholder"];
        }

        let statusInfo = this.props.status;
        if(statusInfo == null){
            statusInfo = {ImpactRiskAssessment: "UNAVAILABLE", Stakeholder: "UNAVAILABLE", ControlSummary: "UNAVAILABLE", Compliance: "UNAVAILABLE"};
        }

        for (var i=0;i<keys.length; i++ ){
            var action = keys[i];
            var status = statusInfo[action];

            let name = this.actionDisplayName(action);
            let label = this.statusLabel(status);
            let clickId = this.clickID(action);

            var row;

            if(status === "UNAVAILABLE"){
                let disclosure = "";
                row = <tr key={label+"-"+i}>
                    <td style={{color:"#AAA"}}>{name}</td>
                    <td>{label}</td>
                    <td>{disclosure}</td>
                </tr>;
            }else{
                let disclosure = <i className="fa fa-angle-right" style={{fontSize:"2em",color:"#636363"}}></i>;
                row = <tr key={label+"-"+i} onClick={() => this.props.actionClick(clickId)} style={{cursor:"pointer"}}>
                    <td style={{verticalAlign:"middle"}}>{name}</td>
                    <td style={{verticalAlign:"middle"}}>{label}</td>
                    <td style={{verticalAlign:"middle"}}>{disclosure}</td>
                </tr>;
            }

            rows.push(row);
        }

        return (

                <div>
                    <div className="ibox-title">
                        <h3>{t("Actions")} {loading}</h3>
                    </div>
                    <div className="ibox-content">
                        <table className="table table-hover no-margins">
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>

        );
    }
}

export default withTranslation()(ProjectActionStatus)


import React from 'react';
import LiteDataSummaryTreeContainer from "../components/containers/LiteDataSummaryTreeContainer";

class LiteImpactSummaryView extends React.Component {

    render() {
        return (
            <div>
                {React.cloneElement(<LiteDataSummaryTreeContainer/>,this.props)}
            </div>
        );
    }

}


export default LiteImpactSummaryView



export default function clients(state = {isLoading:false,updateDone:false}, action) {

    switch (action.type) {

        case("UPDATE_CLIENT_START"):{

            return {...state,isLoading:true,updateDone:false};
        }

        case("UPDATE_CLIENT_DONE"):{


            return {...state,isLoading:false,updateDone:true};
        }

        case("UPDATE_CLIENT_RESET"):{
            return {...state,isLoading:false,updateDone:false};
        }
        default:{}
    }

    return state;
}

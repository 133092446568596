
import React from 'react';
import DataSummaryTreeContainer from "../components/containers/DataSummaryTreeContainer";

class ReceptorSummaryView extends React.Component {

    render() {

        return (
            <div>
                {React.cloneElement(<DataSummaryTreeContainer/>,this.props)}
            </div>
        );
    }

}


export default ReceptorSummaryView;

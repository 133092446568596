/**
 * Created by lewellyn on 4/9/18.
 */

import ebase from '../../utils/ajax'
import UserManageView from '../../views/UserManageView'
import { connect } from 'react-redux'
import SessionManager from "../../utils/SessionManager";

const mapStateToProps = (state, ownProps) =>
{


    return {
        savingUser:state.users.savingUser,
        savedUser:state.users.savedUser,
        manageUserLibraries:state.users.manageUserLibraries,
        siteData:state.session.siteData,
    }
};

const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {

        cancelClick : () => {

            //ownProps.routerPush("/users/list");
            SessionManager.shared().goto("/users");
            //dispatch({type: "UPDATE_USER_DONE", result:null});

        },

        updateUser : (userInfo) => {
            // console.log("update user");
            // console.log(userInfo);

            dispatch({type:"UPDATE_USER_START"});

            ebase.ajax({
                url: "/ebase/core/write-user-updateuser",
                data: userInfo,
                success: (result) => {
                    // console.log("load update user done");
                    // console.log(result);
                    if(result.success === true){
                        dispatch({type:"UPDATE_USER_DONE", result:result.data});
                    }else{
                        dispatch({type:"UPDATE_USER_DONE", result:null});
                        let err = {message:result.error};
                        ownProps.showLoadError("Problem saving user", err);
                    }
                },
                error:   (result) => {
                    dispatch({type:"UPDATE_USER_DONE", result:null});

                    ownProps.showLoadError("Problem saving user", result);
                }
            });
        },

        loadCurrentLibraries: (user_id) =>{

            dispatch({type:"LOAD_USERLIBRARIES_START"});

            ebase.ajax({
                url: "/ebase/core/read-user-userdatasets",
                data: {object_id:user_id},
                success: (result) => {

                    if(result.success === true){
                        dispatch({type:"LOAD_USERLIBRARIES_DONE", result:result});
                    }else{
                        dispatch({type:"LOAD_USERLIBRARIES_DONE", result:result});
                        let err = {message:result.error};
                        ownProps.showLoadError("Problem saving user", err);
                    }
                },
                error:   (result) => {
                    dispatch({type:"UPDATE_USER_DONE", result:null});

                    ownProps.showLoadError("Problem saving user", result);
                }
            });
        }



    }

};

const UserManageContainer = connect(mapStateToProps,mapDispatchToProps)(UserManageView);


export default UserManageContainer;


/**
 * Created by craig on 9/8/17.
 */

import React from 'react';

class SummaryTree extends React.Component {

    constructor(props)
    {
        super();

        this.state = {}
    }

    positionKey(position)
    {
        let key = "pos-";
        for (let i=0; i<position.length; ++i)
        {
            key += position[i] + "-";
        }
        return key;
    }

    doExpand(positionKey, expand, item, context)
    {
        if(expand === true) {
            if (this.props.loadBranch) {
                this.props.loadBranch(item.object_id, context);
            }
        }

        let changeState = {};
        changeState[positionKey] = expand;
        this.setState(changeState);
    }

    checkOpen(positionKey, type)
    {
        if (this.state[positionKey]!=null)
        {
            return this.state[positionKey];
        }
        return false;

        if (this.props.overrideTypes != null && this.props.overrideTypes.includes(type))
        {
            return !this.props.defaultOpen;
        }

        return this.props.defaultOpen;
    }

    renderList(children, position, lastArray)
    {
        // console.log("Render list:");
        // console.log(children);
        // console.log(position);
        // console.log(lastArray);
        // console.log("-----------");
        if (children == null || children.length===0) return null;
        if (position == null) position = [];
        if (lastArray == null) lastArray = [];

        let defaultOpen = this.props.defaultOpen;
        let allowExpansion = defaultOpen != null;

        let index = 0;

        let ret = [];
        for (let i=0; i<children.length; ++i)
        {
            let isLast = i >= children.length-1;

            let item = children[i];
            // console.log("renderList:: item:");
            // console.log(item);
            // console.log("---");

           // let object_id = item.object_id;
            let myPosition = position.slice();
            myPosition.push(index++);

            let positionKey = this.positionKey(myPosition);
            let myLastArray = lastArray.slice();
            myLastArray.push(isLast);

            // var object = item.object;

            let callback = this.props.callback;

            let height = 30;
            let border = "1px solid #DDD";
            let borderHeight = height;

            let isChildren = React.Children.count(item.props.children) > 0;

            // if (item.props.object && item.props.object.name === 'Emissions - Light') {
            //     console.log("item.props:");
            //     console.log(item.props);
            //     console.log("isChildren: "+isChildren);
            // }

            if (isLast){//} && !isChildren) {
                borderHeight = height/3;
            }

            let borders = [];
            for (let k=0; k<lastArray.length; ++k)
            {
                if (!lastArray[k])
                {
                    borders.push(<div key={''+k} style={{position:"absolute", left:k*20, top:0, width:1, height:height, borderLeft:border}}></div>);
                }
            }

            let triangle_size = 0;
            let isOpen = true;
            if (allowExpansion) {
                //console.log("allow expansion");
                //console.log(item.props.object);
                if (item.props.object === undefined){
                    isOpen = false;
                }else {
                    isOpen = this.checkOpen(positionKey, item.props.object.type);
                }


                if (isChildren) {
                    let triangle = null;
                    if (false) {
                        triangle = "fa-caret-down";
                        triangle_size = 3;
                        if (!isOpen) {
                            triangle = "fa-caret-right";
                            triangle_size = 1;
                        }
                    } else {
                        triangle = "fa-minus-square-o";
                        triangle_size = 4;
                        if (!isOpen) {
                            triangle = "fa-plus-square-o";
                            triangle_size = 4;
                        }
                    }

                    let tri_style = {position: "absolute",
                                        left: position.length * 20 - triangle_size,
                                        top: 0,
                                        zIndex: "1000",
                                    };
                    borders.push(<div key={"b"} onClick={this.doExpand.bind(this, positionKey, !isOpen,item.props.object, item.props.context)} style={tri_style}>
                                    <span className={"fa " + triangle} style={{backgroundColor:"#F0F0F0"}}/>
                                 </div>);
                }
            }
            let topExtra = 7;
            borders.push(<div key="ab1" style={{position:"absolute", left:position.length*20, top:-topExtra, width:1, height:borderHeight+topExtra, borderLeft:border}}></div>);

            let width = 15;
            if (i===0) {
                borders.push(<div key="ab2" style={{position:"absolute", left:position.length*20, top:-10, width:width, height:10+height/3, borderBottom:border}}></div>);
            } else {
                borders.push(<div key="ab3" style={{position:"absolute", left:position.length*20, top:0, width:width, height:height/3, borderBottom:border}}></div>);
            }

            let colour = "lighgray"; // TODO
            let object = item.props.object;
            let rendered_item = this.props.renderObject(object, item.props.context);

        //     {this.renderObject(object)}
        // &nbsp;&nbsp;<AnswerLabel risk={answer.risk} riskName={taskUtil.riskName(this.props.task.risk_matrix, answer.risk)} answer={answer.answer}/>

            // for debugging add this below {rendered_item}: <div style={{fontStyle:"italic",marginLeft:"25px",marginTop:"10px"}}>{item.props.context}</div>

            // if (!item.props.object?.object_id) {
            //     console.log("Blank Object Id ", item.props);
            // }
            ret.push(
                <div key={item.props.context}>
                    <div style={{position:"relative", textAlign:"left", height:height, paddingLeft:15+position.length*20, color:colour}}>
                        {borders}
                        <div onClick={(callback==null)?null : () => callback(object,item.props.parent)}>
                            {rendered_item}
                        </div>
                    </div>
                </div>);

            if (isChildren && isOpen)
            {
                ret = ret.concat(this.renderList(React.Children.toArray(item.props.children), myPosition, myLastArray));
            }
        }

        return ret;
    }

    render() {
        return (<div>
                    <div>{this.renderList(React.Children.toArray(this.props.children))}</div>
                </div>);
    }
}

class SummaryItem extends React.Component {

    render()
    {
        return null;
    }


}

export {SummaryTree}
export {SummaryItem}


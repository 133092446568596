/**
 * Created by lewellyn on 13/5/19.
 */
import React from 'react';
import ContainerHeader from "../components/common/ContainerHeader";
import PageWrapper from "../components/common/PageWrapper";
import SummaryFull from "../components/common/SummaryFull";
import SplitPane from 'react-split-pane'
import details from "../utils/details";
import Spinner from "../components/common/Spinner";
import DataDetailsReadOnly from "./DataDetailsReadOnly";
import EbaseAdvert from "../components/common/EbaseAdvert";
import LiteDetailsLandingView from "./LiteDetailsLandingView";
import envid from "../utils/envid";
import {withTranslation} from "react-i18next";

class LiteDataSummaryTreeView extends React.Component {

    selectObject(object,parent)
    {
        // load the object from the server

        let pathname = this.props.location.pathname;

        // console.log("DataSummaryTreeView:: select object ");
        // console.log(object);
        // console.log("parent:");
        // console.log(parent);

        if (pathname === "/impactsummary"){

            //receptors need the parent since the info needs
            //date from the impact-recptor link object
            if(object.type === "Receptor" && parent !== null){
                this.props.loadObject(object,"ImpactSummary",parent);
            }else{
                this.props.loadObject(object,"ImpactSummary",null);
            }


        }
        if (pathname === "/regionsummary"){

            //receptors need the parent since the info needs
            //date from the impact-recptor link object
            if(object.type === "Receptor" && parent !== null){
                this.props.loadObject(object,"RegionSummary",parent);
            }else{
                this.props.loadObject(object,"RegionSummary",null);
            }
        }

    }

    loadBranch(object_id){

        let pathname = this.props.location.pathname;
        // console.log("DataSummaryTreeView:: load branch - "+object_id);
        if (pathname === "/impactsummary"){
            this.props.loadBranch(object_id,"ImpactSummary");
        }
        if (pathname === "/regionsummary"){
            this.props.loadBranch(object_id,"RegionSummary");
        }

    }

    breadCrumbClicked(link){
        if (link === '/'){
            this.props.clearSelected();
        }
    }

    render() {
        const {t} = this.props;

        // console.log("LiteDataSummaryTreeView :: render ",this.props);
        //console.log(this.props);

        let path = this.props.location.pathname;

        // if (this.props.route === null || this.props.route === undefined){
        //     return (<div>No Summary Data for this type</div>);
        // }

        var page_title = "";
        let page_heading = "";
        let ebaseAd = null;

        var topType = "";

        if (path === "/impactsummary"){
            page_title = t("Impact Summary");
            if(this.props.siteData != null && this.props.siteData.dataset != null && this.props.siteData.dataset.name != null){
                page_heading = this.props.siteData.dataset.name;
            }

            topType = "ProjectCategory";
        }
        if (path === "/regionsummary"){
            page_title = t("Region Summary");
            topType = "Region";
        }

        // console.log("DataSummaryTree:: top type: '"+topType+"'");

        let selected = <div style={{marginBottom:50}}>
            <LiteDetailsLandingView />
        </div>;

        let downloadButton =[];

        let pane_style = {padding:20, overflow:"scroll"};
        pane_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height;
        pane_style.overflowY = "scroll";
        pane_style.overflowX = "hidden";
        pane_style.textOverflow = "ellipsis";

        if (this.props.isLoading)
        {
            return <div>
                <ContainerHeader liteVersion={true} title={page_title} crumbs={['/', page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <Spinner/>
                </PageWrapper>
            </div>
        }

        if (this.props.selectedIsLoading === true)
        {
            selected = <Spinner/>
        }
        else if (this.props.selectedData != null)
        {
            let template = details.getObjectReadOnlyTemplate(this.props.selectedData.object.type);

            selected = <DataDetailsReadOnly fields={template[0].fields} currentObject={this.props.selectedData}/>

            let spanStyle = {color:'#35bfc3', fontSize:20};
            if (Math.random() > 0.5){
                ebaseAd = <EbaseAdvert
                    url={envid.getcontactWebsite()}
                    graphicSource={"img/ad-graphic1.png"}
                    text={[t("eBase can support your organisation to manage your "),
                        <span style={spanStyle}>{t("EIA knowledge base")}</span>,
                        t(", which will streamline environmental approvals and ensure consistency within and across projects. Contact us today to see how eBase can support your "),
                        <span style={spanStyle}>{t("digital transformation")}</span>,t(" journey.")]}/>;
            }else{
                ebaseAd = <EbaseAdvert
                    url={envid.getcontactWebsite()}
                    graphicSource={"img/ad-graphic2.png"}
                    text={[t('Check out our website for more information on how eBase can ')
                        ,<span style={spanStyle}>{t("manage your EIA knowledge")}</span>,
                        t(' and support more '),
                        <span style={spanStyle}>{t("consistent, transparent and robust impact assessment")}</span>,
                        '.']}/>;
            }



            // if(this.props.selectedData.object.type === "Region"){
            //     downloadButton = (
            //         <div style={{marginBottom:10,textAlign:'right'}}>
            //             <EButton onClick={() => this.getCSVClick(this.props.selectedData.object.object_id)}>Get Stakeholder CSV</EB>
            //         </div>
            //     );
            // }

        }

        return (
            <div>
                <ContainerHeader liteVersion={true} title={page_heading} crumbs={['/', '/impactsummary']} dimensions={this.props.dimensions} linkClicked={this.breadCrumbClicked.bind(this)}/>
                <PageWrapper dimensions={this.props.dimensions} style={{padding:0, margin:0, position:"relative"}}>
                    <SplitPane split="vertical" minSize={350}>
                        <div style={pane_style}>
                            <SummaryFull topType={topType} children={this.props.children} objects={this.props.objects} onclick={this.selectObject.bind(this)} loadBranch={this.loadBranch.bind(this)} loadState={this.props.loadState}/>
                        </div>
                        <div style={pane_style}>
                            {downloadButton}
                            {selected}
                            {ebaseAd}
                        </div>
                    </SplitPane>
                </PageWrapper>
            </div>

        );
    }
}




export default withTranslation()(LiteDataSummaryTreeView)

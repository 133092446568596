/**
 * Created by craig on 9/8/17.
 */

import React from 'react';
import {SummaryTree, SummaryItem} from './SummaryTree'
import EnvidObjectIcon from "./EnvidObjectIcon"

class ControlTree extends React.Component {

    constructor(props)
    {
        super(props);

        this.renderChildren = this.renderChildren.bind(this);
        this.renderObject = this.renderObject.bind(this);
        this.extractName = this.extractName.bind(this);
        this.checkForControl = this.checkForControl.bind(this);
        this.checkFilter = this.checkFilter.bind(this);
    }

    extractName(object)
    {
        if (object.type === "Control")
        {
            let control_name_id = object.details.control_name_id;
            return this.props.objectLists["ControlName"][control_name_id].details.name;
        }
        else if (object.type === "Impact")
        {
            let impact_type_id = object.details.impact_type_id;
            return this.props.objectLists["ImpactType"][impact_type_id].details.name;
        }

        return object.details.name;
    }

    onclick(object)
    {
        if (object.type === "Control") {
            this.props.onclick(object);
        }
    }

    renderObject(object)
    {
        let style_small_space = {marginRight:"5px"};

        let ret = [];

        let type = object.type;

        ret.push(<EnvidObjectIcon type={type} showName={false}/>);
        ret.push(<span style={style_small_space}/>);
        ret.push(this.extractName(object));

        let className = "tree-item";
        if (object.type === "Control")
        {
            if (this.props.selected === object.object_id)
            {
                className = "tree-item tree-item-selected";
            }

        } else {
            className = "tree-item-static";
        }
        return <span className={className} style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>&nbsp;{ret}</span>
    }

    checkForControl(structureItem)
    {
        if (structureItem == null || structureItem.list == null || structureItem.list.length === 0) {
            return false;
        }

        if (structureItem.listType === "Control" && structureItem.list.length > 0)
        {
            // check the controls
            let count = 0;
            for (let i=0; i<structureItem.list.length; ++i)
            {
                if (this.checkFilter(structureItem.list[i]))
                {
                    count++;
                }
            }
            return count > 0;
        }

        for (let i=0; i<structureItem.list.length; ++i)
        {
            if (this.checkForControl(structureItem.list[i]))
            {
                return true;
            }
        }

        return false;
    }

    checkFilter(control)
    {
        let filter = this.props.filter;
        if (filter == null)
        {
            return true;
        }

        try {
            let defn = null;
            if (control.list != null && control.list.length > 0) {
                let defn_id = control.list[0].object_id;

                defn = this.props.objectLists['ComplianceDefn'][defn_id];
            }

            if (control.object_id === this.props.selected)
            {
                return true;
            }
            else if (filter.getDueIn() > 0 && defn != null)
            {
                // check the due date
                let days = filter.getDueIn();
                let timestamp = defn.details.due_timestamp;
                if (timestamp == null) {
                    return false;
                } else {
                    let now = new Date().getTime();
                    if (timestamp - now  > (days * 24 * 60 * 60 * 1000)) {
                        return false;
                    }
                }
            }
            else if (!filter.showByStatus(defn))
            {
                return false;
            }

            if (filter.showMineOnly())
            {
                if (defn == null) return false;

                // check who owns it
                try {
                    let role_id = defn.details.responsible_role_id;
                    if (role_id != null)
                    {
                        console.log("Role Id = " + role_id);
                        let role = this.props.objectLists["ProjectRole"][role_id];
                        console.log("Role = " + JSON.stringify(role));
                        if (role.details.user_id !== this.props.user.user_id)
                        {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } catch (e) {
                    console.error(e);
                    return false;
                }

            }

        }
        catch (e)
        {
            console.log(e);
        }

        return true;
    }

    loadBranch(base){

        //everthing is already loaded
        return;

    }

    renderChildren(structureItem)
    {
        if (structureItem == null || structureItem.list == null) {
            return null;
        }

        let list = structureItem.list;
        let type = structureItem.listType;

        let children = [];
        for (let i=0; i<list.length; ++i)
        {
            let childItem = list[i];

            // remove any context stuff
            let object_id = childItem.object_id;

            let object = this.props.objectLists[type][object_id];

            let bShow = false;
            if (type === "Control")
            {
                // check if it fits the filter
                bShow = this.checkFilter(childItem);
            }
            else if (type === "ComplianceDefn")
            {
                bShow = false;
            }
            else
            {
                bShow = this.checkForControl(childItem);
            }
            // check if there are any controls under this
            if (bShow)
            {
                let child = <SummaryItem key={object_id} object={object}>{this.renderChildren(childItem)}</SummaryItem>
                children.push(child);
            }
        }


        return children;
    }

    render() {

        // properties will include the structure at the moment
        let structure = this.props.object.structure;

        /**
         * Not sure what this is doing, just removing it for now
         */
        /*
        for (var object_id in this.props.objects) {
            let obj = this.props.objects[object_id];
            if (obj.type == topType) {
                top.push(object_id);
            }
        }
        */


        let overrideTypes = [];

        return (
            <SummaryTree renderObject={this.renderObject} callback={this.onclick.bind(this)} defaultOpen={true} overrideTypes={overrideTypes} loadBranch={this.loadBranch.bind(this)}>
                {this.renderChildren(structure)}
            </SummaryTree>);
    }
}



export default ControlTree


import jquery from "jquery"

export default {
    find : function(element) {
        return jquery(element)
    },

    ajax : function(args)
    {
        return jquery.ajax(args)
    },

    inArray : function(text,array){
        return jquery.inArray(text,array);
    }
}

import React from 'react';
import EButton from "./EButton"
import {FormGroup, FormControl, Form, Modal,ModalBody,ModalHeader,ModalTitle,ModalFooter} from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

class ObjectTypeList extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {editing : false};

        this.onChange = this.onChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onNewNameChange = this.onNewNameChange.bind(this);
        this.onNewNameSave = this.onNewNameSave.bind(this);
        this.onNewNameClose = this.onNewNameClose.bind(this);
    }


    onEdit()
    {
        // find the current option
        let selected_id = this.props.value.value;
        let currentName = null;
        if (selected_id != null)
        {
            // find this in the options
            for (var i in this.props.options)
            {
                let v = this.props.options[i];
                if (selected_id === v.value)
                {
                    currentName = v.label;
                    break;
                }
            }
            this.setState({editing:true, newname:currentName});
        }
    }

    onNewNameChange(event)
    {
        this.setState({newname:event.target.value});
    }

    onNewNameSave()
    {
        this.props.onChangeOptionName(this.props.value.value, this.state.newname);
        this.setState({editing:false, newname:null});
    }
    onNewNameClose()
    {
        this.setState({editing:false, newname:null});
    }


    onChange(value, actionMeta)
    {
        // console.group("onChange");
        // console.log("value", value);
        // console.log("action", actionMeta);
        // console.groupEnd()
        if (this.props.onChange == null)
        {
            console.log("No onChange event found");
            console.log(this.props);
        }

        if (actionMeta.action === 'create-option'){
            // it's a new value, so we need to create it
            this.props.onChange({label:value.label, value:null, type:this.props.objectType});
        }else if (actionMeta.action === 'clear'){

            //Clear didn't work to start with. Didn't implement when upgraded to v3.1 of react-select
            //during stage6 bug fix update.

        }else{
            this.props.onChange(value);
        }


    }

    handleInputChange(value, actionMeta){
        console.log('handleChange ',value,actionMeta);
    }

    render() {

        // console.group("ObjectTypeList");

        let options = this.props.options;

        // console.log('options ',options);
        // console.log('value', this.props.value);
        // console.groupEnd();

        // the button
        var button = null;


        var remove = [];
        // the options can actually be changed by the component. So I'll just
        // fix them if it has changed. Not really right to be editing props. but i think it has to be done
        for (var i=0; i<this.props.options.length; ++i)
        {
            // if there is a new one and a replacement for the new one, just remove the new one
            if (this.props.options[i].value.startsWith("create:"))
            {
                // find the same label
                let label = this.props.options[i].label;
                for (var j=0; j<this.props.options.length; ++j)
                {
                    if (i !== j && label === this.props.options[j].label)
                    {
                        remove.push(i);
                    }
                }
            }
        }

        for (var i=remove.length-1; i>=0; --i)
        {
            this.props.options.splice(remove[i], 1);
        }

        button = <EButton type="edit" disabled={this.props.value == null} onClick={this.onEdit}>Edit Name</EButton>

        var modal = null;
        {
            modal = (

                <Modal show={this.state.editing} onHide={this.onNewNameClose}>
                    <ModalHeader closeButon>
                        <ModalTitle>
                            Edit Name
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>

                        <FormGroup>
                            <Form.Label>New Name</Form.Label>
                            <div>
                                <FormControl type='text'
                                             onChange={this.onNewNameChange}
                                             value={this.state.newname}/>
                            </div>
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <EButton isTable={true} type="cancel" onClick={this.onNewNameClose}>Cancel</EButton>
                        <EButton isTable={true} type="edit" onClick={this.onNewNameSave}>Save</EButton>
                    </ModalFooter>

                </Modal>

            );

        }
        //The updated version of react-select doesn't have SelectCreatable.
        //Functionality is moved to Creatable - still part of react-select library.
        return (
            <div>
                {modal}
                <div className="row">
                    <div className="col-sm-10">
                        <CreatableSelect
                            isClearable
                            placeholder={this.props.placeholder}
                            options={options}
                            value={this.props.value}
                            onChange={this.onChange}
                            />

                    </div>
                    <div className="col-sm-1">
                        {button}
                    </div>
                </div>
            </div>
        )
    }
}

ObjectTypeList.propTypes = {
    value : PropTypes.object.isRequired,
    objectType : PropTypes.string.isRequired,
    options : PropTypes.array.isRequired,
    onChange : PropTypes.func,
    onChangeOptionName : PropTypes.func
};

export default ObjectTypeList

/**
 * Created by lewellyn on 15/12/16.
 */
import React from 'react';
import {DropdownButton,Dropdown} from 'react-bootstrap'
import EnvidObjectIcon from './EnvidObjectIcon'
import {withTranslation} from "react-i18next";

class NewObjectButton extends React.Component {

    constructor(){
        super();

        this.onSelected = this.onSelected.bind(this);
    }

    onSelected(eventKey, event)
    {
        this.props.onAddSelect(String(eventKey));
    }

    render() {

        const {t} = this.props;

        let things = ["ProjectCategory","Activity","Aspect","Receptor","EnvironmentalInput","TechnicalInput","Region","Control","Impact","Stakeholder"];

        if(this.props.items != null){
            things = this.props.items;
        }

        const items = things.map((type) =>
            <Dropdown.Item key={type} style={{marginBottom:"4px", marginTop:"4px"}} eventKey={type}><EnvidObjectIcon type={type} showName={true}/></Dropdown.Item>
        );

        // const options = things.map((type) =>
        //     //<option value="type"><EnvidObjectIcon type={type} showName={true}/></option>
        //     <option key={type} value={type}>{envid.getObjectName(type)}</option>
        // );


        return (
            <DropdownButton id="new-object-dropdown" variant={'primary'} style={{background:"#1ab394", color:"white", marginTop:"30px", borderRadius: "3px"}} title={<span><i className="fa fa-plus"></i> {t("New Object")}</span>} onSelect={this.onSelected}>
                {items}
            </DropdownButton>
        );
    }
}

export default withTranslation()(NewObjectButton)

/**
 * Created by lewellyn on 29/11/16.
 */
import  React from 'react';
import {Breadcrumb, BreadcrumbItem} from 'react-bootstrap'
import sitedata from '../../utils/sitedata'
import {Link} from "react-router-dom";
import Measure from 'react-measure';
import SessionManager from "../../utils/SessionManager";

class ContainerHeader extends React.Component{

    render() {
        var h2;
        if (this.props.subtitle === undefined)
        {
            h2 = (<h2>{this.props.title}</h2>);
        }
        else
        {
            h2 = (<h2>{this.props.title} <span style={{fontWeight:"normal", fontSize:"x-small", color:"#AAA"}}>{this.props.subtitle}</span></h2>);
        }

        if (this.props.crumbs === undefined) {
            //The empty 18px divs are to make the header the same height as one with breadcrumbs (95px).

            if (this.props.liteVersion === true) {

                return (
                    <Measure
                      bounds
                      onResize={dimensions => SessionManager.shared().updateDimensions({item:"heading", height:dimensions.bounds.height})}
                      whitelist={["height"]}>
                        {({measureRef}) => (
                        <div ref={measureRef} className="row wrapper border-bottom white-bg page-heading">
                            <div style={{height:'18px'}}/>
                            <div className="col-lg-10">
                                {h2}
                            </div>
                            <div style={{height:'18px'}}/>
                        </div>
                          )}
                    </Measure>
                )
            }

            return(
                <Measure
                  bounds
                  onResize={(dimensions)=> SessionManager.shared().updateDimensions({item:"heading", height:dimensions.bounds.height})}
                  whitelist={["height"]}>
                    { ({measureRef}) => (
                    <div ref={measureRef} className="row wrapper border-bottom white-bg page-heading">
                        <div style={{height:'18px'}}/>
                        <div className="col-lg-10">
                            {h2}
                        </div>
                        <div style={{height:'18px'}}/>
                    </div>
                    )}
                 </Measure>

            );


        }else{
            let links = this.props.crumbs;

            let clickHandler = this.props.linkClicked;

            const items = links.map(function (link, i) {

                let link_text = null;
                let active = false;
                let key = i.toString();
                let link_object = null;

                if (typeof link !== 'string')
                {
                    return <BreadcrumbItem key="OBJECTKEY">{link}</BreadcrumbItem>
                }

                if (typeof link !== 'string' || link.charAt(0) !== '/') {

                    link_text = link;
                } else {
                    link_text = sitedata.lookupLinkName(link);
                    active = true;

                }

                if (i === links.length-1) {
                    link_object = <strong>{link_text}</strong>;
                } else if (!active)
                {
                    link_object = link_text;
                } else {
                    link_object = <Link to={link}>{link_text}</Link>
                }
                let ret = <BreadcrumbItem onClick={() =>{if (clickHandler != null){ clickHandler(link) } }} key={key} active={active}>{link_object}</BreadcrumbItem>;
                return ret;

            }
            );

            if (this.props.liteVersion === true){
                return(
                    <Measure bounds onResize={(dimensions)=> SessionManager.shared().updateDimensions({item:"heading", height:dimensions.bounds.height})} whitelist={["height"]}>
                        { ({measureRef}) => (
                          <div
                            ref={measureRef}
                          className="row wrapper border-bottom white-bg page-heading"
                          style={{paddingBottom: 5}}>

                            <div className="col-lg-10">
                                {<h2
                                  style={{marginTop: 0}}>{this.props.title}</h2>}
                                <Breadcrumb>
                                    {items}
                                </Breadcrumb>

                            </div>
                        </div>
                        )}
                     </Measure>
                );
            }

            return(
                <Measure bounds onResize={(dimensions)=> SessionManager.shared().updateDimensions({item:"heading", height:dimensions.bounds.height})} whitelist={["height"]}>
                    { ({measureRef}) => (
                    <div ref={measureRef} className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-lg-10">
                            {h2}
                            <Breadcrumb>
                                {items}
                            </Breadcrumb>

                        </div>
                        <div className="float-end col-lg-2 w-auto">
                            {this.props.rightComponent}
                        </div>
                    </div>
                      )}
                </Measure>
            );
        }
    }
}

export default ContainerHeader

/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import sitedata from '../../utils/sitedata'
import StakeholderMgrView from '../../views/StakeholderMgrView'
import { connect } from 'react-redux'
import details from "../../utils/details"
import values from "object.values"

const mapStateToProps = (state, ownProps) =>
{
    let currentObject = state.reports.currentObject;

    return {
        project: (currentObject==null)? null : currentObject.project,
        object: state.stakeholder.object,
        objectLists: state.stakeholder.objectLists,
        stakeholder:state.stakeholder.selectedStakeholder,
        stakeholderLink:state.stakeholder.stakeholderLink,
        changes:state.stakeholder.changes,
        isLoading: false,
        siteData:state.session.siteData,
        dimensions:state.session.dimensions,
        readOnly:(!sitedata.checkWriteAccess(state.session.siteData,"project"))
    }
}


const loadStakeholder = function(project_id, stakeholder_id, dispatch)
{
    if (stakeholder_id == null || stakeholder_id === "none")
    {
        return;
    }

    dispatch({type:"LOAD_STAKEHOLDER_START"});

    ebase.ajax({
        url:"/ebase/stakeholder/read-stakeholder-object",
        data:{project_id : project_id, stakeholder_id : stakeholder_id},
        success:(result) => {
                                if (result.success) {
                                    dispatch({type:"LOAD_STAKEHOLDER_DONE", result:result})
                                }
                                else {
                                    dispatch({type:"LOAD_STAKEHOLDER_FAILED", error:result.error});
                                }
        },
        error:() => dispatch({type:"LOAD_STAKEHOLDER_FAILED", error:"Error contacting server"})
    });


}

const saveFields = function(dispatch, changes)
{
    let changedFields = changes.changedFields;

    if (details.isObjectEmpty(changedFields))
    {
        return;
    }

    if (!Object.values) values.shim();


    dispatch({type:"SAVE_STAKEHOLDERLINK_START"});

    ebase.ajax({
        url: "/ebase/stakeholder/write-stakeholder-updatefields",
        data: Object.values(changedFields),
        success: (result) => {
            dispatch({type:"SAVE_STAKEHOLDERLINK_SUCCESS",result:result});

        },
        error:   () => {
            dispatch({type:"SAVE_STAKEHOLDERLINK_FAILED",action_id:changedFields})
        }
    });

}

const mapDispatchToProps = (dispatch, ownProps) =>
{
    return {
        loadStakeholder : (project_id, stakeholder_id) =>
        {
            loadStakeholder(project_id, stakeholder_id, dispatch);
        },

        loadStakeholderTask : (project_id, stakeholder_id) =>
        {
            dispatch({type:"LOAD_STAKEHOLDERTASK_START"});


            ebase.ajax({
                url:"/ebase/stakeholder/read-stakeholder-loadtask",
                data:{project_id : project_id},
                success:(result) => {
                    if (result.success)
                    {
                        dispatch({type:"LOAD_STAKEHOLDERTASK_DONE", result:result});
                        loadStakeholder(project_id, stakeholder_id, dispatch);
                    }
                    else
                    {
                        dispatch({type:"LOAD_STAKEHOLDERTASK_FAILED", error:result.error});
                    }
                },
                error:() => dispatch({type:"LOAD_STAKEHOLDERTASK_FAILED", error:"Error contacting server"})
            });

        },

        updateField : (object_id, fieldName, fieldValue) =>
        {
            // console.log("Update stakeholder Field : " + fieldName + " = " + fieldValue +" | "+ object_id);
            dispatch({type:"UPDATE_STAKEHOLDERLINK_FIELD", object_id:object_id, field:fieldName, value:fieldValue});
        },

        saveFields(changed)
        {
            saveFields(dispatch, changed)
        },
/*
        startEdit(action_id)
        {
            dispatch(editStakeholderActionStart(action_id));
        },



        deleteAction(action_id)
        {
            ebase.ajax({
                url:"/ebase/Stakeholder/write-Stakeholder-deleteaction",
                data:{object_id : action_id},
                success:(result) => {
                    if (result.success)
                    {
                        dispatch(deleteStakeholderActionDone(action_id, result));
                        dispatch(StakeholderUpdated(new Date()));
                    }
                    else
                    {
                        dispatch(deleteStakeholderActionFailed(result.error));
                    }
                },
                error:() => dispatch(deleteStakeholderActionFailed("Error contacting server"))
            });
        },
        uploadEvidence(action_id, file)
        {
            let data = new FormData();
            data.set("file", file);
            data.set("action_id", action_id);

            console.log("uploadEvidence");
            dispatch(uploadingEvidenceStart(action_id, file.name));

            ebase.ajax({
                url: "/ebase/Stakeholder/write-Stakeholder-uploadpdf",
                data: data,
                contentType: false,
                processData: false,
                method: 'POST',
                success: (result) => {
                    if (result.success) {
                        dispatch(uploadingEvidenceDone(action_id, file.name, result.data));
                    } else {
                        dispatch(uploadingEvidenceError(action_id, file.name, result.error))
                    }
                },
                error: ((error) => dispatch(uploadingEvidenceError(action_id, file.name, "Error comtacting server")))
            }, false);

        }
*/


    }
}

class SHMgrView extends React.Component{
    render() {

        console.log('SHMgrView :: ',this.props);
        return (
            <div>
                {React.cloneElement(<StakeholderMgrView/>, this.props)}
            </div>
        )
    }
    componentWillReceiveProps(nextProps)
    {

        let nextParams = sitedata.getParams(nextProps);
        let params = sitedata.getParams(this.props);

        let project_id = nextParams.projectId;
        let stakeholder_id = nextParams.stakeholderId;

        if (params.stakeholderId !== stakeholder_id || params.projectId !== project_id) {
            this.props.loadStakeholderTask(project_id, stakeholder_id);
        }
    }
    componentWillMount()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let stakeholder_id = params.stakeholderId;

        this.props.loadStakeholderTask(project_id, stakeholder_id);

    }
};

const StakeholderMgrContainer = connect(mapStateToProps, mapDispatchToProps)(SHMgrView)

export default StakeholderMgrContainer

/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Credentials,
  EbaseResult,
  EbaseResultSiteDataResult,
  SetDatasetRequest,
} from '../models/index';
import {
    CredentialsFromJSON,
    CredentialsToJSON,
    EbaseResultFromJSON,
    EbaseResultToJSON,
    EbaseResultSiteDataResultFromJSON,
    EbaseResultSiteDataResultToJSON,
    SetDatasetRequestFromJSON,
    SetDatasetRequestToJSON,
} from '../models/index';

export interface AuthenticateRequest {
    credentials?: Credentials;
}

export interface SelectSessionDatasetRequest {
    setDatasetRequest?: SetDatasetRequest;
}

/**
 * EbaseAuthenticateWSApi - interface
 * 
 * @export
 * @interface EbaseAuthenticateWSApiInterface
 */
export interface EbaseAuthenticateWSApiInterface {
    /**
     * 
     * @summary Authenticate a users
     * @param {Credentials} [credentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseAuthenticateWSApiInterface
     */
    authenticateRaw(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSiteDataResult>>;

    /**
     * Authenticate a users
     */
    authenticate(credentials?: Credentials, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSiteDataResult>;

    /**
     * Check if you have a session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseAuthenticateWSApiInterface
     */
    checkSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSiteDataResult>>;

    /**
     * Check if you have a session
     */
    checkSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSiteDataResult>;

    /**
     * Logout of ebase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseAuthenticateWSApiInterface
     */
    logoutPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>>;

    /**
     * Logout of ebase
     */
    logoutPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult>;

    /**
     * Sets the client and the dataset being used in the current session.
     * @param {SetDatasetRequest} [setDatasetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseAuthenticateWSApiInterface
     */
    selectSessionDatasetRaw(requestParameters: SelectSessionDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSiteDataResult>>;

    /**
     * Sets the client and the dataset being used in the current session.
     */
    selectSessionDataset(setDatasetRequest?: SetDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSiteDataResult>;

    /**
     * are you there ebase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseAuthenticateWSApiInterface
     */
    testRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * are you there ebase
     */
    test(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

}

/**
 * 
 */
export class EbaseAuthenticateWSApi extends runtime.BaseAPI implements EbaseAuthenticateWSApiInterface {

    /**
     * Authenticate a users
     */
    async authenticateRaw(requestParameters: AuthenticateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSiteDataResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/authenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsToJSON(requestParameters['credentials']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultSiteDataResultFromJSON(jsonValue));
    }

    /**
     * Authenticate a users
     */
    async authenticate(credentials?: Credentials, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSiteDataResult> {
        const response = await this.authenticateRaw({ credentials: credentials }, initOverrides);
        return await response.value();
    }

    /**
     * Check if you have a session
     */
    async checkSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSiteDataResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/checkSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultSiteDataResultFromJSON(jsonValue));
    }

    /**
     * Check if you have a session
     */
    async checkSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSiteDataResult> {
        const response = await this.checkSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * Logout of ebase
     */
    async logoutPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultFromJSON(jsonValue));
    }

    /**
     * Logout of ebase
     */
    async logoutPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult> {
        const response = await this.logoutPostRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sets the client and the dataset being used in the current session.
     */
    async selectSessionDatasetRaw(requestParameters: SelectSessionDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSiteDataResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/setSessionAccess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDatasetRequestToJSON(requestParameters['setDatasetRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultSiteDataResultFromJSON(jsonValue));
    }

    /**
     * Sets the client and the dataset being used in the current session.
     */
    async selectSessionDataset(setDatasetRequest?: SetDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSiteDataResult> {
        const response = await this.selectSessionDatasetRaw({ setDatasetRequest: setDatasetRequest }, initOverrides);
        return await response.value();
    }

    /**
     * are you there ebase
     */
    async testRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/test`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * are you there ebase
     */
    async test(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.testRaw(initOverrides);
        return await response.value();
    }

}

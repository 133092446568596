import moment from 'moment'

class EnvidDate extends Object
{
    constructor(sdate, t) {
        super();

        this.moment = moment(sdate, "YYYY-MM-DD");

        this.toString = function () {
            return this.moment.format("YYYY-MM-DD")
        }

        this.format = function (format) {
            return this.moment.format(format);
        }

        this.fromNow = function () {

            if (moment().format("YYYY-MM-DD") === this.moment.format("YYYY-MM-DD"))
            {
                return t("Today")
            }
            return this.moment.fromNow();
        }
    }

}

export default EnvidDate;

import React from 'react';
import envid from "../../utils/envid"
import {withTranslation} from "react-i18next";

class HubBox extends React.Component {

    constructor()
    {
        super();

        this.state = {hover:false};
    }

    render() {

        const {t} = this.props;

        let style = Object.assign({}, this.props.hubstyle);

        if (style == null) style = {width:0};
        try {
            if (isNaN(style.width) || style.width.indexOf("%")>=0) {
                style.width = 0;
            }
        } catch (e)
        {
        }

        style.display = "inline-block";
        style.color = "white";
        style.fontSize = "12pt";
        style.marginBottom = 20;
        style.position = "relative";
        style.overflow = "hidden";
        //style.background = this.props.colour;
        style.cursor = "pointer";
        style.userSelect = "none";

        let width = style.width;

        let scale = 1;
        if (width < 200) {
            scale = width / 200;
        }

        let tri_width = 20*scale;
        let box_width = style.width - tri_width;

        let iconFontSize = 75 * scale;
        let statusFontSize = 12 * scale;
        let statusWidth = 8*statusFontSize;
        let nameFontSize = 16 * scale;
        style.height *= scale;


        let nameStyle = {position:"absolute", left:5, top:5, width:box_width, fontSize:nameFontSize};
        let statusStyle = {position:"absolute", left:3, bottom:3, borderRadius:3, width:statusWidth, height:25*scale, background:"white", color:"grey", padding:3, fontSize:statusFontSize};
        let iconStyle = {position:"absolute", right:5, bottom:-25*scale,color:"#F5F5F5", opacity:0.2, fontSize:iconFontSize, WebkitTransform:(this.state.hover && !this.props.selected)?"":"rotate(-25deg)"};

        if (this.props.selected) iconStyle.opacity = 1.0;

        let bCanSelect = true;
        let statusText = this.props.status;
        let statusIcon = null;
        let statusColour = null;

        if (this.props.status === "Complete")
        {
            style.cursor = "pointer";
            statusIcon = "fa-check";
            statusColour = envid.getObjectColours("Control")
        }
        else if (this.props.status === "InProgress")
        {
            style.cursor = "pointer";
            statusText = "In progress";
            statusIcon = "fa-clock-o"
            statusColour = envid.getObjectColours("Aspect")
        }
        else if (this.props.status === "Ready")
        {
            style.cursor = "pointer";
            statusIcon = "fa-chevron-circle-right";
            statusColour = envid.getObjectColours("Impact")

        }
        else if (this.props.status === "NotReady")
        {
            //bCanSelect = false;
            //style.cursor = "not-allowed";

            style.cursor = "pointer";
            statusText = "Not ready";
            statusIcon = "fa-circle-o"
        }

        let shape = null;
        let points = null;
        if (this.props.hasPoint) {

            points =  [0, 0, box_width, 0, style.width, style.height / 2, box_width, style.height, 0, style.height];


            let clipPolygon = "polygon(";
            for (let i=0; i<points.length; i+=2)
            {
                if (i !== 0) clipPolygon += ",";
                clipPolygon += points[i] + "px " + points[i+1] + "px";
            }
            clipPolygon += ")";
            style.clipPath = clipPolygon;
        }
        else
        {
            points =  [0, 0, box_width, 0, box_width, style.height, 0, style.height];
        }

        //console.log("points");
        //console.log(points);

        if (this.props.icon === "fa-compress") {
            iconStyle = Object.assign(iconStyle, {WebkitTransform:(this.state.hover && !this.props.selected)?"rotate(25deg)":""});
        }
        return (
            <div style={style} key={this.props.name} onClick={(bCanSelect)?this.props.onSelect:null} onMouseEnter={() => this.setState({hover:true})} onMouseLeave={() => this.setState({hover:false})}>
                <svg style={{fill:this.props.colour}}>
                    <polygon points={points}></polygon>
                </svg>
                <div style={nameStyle}>{this.props.name}</div>
                <div style={statusStyle}>
                    {t(statusText)}
                    <i className={"fa " + statusIcon} style={{position:"absolute", right:7, top:7, color:statusColour}}></i>
                </div>
                <div style={iconStyle}><i className={"fa " + this.props.icon}></i></div>
                {shape}
            </div>
        )
    }
}

export default withTranslation()(HubBox)

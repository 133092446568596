/**
 *
 */
import React from 'react';
import {TemplateEditView} from '../../views/TemplateEditView';

const TemplateEditContainer = (props) => {
    return React.cloneElement(<TemplateEditView/>, props);
}

export {TemplateEditContainer}

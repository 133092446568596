import React from 'react';

import {Row, Col} from 'react-bootstrap'
import {Table} from 'react-bootstrap'
import {FormControl} from 'react-bootstrap'

import EnvidObjectIcon from '../components/common/EnvidObjectIcon'
import EButton from "../components/common/EButton"
import ProjectRoleSelect from '../components/common/ProjectRoleSelect'
import envid from '../utils/envid'
import {EventSectionTitle} from '../components/common/StakeholderUtils'
import {withTranslation} from "react-i18next";

class StakeholderEventActionsView extends React.Component {

    finishedField(object_id, fieldName, event) {
        this.props.saveFields();

    }

    inputChanged(object_id, fieldName, event){
        event.preventDefault();
        let value = event.target.value;
        this.props.updateField(object_id, fieldName, value);
    }

    updateStatus(object_id, fieldName, value)
    {
        this.props.updateField(object_id, fieldName, value);
        setTimeout(this.props.saveFields, 100);
    }

    selectChanged(object_id, fieldName, event){
        let value = event.target.value;

        this.props.updateField(object_id, fieldName, value);
        setTimeout(this.props.saveFields, 100);
    }

    render()
    {
        const {t} = this.props;

        // console.log('StakeholderEventActionsView:: ',this.props);
        let actions = this.props.actions;
        if (actions == null) actions = [];

        let body = <span>Hello</span>;

        let addButton = null;
        let haveRoles = !!this.props.objectLists ? this.props.objectLists["ProjectRole"] != null : false;
        if (!this.props.published && haveRoles && !this.props.readOnly) {
            addButton = (
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <EButton type="add" onClick={this.props.newAction} disabled={this.props.selectedAction!=null}>{t("New Action")}</EButton>
                </div>);
        }

        if (actions.length === 0)
        {
            if (haveRoles){
                body = (this.props.published) ? <span>{t('No Actions for this Communication')}</span> : <span>{t("No Actions, you can add one now.")}</span>
            }else{
                body = (this.props.published) ? <span>{t('No Actions for this Communication')}</span> : <span>{t("Cannot add any Actions until you add at least one Project Role")}</span>
            }

        }
        else
        {
            let rows = [];

            rows = actions.map((action) => {
                let role_id = envid.getObjectField(action, 'role_id');
                let description = envid.getObjectField(action, 'name');

                let role_name = t("Unknown Role");
                let user_name = t("Unknown User");
                try {
                    let role = this.props.objectLists["ProjectRole"][role_id];
                    role_name = envid.getObjectField(role, 'name');

                    let user_id = envid.getObjectField(role, 'user_id');
                    let user = this.props.objectLists["User"][user_id];
                    user_name = envid.getObjectField(user, 'name');
                } catch (e) {
                    console.log(e);
                }

                let isEditing = this.props.selectedAction === action.object_id;
                let isNavigate = this.props.navigate;

                let complete = action.details.status === "COMPLETE";
                let status = (complete) ? <span className="label label-primary">{t("Completed")}</span> :
                                          <span className="label label-warning">{t("In Progress")}</span>

                let button = null;
                if (this.props.published)
                {
                    if (complete)
                    {
                        button = (
                            <EButton type="progress" isTable={true}
                                     onClick={this.updateStatus.bind(this, action.object_id, "details.status", "IN_PROGRESS")}>
                                {t("Mark as In Progress")}
                            </EButton>);
                    } else {
                        button = (
                            <EButton type="done" isTable={true}
                                     onClick={this.updateStatus.bind(this, action.object_id, "details.status", "COMPLETE")}>
                                {t("Mark as Complete")}
                            </EButton>);
                    }
                } else {
                    if (isEditing)
                    {
                        button = <EButton type="done" isTable={true} disabled={!this.props.selectedActionValid}
                                        onClick={() => this.props.doneAction(action.object_id)}>

                            {t("Done")}
                        </EButton>
                    } else {
                        button = <EButton type="remove" isTable={true}
                                          onClick={() => this.props.removeAction(action.object_id)}>
                            {t("Remove")}
                                 </EButton>
                    }
                }

                if (this.props.readOnly){
                    button = null;
                }

                let the_role = <span>{role_name}<br/><small><i className="fa fa-user"></i> {user_name}</small></span>
                if (isEditing)
                {
                    // console.log("StakeholderEventActionsView - ProjectRoleSelect:: ",this.props);
                    the_role = (
                                <ProjectRoleSelect value={role_id}
                                        onChange={this.selectChanged.bind(this, action.object_id, "details.role_id")}
                                        roles={this.props.objectLists["ProjectRole"]}
                                        users={this.props.objectLists["User"]}/>);


                    description = <FormControl
                        type="text"
                        field={"details.name"}
                        onClick={(event) => event.target.select()}
                        onChange={this.inputChanged.bind(this,action.object_id,"details.name")}
                        onBlur={this.finishedField.bind(this, action.object_id, "details.name")}
                        style={{width:"100%", fontSize:"13px"}}
                        value={action.details.name}/>;

                }
                let navButton = null;
                if(isNavigate === true){
                    navButton =
                        <EButton type="progress" isTable={true}
                             onClick={this.props.navigateClick.bind(this, action.details.event_id)}>
                            {t("View")}
                    </EButton>;

                    return (
                        <tr key={"ACTION-"+action.object_id}>
                            <td>{status}</td>
                            <td>{the_role}</td>
                            <td>{description}</td>
                            <td>{button}</td>
                            <td>{navButton}</td>
                        </tr>);
                }



                return (
                    <tr>
                        <td>{status}</td>
                        <td>{the_role}</td>
                        <td>{description}</td>
                        <td>{button}</td>
                    </tr>);
            })

            if(this.props.navigate === true){
                body = (
                    <Table className="table table-hover">
                        <thead>
                        <tr>
                            <th>{t("Status")}</th>
                            <th><i className="fa fa-address-card-o" style={{marginRight: 5, fontSize: '1.1em'}}></i>{t("Role")}
                            </th>
                            <th>{t("Description")}</th>
                            <th style={{width: 1}}></th>
                            <th style={{width: 1}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                );

            }else {

                body = (
                    <Table className="table table-hover">
                        <thead>
                        <tr>
                            <th>{t("Status")}</th>
                            <th><i className="fa fa-address-card-o" style={{marginRight: 5, fontSize: '1.1em'}}></i>{t("Role")}
                            </th>
                            <th>{t("Description")}</th>
                            <th style={{width: 1}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                );
            }
        }

        let title = null;
        if (this.props.showTitle !== false)
        {
            title = (
                <Row>
                    <Col md={2}><EventSectionTitle><EnvidObjectIcon type="Action"/>&nbsp;&nbsp;&nbsp;{t("Actions")}</EventSectionTitle></Col>
                    <Col md={10}>{addButton}</Col>
                </Row>);
        }

        return (
            <div>
                {title}
                <div>
                    {body}
                </div>
            </div>
        );
    }
}

export default withTranslation()(StakeholderEventActionsView)

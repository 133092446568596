
import details from '../../utils/details'
import update from 'immutability-helper';


export default function stakeholder(state = {isLoading:true, isStakeholderLoading:false, changes:{savedStatus:"unchanged", changedFields:{}}, statusInfo:null,isStatusLoading:false}, action) {

    switch (action.type)
    {
        case "LOAD_STAKEHOLDERTASK_START": {

            return {...state, isLoading:true};
        }

        case "LOAD_STAKEHOLDERTASK_DONE": {

            let data = action.result.data;
            return {...state, objectLists: data.objectLists, object:data.object, isLoading: false, isStakeholderLoading:false};
        }

        case "LOAD_STAKEHOLDERTASK_FAILED": {

            return {...state, error:action.error, isLoading: false, isStakeholderLoading:false};
        }

        case "LOAD_STAKEHOLDER_START": {

            return {...state, isStakeholderLoading:true, editing_id:null};
        }

        case "LOAD_STAKEHOLDER_DONE": {

            let data = action.result.data;

            let link = Object.values(data.objectLists["ProjectStakeholderLink"])[0];

            return {...state, selectedStakeholder: data.object, stakeholderLink:link, isStakeholderLoading: false, editing_id:null};
        }

        // case NEW_COMPLIANCEACTION_DONE: {
        //     // slot the new object into the various stuff
        //     console.log("NEW_COMPLIANCEACTION_DONE");
        //     console.log(action);
        //
        //     let new_object = action.result.object;
        //
        //     let actions = state.complianceActions.slice();
        //     actions.splice(0, 0, new_object);
        //     return {...state, complianceActions:actions, editing_id:new_object.object_id}
        //
        // }
        //
        // case NEW_COMPLIANCEACTION_FAILED: {
        //     return state;
        // }

        case "LOAD_STAKEHOLDER_STATUS_START":{
            return {...state, isStatusLoading:true};
        }

        case "LOAD_STAKEHOLDER_STATUS_DONE":{
            return {...state, isStatusLoading:false,statusInfo:action.result};
        }

        case "SAVE_STAKEHOLDERLINK_START":
        {
            var changes = {savedStatus:"saving", changedFields:{}};
            return {...state, changes:changes};
        }

        case "SAVE_STAKEHOLDERLINK_SUCCESS":
        {

            // clear the update fields stuff
            var changes = {savedStatus:(details.isObjectEmpty(state.changes.changedFields))?"saved":"saving", changedFields:state.changes.changedFields};


            return {...state, changes:changes};

        }
        case "SAVE_STAKEHOLDERLINK_FAILED":
        {
            return state;
        }

        case "UPDATE_STAKEHOLDERLINK_FIELD":
        {
            // console.log("UPDATE_STAKEHOLDER_FIELD");
            // console.log(state);

                let object_id = action.object_id;
                let field = action.field;
                let value = action.value;

            let updateObject = details.getUpdate(field, value);

            let link = state.objectLists.ProjectStakeholderLink[object_id];
            if(link !== null && link !== undefined){

                if(link.object_id === object_id){

                    link = update({object:link}, updateObject).object;

                    let objectLists = state.objectLists;
                    objectLists.ProjectStakeholderLink[object_id] = link;


                    // now update the changed list
                    var changes = {savedStatus:"saving"};

                    // now fix up the changes stuff
                    var fchanges = {};
                    if( Object.prototype.toString.call( value ) === '[object Array]' ) {
                        fchanges[details.makeId(object_id, field)] = {object_id:object_id, field:field, list:value};
                    } else {
                        fchanges[details.makeId(object_id, field)] = {object_id:object_id, field:field, value:value};
                    }
                    changes.changedFields = update(state.changes.changedFields, {$merge: fchanges});

                    return {...state, stakeholderLink:link,objectLists:objectLists, changes:changes};


                }

            }

            return state;

        }

        default: {}

        // case LOAD_COMPLIANCECONTROL_FAILED: {
        //
        //     return {error:action.error, isLoading: false};
        // }
        //
        // case NEW_COMPLIANCEACTION_DONE: {
        //     // slot the new object into the various stuff
        //     console.log("NEW_COMPLIANCEACTION_DONE");
        //     console.log(action);
        //
        //     let new_object = action.result.object;
        //
        //     let actions = state.complianceActions.slice();
        //     actions.splice(0, 0, new_object);
        //     return {...state, complianceActions:actions, editing_id:new_object.object_id}
        //
        // }
        //
        // case NEW_COMPLIANCEACTION_FAILED: {
        //     return state;
        // }
        //
        // case EDIT_COMPLIANCEACTION_START:
        // {
        //     console.log("--------- EDIT_COMPLIANCEACTION_START ----------" + action.action_id)
        //     return {...state, editing_id:action.action_id}
        //
        // }
        //
        // case UPDATE_COMPLIANCE_FIELD:
        // {
        //     let object_id = action.object_id;
        //     let field = action.field;
        //     let value = action.value;
        //     let object = null;
        //     let index = null;
        //
        //     let complianceDefn = state.complianceDefn;
        //     let complianceActions = state.complianceActions;
        //
        //     let updateObject = details.getUpdate(field, value);
        //
        //     if (state.complianceDefn.object_id == object_id)
        //     {
        //         complianceDefn = update({object:complianceDefn}, updateObject).object;
        //
        //     } else {
        //         for (let i=0; i<state.complianceActions.length; ++i)
        //         {
        //             if (complianceActions[i].object_id == object_id)
        //             {
        //                 complianceActions = complianceActions.slice();
        //                 complianceActions[i] = update({object:complianceActions[i]}, updateObject).object;
        //                 break;
        //             }
        //         }
        //     }
        //
        //     // now update the changed list
        //     var changes = {savedStatus:"saving"};
        //
        //     // now fix up the changes stuff
        //     var fchanges = {};
        //     if( Object.prototype.toString.call( value ) === '[object Array]' ) {
        //         fchanges[details.makeId(object_id, field)] = {object_id:object_id, field:field, list:value};
        //     } else {
        //         fchanges[details.makeId(object_id, field)] = {object_id:object_id, field:field, value:value};
        //     }
        //     changes.changedFields = update(state.changes.changedFields, {$merge: fchanges});
        //
        //     console.log("Changes = ");
        //     console.log(changes);
        //
        //     return {...state, complianceDefn:complianceDefn, complianceActions:complianceActions, changes:changes};
        // }
        //

        // case SAVING_COMPLIANCE_SUCCESS:
        // {
        //     console.log("SAVING_COMPLIANCE_SUCCESS : " + JSON.stringify(action));
        //
        //     // clear the update fields stuff
        //     var changes = {savedStatus:(details.isObjectEmpty(state.changes.changedFields))?"saved":"saving", changedFields:state.changes.changedFields};
        //
        //     let complianceDefn = updateComplianceDefn(action.results.data, state.complianceDefn);
        //
        //     return {...state, changes:changes, complianceDefn:complianceDefn};
        //
        // }
        // case SAVING_COMPLIANCE_FAILED:
        // {
        //     return state;
        // }
        //
        // case UPLOAD_EVIDENCE_START:
        // {
        //     let action_id = action.action_id;
        //     let filename = action.filename;
        //
        //     console.log("UPLOAD_EVIDENCE_START: " + JSON.stringify(action));
        //     // find the action and add the file to the path
        //
        //     let complianceActions = state.complianceActions;
        //     for (let i=0; i<state.complianceActions.length; ++i)
        //     {
        //         let a = complianceActions[i];
        //         if (a.object_id == action_id)
        //         {
        //             console.log("Found Action: " + JSON.stringify(a));
        //             complianceActions = complianceActions.slice();
        //             let evidence_paths = a.details.evidence_paths;
        //             if (evidence_paths == null)
        //             {
        //                 evidence_paths = [];
        //             } else {
        //                 evidence_paths = evidence_paths.slice();
        //             }
        //             evidence_paths.push("/uploading/"+filename);
        //             a = {...a, details:{...a.details, evidence_paths:evidence_paths}};
        //             complianceActions[i] = a;
        //
        //             break;
        //         }
        //     }
        //
        //     return {...state, complianceActions:complianceActions};
        //
        // }
        //
        // case UPLOAD_EVIDENCE_DONE:
        // {
        //     let action_id = action.action_id;
        //     let filename = action.filename;
        //     let path = action.path;
        //
        //     // find the action and add the file to the path
        //
        //     let complianceActions = state.complianceActions;
        //     for (let i=0; i<state.complianceActions.length; ++i)
        //     {
        //         let a = complianceActions[i];
        //         if (a.object_id == action_id)
        //         {
        //             complianceActions = complianceActions.slice();
        //             let evidence_paths = a.details.evidence_paths;
        //
        //             for (let j=evidence_paths.length-1; j>=0; --j)
        //             {
        //                 if (evidence_paths[j] == "/uploading/"+filename)
        //                 {
        //                     evidence_paths = evidence_paths.slice();
        //                     evidence_paths[j] = path;
        //                 }
        //
        //             }
        //
        //
        //             a = {...a, details:{...a.details, evidence_paths:evidence_paths}};
        //             complianceActions[i] = a;
        //
        //             break;
        //         }
        //     }
        //
        //     return {...state, complianceActions:complianceActions};
        // }
        //
        // case UPLOAD_EVIDENCE_ERROR:
        // {
        //     let action_id = action.action_id;
        //     let filename = action.filename;
        //     let path = "/error/" + filename;
        //
        //     // find the action and add the file to the path
        //
        //     let complianceActions = state.complianceActions;
        //     for (let i=0; i<state.complianceActions.length; ++i)
        //     {
        //         let a = complianceActions[i];
        //         if (a.object_id == action_id)
        //         {
        //             complianceActions = complianceActions.slice();
        //             let evidence_paths = a.details.evidence_paths;
        //
        //             for (let j=evidence_paths.length-1; j>=0; --j)
        //             {
        //                 if (evidence_paths[j] == "/uploading/"+filename)
        //                 {
        //                     evidence_paths = evidence_paths.slice();
        //                     evidence_paths[j] = path;
        //                 }
        //
        //             }
        //
        //
        //             a = {...a, details:{...a.details, evidence_paths:evidence_paths}};
        //             complianceActions[i] = a;
        //
        //             break;
        //         }
        //     }
        //
        //     return {...state, complianceActions:complianceActions};
        //
        // }
        //
        // case DELETE_COMPLIANCEACTION_DONE:
        // {
        //     let action_id = action.action_id;
        //
        //     let complianceActions = state.complianceActions;
        //     for (let i=0; i<state.complianceActions.length; ++i)
        //     {
        //         let a = complianceActions[i];
        //         if (a.object_id == action_id)
        //         {
        //             complianceActions = complianceActions.slice();
        //             complianceActions.splice(i, 1);
        //             break;
        //         }
        //     }
        //
        //     let complianceDefn = updateComplianceDefn(action.results.data, state.complianceDefn);
        //
        //     return {...state, complianceActions:complianceActions, complianceDefn:complianceDefn};
        // }
        // case DELETE_COMPLIANCEACTION_FAILED:
        // {
        //     return state;
        // }
        //
        // case COMPLIANCE_UPDATED:
        // {
        //     return {...state, lastComplianceUpdateTime:action.time};
        // }

    }
    return state;
}

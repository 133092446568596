import React from 'react';
import EButton from "../components/common/EButton"
import {Row,Col} from 'react-bootstrap'
import {EventSectionTitle} from '../components/common/StakeholderUtils'
import SessionManager from "../utils/SessionManager";
import {withTranslation} from "react-i18next";

class StakeholderEventAttachmentsView extends React.Component {

    constructor() {
        super();
        this.showLoadError = this.showLoadError.bind(this);
        this.uploadfile = this.uploadfile.bind(this);
        this.loaderror = React.createRef();
    }

    uploadfile(event){
        const {t} = this.props;

        if (event.target.files.length > 0){

            let file = event.target.files[0];
            if (file.type !== "application/pdf")
            {
                this.showLoadError(t("Bad File"), t("Only PDF files are accepted"));
                return;
            }else {
                this.props.uploadAttachment(file);
            }

        }
    }

    showLoadError(title, message){

        let error_message = message;
        SessionManager.shared().showLoadError(error_message,title,false);
    }

    render()
    {
        const {t} = this.props;

        if (this.props.published)
        {

            let attachments = [];

            if(this.props.attachments !== null && this.props.attachments !== undefined){

                this.props.attachments.forEach((path) => {

                    let parts = path.split("/");
                    let name = parts[parts.length - 1];

                    let uploading = path.startsWith("/uploading");

                    let isError = path.startsWith("/error");

                    if(!isError && !uploading){
                        let file =
                            <Row key={"X-"+path} style={{marginTop: 5}}>
                                <Col md={4}>
                                <span><i className="fa fa-file-pdf-o"></i>&nbsp;&nbsp;
                                    <a href={"/ebase/stakeholder/read-stakeholder-attachment" + path}
                                       target="_blank" rel="noopener noreferrer">{name}</a>
                                </span>
                                </Col>
                            </Row>;
                        attachments.push(file);
                    }
                });

            }

            if(attachments.length === 0){
                attachments = t("None");
            }




            return (
                <div>
                    <EventSectionTitle><i className="fa fa-paperclip"></i> {t("Attachments")}</EventSectionTitle>
                    <div>{attachments}</div>
                </div>);
        }

        let addButton =
            <div style={{display:"flex",justifyContent:"flex-end"}}>
                <EButton type="add" onClick={() => {this.refs.uploadFileRef.click()}}>{t("New Attachment")}</EButton>
            </div>;

        let attachments = [];

        if(this.props.attachments !== null && this.props.attachments !== undefined){

            this.props.attachments.forEach((path) => {

                let parts = path.split("/");
                let name = parts[parts.length - 1];

                let uploading = path.startsWith("/uploading");

                let isError = path.startsWith("/error");

                if(isError){
                    let file =
                        <Row key={path} style={{marginTop: 5}}>
                            <Col md={4}>
                                <span><i className="fa fa-file-pdf-o"></i>&nbsp;&nbsp;{name}</span>
                            </Col>
                            <Col>
                                <span className="label label-danger" style={{marginLeft: 20}}>{t("Error")}</span>
                            </Col>
                        </Row>;
                    attachments.push(file);
                }else {

                    if (uploading) {
                        let file =
                            <Row key={path} style={{marginTop: 5}}>
                                <Col md={4}>
                                    <span><i className="fa fa-file-pdf-o"></i>&nbsp;&nbsp;{name}</span>
                                </Col>
                                <Col>
                                    <span className="label label-success" style={{marginLeft: 20}}>{t("Uploading")}...</span>
                                </Col>
                            </Row>;
                        attachments.push(file);

                    } else {

                        let deleting = path.startsWith("/deleting");

                        let file =
                            <Row key={path} style={{marginTop: 5}}>
                                <Col md={4}>
                                <span><i className="fa fa-file-pdf-o"></i>&nbsp;&nbsp;
                                    <a href={"/ebase/stakeholder/read-stakeholder-attachment" + path}
                                       target="_blank" rel="noopener noreferrer">{name}</a>
                                </span>
                                </Col>
                                {this.props.readOnly ? [] :
                                    <Col>
                                        <EButton ebSize="xs" type="remove" isTable={true} disabled={deleting}
                                                 onClick={this.props.deleteAttachment.bind(this, path)}>
                                            Remove
                                        </EButton>
                                    </Col>
                                }
                            </Row>;
                        attachments.push(file);
                    }
                }
            });

        }

        if(attachments.length === 0){
            attachments = t("None");
        }


        return (
            <div>

                <Row>
                    <Col md={2}><h3><i className="fa fa-paperclip"></i> {t("Attachments")}</h3></Col>
                    <Col md={10}>{this.props.readOnly ? [] : addButton}</Col>
                </Row>
                <div>
                    <input type="file" ref="uploadFileRef"
                           style={{display:"none"}}
                           onChange={this.uploadfile.bind(this)}
                    />

                </div>
                    {attachments}
            </div>
        );
    }
}

export default withTranslation()(StakeholderEventAttachmentsView)

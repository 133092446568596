/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import sitedata from '../../utils/sitedata'
import StakeholderCommsView from '../../views/StakeholderCommsView'
import { connect } from 'react-redux'
import details from "../../utils/details"
import CommsFilter from "../../utils/CommsFilter";
import values from "object.values"
import SessionManager from "../../utils/SessionManager";

const mapStateToProps = (state, ownProps) =>
{
    let currentObject = state.reports.currentObject;

    return {
        project: (currentObject==null)? null : currentObject.project,
        selectedObject: state.comms.selectedObject,
        events: state.comms.events,
        isLoading: state.comms.isLoading,
        isObjectLoading: state.comms.isObjectLoading,
        changes:state.comms.changes,
        stakeholdersMap:(state.comms.objectLists!=null) ? state.comms.objectLists["Stakeholder"] : {},
        objectLists:state.comms.objectLists,
        // event actions
        actions : state.comms.actions,
        selectedAction : state.comms.selectedAction,
        selectedActionValid:state.comms.selectedActionValid,
        allActions:state.comms.allActions,
        //objections
        objectionLinks:state.comms.objectionLinks,
        allObjections:state.comms.allObjections,

        isSearching:state.comms.isSearching,
        searchResults:state.comms.eventSearchResults,
        searchTerm:state.comms.eventSearchTerm,
        dimensions:state.session.dimensions,
    }
}

const loadEvents = function(project_id, event_id, dispatch, ownProps)
{
    dispatch({type: "LOAD_SHEVENTS_START"});


    ebase.ajax({
        url: "/ebase/stakeholder/read-stakeholder-events",
        data: {project_id: project_id},
        success: (result) => {
            if (result.success) {
                dispatch({type: "LOAD_SHEVENTS_DONE", result: result});
                loadEvent(project_id, event_id, dispatch, ownProps);
            }
            else {
                dispatch({type: "LOAD_SHEVENTS_FAILED", error: result.error});
            }
        },
        error: () => dispatch({type: "LOAD_SHEVENTS_FAILED", error: "Error contacting server"})
    });

}

const loadEvent = function(project_id, event_id, dispatch, props)
{
    if (event_id == null || event_id === "none" || event_id === "loading")
    {
        return;
    }
    dispatch({type:"LOAD_SHEVENT_START"});

    ebase.ajax({
        url:"/ebase/stakeholder/read-stakeholder-event",
        data:{project_id : project_id, object_id : event_id/*, type:"StakeholderEvent"*/},
        success:(result) => {
                                if (result.success) {
                                    dispatch({type:"LOAD_SHEVENT_DONE", result:result})
                                }
                                else {
                                    dispatch({type:"LOAD_SHEVENT_FAILED", error:result.error});
                                    props.showLoadError(result.error);
                                }
        },
        error:(error) => {
            dispatch({type:"LOAD_SHEVENT_FAILED", error:"Error contacting server"});
            props.showLoadError(error);


        }
    });


}

const loadObjections = function(project_id, objection_id, dispatch, ownProps)
{
    dispatch({type: "LOAD_OBJECTIONS_START"});


    ebase.ajax({
        url: "/ebase/stakeholder/read-stakeholder-objections",
        data: {project_id: project_id},
        success: (result) => {
            if (result.success) {
                console.log('loadObjections done ',result);
                dispatch({type: "LOAD_OBJECTIONS_DONE", result: result});
                loadObjection(project_id, objection_id, dispatch, ownProps);
            }
            else {
                dispatch({type: "LOAD_OBJECTIONS_FAILED", error: result.error});
            }
        },
        error: () => dispatch({type: "LOAD_OBJECTIONS_FAILED", error: "Error contacting server"})
    });

}

const loadObjection = function(project_id, objection_id, dispatch, props)
{
    if (objection_id == null || objection_id === "none" || objection_id === "loading")
    {
        return;
    }

    dispatch({type:"LOAD_OBJECTION_START"});

    ebase.ajax({
        url:"/ebase/stakeholder/read-stakeholder-objection",
        data:{project_id : project_id, object_id : objection_id},
        success:(result) => {
            if (result.success) {
                dispatch({type:"LOAD_OBJECTION_DONE", result:result})
            }
            else {
                dispatch({type:"LOAD_OBJECTION_FAILED", error:result.error});
                props.showLoadError(result.error);
            }
        },
        error:(error) => {
            dispatch({type:"LOAD_OBJECTION_FAILED", error:"Error contacting server"});
            props.showLoadError(error);


        }
    });


}


const loadActions = function(project_id, dispatch, ownProps){

    dispatch({type: "LOAD_SHACTIONS_START"});

    ebase.ajax({
        url: "/ebase/stakeholder/read-stakeholder-actions",
        data: {project_id: project_id},
        success: (result) => {
            if (result.success) {
                console.log("read-stakeholder-actions result ",result);
                dispatch({type: "LOAD_SHACTIONS_DONE", result: result});

            }
            else {
                dispatch({type: "LOAD_SHACTIONS_FAIL", error: result.error});
            }
        },
        error: () => dispatch({type: "LOAD_SHACTIONS_FAIL", error: "Error contacting server"})
    });

}

const saveEventFields = function(dispatch, changes, props)
{
    let changedFields = changes.changedFields;

    if (details.isObjectEmpty(changedFields))
    {
        return;
    }

    if (!Object.values) values.shim();


    dispatch({type:"SAVING_SHEVENTS_START"});

    ebase.ajax({
        url: "/ebase/stakeholder/write-stakeholder-updatefields",
        data: Object.values(changedFields),
        success: (result) => {
            if (result.success) {
                console.log("Stakeholder Events save fields success");
                dispatch({type: "SAVING_SHEVENTS_DONE", result: result});
            } else {
                props.showLoadError(result.error);
            }
        },
        error:   (error) => {
            dispatch({type:"SAVING_SHEVENTS_FAILED", error:error})
            props.showLoadError("Error saving stakeholder communications", error);
        }
    });

}

const mapDispatchToProps = (dispatch, ownProps) =>
{

    ownProps.showLoadError = SessionManager.shared().showLoadError;
    ownProps.params = sitedata.getParams(ownProps);
    return {
        loadList: (listType, project_id, object_id) =>
        {
            console.log('loadList',listType,project_id,object_id);
            // console.log("Loading - " + listType + " - " + project_id);
            if (listType ==="events")
            {
                loadEvents(project_id, object_id, dispatch, ownProps);
            }
            else if (listType === "actions")
            {
                loadActions(project_id, dispatch, ownProps);
            }
            else if (listType === "objections")
            {
                loadObjections(project_id, object_id, dispatch, ownProps);
            }

        },
        loadObject: (listType, project_id, object_id) =>
        {
            if (listType === "events")
            {
                loadEvent(project_id, object_id, dispatch, ownProps);
            }
            else if (listType === "actions")
            {
            }
            else if (listType === "objections")
            {
                loadObjection(project_id, object_id, dispatch, ownProps);
            }
        },

        loadEvent: (project_id, event_id) => {
            loadEvent(project_id, event_id, dispatch, ownProps);
        },

        loadEvents: (project_id, event_id) => {
            loadEvents(project_id, event_id, dispatch, ownProps);
        },

        loadObjection: (project_id, objection_id) => {
            loadObjection(project_id, objection_id, dispatch, ownProps);
        },

        loadObjections: (project_id, objection_id) => {
            loadObjections(project_id, objection_id, dispatch, ownProps);
        },

        newAction(event_id)
        {
            dispatch({type: "NEW_SHACTION_START"});

            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-newaction",
                data: {object_id: event_id},
                success: (result) => {

                    if (result.success) {
                        dispatch({type: "NEW_SHACTION_DONE", result: result});
                    } else {
                        dispatch({type: "NEW_SHACTION_FAILED", error: result.error});
                        ownProps.showLoadError("Error Adding Communication Action", result);
                    }
                },
                error: (result) => {
                    dispatch({type: "NEW_SHACTION_FAILED", error: result.error});
                    ownProps.showLoadError("Error Creating Communication Action", result);
                }

            });


        },
        removeAction(action_id)
        {
            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-removeaction",
                data: {object_id: action_id},
                success: (result) => {

                    if (result.success) {
                        dispatch({type: "REMOVE_SHACTION_DONE", action_id: action_id, result: result});
                    } else {
                        dispatch({type: "REMOVE_SHACTION_FAILED", error: result.error});
                        ownProps.showLoadError("Error Removing Communication Action", result);
                    }
                },
                error: (result) => {
                    dispatch({type: "REMOVE_SHACTION_FAILED", error: result.error});
                    ownProps.showLoadError("Error Removing Communication Action", result);
                }

            });
        },

        doneAction(action_id)
        {
            dispatch({type: "EDIT_SHACTION_DONE", action_id: action_id});
        },

        newEvent(project_id)
        {
            dispatch({type: "NEW_SHEVENT_START"});

            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-newevent",
                data: {project_id: project_id, type: "StakeholderEvent"},
                success: (result) => {

                    if (result.success) {
                        dispatch({type: "NEW_SHEVENT_DONE", result: result});

                        let listType = ownProps.params.listType;
                        let filter = new CommsFilter(ownProps.params.filter);
                        let object_id = result.data.object.object_id;
                        SessionManager.shared().goto('/stakeholder/' + project_id + '/comms/' + listType + '/' + filter.toString() + '/' + object_id);


                    } else {
                        dispatch({type: "NEW_SHEVENT_FAILED", error: result.error});
                        ownProps.showLoadError("Error Loading Compliance Action", result);
                    }
                },
                error: (result) => {
                    dispatch({type: "NEW_SHEVENT_FAILED", error: result.error});
                    ownProps.showLoadError("Error Creating Communication", result);
                }

            });

        },

        updateEventField: (object_id, fieldName, fieldValue) => {
            console.log("Update Field : " + fieldName + " = " + fieldValue);
            dispatch({type: "UPDATE_SHEVENTS_FIELD", object_id: object_id, field: fieldName, value: fieldValue});
        },

        saveEventFields(changed)
        {
            saveEventFields(dispatch, changed, ownProps)
        },

        uploadAttachment(event_id, file)
        {
            let data = new FormData();
            data.set("file", file);
            data.set("event_id", event_id);

            dispatch({type: "UPLOAD_COMMSEVENT_ATTACHMENT_START", event_id: event_id, file: file.name});

            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-uploadcommsattachment",
                data: data,
                contentType: false,
                processData: false,
                method: 'POST',
                success: (result) => {
                    if (result.success) {
                        dispatch({
                            type: "UPLOAD_COMMSEVENT_ATTACHMENT_DONE",
                            event_id: event_id,
                            path: result.data,
                            filename: file.name
                        });
                    } else {
                        dispatch({type: "UPLOAD_COMMSEVENT_ATTACHMENT_ERROR", event_id: event_id, file: file.name});
                    }
                },
                error: ((error) => dispatch({
                    type: "UPLOAD_COMMSEVENT_ATTACHMENT_ERROR",
                    event_id: event_id,
                    file: file.name
                }))
            }, false);
        },

        deleteAttachment(event_id, path){

            dispatch({type: "DELETE_COMMSEVENT_ATTACHMENT_START", event_id: event_id, path: path});

            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-deleteattachment",
                data: {object_id: event_id, project_id: path},
                success: (result) => {
                    if (result.success === true) {
                        dispatch({type: "DELETE_COMMSEVENT_ATTACHMENT_DONE", event_id: event_id, path: result.data});
                    } else {
                        dispatch({type: "DELETE_COMMSEVENT_ATTACHMENT_ERROR", event_id: event_id, path: path});
                    }
                },
                error: () => dispatch({type: "DELETE_COMMSEVENT_ATTACHMENT_ERROR", event_id: event_id, path: path})
            });
        },

        loadActions(project_id){
            loadActions(project_id, dispatch, ownProps);
        },

        newObjection(event_id, details)
        {
            console.log("EventId = " + event_id);
            console.log("details = " + details);

            dispatch({type: "NEW_OBJECTION_START"});

            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-newobjection",
                data: {event_id: event_id, details: details},
                success: (result) => {

                    if (result.success) {
                        dispatch({type: "NEW_OBJECTION_DONE", result: result});
                    } else {
                        dispatch({type: "NEW_OBJECTION_FAILED", error: result.error});
                        ownProps.showLoadError("Error Creating Objection", result);
                    }
                },
                error: (result) => {
                    dispatch({type: "NEW_OBJECTION_FAILED", error: result.error});
                    ownProps.showLoadError("Error Creating Objection", result);
                }

            });
        },

        removeObjection(link_id)
        {
            console.log("REMOVE OBjection");
            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-removeobjection",
                data: {object_id: link_id},
                success: (result) => {

                    if (result.success) {
                        dispatch({type: "REMOVE_OBJECTION_DONE", objection_id: link_id, result: result});
                    } else {
                        dispatch({type: "REMOVE_OBJECTION_FAILED", error: result.error});
                        ownProps.showLoadError("Error Removing Objection", result);
                    }
                },
                error: (result) => {
                    dispatch({type: "REMOVE_OBJECTION_FAILED", error: result.error});
                    ownProps.showLoadError("Error Removing  Objection", result);
                }

            });
        },

        linkObjection(event_id, details)
        {

        },

        updateObjection(objection_id, details, afterSuccess)
        {

            dispatch({type: "NEW_OBJECTION_START"});
            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-updateobjection",
                data: {objection_id: objection_id, details: details},
                success: (result) => {
                    if (result.success) {
                        dispatch({type: "UPDATE_OBJECTION_DONE", result: result.data});
                        if (afterSuccess){
                            afterSuccess();
                        }
                    } else {
                        ownProps.showLoadError("Updating Objection Failed", result);
                    }
                },
                error: (result) => {
                    dispatch({type: "NEW_OBJECTION_FAILED", error: result.error});
                    ownProps.showLoadError("Error Creating Objection", result);
                }

            })
        },
        searchComms(term, project_id){

            dispatch({type: "COMMS_SEARCH_START"});

            ebase.ajax({
                url: "/ebase/stakeholder/read-stakeholder-searchcomms",
                data: {filterString: term, project_id: project_id},
                success: (result) => {
                    if (result.success) {
                        dispatch({type: "COMMS_SEARCH_DONE", result: result});

                    }
                    else {
                        dispatch({type: "COMMS_SEARCH_ERROR", error: result.error});
                    }
                },
                error: () => dispatch({type: "COMMS_SEARCH_ERROR", error: "Error contacting server"})
            });
        },

        deleteEvent(event_id)
        {
            dispatch({type: "DELETE_SHEVENT_START", event_id: event_id});

            ebase.ajax({
                url: "/ebase/stakeholder/write-stakeholder-deleteevent",
                data: {object_id: event_id},
                success: (result) => {
                    if (result.success === true) {
                        dispatch({type: "DELETE_SHEVENT_DONE", event_id: event_id});
                    } else {
                        dispatch({type: "DELETE_SHEVENT_FAIL", event_id: event_id});
                    }
                },
                error: () => dispatch({type: "DELETE_SHEVENT_FAIL", event_id: event_id})
            });

        },

        clearLists()
        {
            dispatch({type:"COMMS_CLEAR_LISTS"});
        },

        clearCommsSearch(){
            dispatch({type:"COMMS_SEARCH_CLEAR"});
        },


/*
        startEdit(action_id)
        {
            dispatch(editStakeholderActionStart(action_id));
        },


        deleteAction(action_id)
        {
            ebase.ajax({
                url:"/ebase/Stakeholder/write-Stakeholder-deleteaction",
                data:{object_id : action_id},
                success:(result) => {
                    if (result.success)
                    {
                        dispatch(deleteStakeholderActionDone(action_id, result));
                        dispatch(StakeholderUpdated(new Date()));
                    }
                    else
                    {
                        dispatch(deleteStakeholderActionFailed(result.error));
                    }
                },
                error:() => dispatch(deleteStakeholderActionFailed("Error contacting server"))
            });
        },

*/


    }
}

class SHCommsView extends React.Component{
    render() {
        return (
            <div>
                {React.cloneElement(<StakeholderCommsView/>, this.props)}
            </div>
        )
    }
    // componentWillReceiveProps(nextProps)
    // {
    //      console.log("StakeholderCommsContainer - SHCommsView:: componentWillReceiveProps ");
    //     let nextParams = sitedata.getParams(nextProps);
    //     let params = sitedata.getParams(this.props);
    //
    //     let project_id = nextParams.projectId;
    //     let object_id = nextParams.objectId;
    //     let listType = nextParams.listType;
    //
    //     if (params.listType != listType)
    //     {
    //         console.log("SHCommsView:: Load List");
    //         console.log(" params.listType ",params.listType);
    //         console.log(" listType ",listType);
    //         this.props.loadList(listType, project_id, object_id);
    //     }
    //     else if (params.objectId != object_id || params.projectId != project_id) {
    //         console.log("SHCommsView:: Load Event : " + object_id + " | list: "+listType + " | project: "+project_id);
    //         this.props.loadObject(listType, project_id, object_id);
    //     }
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // console.log("StakeholderCommsContainer - SHCommsView:: componentDidUpdate ");

        let nextParams = sitedata.getParams(this.props);
        let params = sitedata.getParams(prevProps);

        let project_id = nextParams.projectId;
        let object_id = nextParams.objectId;
        let listType = nextParams.listType;

        if (params.listType !== listType)
        {
            // console.log("SHCommsView:: Load List");
            // console.log(" params.listType ",params.listType);
            // console.log(" listType ",listType);
            this.props.loadList(listType, project_id, object_id);
        }
        else if (params.objectId !== object_id || params.projectId !== project_id) {
            // console.log("SHCommsView:: Load Event : " + object_id + " | list: "+listType + " | project: "+project_id);
            this.props.loadObject(listType, project_id, object_id);
        }
    }

    componentDidMount()
    {
        let params = sitedata.getParams(this.props);

        let project_id = params.projectId;
        let object_id = params.objectId;
        let listType = params.listType;

        this.props.loadList(listType, project_id, object_id);

    }


};

const StakeholderCommsContainer = connect(mapStateToProps, mapDispatchToProps)(SHCommsView)

export default StakeholderCommsContainer

class ComplianceFilter extends Object
{
    constructor(filter) {
        super();

        if (filter == null)
        {
            filter = "0-0000-0";
        }
        this.filter = filter;

        this.toString = function () {
            return this.filter;
        }

        this.setShowDueIn = function(days)
        {
            this.filter = this.filter.slice(0, 7) + days;
        }

        this.setMineOnly = function(b)
        {
            this.filter = (b?"1":"0") + this.filter.slice(1);
        }

        this.setStatus = function(status, b)
        {
            let pos = this.statusIndex(status);

            this.filter = this.filter.slice(0, pos) + (b?"1":"0") + this.filter.slice(pos+1);
        }

        this.showMineOnly = function () {
            return this.filter.charAt(0) === '1';
        }

        this.getDueIn = function()
        {
            try
            {
                return parseInt(this.filter.slice(7));
            }
            catch (e)
            {
                console.error(e);
                return 0;
            }
        }

        this.showAll = function () {
            if (filter === "0" || filter === "0-0000-0")
            {
                return true;
            }
            return false;
        }

        this.statusIndex = function(status)
        {
            if (status == null)
            {
                return 2;
            }
            else if (status === "COMPLIANT")
            {
                return 3;
            }
            else if (status === "NOT_COMPLIANT")
            {
                return 4;
            }
            else if (status === "IN_PROGRESS")
            {
                return 5;
            }
            else
            {
                return 2;
            }

        }
        this.checkStatus = function(status)
        {
            let bStatusCheck = 0;
            let pos = this.statusIndex(status);
            bStatusCheck = this.filter.charAt(pos) === '1';

            return bStatusCheck;
        }

        this.showByStatus = function(defn)
        {
            let bStatusCheck = false;

            let status = null;

            if (this.showAll()) return true;

            if (defn != null) {
                status = defn.details.status;
            }

            bStatusCheck = this.checkStatus(status);

            return bStatusCheck;
        }

        this.getDisplayMessage = function()
        {
            let message = null;
            let bShowMine = this.showMineOnly();

            if (this.checkStatus("COMPLIANT"))
            {
                message = "Compliant Controls";
            }
            else if (this.checkStatus("NOT_COMPLIANT"))
            {
                message = "Non Compliant Controls";
            }
            else if (this.checkStatus("IN_PROGRESS"))
            {
                message = "In Progress Controls";
            }
            else if (this.checkStatus(null))
            {
                message = "New Controls";
            }
            else if (this.getDueIn() > 0)
            {
                message = "Controls due in less than " + this.getDueIn() + " days";
            }

            if (bShowMine)
            {
                return "Showing the " + message + " for your roles only";
            }

            return "Showing " + message + " Only";
        }
    }
}

export default ComplianceFilter;

import React from 'react';
import {FormControl, Form, FormGroup} from 'react-bootstrap'
import Editor from "../components/common/Editor"
import details from '../utils/details'
import { connect } from 'react-redux'
import Spinner from "../components/common/Spinner"
import {withTranslation} from "react-i18next";

class ProjectStakeholderLinkSetupView extends React.PureComponent {

    constructor(props){
        super(props);
        this.statusPretty = this.statusPretty.bind(this);
        this.makeStatusLabel = this.makeStatusLabel.bind(this);
    }

    handleModelChange(field,model){
        this.props.updateField(this.props.projectStakeholderLink.object_id,field,model);
        setTimeout(this.props.saveFields, 400);
    }

    statusChanged(event){
        let updateStatus = event.target.value;
        if(updateStatus !== "" && updateStatus !== null && updateStatus !== undefined) {
            this.props.updateField(this.props.projectStakeholderLink.object_id, "details.status", updateStatus);
            setTimeout(this.props.saveFields, 250);
        }
    }

    statusPretty(rawStatus,t){
        if (rawStatus === ""){
            return t("Unassigned")
        }
        if (rawStatus === "IN_PROGRESS"){
            return t("In Progress");
        }
        if (rawStatus === "COMPLETE"){
            return t("Complete");
        }
        if (rawStatus === "COMPLETE_WITH_CONSULTATION") {
            return t("Complete With Consultation");
        }
        if (rawStatus === "NA") {
            return t("N/A");
        }
            return rawStatus;
    }

    makeStatusLabel(rawStatus){
        let {t} = this.props;
        let labelClass = 'label ';

        if (rawStatus === "IN_PROGRESS"){
            labelClass = labelClass + "label-warning";
        }
        if (rawStatus === "COMPLETE"){
            labelClass = labelClass + "label-primary";
        }
        if (rawStatus === "COMPLETE_WITH_CONSULTATION"){
            labelClass = labelClass + "label-success";
        }
        if (rawStatus === "NA"){
            labelClass = labelClass + "label-default";
        }
        return <span className={labelClass}>{this.statusPretty(rawStatus,t)}</span>
    }

    render(){

        const {t} = this.props;

        // console.group("ProjectStakeholderLinkSetupView Render");
        // console.log(this.props);
        // console.groupEnd("------------");

        // Display spinner while loading. Makes sure the Editor text refreshes when loading
        // new stakeholder.
        if (this.props.isLoading){
            return <Spinner/>;
        }

        let link = this.props.projectStakeholderLink;
        if(link === null || link === undefined){
            return <div></div>;
        }

        let selectStatus = link.details.status;

        let options = [<option key="IN_PROGRESS" value="IN_PROGRESS">{this.statusPretty("IN_PROGRESS",t)}</option>,
                        <option key="COMPLETE"  value="COMPLETE">{this.statusPretty("COMPLETE",t)}</option>,
                        <option key="COMPLETE_WITH_CONSULTATION"  value="COMPLETE_WITH_CONSULTATION">{this.statusPretty("COMPLETE_WITH_CONSULTATION",t)}</option>,
                        <option key="NA"  value="NA">{t("Not Relevant")}</option>];
        if(selectStatus == null){
            selectStatus = "";
            options.unshift(<option key="select" value="">{t("Select")}...</option>);

        }else{

        }

        let desc = details.getValue(link,"details.desc");

        if (this.props.readOnly === true){
            return (
                <div>
                    <br/>

                    <FormGroup key="details.status" className="" >
                        <Form.Label className="">{t("Status of Stakeholder")}</Form.Label>
                        <div className="" >

                            {/*<p>{selectStatus === "" ? t("Unassigned") : this.statusPretty(selectStatus,t)}</p>*/}
                            <div>{this.makeStatusLabel(selectStatus)}</div>
                        </div>
                    </FormGroup>

                    <FormGroup key="details.notes" className="">
                        <Form.Label>{t("Notes")}</Form.Label>

                        <div key={"hmmmmm"}>
                            <p dangerouslySetInnerHTML={{__html: (desc == null || desc === "") ? t('No Description') : desc}}/>
                        </div>
                    </FormGroup>

                </div>
            )
        }


        return (
        <div>
            <br/>

            <FormGroup key="details.status" className="" >
                <Form.Label className="">{t("Status of Stakeholder")}</Form.Label>
                <div className="" >

                    <Form.Select placeholder="select"
                                 onChange={this.statusChanged.bind(this)}
                                value={selectStatus}>
                                {options}
                    </Form.Select>

                </div>
            </FormGroup>

            <FormGroup key="details.notes" className="">
                <Form.Label>{t("Notes")}</Form.Label>

                <div key={"hmmmmm"}>
                    <Editor model={desc}
                            handleModelChange={this.handleModelChange.bind(this, "details.desc")}
                            onBlur={this.props.saveFields}
                            off={true}
                    />
                </div>
            </FormGroup>

        </div>
        );

    }

}


// Connected to redux in this component instead of StakeholderMgrContainer because
// the loading state changing causes the left pane to blink a lot everytime you click a stakeholder.
// After the stage6 react update the editor model stopped updating for some reason. It would change but wouldn't display
// the updated. I didn't figure why so instead made it render the spinner while loading and then back to the editor.
// That way "forced" the editor to reload.
const mapStateToProps = (state, ownProps) =>
{
    // let currentObject = state.reports.currentObject;

    return {
        isLoading: state.stakeholder.isStakeholderLoading || state.stakeholder.isLoading,

    }
}

export default withTranslation()(connect(mapStateToProps)(ProjectStakeholderLinkSetupView));

import React from 'react';
import {Row,Col} from 'react-bootstrap'
import details from '../utils/details'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import moment from 'moment';
import {StakeholderEventType} from '../components/common/StakeholderEventType'
import {withTranslation} from "react-i18next";



class StakeholderEventListView extends React.Component {

    constructor()
    {
        super();
        this.searchValueChanged = this.searchValueChanged.bind(this);
        this.doSearch = this.doSearch.bind(this);

        this.state={searchTerm:""};
    }

    clickItem(object_id)
    {
        this.props.onClick(object_id);
    }

    searchValueChanged(event){
        this.setState({searchTerm:event.target.value});
    }

    doSearch()
    {
        if(this.props.isSearching){
            return;
        }
        let term = this.state.searchTerm.trim();
        if(term.length > 0) {
            this.props.search(term);
        }
        if(term.length === 0){
            this.props.clearSearch();
        }
    }

    clearSearch(){
        this.setState({searchTerm:""});
        this.props.clearSearch();
    }

    render() {

        const {t} = this.props;

        // console.log("Stakeholder Event List RENDER");
        // console.log(this.props);
        // console.log("------");


        // let stakeholdersMap = this.props.stakeholdersMap;

        let events = this.props.events;
        if (events == null)
        {
            events = [];
        }

        let showingSearchResults = this.props.searchTerm !== "";

        if(this.props.searchResults !== undefined){
            if(this.props.searchResults.length > 0){
                let filtered = events.filter((ev) => {
                    return this.props.searchResults.includes(ev.object_id);
                });
                events = filtered;
            }

            if(this.props.searchResults.length === 0 && showingSearchResults){
                events = [];
            }

        }
        events = events.sort( (a,b) => {
            let aTime = a.details.timestamp;
            let bTime = b.details.timestamp;
            aTime = aTime.toString(); //Fixes .localCompare error
            bTime = bTime.toString();

            let ret = 0;
            if (!!bTime && !aTime) {
                ret = -1;
            } else if (!bTime && !!aTime) {
                ret = 1;
            } else if (!!aTime && !!bTime) {
                ret = bTime.localeCompare(aTime);
            }
            if (ret === 0) {
                ret = b.object_id.localeCompare(a.object_id);
            }
            return ret;
        })

        let resultInfo = null;
        if(showingSearchResults){

            resultInfo =
                <div className="alert alert-info" style={{marginBottom: 0}}>
                    <Row style={{height: 15}}>
                        <Col md={10} style={{marginTop: -2}}>
                            <i className="fa"></i> <b>{"Showing "+ events.length + " result" + (events.length !== 1? "s": "")} {" for \"" + this.props.searchTerm+"\""}</b>
                        </Col>
                        <Col md={2} style={{textAlign: "right", marginTop: -7}}>
                            <EButton isTable={true} type="clear" onClick={this.clearSearch.bind(this)}>{t("Show All")}</EButton>
                        </Col>
                    </Row>
                </div>


        }

        let rows = [];

        if(this.props.isSearching){
            rows = <Spinner/>;
            resultInfo = null;
        }
        else {
            rows = events.map((event) => {

                let stakeholders_short = details.getStakeholderList(event.details.stakeholders, this.props.stakeholdersMap, "short");

                let is_draft = event.publishingInfo == null || !event.publishingInfo.published;

                let time = moment(event.details.timestamp);

                let indicator_style = {display:"inline-block", width:20};
                let action_indicator = <div style={indicator_style}>{(event.details.action_count > 0) ? <EnvidObjectIcon type="Action"/> : null}</div>
                let objection_indicator = <div style={indicator_style}>{(event.details.objection_count > 0) ? <EnvidObjectIcon type="Objection"/> : null}</div>
                let attachment_indicator = <div style={indicator_style}>{(event.details.attachment_count > 0) ? <i className="fa fa-paperclip"></i> : null}</div>

                let indicators = <span>{action_indicator}{objection_indicator}{attachment_indicator}</span>

                let type_icon = <StakeholderEventType type={event.details.event_type} style={{color: "#12b394"}}/>
                if (is_draft) {
                    indicators = <span className="label label-danger">{t("DRAFT")}</span>
                    type_icon = <i className="fa fa-circle text-danger"/>
                }
                return (
                    <tr key={event.object_id} className="read" onClick={this.clickItem.bind(this, event.object_id)}
                        style={{cursor: "pointer"}}>
                        <td className="check-mail">{type_icon}</td>
                        <td className="mail-ontact"><span>{stakeholders_short}</span></td>
                        <td className="mail-subject"><span>{event.details.name}</span></td>
                        <td className="">{indicators}</td>
                        <td className="text-right mail-date">{time.calendar(null, {sameElse: "Do MMMM YYYY"})}</td>
                    </tr>
                );
            });
        }



        return (
            <div>
                <div className="mail-box-header">

                    <div className="pull-right mail-search">
                        <div className="input-group">
                            <input type="text" className="form-control input-sm" name="search"
                                   placeholder={t("Search Events")}
                                   value={this.state.searchTerm} onChange={this.searchValueChanged} onKeyUp={(event) => {return (event.keyCode === 13)? this.doSearch() : null}}/>
                            <div className="input-group-btn">
                                <button type="submit" className="btn btn-sm btn-primary" onClick={this.doSearch} disabled={this.props.isSearching}>
                                    {this.props.isSearching ? t("Searching") : t("Search")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <h2>
                        <EnvidObjectIcon type="Communication"/>&nbsp;&nbsp;&nbsp;&nbsp;{t("Stakeholder Communications")}
                        {/*&nbsp;&nbsp;<span className="label label-success" style={{fontSize:16}}>{events.length}</span>*/}
                    </h2>
                </div>

                <div className="mail-box">
                    {resultInfo}
                    <table className="table table-hover table-mail">
                        <tbody>
                        {rows}

                        </tbody>
                    </table>


                </div>
            </div>
        );


    }

}

export default withTranslation()(StakeholderEventListView)

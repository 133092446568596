
import auth from "../utils/auth"
import jq from "../utils/jq"
import ebase from "./ajax";
import i18n from "../i18n";
import moment from 'moment';
import EbaseDS from "./EbaseDS";

require ('moment/locale/en-au');

let staticSessionManager;

class SessionManager{


    constructor(store) {
        this.store = store;
        staticSessionManager = this;
        moment.locale('en-au');
        const {REACT_APP_API_URL } = process.env;
        if (!!REACT_APP_API_URL) {
            this.ebaseDS = new EbaseDS(REACT_APP_API_URL, this.store);
        }else{
            this.ebaseDS = new EbaseDS(window.location.origin + '/ebase', this.store);
        }
    }

    checkSession(callback){
        console.log("SessionManager:: checkSession");
        this.store.dispatch({ type: 'CHECK_SESSION_START' });
        auth.checkSession((data,error) =>{

            console.log("SessionManager:: checkSession result: ",data,error);
            if(error == null) {
                data.ajaxSaveError = this.ajaxSaveError;
                this.store.dispatch({type: 'CHECK_SESSION_SUCCESS', data: data});
            }else {
                this.goto("/");
                this.store.dispatch({type: 'CHECK_SESSION_NO_SUCCESS'});
            }
            if(callback) {
                callback(data);
            }
        });
    }

    authenticate(email,password){

        this.store.dispatch({ type: 'AUTH_START' });

        // this.ebaseDS.loginSession(email,password, (resultData, resultError) => {
        //
        //     console.log("SessionManager:: auth result: ",resultData,resultError);
        //
        //     if (resultError == null){
        //         resultData.ajaxSaveError = this.ajaxSaveError;
        //         this.store.dispatch({type: 'AUTH_SUCCESS', data: resultData});
        //
        //         if (!auth.getIsLite()) {
        //             if (resultData.dataset == null) {
        //                 SessionManager.shared().goto("/libraries")
        //             } else {
        //                 SessionManager.shared().goto("/select")
        //             }
        //         }
        //
        //     }else{
        //         this.store.dispatch({type: 'AUTH_ERROR', data: resultError});
        //     }
        //
        //
        // });

        auth.loginSession(email,password, (resultData, resultError) => {

            console.log("SessionManager:: auth result: ",resultData,resultError);

            if (resultError == null){
                resultData.ajaxSaveError = this.ajaxSaveError;
                this.store.dispatch({type: 'AUTH_SUCCESS', data: resultData});

                if (!auth.getIsLite()) {
                    if (resultData.dataset == null) {
                        SessionManager.shared().goto("/libraries")
                    } else {
                        SessionManager.shared().goto("/select")
                    }
                }

            }else{
                this.store.dispatch({type: 'AUTH_ERROR', data: resultError});
            }


        });

    }

    logout(){
        this.store.dispatch({ type: 'AUTH_LOGOUT' });
        auth.logoutSession();
        this.history.push("/");
    }

    isLite(){
        return auth.getIsLite();
    }

    setHistory(history){
        this.history = history;
    }

    goto(link){
        console.log("SessionManager:: goto "+link,this.history);

        if (this) {

            if (this.history != null) {
                if (this.history.location.pathname !== link) {
                    this.history.push(link);
                }
            }
        }else{
            if (SessionManager.shared().history != null){
                if (SessionManager.shared().history.location.pathname !== link) {
                    SessionManager.shared().history.push(link);
                }
            }
        }
    }

    selectDatasetAccess(clientId, datasetId){
        console.log("SessionManager:: selectDatasetAccess: client: " + clientId + " | " + datasetId);

        // this.routerPush("/");
        auth.setSessionAccess(clientId,datasetId,(result) =>{

            console.log("SessionManager:: handleSetDatasetSuccess");
            console.log(result);

            if(result.success) {

                let siteData = result.data;

                let data = SessionManager.shared().getState().session.siteData;// this.state.siteData;
                data.client = siteData.client;
                data.dataset = siteData.dataset;
                data.access = siteData.access;
                data.languages = siteData.languages;

                let languageCode = data.dataset.languageCode;
                if (languageCode != null) languageCode = languageCode.toLowerCase();
                console.log("Changing Language to ", languageCode)

                i18n.changeLanguage(languageCode);
                // this.setState({siteData: data});

                SessionManager.shared().store.dispatch({type: "UPDATE_SESSION_SITEDATA", data:data});
                SessionManager.shared().goto("/select");
            }else{

                if(result.error !== undefined && result.error !== null){
                    if(result.error === "Session Expired") {
                        SessionManager.shared().goto("/");
                        SessionManager.shared().setState({siteData: null, datasetAccessList: null});
                    }else {
                        SessionManager.shared().showLoadError("Error selecting Library " + result.error, "");
                    }
                }else{
                    SessionManager.shared().showLoadError("Error selecting Library","");
                }
            }


        });
    }

    dispatch(action){
        if (this){
            this.store.dispatch(action);
        }else{
            SessionManager.shared().store.dispatch(action);
        }
    }

    getState(){
        if (this){
            return this.store.getState();
        }
        return SessionManager.shared().store.getState();
    }

    updateDimensions(values){
        // console.log("SessionManager:: update dimensions ",values);
        // console.log('store: ',this.store);

        let stateDim = SessionManager.shared().getState().session.dimensions;

        var dim = Object.assign({}, stateDim);
        dim.window =  {width:jq.find(window).width(), height: jq.find(window).height()};

        if (values != null && values.height != null)
        {
            dim[values.item + "Height"] = values.height;
            //console.log("UPDATE DIMENSIONS CALLED - " + values.item + " = " + values.height);// + JSON.stringify(dim));
        }

        dim.extras = {width: dim.window.width, height:dim.footerHeight+dim.topheaderHeight+dim.headingHeight};
        SessionManager.shared().dispatch({ type: 'UPDATE_DIMENSIONS', data:dim });

    }

    performRetry()
    {

        let saveErrors = this.getState().session.saveErrors;

        if (saveErrors != null && saveErrors.length > 0)
        {
            let request = saveErrors[0];
            let newList = saveErrors.slice(1);
            // this.setState({retrying:true, saveErrors:newList});
            this.dispatch({type:"UPDATE_RETRY_STATE",data:{retry: true, errors:newList}});

            console.log("performRetry");
            console.log(request);

            // take it off the list, then do a retry
            ebase.ajax(request);
        } else {
            // this.setState({retrying:false});
            this.dispatch({type:"UPDATE_RETRY_STATE",data:{retry: false}});
        }
    }

    cancelRetry()
    {
        // this.setState({saveErrors:[], retrying:false});
        this.dispatch({type:"UPDATE_RETRY_STATE",data:{retry: false, errors:[]}});
        window.location.reload();
    }

    ajaxSaveError(request, textStatus, errorThrown, original_request) {
        // check if it's because the

        console.log("ajaxSaveError");
        console.log(original_request);

        let new_request = Object.assign({}, original_request);
        new_request.error = (request, textStatus, errorThrown, original_request) => {
            // put it back on the list
            console.error("Still in error");
            let saveErrors = SessionManager.shared().getState().session.saveErrors;

            let newList = saveErrors.slice();
            newList.unshift(original_request);
            // this.setState({retrying:false, saveErrors:newList});
            SessionManager.shared().dispatch({type:"UPDATE_RETRY_STATE",data:{retry: false, errors:newList}});
        }

        new_request.success = (results) => {
            original_request.success(results);
            SessionManager.shared().performRetry();
        }

        let saveErrors = SessionManager.shared().getState().session.saveErrors;
        let newList = saveErrors.slice();
        newList.push(new_request);
        console.log(newList);

        // this.setState({saveErrors: newList});
        SessionManager.shared().dispatch({type:"UPDATE_RETRY_STATE",data:{errors: newList}});
    }

    setErrorHandler(handler){
        this.errorHandler = handler;
    }

    //redirect - boolean defaults to true.
    //redirectPath (optional) - string for the path to go to. If not set but redirect is true will default to '/'
    showLoadError(message, error,redirect, redirectPath){
        // console.log("SessionManager:: showLoadError ",this);
        SessionManager.shared().errorHandler(message,error);

        if (redirect != null){
            if (redirect === true){
                if (redirectPath != null){
                    SessionManager.shared().goto(redirectPath);
                }else{
                    SessionManager.shared().goto("/");
                }
            }
        }else {
            SessionManager.shared().goto("/");
        }
    }


    static shared(){
        return staticSessionManager;
    }
}
export default SessionManager;

import React from 'react';
import PropTypes from 'prop-types';

class EbaseAdvert extends React.Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    render(){

        let width = '100%';
        let height = 140;
        if (this.props.height != null){
            height = this.props.height;
        }
        if (this.props.width != null){
            width = this.props.width;
        }

        let base = {cursor:'pointer',width:width, height:height, backgroundColor: this.props.background, display:'flex',flexDirection:'row',...this.props.style};

        return (
            <a target="_blank" href={this.props.url} rel="noopener noreferrer">
            <div style={base}>
                <div style={{width:'45%',display:'flex', alignItems:'center', justifyContent:'space-between', paddingLeft: 15, paddingRight: 15}}>

                    <div style={{minWidth:'50%'}}>
                        <img alt={"Ebase Logo"} src="img/ebase-logo-white.png" style={{maxWidth:'100%',objectFit:'scale-down'}} />
                    </div>

                    {this.props.graphicSource != null ?
                        <div style={{display: 'flex', height: '100%', overflow: 'hidden'}}>
                        <img alt={"Ebase Graphic"} src={this.props.graphicSource} style={{maxWidth: '100%', objectFit: 'scale-down'}} />
                        </div>
                        :
                        null
                    }


                </div>

                <div style={{width:'55%',display:'flex', alignItems:'center', paddingRight: 15, paddingLeft: 15}}>
                    <p style={{...this.props.textStyle}}>
                        {this.props.text}
                    </p>
                </div>
            </div>
            </a>
        );
    }


}

EbaseAdvert.propTypes = {
    text: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    background: PropTypes.string,
    style: PropTypes.object,
    textStyle: PropTypes.object,
    url: PropTypes.string,
    graphicSource:PropTypes.string,

};

EbaseAdvert.defaultProps = {
    background: "#283543",
    text:"",
    textStyle: {color:"white", fontSize:13},
}

export default EbaseAdvert;

export const LOAD_USERS_START = 'LOAD_USERS_START'
export const LOAD_USERS_DONE = 'LOAD_USERS_DONE'

export const LOAD_PROJECTS_START = 'LOAD_PROJECTS_START'
export const LOAD_PROJECTS_DONE = 'LOAD_PROJECTS_DONE'

export const LOAD_PROJECT_START = 'LOAD_PROJECT_START'
export const LOAD_PROJECT_DONE = 'LOAD_PROJECT_DONE'

export const LOAD_PROJECT_STATUS_START = 'LOAD_PROJECT_STATUS_START'
export const LOAD_PROJECT_STATUS_DONE = 'LOAD_PROJECT_STATUS_DONE'

export const LOAD_PROJECT_COMPLIANCE_STATUS_START = 'LOAD_PROJECT_COMPLIANCE_STATUS_START'
export const LOAD_PROJECT_COMPLIANCE_STATUS_DONE = 'LOAD_PROJECT_COMPLIANCE_STATUS_DONE'

export const UPDATE_PROJECT_FIELD = 'UPDATE_PROJECT_FIELD'
export const UPDATE_PROJECTOBJECT_FIELD = 'UPDATE_PROJECTOBJECT_FIELD'

export const SAVING_PROJECT_START = 'SAVING_FIELDS_START'
export const SAVING_PROJECT_SUCCESS = 'SAVING_FIELDS_SUCCESS'
export const SAVING_PROJECT_FAILED = 'SAVING_FIELDS_FAILED'

export const LOAD_ALERTS_START = "LOAD_ALERTS_START"
export const LOAD_ALERTS_DONE = "LOAD_ALERTS_DONE"

export const NEW_PROJECT_START = "NEW_PROJECT_START"
export const NEW_PROJECT_DONE = "NEW_PROJECT_DONE"

/**
  REPORTS
 */
export const LOAD_REPORT_START = 'LOAD_REPORT_START'
export const LOAD_REPORT_DONE = 'LOAD_REPORT_DONE'

export const LOAD_TASK = 'LOAD_TASK'

export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const SAVE_ANSWERS = 'SAVE_ANSWERS'
export const GOTO_ANSWER = 'GOTO_ANSWER'

export const LOAD_REPORT_TEMPLATES = 'LOAD_REPORT_TEMPLATES'
export const SAVE_REPORT_TEMPLATES = 'SAVE_REPORT_TEMPLATES'
export const UPDATE_REPORT_TEMPLATE = 'UPDATE_REPORT_TEMPLATE'

export const LOAD_ADMIN_REPORT_TEMPLATES = 'LOAD_ADMIN_REPORT_TEMPLATES'
//export const SAVE_ADMIN_REPORT_TEMPLATES = 'SAVE_ADMIN_REPORT_TEMPLATES'
//export const UPLOAD_ADMIN_REPORT_TEMPLATE = 'UPLOAD_ADMIN_REPORT_TEMPLATE'

export const CHECK_REPORT_STATUS = 'CHECK_REPORT_STATUS'

export const LOAD_TREE_START = 'LOAD_TREE_START'
export const LOAD_TREE_DONE = 'LOAD_TREE_DONE'
export const LOAD_BRANCH_START = 'LOAD_BRANCH_START'
export const LOAD_BRANCH_DONE = 'LOAD_BRANCH_DONE'
export const LOAD_TREE_ERROR = 'LOAD_TREE_ERROR'
export const LOAD_BRANCH_ERROR = 'LOAD_BRANCH_ERROR'

export const LOAD_SUMMARY_OBJECT_START = 'LOAD_SUMMARY_OBJECT_START'
export const LOAD_SUMMARY_OBJECT_DONE = 'LOAD_SUMMARY_OBJECT_DONE'
export const CLEAR_SUMMARY_OBJECT = 'CLEAR_SUMMARY_OBJECT'

export const LOAD_PROJECTOBJECTS_START = 'LOAD_PROJECTOBJECTS_START'
export const LOAD_PROJECTOBJECTS_DONE = 'LOAD_PROJECTOBJECTS_DONE'
export const LOAD_PROJECTOBJECTS_ERROR = 'LOAD_PROJECTOBJECTS_ERROR'

export const NEW_PROJECTOBJECT_START = 'NEW_PROJECTOBJECT_START'
export const NEW_PROJECTOBJECT_DONE = 'NEW_PROJECTOBJECT_DONE'
export const NEW_PROJECTOBJECT_ERROR = 'NEW_PROJECTOBJECT_ERROR'

export const EDIT_PROJECTOBJECT_START = 'EDIT_PROJECTOBJECT_START'

export const LOAD_COMPLIANCE_START = 'LOAD_COMPLIANCE_START'
export const LOAD_COMPLIANCE_DONE = 'LOAD_COMPLIANCE_DONE'
export const LOAD_COMPLIANCE_FAILED = 'LOAD_COMPLIANCE_FAILED'

export const LOAD_COMPLIANCECONTROL_START = 'LOAD_COMPLIANCECONTROL_START'
export const LOAD_COMPLIANCECONTROL_DONE = 'LOAD_COMPLIANCECONTROL_DONE'
export const LOAD_COMPLIANCECONTROL_FAILED = 'LOAD_COMPLIANCECONTROL_FAILED'

export const NEW_COMPLIANCEACTION_DONE = 'NEW_COMPLIANCEACTION_DONE'
export const NEW_COMPLIANCEACTION_FAILED = 'NEW_COMPLIANCEACTION_FAILED'
export const EDIT_COMPLIANCEACTION_START = 'EDIT_COMPLIANCEACTION_START'

export const SAVING_COMPLIANCE_START = 'SAVING_COMPLIANCE_START'
export const SAVING_COMPLIANCE_SUCCESS = 'SAVING_COMPLIANCE_SUCCESS'
export const SAVING_COMPLIANCE_FAILED = 'SAVING_COMPLIANCE_FAILED'

export const UPDATE_COMPLIANCE_FIELD = 'UPDATE_COMPLIANCE_FIELD'

export const UPLOAD_EVIDENCE_START = 'UPLOAD_EVIDENCE_START'
export const UPLOAD_EVIDENCE_DONE = 'UPLOAD_EVIDENCE_DONE'
export const UPLOAD_EVIDENCE_ERROR = 'UPLOAD_EVIDENCE_ERROR'

export const DELETE_COMPLIANCE_EVIDENCE_UPLOAD = "DELETE_COMPLIANCE_EVIDENCE_UPLOAD"
export const DELETE_COMPLIANCE_EVIDENCE_UPLOAD_DONE = "DELETE_COMPLIANCE_EVIDENCE_UPLOAD_DONE"

export const DELETE_COMPLIANCEACTION_DONE = 'DELETE_COMPLIANCEACTION_DONE'
export const DELETE_COMPLIANCEACTION_FAILED = 'DELETE_COMPLIANCEACTION_FAILED'

export const COMPLIANCE_UPDATED = 'COMPLIANCE_UPDATED'

export const LOAD_STAKEHOLDERTASK_START = 'LOAD_STAKEHOLDERTASK_START'
export const LOAD_STAKEHOLDERTASK_DONE = 'LOAD_STAKEHOLDERTASK_DONE'
export const LOAD_STAKEHOLDERTASK_FAILED = 'LOAD_STAKEHOLDERTASK_FAILED'
export const LOAD_STAKEHOLDER_START = 'LOAD_STAKEHOLDER_START'
export const LOAD_STAKEHOLDER_DONE = 'LOAD_STAKEHOLDER_DONE'
export const LOAD_STAKEHOLDER_FAILED = 'LOAD_STAKEHOLDER_FAILED'


/**
 * Summary Stuff
 */
export function loadTreeStart()
{
    return {type:LOAD_TREE_START};
}

export function loadTreeDone(data)
{
    return {type:LOAD_TREE_DONE, data:data};
}

export function loadBranchStart(object_id)
{
    return {type:LOAD_BRANCH_START, object_id:object_id};
}

export function loadBranchDone(object_id, data)
{
    return {type:LOAD_BRANCH_DONE, object_id:object_id, data:data};
}

export function loadSummaryObjectStart(object_id, displayed_object_id)
{
    return {type:LOAD_SUMMARY_OBJECT_START, object_id:object_id, displayed_object_id:displayed_object_id};
}

export function loadSummaryObjectDone(object_id, data)
{
    return {type:LOAD_SUMMARY_OBJECT_DONE, object_id:object_id, data:data};
}

export function clearSummaryObject()
{
    return {type: CLEAR_SUMMARY_OBJECT};
}


/**
 * Users Stuff
 */
export function loadUsersStart() {
    return {type: LOAD_USERS_START};
}

export function loadUsersDone(result) {
    return {type: LOAD_USERS_DONE, result:result};
}

/**
 * Projects Stuff
 */

export function loadProjectsStart() {
    return {type: LOAD_PROJECTS_START};
}

export function loadProjectsDone(result) {
    return {type: LOAD_PROJECTS_DONE, result:result};
}

export function loadProjectStart(projectName) {
    return {type: LOAD_PROJECT_START, projectName:projectName};
}

export function loadProjectDone(result) {
    return {type: LOAD_PROJECT_DONE, result:result};
}

export function newProjectStart() {
    return {type: NEW_PROJECT_START};
}

export function newProjectDone(result) {
    return {type: NEW_PROJECT_DONE, result:result};
}

export function updateProjectField(name, value)
{
    return {type:UPDATE_PROJECT_FIELD, name:name, value:value};
}

export function savingProjectStart()
{
    return {type:SAVING_PROJECT_START};
}

export function savingProjectSuccess()
{
    return {type:SAVING_PROJECT_SUCCESS};
}

export function savingProjectFailed(changes)
{
    return {type:SAVING_PROJECT_FAILED, changes:changes};
}

export function loadProjectStatusStart() {
    return {type:LOAD_PROJECT_STATUS_START};
}

export function loadProjectStatusDone(result){
    return {type:LOAD_PROJECT_STATUS_DONE, result:result};
}

export function loadProjectComplianceStatusStart() {
    return {type:LOAD_PROJECT_COMPLIANCE_STATUS_START};
}

export function loadProjectComplianceStatusDone(result) {
    return {type:LOAD_PROJECT_COMPLIANCE_STATUS_DONE,result:result};
}


/**
 * Reports Stuff
 */
export function loadReportStart()
{
    return {type:LOAD_REPORT_START};
}

export function loadReportDone(result)
{
    return {type:LOAD_REPORT_DONE, result:result};
}

export function loadTaskStart()
{
    return {type:LOAD_TASK};
}

export function loadTaskSuccess(result)
{
    return {type:LOAD_TASK, result:result};
}

export function loadTaskFailed(error)
{
    return {type:LOAD_TASK, error:error};
}

export function updateAnswer(answer)
{
    return {type:UPDATE_ANSWER, answer:answer};
}

export function saveAnswersStart()
{
    return {type:SAVE_ANSWERS};
}

export function saveAnswersSuccess(result)
{
    return {type:SAVE_ANSWERS, result:result}
}

export function saveAnswersFailed(error)
{
    if (error == null) error = "Unknown Error";

    return {type:SAVE_ANSWERS, error:error}
}

export function gotoAnswer(direction)
{
    if (typeof direction === 'string')
    {
        return {type:GOTO_ANSWER, direction:direction};
    } else {
        return {type:GOTO_ANSWER, taskPosition:direction};
    }
}

export function loadAdminReportTemplatesStart()
{
    return {type:LOAD_ADMIN_REPORT_TEMPLATES};
}

export function loadAdminReportTemplatesSuccess(result)
{
    return {type:LOAD_ADMIN_REPORT_TEMPLATES, result:result};
}

export function loadAdminReportTemplatesFailed(error)
{
    return {type:LOAD_ADMIN_REPORT_TEMPLATES, error:error};
}

export function loadReportTemplatesStart()
{
    return {type:LOAD_REPORT_TEMPLATES};
}

export function loadReportTemplatesSuccess(result)
{
    return {type:LOAD_REPORT_TEMPLATES, result:result};
}

export function loadReportTemplatesFailed(error)
{
    return {type:LOAD_REPORT_TEMPLATES, error:error};
}

export function saveReportTemplatesStart()
{
    return {type:SAVE_REPORT_TEMPLATES};
}

export function saveReportTemplatesSuccess(result)
{
    return {type:SAVE_REPORT_TEMPLATES, result:result};
}

export function saveReportTemplatesFailed(error)
{
    return {type:SAVE_REPORT_TEMPLATES, error:error};
}


export function updateReportTemplate(template_id, activate)
{
    return {type:UPDATE_REPORT_TEMPLATE, template_id:template_id, activate:activate};
}

export function checkReportStatusStart(instance_id)
{
    return {type:CHECK_REPORT_STATUS, instance_id:instance_id};
}

export function checkReportStatusSuccess(result)
{
    return {type:CHECK_REPORT_STATUS, reportSummary:result};
}

export function checkReportStatusFailed(error)
{
    return {type:CHECK_REPORT_STATUS, error:error};
}

export function loadProjectObjectsStart(type)
{
    return {type:LOAD_PROJECTOBJECTS_START, object_type:type};
}

export function loadProjectObjectsDone(type, data)
{
    return {type:LOAD_PROJECTOBJECTS_DONE, object_type:type, data:data};
}

export function loadProjectObjectsError(type, error)
{
    return {type:LOAD_PROJECTOBJECTS_ERROR, object_type:type, error:error};
}

export function newProjectObjectStart(type)
{
    return {type:NEW_PROJECTOBJECT_START, object_type:type};
}

export function newProjectObjectDone(type, data)
{
    return {type:NEW_PROJECTOBJECT_DONE, object_type:type, data:data};
}

export function newProjectObjectError(type, error)
{
    return {type:NEW_PROJECTOBJECT_ERROR, object_type:type, error:error};
}


export function startProjectObjectEdit(type, object_id)
{
    return {type:EDIT_PROJECTOBJECT_START, object_type:type, object_id:object_id};
}

export function updateProjectObjectField(type, object_id, name, value)
{
    return {type:UPDATE_PROJECTOBJECT_FIELD, object_type:type, object_id:object_id, name:name, value:value};
}

export function loadComplianceStart()
{
    return {type:LOAD_COMPLIANCE_START};
}

export function loadComplianceDone(result)
{
    return {type:LOAD_COMPLIANCE_DONE, result:result};
}

export function loadComplianceFailed(error)
{
    return {type:LOAD_COMPLIANCE_FAILED, error:error};
}

export function loadComplianceControlStart()
{
    return {type:LOAD_COMPLIANCECONTROL_START};
}

export function loadComplianceControlDone(result)
{
    return {type:LOAD_COMPLIANCECONTROL_DONE, result:result};
}

export function loadComplianceControlFailed(error)
{
    return {type:LOAD_COMPLIANCECONTROL_FAILED, error:error};
}

export function newComplianceActionDone(result)
{
    return {type:NEW_COMPLIANCEACTION_DONE, result:result};
}

export function newComplianceActionError(error)
{
    return {type:NEW_COMPLIANCEACTION_FAILED, error:error};
}

export function editComplianceActionStart(action_id)
{
    return {type:EDIT_COMPLIANCEACTION_START, action_id:action_id};
}

export function savingComplianceStart()
{
    return {type:SAVING_COMPLIANCE_START};
}

export function savingComplianceSuccess(results)
{
    return {type:SAVING_COMPLIANCE_SUCCESS, results:results};
}

export function savingComplianceFailed(error)
{
    return {type:SAVING_COMPLIANCE_FAILED, action_id:error};
}


export function updateComplianceField(object_id, field, value)
{
    return {type:UPDATE_COMPLIANCE_FIELD, object_id:object_id, field:field, value:value};
}


export function uploadingEvidenceStart(action_id, filename)
{
    return {type:UPLOAD_EVIDENCE_START, action_id:action_id, filename:filename};
}

export function uploadingEvidenceDone(action_id, filename, path)
{
    return {type:UPLOAD_EVIDENCE_DONE, action_id:action_id, filename:filename, path:path};
}

export function uploadingEvidenceError(action_id, filename, error)
{
    return {type:UPLOAD_EVIDENCE_ERROR, action_id:action_id, filename:filename, error:error};
}

export function deleteComplianceActionDone(action_id, results)
{
    return {type:DELETE_COMPLIANCEACTION_DONE, action_id:action_id, results:results};
}

export function deleteComplianceActionFailed(error)
{
    return {type:DELETE_COMPLIANCEACTION_FAILED, error:error};
}

export function complianceUpdated(updateTime)
{
    return {type: COMPLIANCE_UPDATED, time:updateTime};
}

export function loadAlertsStart()
{
    return {type:LOAD_ALERTS_START};
}

export function loadAlertsDone(result)
{
    return {type:LOAD_ALERTS_DONE,result:result};
}

export function deleteComplianceEvidenceUpload(action_id,path)
{
    return {type:DELETE_COMPLIANCE_EVIDENCE_UPLOAD,action_id:action_id,upload:path};
}

export function deleteComplianceEvidenceUploadDone(action_id,result){
    return {type:DELETE_COMPLIANCE_EVIDENCE_UPLOAD_DONE,action_id:action_id, result:result};
}



/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import ProjectsView from '../../views/ProjectsView'
import { connect } from 'react-redux'
import details from "../../utils/details"
import values from "object.values"
import siteDataUtil from "../../utils/sitedata"

import {
    loadProjectsStart, loadProjectsDone, loadProjectStart, loadProjectDone,
    updateProjectField, savingProjectFailed, savingProjectStart, savingProjectSuccess, loadProjectStatusDone,
    loadProjectStatusStart
} from '../../redux/actions/actions'
import SessionManager from "../../utils/SessionManager";
import sitedata from "../../utils/sitedata";
import ProjectContainer from "./ProjectContainer";

const mapStateToProps = (state, ownProps) =>
{
    // console.log("map state to props");
    // console.log(siteDataUtil.checkWriteAccess(ownProps,"project"));

    return {
        list: state.projects.list,
        currentObject: state.projects.currentObject,
        isLoading: state.projects.isLoading,
        doLoad : state.projects.list==null && state.projects.isLoading===false,
        changes : state.projects.changes,
        project_status: state.projects.project_status,
        isStatusLoading:state.projects.isStatusLoading,
        readOnly:(!siteDataUtil.checkWriteAccess(state.session.siteData,"project")),
        siteData:state.session.siteData,
        dimensions:state.session.dimensions,
        publishSummary: state.summary.publishSummary,
        isPublishSummaryLoading: state.summary.isLoading
    }
}

const newProject = function(dispatch, props)
{
    dispatch(loadProjectStart());

    ebase.ajax({
        url:"/ebase/core/write-project-new",
        data:{},
        success:(result) => {
            if (result.success) {
                dispatch(loadProjectDone(result));
                SessionManager.shared().goto("/projects/project/" + result.data.object.object_id+'/info');
            } else
            {
                props.showLoadError("Error loading project", result);
            }
        },
        error:(result) => {
            props.showLoadError("Error loading project", result);
        }

    });


};

const loadProject = function(dispatch, props, project_id, projectName)
{
    dispatch(loadProjectStart(projectName));

    ebase.ajax({
        url:"/ebase/core/read-project-load",
        data:{object_id : project_id},
        success:(result) => {if (!result.success) props.showLoadError("Error Loading Project"); else dispatch(loadProjectDone(result));},
        error:(result) => {
            props.showLoadError("Error loading project", result);
        }
    });

};

const projectClicked = function(props, project_id, type)
{
    SessionManager.shared().goto("/projects/project/"+project_id+"/info")
};

const updateField = function(dispatch, name, value)
{
    dispatch(updateProjectField(name, value));
};


const saveFields = function(dispatch, changes, ownProps)
{
    let changedFields = changes.changedFields;

    if (details.isObjectEmpty(changedFields))
    {
        return;
    }

    if (!Object.values) values.shim();


    dispatch(savingProjectStart());
    let params = sitedata.getParams(ownProps);
    ebase.ajax({
        url: "/ebase/core/write-project-updatefields",
        data: Object.values(changedFields),
        success: (result) => {
            dispatch(savingProjectSuccess());

             let field = changedFields[Object.keys(changedFields)[0]].field;
             if(field !== null && field !== undefined){
                 if(field === "details.project_categories" || field === "details.region_id"){
                     loadProjectStatus(dispatch,params.objectId);
                 }
             }

        },
        error:   () => {
            dispatch(savingProjectFailed(changedFields))
        }
    });

};

const loadProjectStatus = function(dispatch,project_id){
    if(project_id === null || project_id === undefined){
        return;
    }

    dispatch(loadProjectStatusStart());
    ebase.ajax({
        url: "/ebase/core/read-project-status",
        data: {project_id: project_id},
        success: (result) => {
            dispatch(loadProjectStatusDone(result));
        },
        error:   (error) => {
            dispatch(loadProjectStatusDone(error))
        }
    });

};

const deleteProject = function (dispatch,ownProps, project_id){

    if(project_id === null || project_id === undefined){
        return;
    }

    ebase.ajax({
        url: "/ebase/envid-admin/write-enviddata-delete",
        data: {object_id: project_id},
        success: (result) => {
            // console.log("project delete done");
            // console.log(result);
            if (!result.success) {
                ownProps.showLoadError("Problem Deleting Project", result);
            }
            SessionManager.shared().goto("/projects");
        },
        error:   () => {
            console.log("project delete error");
            ownProps.showLoadError("Error Deleting Project");
        }
    });

};

const loadLibraryPublishSummary = function(dispatch,ownProps){

    dispatch({type:"LOAD_LIBRARY_PUBLISH_SUMMARY_START"})
    ebase.ajax({
        url: "/ebase/summary/read-summary-libstatus",
        method:"GET",
        // data: {project_id: project_id},
        success: (result) => {
            // console.log('read-summary-libstatus done',result)
            if (!result.success) {
                dispatch({type:"LOAD_LIBRARY_PUBLISH_SUMMARY_DONE", data:null})
                ownProps.showLoadError("Problem Loading Library Summary",result)
            }else {
                dispatch({type: "LOAD_LIBRARY_PUBLISH_SUMMARY_DONE", data: result.data})
            }
        },
        error:   (error) => {
            console.log('read-summary-libstatus error',error)
            dispatch({type:"LOAD_LIBRARY_PUBLISH_SUMMARY_DONE", result: null})
            ownProps.showLoadError("Error Loading Library Summary",error)
        }
    });
};

const viewObjectsWithPublishState = function(dispatch,publishState,ownProps){
    // console.log('viewObjectsWithPublishState',publishState,ownProps);
    ownProps.history.push("/enviddata");
    dispatch({type:"SEARCH_SET_CRITERIA", data:{term:"", type:"", page:0,max:50, publish:[publishState]}});

}

const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {
        load : () => {

            dispatch(loadProjectsStart());
            ebase.ajax({
                url: "/ebase/core/read-project-list",
                data:{},
                success: (result) => {
                    dispatch(loadProjectsDone(result))
                    if (!result.success) {
                        ownProps.showLoadError("Unable to load Projects", result);
                    }
                },
                error : (result) => {
                    ownProps.showLoadError("Unable to load Projects", result);
                }

            })

        },

        projectClicked: (projectId, type) => projectClicked(ownProps, projectId, type),
        newProject: () => newProject(dispatch, ownProps),
        loadProject: (project_id, projectName) => loadProject(dispatch, ownProps, project_id, projectName),
        updateField: (name, value) => updateField(dispatch, name, value),
        saveFields: (changes) => saveFields(dispatch, changes,ownProps),
        loadProjectStatus: (project_id) => loadProjectStatus(dispatch,project_id),
        deleteProject : (project_id) => deleteProject(dispatch,ownProps,project_id),
        loadLibraryPublishSummary : () => loadLibraryPublishSummary(dispatch,ownProps),
        viewObjectsWithPublishState: (publishState) => viewObjectsWithPublishState(dispatch,publishState,ownProps),
    }
}

const ProjectsHomeView = (props) =>
{

    let params = sitedata.getParams(props);

    if (params.objectId == null){
        return (
            <div>
                {React.cloneElement(<ProjectsView />, props)}
            </div>
        )
    }else{
        return (
            <div>
                {React.cloneElement(<ProjectContainer />, props)}
            </div>
        )
    }

}


const ProjectsHomeContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectsHomeView)

export default ProjectsHomeContainer

/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import ReportTaskView from '../../views/ReportTaskView'
import { connect } from 'react-redux'
import sitedata from "../../utils/sitedata"
import values from "object.values"
import {loadTaskStart, loadTaskSuccess, loadTaskFailed} from '../../redux/actions/actions'
import {updateAnswer, saveAnswersStart, saveAnswersSuccess, saveAnswersFailed, gotoAnswer} from '../../redux/actions/actions'


const mapStateToProps = (state, ownProps) =>
{
    return {
        task: state.reports.currentTask,
        taskPosition:state.reports.taskPosition,
        unsavedAnswers:state.reports.unsavedAnswers,
        dimensions:state.session.dimensions,
        siteData:state.session.siteData,
    }
}


const loadTask = function(dispatch, project_id, type)
{
    dispatch(loadTaskStart());

    ebase.ajax({
        url:"/ebase/envid-client/read-report-loadtask",
        data:{project_id : project_id, report_type:"ImpactAssessment", type:type},
        success:(result) => {if (result.success) dispatch(loadTaskSuccess(result)); else dispatch(loadTaskFailed(result.error));},
        error:() => dispatch(loadTaskFailed("Error contacting server"))
    });

}

const saveAnswers = function(dispatch, unsavedAnswers)
{

    if (unsavedAnswers.answers == null || unsavedAnswers.savingAnswers != null) return;
    let answers = unsavedAnswers.answers;

    dispatch(saveAnswersStart());

    if (!Object.values) values.shim();

    // save the answer
    ebase.ajax({
        url:"/ebase/envid-client/write-report-saveanswers",
        data:Object.values(answers),
        success:(result) => {
            // once saved it will return all the changes, we'll then apply the changes to the model
            if (result.success) {
                dispatch(saveAnswersSuccess(result.data));
            } else {
                dispatch(saveAnswersFailed(result.error));
            }
        },
        error:() => dispatch(saveAnswersFailed("Couldn't contact server"))
    });

}
const mapDispatchToProps = (dispatch, ownProps) =>
{
    return {
        loadTask : (project_id, type) => loadTask(dispatch, project_id, type),
        updateAnswer : (answer) => dispatch(updateAnswer(answer)),
        saveAnswers : (unsavedAnswers) => saveAnswers(dispatch, unsavedAnswers),
        gotoAnswer : (move) => dispatch(gotoAnswer(move))
    }
}

class ReportTaskIntView extends React.Component{

    constructor(props) {
        super(props);
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let task = params.task;
        this.props.loadTask(project_id, task);
    }

    render() {

        if (this.props.unsavedAnswers != null)
        {
            this.props.saveAnswers(this.props.unsavedAnswers);
        }

        return (
            <div>
                {React.cloneElement(<ReportTaskView/>, this.props)}
            </div>
        )
    }
}


const ReportTaskContainer = connect(mapStateToProps, mapDispatchToProps)(ReportTaskIntView);

export default ReportTaskContainer

import auth from '../../utils/auth'

const initDimensions = function(){
    var dim = {window: {width:0, height: 0}};
    dim.footerHeight = 39;
    dim.topheaderHeight = 62;
    if(auth.getIsLite() === true){
        dim.topheaderHeight = 86;
    }
    dim.headingHeight = 95;
    dim.extras = {width: dim.window.width, height:dim.footerHeight+dim.topheaderHeight+dim.headingHeight};

    return dim;
}

export default function session(
    state = {
        isLoading:false,
        isAuthenticated:false,
        siteData:null,
        dimensions: initDimensions(),
        retrying: false,
        saveErrors:[],
        error:null,
    }, action){

    switch (action.type) {
        case 'AUTH_START':
            return {
                ...state,
                isAuthenticated: false,
                isLoading: true,
                siteData: null,
                error: null,
            };

        case "AUTH_SUCCESS":{
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                siteData: action.data,
                error: null
            }
        }
        case "AUTH_ERROR":{
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                error: action.data,
            }
        }
        case "AUTH_LOGOUT":{
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                siteData: null
            }
        }

        case 'CHECK_SESSION_START':{
            return {
                ...state,
                isLoading: true,
            }
        }

        case 'CHECK_SESSION_SUCCESS': {
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                siteData: action.data
            }
        }

        case 'CHECK_SESSION_NO_SUCCESS':{
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                siteData: null,
            }
        }

        case 'UPDATE_SESSION_SITEDATA':{
            return {
                ...state,
                siteData: action.data,
            }
        }

        case "UPDATE_DIMENSIONS":{
            return {
                ...state,
                dimensions: action.data,
            }
        }

        case "UPDATE_RETRY_STATE":{

            let retry = action.data.retrying;
            let errors = action.data.errors;

            if (retry != null && errors == null){
                return {
                    ...state,
                    retrying: retry,
                }
            }
            if (retry != null && errors != null){
                return {
                    ...state,
                    retrying: retry,
                    saveErrors: errors,
                }
            }
            if (retry == null && errors != null){
                return{
                    ...state,
                    saveErrors: errors,
                }
            }

            return state;
        }
        default:{}

    }

    return state;
}

import React from 'react';
import envid from "../../utils/envid"
import {withTranslation} from 'react-i18next';

class EnvidObjectIcon extends React.Component {

    render() {

        const type = this.props.type;
        // console.log("State: "+JSON.stringify(this.state));
        // console.log("type: "+type);

        let showName = true;
        if(this.props.showName === undefined){
            showName = false;
        }else{
            showName = this.props.showName;
        }

        // console.log("   showName: "+showName);

        let icon = <i></i>;
        var iconNames;
        var name;
        var colours = envid.getObjectColours(type);
        iconNames = envid.getObjectIcons(type);
        name = envid.getObjectName(type);

        var span_style = Object.assign({}, this.props.style);
        //span_style.color = style.color;

        let icons = [];
        for (var i=0; iconNames != null && i<iconNames.length; ++i)
        {
            var style = {};// Object.assign({}, this.props.style);
            if (iconNames.length > 1 && i === 0)
            {
                style.width = "15px";
            } else if (iconNames[0] === "fa-shield") {
                style.width = "10px";
                style.paddingLeft = "2px";
            } else {
            style.width = "10px";
            }

            if (this.props.ignoreColour === true) {

            }
            else if (this.props.color == null) {
                style.color = colours[i];
            } else {
                style.color = this.props.color;
            }

            icons.push(<i key={iconNames[i]} className={"fa " + iconNames[i]} style={style}></i>);
        }
        if(showName){
            icon = <span style={span_style}>{icons}&nbsp;{this.props.t(name)}</span>;
        }else {
            icon = <span style={span_style}>{icons}</span>;
        }


        return icon;
    }
}

export default withTranslation()(EnvidObjectIcon);

import React from 'react';

const Spinner = (props) => {
        return (
            <div className="ebase-spinner" style={props.style}>
                <div className="rect1"/>
                <div className="rect2"/>
                <div className="rect3"/>
                <div className="rect4"/>
                <div className="rect5"/>
            </div>
        )
}

export default Spinner;




/**
 * Created by lewellyn on 14/12/16.
 */
import React from 'react'
import ebase from '../../utils/ajax'
import update from 'immutability-helper';
import sitedata from '../../utils/sitedata'
import DataPublishing from '../../views/DataPublishing'

class DataPublishingContainer extends React.Component{


    constructor(){
        super();

        this.state={updating:false};

        this.onChangePublished = this.onChangePublished.bind(this);
        this.updateSuccess = this.updateSuccess.bind(this);
        this.updateError = this.updateError.bind(this);
    }

    onChangePublished(e){

        let newPublishState = e.target.value;

        this.setState({updating:true});
        this.props.updateSavedStatus(this.props.currentObject.object.object_id, "saving");

        ebase.ajax({
           url:"/ebase/envid-admin/write-enviddata-updatefield",
            data:{object_id:this.props.currentObject.object.object_id,field:{name:"publishing.state",value: newPublishState}},
            success: this.updateSuccess.bind(this, newPublishState),
            error: this.updateError.bind(this)

        });
    }

    updateSuccess(published, result){
        //console.log("update success: "+JSON.stringify(result));
        console.log("UPDATE PUBLISH STATE: "+published);

        let updatedObject = update(this.props.currentObject, {object: {publishingInfo: {state:{$set: published}}}});

        updatedObject.last_update_user = sitedata.getUser(this.props);
        updatedObject.object.documentInfo.last_update_time = new Date().getTime();
        updatedObject.object.documentInfo.last_update_user = updatedObject.last_update_user.user_id;

        this.setState({updating:false});

        this.props.updateSavedStatus(this.props.currentObject.object.object_id, "saved");
        this.props.updateObject(updatedObject);
    }

    updateError(result){
        console.log("update error: "+result);
        this.setState({updating:false});
        this.props.updateSavedStatus(this.props.currentObject.object.object_id, "error");
    }


    render()
    {


        return (

                <DataPublishing currentObject={this.props.currentObject}
                                doChangePublished = {this.onChangePublished}
                                isUpdating={this.state.updating}/>

        );
    }


}

export default DataPublishingContainer



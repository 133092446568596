import React from 'react';
import taskUtil from '../utils/taskUtil'
import sitedata from '../utils/sitedata'
import ReportInputAnswer from '../components/common/ReportInputAnswer'
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";

class ReportTaskView extends React.Component {

    onSave(answer)
    {
        console.log("Update Answer - " + JSON.stringify(answer));
        let params = sitedata.getParams(this.props);
        let changes = {project_id:params.projectId, taskType:params.task};
        this.props.updateAnswer(Object.assign({}, answer, changes));
    }

    render() {

        const {t} = this.props;

        console.log("ReportTaskView");
        console.log(this.props);

        let task = this.props.task;
        // let report = this.props.report;
        // let project = this.props.project;
        let structure = this.props.task.object.structure;
        // let task_item = taskUtil.findTaskItem(this.props.task.object.type);
        // let task_type = this.props.params.task;

        let inputs = structure.list.map( (input) => {

            let answer = taskUtil.getAnswer(task, input.object_id)
            if (answer == null)
            {
                return <div>{t("No Answer for")} {input.object_id}</div>
            }
            let object = taskUtil.getObject(task, answer.type, answer.object_id);

            if (object == null) return <p>{JSON.stringify(answer)}</p>;
            return (
                <div style={{borderTop:"lightgray 1pt solid", padding:10, marginBottom:10}}>
                    <div className="" style={{fontWeight:"bold"}}>{object.details.name}</div>
                    <div className=""><p dangerouslySetInnerHTML={{__html: (object.details.desc == null) ? t("No Description") : object.details.desc.text}}/></div>
                    <div className="">
                        <ReportInputAnswer answer={answer} onSave={this.onSave.bind(this)} readOnly={this.props.readOnly}/>
                    </div>
                </div>

            );
        });


        return <div>{inputs}</div>
    }

}

ReportTaskView.propTypes = {
    report : PropTypes.object.isRequired,
    project : PropTypes.object.isRequired,
    task : PropTypes.object.isRequired,
    isLoading : PropTypes.bool.isRequired,
};


export default withTranslation()(ReportTaskView)

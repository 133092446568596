import React, {useEffect, useState} from 'react';
import {Col,FormControl, Form, FormGroup, Modal,ModalBody,ModalHeader,ModalTitle,ModalFooter} from 'react-bootstrap'
import details from '../utils/details'
import envid from "../utils/envid";
import EButton from "../components/common/EButton"
import Editor from '../components/common/Editor'
import Select from 'react-select';
import {StakeholderEventType} from '../components/common/StakeholderEventType'
import StakeholderEventObjectionsView from './StakeholderEventObjectionsView'
import StakeholderEventActionsView from './StakeholderEventActionsView'
import StakeholderEventAttachmentsView from './StakeholderEventAttachmentsView'
import {EventSectionTitle} from '../components/common/StakeholderUtils'
import {Trans, useTranslation} from 'react-i18next';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const StakeholderEventView = (props) => {

    const DATE_FORMAT = "dd/MM/yyyy hh:mm:a";

    const [showModal, setShowModal] = useState(false);

    const [timestamp, setTimestamp] = useState();

    useEffect( () => {
        //let newTimestamp = '';
        let timestampDate;
        if (props.event?.details?.timestamp) {
            timestampDate = new Date(event.details.timestamp);
        } else {
            timestampDate = new Date();
        }
        //newTimestamp = timestampMoment.format(DATE_FORMAT);
        setTimestamp(timestampDate);
    }, [props.event.details.timestamp]);

    const selectChanged = (object_id, fieldName, event) => {
        let value = event.target.value;
        props.updateField(object_id, fieldName, value);
        setTimeout(props.saveFields, 250);
    }

    const inputChanged = (object_id, fieldName, event) => {
        event.preventDefault();
        let value = event.target.value;
        props.updateField(object_id, fieldName, value);
    }

    const inputDateChanged = (object_id, fieldName, value) => {
        //let value = event.target.value;
        //console.log("TYPE "+typeof(value)+ " "+typeof(momentValue), ' ', value, ' ', momentValue);
        if (value) {
            console.log("SET TIMESTAMP ", value);
            setTimestamp(value);
            props.updateField(object_id, fieldName, value.getTime());
        } else {
            console.log("DONT SET TIMESTAMP")
        }
        //
        // let m = moment(value, "YYYY-MM-DD[T]HH[:]mm");
        // if (!m || isNaN(m)) {
        //     console.log("Invalid Moment = " + m);
        //     return;
        // }
        // event.preventDefault();
        // console.log("Moment = " + m);
        // props.updateField(object_id, fieldName, m.valueOf());
    }

    // const finishedDateField = (object_id, fieldName, val) => {
    //     event.preventDefault();
    //     if (timestamp) {
    //         let m = moment(val, DATE_FORMAT);
    //         if (!m || isNaN(m)) {
    //             return;
    //         }
    //         console.log("Save Timestamp = " + m);
    //         props.updateField(object_id, fieldName, m.valueOf());
    //     }
    // }

    const reactMultiSelectChanged = (object_id, fieldName, value) => {
        if (value != null) {
            props.updateField(object_id, fieldName, value.map((l) => l.value));
        } else{
            props.updateField(object_id, fieldName, []);
        }
        setTimeout(props.saveFields, 250);
    }

    const finishedField = (object_id, fieldName, event) => {

        props.saveFields();
    }

    const handleModelChange = (object_id, fieldName, model) => {
        //event.preventDefault();
        let value = model;
        props.updateField(object_id, fieldName, value);
    }

    const onPublish = (object_id) =>
    {
        props.updateField(object_id, "publishing.published", true);
        setTimeout(props.saveFields, 100);
        setTimeout(props.back, 300);
    }

    const confirmDeleteEvent = () => {
        setShowModal(true);
    }

    const onDelete = (event_id) =>
    {
        props.deleteEvent(event_id);
        setShowModal(false);
        setTimeout(props.back, 300);
    }

    const uploadAttachment = (file) => {
        props.uploadAttachment(props.event.object_id,file);
    }

    const deleteAttachment =(path) => {
        props.deleteAttachment(props.event.object_id,path);
    }

    const onCloseClick = () => {
        setShowModal(false);
    }

    const {t} = useTranslation();

    let event = props.event;
    if (event == null)
    {
        return <div>{t("Event not found")}</div>
    }


    let stakeholdersMap = props.stakeholdersMap;

    let extra_sections = [];

    let modal = (

        <Modal show={showModal} onHide={()=>onCloseClick()} size="lg">
            <ModalHeader closeButton>
                <ModalTitle>
                    {t("Delete Communication?")}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div>
                    <Trans i18nKey="communication-delete-warning">
                        <p>Are you sure you want to delete this communication?</p><p>All attachments, actions and relevant objections will be deleted as well. This is permanent and you cannot undo it.</p>
                    </Trans>
                    </div>
            </ModalBody>
            <ModalFooter>
                <EButton isTable={false} type="standard" onClick={() => setShowModal(false)}>{t("Cancel")}</EButton>
                <EButton isTable={false} type="remove" onClick={() => onDelete(event.object_id)}>{t("Delete")}</EButton>
            </ModalFooter>

        </Modal>

    );

    let isPublished = event.publishingInfo.published;
    extra_sections.push(
        <div key="attachments" className="mail-body">
            <StakeholderEventAttachmentsView
                published={isPublished}
                readOnly={props.readOnly}
                attachments={props.event.details.attachments}
                uploadAttachment={uploadAttachment}
                deleteAttachment = {deleteAttachment}/>
        </div>
    );

    extra_sections.push(
        <div key="actions" className="mail-body">
          <StakeholderEventActionsView published={isPublished}
                                       readOnly={props.readOnly}
                                        actions={props.actions}
                                        selectedAction={props.selectedAction}
                                        selectedActionValid={props.selectedActionValid}
                                        objectLists={props.objectLists}
                                        updateField={props.updateField}
                                        saveFields={props.saveFields}
                                        newAction={props.newAction}
                                        removeAction={props.removeAction}
                                        doneAction={props.doneAction}

          />
        </div>
    );

    extra_sections.push(
        <div key="objections" className="mail-body">
            <StakeholderEventObjectionsView published={isPublished}
                                            readOnly={props.readOnly}
                                            objectionLinks={props.objectionLinks}
                                            objectLists={props.objectLists}
                                            newObjection={props.newObjection}
                                            linkObjection={props.linkObjection}
                                            removeObjection={props.removeObjection}
                                            viewObjection={props.viewObjection}
                                            updateObjection={props.updateObjection}
                                            />
        </div>
    );

    if (isPublished || props.readOnly)
    {
        let stakeholders_long = details.getStakeholderList(event.details.stakeholders, props.stakeholdersMap);
        let desc = details.getValue(event, "details.desc");
        let deleteBtn = <EButton type="delete" isTable={true} onClick={()=>confirmDeleteEvent()}>{t('Delete')}</EButton>;

        return (
            <div>
                {modal}
                <div className="mail-box-header">
                    <div className="pull-right tooltip-demo">
                        {props.readOnly ? [] : deleteBtn}
                        &nbsp;
                        <EButton type="back" isTable={true}
                                 onClick={props.back}>{t("Back")}</EButton>
                    </div>

                    <h2>
                        <StakeholderEventType type={event.details.event_type} style={{color:"#12b394"}} showTitle={true}/>
                    </h2>
                    <div className="mail-tools tooltip-demo m-t-md">
                        <h3>
                            <span className="font-noraml font-normal">{t("Subject")}: </span>{event.details.name}
                        </h3>
                        <h5>
                            <span className="pull-right font-normal font-noraml">{(timestamp==null) ? '' : timestamp.toLocaleTimeString()+' '+timestamp.toLocaleDateString()}</span>
                            <span className="font-normal font-noraml">{t("Stakeholders")}: </span>{stakeholders_long}
                        </h5>
                    </div>
                </div>

                <div className="mail-box">
                    <div className="mail-body">
                        <EventSectionTitle><i className="fa fa-file-text-o"/> {t("Description")}</EventSectionTitle>
                        <p dangerouslySetInnerHTML={{__html: (desc == null) ? t("No Description") : desc}}/>
                    </div>

                    {extra_sections}

                    <div className="clearfix"/>
                </div>
            </div>
        )
    }
    else {
        let stakeholderOptions = Object.values(stakeholdersMap).sort((s1,s2) => s1.name.localeCompare(s2.name)).map((sh) => {
            return {label: sh.name, value: sh.object_id};
        });

        let selected = details.getValue(event, "details.stakeholders");

        selected = selected.map( sid => {
            let obj = stakeholdersMap[sid];
            if(obj != null) {
                return {label: obj.name, value: sid};
            }else{
                return {label: t("NOT FOUND"), value: sid};
            }
        });


        let canPublish = true;
        if (event.details.stakeholders.length === 0) {
            canPublish = false;
        } else if (event.details.name.length === 0 || event.details.name === "New Communication") {
            canPublish = false;
        } else if (event.details.event_type == null) {
            canPublish = false;
        }

        if (props.selectedAction != null)
        {
            canPublish = false;
        }

        return (
            <div>
                {modal}
                <div className="mail-box-header">

                    <div className="pull-right tooltip-demo">
                        <EButton type="delete" isTable={true} onClick={(ev)=>{confirmDeleteEvent(ev)}}>{t("Delete")}</EButton>
                        &nbsp;
                        <EButton type="done" isTable={true} disabled={!canPublish}
                                 onClick={(ev)=>onPublish(event.object_id, ev)}>{t("Done")}</EButton>
                    </div>

                    <h2>
                        {t("New Communication")}
                    </h2>
                </div>

                <div className="mail-box">

                    <div className="mail-body">
                        <div className="form-horizontal">

                            <FormGroup>
                                <Form.Label className="col-sm-2">{t('Type')}:</Form.Label>
                                <Col sm={5}>
                                    <Form.Select placeholder="select"
                                                 value={event.details.event_type}
                                                 onChange={(ev)=>selectChanged(event.object_id, "details.event_type", ev)}>
                                        {(event.details.event_type == null) ?
                                            <option value="">{t("Select")}...</option> : null}
                                        <option value="EMAIL">{t("Email")}</option>
                                        <option value="PHONE">{t("Phone Call")}</option>
                                        <option value="MEETING">{t("Meeting")}</option>
                                        <option value="MAIL">{t("Mail")}</option>
                                        <option value="OTHER">{t("Other")}</option>
                                    </Form.Select>
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Form.Label className="col-sm-2">{t("Stakeholders")}:</Form.Label>
                                <Col sm={10}>
                                    <Select
                                        value={selected}
                                        options={stakeholderOptions}
                                        placeholder={t("Select Stakeholders")}
                                        onChange={(ev)=>reactMultiSelectChanged(event.object_id, "details.stakeholders", ev)}
                                        isMulti={true}
                                        styles={envid.getMultiSelectStyle()}

                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Form.Label className="col-sm-2">{t("Time")}:</Form.Label>
                                <Col sm={5}>
                                    {/*<FormControl type='datetime-local' field="details.timestamp"*/}
                                    {/*             onChange={(ev)=>inputDateChanged(event.object_id, "details.timestamp", ev)}*/}
                                    {/*             onBlur={(ev)=>finishedDateField(event.object_id, "details.timestamp", ev)}*/}
                                    {/*             value={timestamp}/>*/}
                                    <DatePicker
                                             showTimeSelect
                                                dateFormat={DATE_FORMAT}
                                              onChange={(date)=>inputDateChanged(event.object_id, "details.timestamp", date)}
                                              //onBlur={(ev)=>finishedDateField(event.object_id, "details.timestamp", ev)}
                                              selected={timestamp}/>
                                </Col>
                            </FormGroup>

                            <FormGroup>
                                <Form.Label className="col-sm-2">{t("Subject")}:</Form.Label>
                                <Col sm={10}>
                                    <FormControl type='text' field="details.name" className="col-sm-10"
                                                 onChange={(ev)=>inputChanged(event.object_id, "details.name", ev)}
                                                 onBlur={(ev)=>finishedField(event.object_id, "details.name", ev)}
                                                 value={event.details.name}/>
                                </Col>
                            </FormGroup>

                        </div>
                    </div>

                    <div className="mail-body">
                        <FormGroup>
                            <Editor model={details.getValue(event, "details.desc")}
                                    handleModelChange={(ev)=>{handleModelChange(event.object_id, "details.desc", ev)}}
                                    onBlur={(ev)=>{finishedField(event.object_id, "details.desc", ev)}}
                                    off={true}
                            />
                        </FormGroup>
                    </div>

                    {extra_sections}

                    <div className="clearfix"></div>
                </div>

            </div>
        );
    }

}

export default StakeholderEventView;

import React from 'react';

import EButton from "./EButton"
import EnvidObjectIcon from "./EnvidObjectIcon"
import {withTranslation} from "react-i18next";


class ProjectSidebarWrapper extends React.Component {

    render() {

        let project = this.props.project;
        let editProject = this.props.editProject;

        let button = "";

        if(this.props.allowEdit){
            button = (
                <div className="pull-right"><EButton type="edit" isTable={true}
                                                     onClick={editProject}>{this.props.t('Edit')}</EButton></div>
            );
        }

        return (
            <div className="row">

                <div className="col-lg-9">
                    {this.props.children}
                </div>

                <div className="col-lg-3">
                    <div className="wrapper wrapper-content project-manager">
                        <p className="huge"
                           style={{fontSize: "20pt", marginTop: "-20px", fontWeight: "bold"}}>{project.name}</p>
                        <h5><EnvidObjectIcon
                            type="Region"/> {(project.region == null) ? "Unknown" : project.region.name}
                        </h5>
                        <h5>{this.props.t('Project Description')}</h5>
                        <p className="small" style={{marginLeft: "0px"}}>{project.desc}</p>
                        <h5>{this.props.t('Project Types')}</h5>
                        <ul className="fa-ul small" style={{marginLeft: "0px"}}>
                            {(project.project_categories == null) ? null : project.project_categories.map((cat) => <li
                                    key={cat.name} style={{paddingBottom: 3}}>
                                <EnvidObjectIcon type="ProjectCategory" showName={false}/>&nbsp; {cat.name}
                                </li>)}
                        </ul>
                        <br/>
                        <br/>
                        {button}
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslation()(ProjectSidebarWrapper);

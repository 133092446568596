/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientResult
 */
export interface ClientResult {
    /**
     * 
     * @type {string}
     * @memberof ClientResult
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResult
     */
    desc?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResult
     */
    logo?: string;
}

/**
 * Check if a given object implements the ClientResult interface.
 */
export function instanceOfClientResult(value: object): value is ClientResult {
    return true;
}

export function ClientResultFromJSON(json: any): ClientResult {
    return ClientResultFromJSONTyped(json, false);
}

export function ClientResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientResult {
    if (json == null) {
        return json;
    }
    return {
        
        'clientId': json['client_id'] == null ? undefined : json['client_id'],
        'name': json['name'] == null ? undefined : json['name'],
        'desc': json['desc'] == null ? undefined : json['desc'],
        'logo': json['logo'] == null ? undefined : json['logo'],
    };
}

export function ClientResultToJSON(value?: ClientResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'client_id': value['clientId'],
        'name': value['name'],
        'desc': value['desc'],
        'logo': value['logo'],
    };
}


import React from 'react';
import EnvidDate from '../../utils/EnvidDate'
import {useTranslation} from "react-i18next";


const ComplianceLabel = function(props)
{

    const { t } = useTranslation();

    if (props.status === "COMPLIANT")
    {
        return <span className="label label-primary">{t("Compliant")}</span>
    }
    else if (props.status === "NOT_COMPLIANT")
    {
        return <span className="label label-danger">{t("Not Compliant")}</span>
    }
    else if (props.status === "IN_PROGRESS")
    {
        return <span className="label label-warning">{t("In Progress")}</span>
    }
    else if (props.status == null)
    {
        return <span className="label label-success">{t("New")}</span>
    }
    else {
        return <span className="label label-success">{t("Unknown")} ({props.status})</span>
    }
}

const ComplianceDueIn = function(props)
{
    const {t} = useTranslation();
    if (props.date == null)
    {
        if (props.status == null)
        {
            return <span>{t('New')}</span>
        }
        else
        {
            return <span>{t('Complete')}</span>
        }
    }
    let d = new EnvidDate(props.date, t);
    return <span>{d.fromNow()}</span>
}


export {ComplianceLabel, ComplianceDueIn}

import React from 'react';
import { connect } from 'react-redux'

import ProjectDetails from '../../views/ProjectDetails'

const mapStateToProps = (state, ownProps) =>{

    return {
        project_status: state.projects.project_status,
    };
};

const mapDispatchToProps = (dispatch, ownProps) =>{

    return {};
};



const ProjectDetailsView = (props) =>{

    return (
        <div>
            {React.cloneElement(<ProjectDetails/>, props)}
        </div>
    );
};


const ProjectDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsView);

export default ProjectDetailsContainer

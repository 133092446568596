import React from 'react';

class TabCheck extends React.Component {
    constructor()
    {
        super();
        this.rendered = false;
    }
    render() {

        if (!this.rendered && this.props.activeKey !== this.props.eventKey)
        {
            return null;
        }

        this.rendered = true;
        return <div>{this.props.children}</div>

    }
}

export default TabCheck

export default {

    task_items : [{name:"Activities & Aspects", icon:"Aspect", type:"AspectActivity", desc:"Select the Activities and their Aspects which are relevant to this project."},
        {name:"Receptors",type:"Receptor", icon:"Receptor", desc: "Select the Receptors that are relevant to this project."},
        {name:"Technical Inputs",icon:"TechnicalInput", type:"TechnicalInputs", desc:"First complete the selection of Activities & Aspects and Receptors, then select the Relevant Impacts & Controls."},
        {name:"Environmental Inputs",icon:"EnvironmentalInput", type:"EnvironmentalInputs", desc:"First complete the selection of Activities & Aspects and Receptors, then select the Relevant Impacts & Controls."},
        {name:"Impacts",icon:"Impact", type:"Impact", desc:"First complete the selection of Activities & Aspects and Receptors, then select the Relevant Impacts & Controls."},
        {name:"Controls",icon:"Control", type:"Control", desc:"First complete the selection of Activities & Aspects and Receptors, then select the Relevant Impacts & Controls."},
        {name:"Risk",icon:"Risk", type:"Risk", desc:"First complete the selection of Activities & Aspects and Receptors, then select rate the Risk."},

    ],

    matrix_colours : ["#DF6069", "#A1328B", "#EEAE68", "#3584C1", "#5DBDC2"],


    findTaskItem(type)
    {
        return this.task_items.find((i) => (i.type === type));
    },

    getObject(task, type, object_id)
    {
        if (task.objectLists[type] == null) return null;

        return task.objectLists[type][object_id];
    },

    getLinkObject(task, item)
    {
        if (item.link == null) return null;
        if (task.objectLists[item.linkType]==null) return null;

        return task.objectLists[item.linkType][item.link];
    },

    getAnswer(task, object_id)
    {

        let answer = task.object.answers[object_id];

        if (answer == null)
        {
            console.log("Answer is NULL for " + object_id);
            console.log(task.object.answers);
        }
        return answer;
    },

    getObjectAtPosition(task, taskPosition)
    {

        //let item = task.object.structure.list[taskPosition[0]];
        let itemType = task.object.structure.listType;

        let sortedList = this.sortedItemList(itemType,task,task.object.structure.list);

        let item = sortedList[taskPosition[0]];


        if (taskPosition.length > 1) {
            itemType = item.listType;
            item = item.list[taskPosition[1]];

            //item = item.list[taskPosition[1]];
        }
        if (taskPosition.length > 2) {
            itemType = item.listType;
            item = item.list[taskPosition[2]];

            //item = item.list[taskPosition[2]];
        }
        if (taskPosition.length > 3) {
            itemType = item.listType;
            item = item.list[taskPosition[3]];

            //item = item.list[taskPosition[3]];
        }

        if (item == null) {
            console.log("Item is null");
            console.log(taskPosition);
            console.log("Item is null");
        }
        let object = this.getObject(task, itemType, item.object_id);
        return object;

    },

    sortedItemList(type,task, list){

        list.sort((a,b) => {

            let objA = null;
            let objB = null;

            if(a.link !== null){
                objA = this.getLinkObject(task, a)
            }else{
                objA = this.getObject(task, type, a.object_id);
            }

            if(b.link !== null){
                objB = this.getLinkObject(task, b)
            }else{
                objB = this.getObject(task, type, b.object_id);
            }

            if (objA == null)
            {
                console.log("objA == null", a)
                return 1;
            }

            if (objB == null)
            {
                console.log("objB == null", b)
                return -1;
            }
            return objA.displayName.localeCompare(objB.displayName);

        });

        return list;

    },

    getStructureItem(task, taskPosition)
    {
        if (taskPosition == null || taskPosition.length === 0) {
            return task.object.structure;
        }

        let item = task.object.structure.list[taskPosition[0]];

        if (taskPosition.length > 1) {
            item = item.list[taskPosition[1]];
        }
        if (taskPosition.length > 2) {
            item = item.list[taskPosition[2]];
        }
        if (taskPosition.length > 3) {
            item = item.list[taskPosition[3]];
        }
        if (taskPosition.length > 4) {
            item = item.list[taskPosition[4]];
        }
        return item;
    },

    getObjectFromLink(task, object, type)
    {
        let new_object_id = null;

        if (object.type === "ControlReceptorActivityLink")
        {
            if (type === "Receptor")
            {
                new_object_id = object.details.receptor_id;
            }
            else if (type === "Activity")
            {
                new_object_id = object.details.activity_id;
            }
            else if (type === "Control")
            {
                new_object_id = object.details.control_id;
            }
        }
        else
        {
            let link = object.link;
            new_object_id = (link.left_type === type) ? link.left_object_id : link.right_object_id;
        }
        return this.getObject(task, type, new_object_id);
    },

    getRegion(task)
    {
        let regions = task.objectLists.Region;
        for (let key in regions)
        {
            if (regions.hasOwnProperty(key))
            {
                return regions[key];
            }
        }
        return null;
    },

    getReceptorRegionLink(task, receptor_id, region_id)
    {
        if (task.objectLists.ReceptorRegionLink != null)
        {
            //console.log("Searching for " + receptor_id + " - " + region_id);
            let links = task.objectLists.ReceptorRegionLink;
            for (let key in links)
            {
                if (links.hasOwnProperty(key))
                {
                    let lnk = links[key];
                    //console.log("  Searching " + JSON.stringify(lnk.link));
                    if (lnk.link == null)
                    {
                        //console.log("  Bad Link Object " + JSON.stringify(lnk));
                    }
                    else if (lnk.link.left_object_id === receptor_id && lnk.link.right_object_id === region_id) {
                        return lnk;
                    }
                    else if (lnk.link.right_object_id === receptor_id && lnk.link.left_object_id === region_id) {
                        return lnk;
                    }
                }
            }
        }
        return null;
    },

    findProjectActivityLinksForActivity(task, activity_id)
    {
        let list = [];
        let links = task.objectLists.ProjectActivityLink;
        for (let key in links)
        {
            if (links.hasOwnProperty(key))
            {
                let link = links[key];
                if (link.link.left_object_id === activity_id || link.link.right_object_id === activity_id) {
                    list.push(link);
                }
            }

        }
        return list;

    },

    findProjectTypesForActivity(task, activity_id)
    {
        let list = [];
        let projects = {};
        let links = task.objectLists.ProjectActivityLink;
        for (let key in links)
        {
            if (links.hasOwnProperty(key))
            {
                let link = links[key];
                if (link.link.left_object_id === activity_id || link.link.right_object_id === activity_id)
                {
                    let project = this.getObjectFromLink(task, link, "ProjectCategory");
                    if (projects[project.object_id] == null) {
                        list.push(project);
                        projects[project.object_id] = true;
                    }
                }

            }

        }
        return list;

    },

    getImpactType(task, impact)
    {
        if (impact != null && impact.details.impact_type_id != null) {
            let type = this.getObject(task, "ImpactType", impact.details.impact_type_id);
            if (type != null) {
                return type.details.name;
            }
        }
        return "Unknown Impact";
    },

    getControlName(task, control)
    {
        if (control != null && control.details.control_name_id != null) {
            let type = this.getObject(task, "ControlName", control.details.control_name_id);
            if (type != null) {
                return type.details.name;
            }
        }
        return "Unknown Control";
    },
    getControlSource(task, control)
    {
        if (control != null && control.details.control_source_id != null) {
            let type = this.getObject(task, "ControlSource", control.details.control_source_id);
            if (type != null) {
                return type.details.name;
            }
        }
        return "Unknown Control";
    },
    getControlType(task, control)
    {
        if (control != null && control.details.control_type_id != null) {
            let type = this.getObject(task, "ControlType", control.details.control_type_id);
            if (type != null) {
                return type.details.name;
            }
        }
        return "Unknown Control";
    },

    getControlsReceptors(task, control)
    {
        let links = task.objectLists.ControlReceptorLink;
        let receptor_list = [];
        for (let key in links) {
            if (links.hasOwnProperty(key)) {
                let link = links[key];

                let c = this.getObjectFromLink(task, link, "Control");
                let r = this.getObjectFromLink(task, link, "Receptor");

                if (c.object_id === control.object_id)
                {
                    receptor_list.push(r);
                }
            }
        }

        return receptor_list;

    },

    getControlsActivities(task, control)
    {
        let links = task.objectLists.ControlActivityLink;
        let activity_list = [];
        for (let key in links) {
            if (links.hasOwnProperty(key)) {
                let link = links[key];

                let c = this.getObjectFromLink(task, link, "Control");
                let a = this.getObjectFromLink(task, link, "Activity");

                if (c.object_id === control.object_id)
                {
                    activity_list.push(a);
                }
            }
        }

        return activity_list;

    },

    getOtherControls(task, impact, control)
    {
        let links = task.objectLists.Control;
        let control_list = [];
        for (let key in links) {
            if (links.hasOwnProperty(key)) {
                let c = links[key];
                if (c.details.impact_id === impact.object_id) {
                    control_list.push(c);
                }
            }
        }

        return control_list;
    },

    combineStatus(statuses)
    {
        let completeCount = 0;
        let unavailableCount = 0;
        let pendingCount = 0;
        let newCount = 0;

        for (let i=0; i<statuses.length; ++i)
        {
            let status = (statuses[i]!=null) ? statuses[i].status : "New";

            if (status === "Completed") ++ completeCount;
            else if (status === "Unavailable") ++ unavailableCount;
            else if (status === "Pending") ++ pendingCount;
            else if (status === "New") ++ newCount;
        }

        let combined = "";

        if (completeCount === statuses.length) {
            combined = "Complete";
        }
        else if (unavailableCount === statuses.length) {
            combined = "NotReady";
        }
        else if (newCount === statuses.length) {
            combined = "Ready";
        }
        else
        {
            combined = "InProgress";
        }

        return combined;


    },

    riskName(matrix, risk)
    {
        if (risk === undefined || risk === null) {
            return null;
        }

        //console.log(matrix);
        //console.log(risk);

        return matrix.residual_risk[risk].name;
    }




}

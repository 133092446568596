import React from 'react';

class EbasePanel extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="ibox float-e-margins">
                        <div className="ibox-content p-md">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EbasePanel
import {LOAD_ADMIN_REPORT_TEMPLATES} from "../actions/actions"

export default function templates(state = {isLoading:false, currentObject:null}, action) {

    switch (action.type)
    {
        case LOAD_ADMIN_REPORT_TEMPLATES:
        {
            if (action.error != null)
            {
                return Object.assign({}, state, {templates:null, templateError:action.error});
            }
            else if (action.result != null)
            {
                let templates = action.result;

                return Object.assign({}, state, {templates:templates});
            }
            else
            {
                return Object.assign({}, state, {templates:null, templateError:null});
            }
        }
        default:{}
    }
    return state;
}

import {
    LOAD_COMPLIANCE_START, LOAD_COMPLIANCE_DONE, LOAD_COMPLIANCE_FAILED,
    COMPLIANCE_UPDATED, DELETE_COMPLIANCE_EVIDENCE_UPLOAD, DELETE_COMPLIANCE_EVIDENCE_UPLOAD_DONE
} from "../actions/actions"
import {LOAD_COMPLIANCECONTROL_START, LOAD_COMPLIANCECONTROL_DONE, LOAD_COMPLIANCECONTROL_FAILED} from "../actions/actions"
import {NEW_COMPLIANCEACTION_DONE, NEW_COMPLIANCEACTION_FAILED} from "../actions/actions"
import {EDIT_COMPLIANCEACTION_START, UPDATE_COMPLIANCE_FIELD} from "../actions/actions"
import {SAVING_COMPLIANCE_START, SAVING_COMPLIANCE_SUCCESS, SAVING_COMPLIANCE_FAILED} from "../actions/actions"
import {UPLOAD_EVIDENCE_START, UPLOAD_EVIDENCE_DONE, UPLOAD_EVIDENCE_ERROR} from "../actions/actions"
import {DELETE_COMPLIANCEACTION_DONE, DELETE_COMPLIANCEACTION_FAILED} from "../actions/actions"

import details from '../../utils/details'
import update from 'immutability-helper';

const updateComplianceDefn = function(updates, complianceDefn)
{
    try
    {
        // see if there are any changes to the defn
        console.log("SAVING_COMPLIANCE_SUCCESS : " + JSON.stringify(updates));
        console.log("SAVING_COMPLIANCE_SUCCESS : " + JSON.stringify(complianceDefn));

        for (let i=0; i<updates.length; ++i)
        {
            console.log("Checking " + updates[i]);

            let u = updates[i];
            if (u.type === "ComplianceDefn" && u.object_id === complianceDefn.object_id)
            {
                let theupdate = details.getUpdate(u.field.name, (u.field.list==null)?u.field.value:u.field.list);
                console.log("Updated Compliance Defn : " + JSON.stringify(theupdate));
                complianceDefn = update({object:complianceDefn}, theupdate).object;
            }
        }
    } catch (e)
    {
        console.log("__ERROR__");
        console.log(e);
    }

    return complianceDefn;
}

export default function compliance(state = {isLoading:true, isControlLoading:false, changes:{savedStatus:"unchanged", changedFields:{}}}, action) {

    switch (action.type)
    {
        case LOAD_COMPLIANCE_START: {

            return {...state, isLoading:true};
        }

        case LOAD_COMPLIANCE_DONE: {

            let data = action.result.data;
            return {...state, objectLists: data.objectLists, object:data.object, isLoading: false, isControlLoading:false};
        }

        case LOAD_COMPLIANCE_FAILED: {

            return {...state, error:action.error, isLoading: false, isControlLoading:false};
        }

        case LOAD_COMPLIANCECONTROL_START: {

            return {...state, isControlLoading:true, editing_id:null};
        }

        case LOAD_COMPLIANCECONTROL_DONE: {

            let data = action.result.data;

            let defn = Object.values(data.objectLists["ComplianceDefn"])[0];
            let actionList = data.objectLists["ComplianceAction"];
            let actions = [];
            if (actionList != null) {
                actions = Object.values(actionList);
                actions = actions.sort((a, b) => {return ((b.details.actioned_date > a.details.actioned_date) ? 1 : -1);})
            }

            console.log("Load Compliance Control");
            console.log(actions);

            return {...state, selectedControl: data.object, complianceDefn:defn, complianceActions:actions, isControlLoading: false, editing_id:null};
        }

        case LOAD_COMPLIANCECONTROL_FAILED: {

            return {error:action.error, isLoading: false};
        }

        case NEW_COMPLIANCEACTION_DONE: {
            // slot the new object into the various stuff
            console.log("NEW_COMPLIANCEACTION_DONE");
            console.log(action);

            let new_object = action.result.object;

            let actions = state.complianceActions.slice();
            actions.splice(0, 0, new_object);
            return {...state, complianceActions:actions, editing_id:new_object.object_id}

        }

        case NEW_COMPLIANCEACTION_FAILED: {
            return state;
        }

        case EDIT_COMPLIANCEACTION_START:
        {
            console.log("--------- EDIT_COMPLIANCEACTION_START ----------" + action.action_id)
            return {...state, editing_id:action.action_id}

        }

        case UPDATE_COMPLIANCE_FIELD:
        {
            let object_id = action.object_id;
            let field = action.field;
            let value = action.value;

            let complianceDefn = state.complianceDefn;
            let complianceActions = state.complianceActions;

            let updateObject = details.getUpdate(field, value);

            if (state.complianceDefn.object_id === object_id)
            {
                complianceDefn = update({object:complianceDefn}, updateObject).object;

            } else {
                for (let i=0; i<state.complianceActions.length; ++i)
                {
                    if (complianceActions[i].object_id === object_id)
                    {
                        complianceActions = complianceActions.slice();
                        complianceActions[i] = update({object:complianceActions[i]}, updateObject).object;
                        break;
                    }
                }
            }

            let changes = details.updateChanges(state.changes, object_id, field, value);

            return {...state, complianceDefn:complianceDefn, complianceActions:complianceActions, changes:changes};
        }

        case SAVING_COMPLIANCE_START:
        {
            var changes = {savedStatus:"saving", changedFields:{}};
            return {...state, changes:changes};
        }
        case SAVING_COMPLIANCE_SUCCESS:
        {
            //console.log("SAVING_COMPLIANCE_SUCCESS : " + JSON.stringify(action));

            // clear the update fields stuff
            var changes = {savedStatus:(details.isObjectEmpty(state.changes.changedFields))?"saved":"saving", changedFields:state.changes.changedFields};

            let complianceDefn = updateComplianceDefn(action.results.data, state.complianceDefn);

            return {...state, changes:changes, complianceDefn:complianceDefn};

        }
        case SAVING_COMPLIANCE_FAILED:
        {
            return state;
        }

        case UPLOAD_EVIDENCE_START:
        {
            let action_id = action.action_id;
            let filename = action.filename;

            console.log("UPLOAD_EVIDENCE_START: " + JSON.stringify(action));
            // find the action and add the file to the path

            let complianceActions = state.complianceActions;
            for (let i=0; i<state.complianceActions.length; ++i)
            {
                let a = complianceActions[i];
                if (a.object_id === action_id)
                {
                    console.log("Found Action: " + JSON.stringify(a));
                    complianceActions = complianceActions.slice();
                    let evidence_paths = a.details.evidence_paths;
                    if (evidence_paths == null)
                    {
                        evidence_paths = [];
                    } else {
                        evidence_paths = evidence_paths.slice();
                    }
                    evidence_paths.push("/uploading/"+filename);
                    a = {...a, details:{...a.details, evidence_paths:evidence_paths}};
                    complianceActions[i] = a;

                    break;
                }
            }

            return {...state, complianceActions:complianceActions};

        }

        case UPLOAD_EVIDENCE_DONE:
        {
            let action_id = action.action_id;
            let filename = action.filename;
            let path = action.path;

            // find the action and add the file to the path

            let complianceActions = state.complianceActions;
            for (let i=0; i<state.complianceActions.length; ++i)
            {
                let a = complianceActions[i];
                if (a.object_id === action_id)
                {
                    complianceActions = complianceActions.slice();
                    let evidence_paths = a.details.evidence_paths;

                    for (let j=evidence_paths.length-1; j>=0; --j)
                    {
                        if (evidence_paths[j] === "/uploading/"+filename)
                        {
                            evidence_paths = evidence_paths.slice();
                            evidence_paths[j] = path;
                        }

                    }


                    a = {...a, details:{...a.details, evidence_paths:evidence_paths}};
                    complianceActions[i] = a;

                    break;
                }
            }

            return {...state, complianceActions:complianceActions};
        }

        case UPLOAD_EVIDENCE_ERROR:
        {
            let action_id = action.action_id;
            let filename = action.filename;
            let path = "/error/" + filename;

            // find the action and add the file to the path

            let complianceActions = state.complianceActions;
            for (let i=0; i<state.complianceActions.length; ++i)
            {
                let a = complianceActions[i];
                if (a.object_id === action_id)
                {
                    complianceActions = complianceActions.slice();
                    let evidence_paths = a.details.evidence_paths;

                    for (let j=evidence_paths.length-1; j>=0; --j)
                    {
                        if (evidence_paths[j] === "/uploading/"+filename)
                        {
                            evidence_paths = evidence_paths.slice();
                            evidence_paths[j] = path;
                        }

                    }


                    a = {...a, details:{...a.details, evidence_paths:evidence_paths}};
                    complianceActions[i] = a;

                    break;
                }
            }

            return {...state, complianceActions:complianceActions};

        }

        case DELETE_COMPLIANCEACTION_DONE:
        {
            let action_id = action.action_id;

            let complianceActions = state.complianceActions;
            for (let i=0; i<state.complianceActions.length; ++i)
            {
                let a = complianceActions[i];
                if (a.object_id === action_id)
                {
                    complianceActions = complianceActions.slice();
                    complianceActions.splice(i, 1);
                    break;
                }
            }

            let complianceDefn = updateComplianceDefn(action.results.data, state.complianceDefn);

            return {...state, complianceActions:complianceActions, complianceDefn:complianceDefn};
        }
        case DELETE_COMPLIANCEACTION_FAILED:
        {
            return state;
        }

        case COMPLIANCE_UPDATED:
        {
            return {...state, lastComplianceUpdateTime:action.time};
        }

        case DELETE_COMPLIANCE_EVIDENCE_UPLOAD:
        {
            let action_id = action.action_id;
            let path = action.upload;


            let complianceActions = state.complianceActions;
            for (let i=0; i<state.complianceActions.length; ++i)
            {
                let a = complianceActions[i];
                if (a.object_id === action_id)
                {

                    complianceActions = complianceActions.slice();
                    let evidence_paths = a.details.evidence_paths;
                    if (evidence_paths == null)
                    {
                        evidence_paths = [];
                    } else {
                        evidence_paths = evidence_paths.slice();
                    }

                    let current = evidence_paths.indexOf(path);
                    let currentPath = evidence_paths[evidence_paths.indexOf(path)];

                    currentPath = currentPath.replace("evidence","deleting"); //UI will disable the delete button if it sees 'deleting'
                    evidence_paths[current] = currentPath;

                    a = {...a, details:{...a.details, evidence_paths:evidence_paths}};

                    complianceActions[i] = a;

                    break;
                }
            }

            return {...state, complianceActions:complianceActions};
        }

        case DELETE_COMPLIANCE_EVIDENCE_UPLOAD_DONE:
        {
            if(action.result.success === true) {

                let action_id = action.action_id;
                let path = action.result.data;
                path = path.replace("evidence","deleting");
                // find the action and remove the path

                let complianceActions = state.complianceActions;
                for (let i = 0; i < state.complianceActions.length; ++i) {
                    let a = complianceActions[i];
                    if (a.object_id === action_id) {
                        complianceActions = complianceActions.slice();
                        let evidence_paths = a.details.evidence_paths;

                        let index = evidence_paths.indexOf(path);
                        console.log("index: "+index);
                        if(index >= 0){
                            evidence_paths.splice(index,1);
                            a = {...a, details: {...a.details, evidence_paths: evidence_paths}};
                            complianceActions[i] = a;

                        }

                        break;
                    }
                }

                return {...state, complianceActions: complianceActions};

            }else{
                return state;
            }
        }

        default:{}

    }
    return state;
}

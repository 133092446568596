import React from 'react'
import ebase from '../../utils/ajax'
import DataLink from '../../views/DataLink'
import update from 'immutability-helper';

class DataLinkContainer extends React.Component{


    constructor(){
        super();

        this.state = {
            linkInfo : null,
            loading  : null,
            loadTime : null,
            loadLinkTime : null
        };

        this.loadLinkInfo = this.loadLinkInfo.bind(this);
        this.loadLinkObject = this.loadLinkObject.bind(this);
        this.addLink = this.addLink.bind(this);
        this.removeLink = this.removeLink.bind(this);

        this.setLinkInfo = this.setLinkInfo.bind(this);
        this.updateLinkInfo = this.updateLinkInfo.bind(this);
        this.updateLinkObject = this.updateLinkObject.bind(this);

        this.getObjectId = this.getObjectId.bind(this);
        this.getLinkType = this.getLinkType.bind(this);
        this.getShowType = this.getShowType.bind(this);

    }

    componentDidMount()
    {
        //this.props.clearInterval();
        //this.props.setInterval(this.saverTimeout, 1000);
    }

    getObjectId()
    {
        return this.props.currentObject.object.object_id;
    }

    getLinkType()
    {
        return this.props.template.linkObject;
    }

    getShowType()
    {
        return this.props.template.showObject;
    }

    updateLinkObject(object)
    {
        this.updateLinkInfo({currentObject: {$set:object}});
    }

    setLinkInfo(linkInfo)
    {
        this.setState({linkInfo:linkInfo});
    }

    updateLinkInfo(set)
    {
        let linkInfo = update(this.state.linkInfo, set);
        this.setState({linkInfo : linkInfo});
    }

    /*******************************************************************************
     *
     * LINK METHODS METHODS
     *
     *******************************************************************************/

    loadLinkInfo()
    {
        var request = {
            object_id:this.getObjectId(),
            link_type:this.getLinkType(),
            object_type:this.props.currentObject.object.type,
            list_type:this.getShowType()
        }

        this.state.loadTime = null;
        this.state.loadLinkTime = (new Date()).getTime();

        ebase.ajax({
            url:"/ebase/envid-admin/read-enviddata-linklist",
            data:request,
            success: this.linkInfoSuccess.bind(this, request),
            error:   () => this.updateLinkInfo({error:{$set:"Error talking to server"}})
        });
    }

    linkInfoSuccess(request, result)
    {
        var linkInfo = {};

        linkInfo.object_id = request.object_id;
        linkInfo.type = request.link_type;
        linkInfo.loading = false;
        linkInfo.currentObject = null;

        if (!result.success)
        {
            linkInfo.error = result.error;
        }
        else
        {
            linkInfo.list = result.data;
        }

        this.setState({linkInfo : linkInfo, loading:null});
        this.setLinkInfo(linkInfo);
    }

    removeLink(link_id)
    {
        this.updateLinkInfo({currentObject:{$set:null}, loading:{$set:true}});

        ebase.ajax({
            url:"/ebase/envid-admin/write-enviddata-removelink",
            data:{object_id:link_id},
            success:(result) => {
                if(result.success){

                    var newList = [];
                    var newItem = null;
                    for (var i in this.state.linkInfo.list)
                    {
                        let item = this.state.linkInfo.list[i];
                        if (item.link_id === link_id)
                        {
                            newItem = {object_id:item.object_id, name:item.name};
                            //console.log(newItem);
                        } else {
                            newList.push(item);
                        }
                    }
                    if (newItem != null) {
                        newList.push(newItem);
                    } else {
                        console.log("No Item found");
                    }
                    // find the element in the list and add a link id to it
                    this.updateLinkInfo({loading:{$set:false}, list:{$set : newList}});
                }else{
                    this.updateLinkInfo({error:{$set:result.error}});
                }
            },
            error:   () => this.updateLinkInfo({error:{$set:"Error talking to server"}})
        });

    }

    addLink(request)
    {
        this.updateLinkInfo({currentObject:{$set:null}, loading:{$set:true}});

        ebase.ajax({
            url:"/ebase/envid-admin/write-enviddata-newlink",
            data:request,
            success:(result) => {
                if(result.success){

                    //console.log("New Link Created - " + request.right_id);
                    var newList = [];
                    var newItem = null;
                    for (var i in this.state.linkInfo.list)
                    {
                        let item = this.state.linkInfo.list[i];
                        if (item.object_id === request.right_id)
                        {
                            newItem = {object_id:item.object_id, name:item.name, link_id:result.data.object.object_id};
                        } else {
                            newList.push(item);
                        }
                    }
                    if (newItem != null) {
                        newList.push(newItem);
                    } else {
                        console.log("No item found to update");
                    }
                    // find the element in the list and add a link id to it
                    this.updateLinkInfo({currentObject:{$set:result.data}, loading:{$set:false}, list:{$set : newList}});
                }else{
                    this.updateLinkInfo({error:{$set:result.error}});
                }
            },
            error:   () => this.updateLinkInfo({error:{$set:"Error talking to server"}})
        });
    }


    loadLinkObject(link_object_id)
    {
        this.updateLinkInfo({currentObject:{$set:null}, loading:{$set:true}})

        this.state.loadTime = (new Date()).getTime();

        ebase.ajax({
            url:"/ebase/envid-admin/read-enviddata-object",
            data:{object_id:link_object_id},
            success:(result) =>{
                if(result.success){
                    this.updateLinkInfo({currentObject:{$set:result.data}, loading:{$set:false}})
                }else{
                    this.updateLinkInfo({error:{$set:result.error}})
                }
            }
        });
    }




    /*******************************************************************************
     *
     * RENDER METHOD
     *
     *******************************************************************************/
    render()
    {
        let bNeedsReload = false;

        if (this.state.linkInfo == null)
        {
            // show the spinner, and request they be loaded
            if (this.state.loading == null || this.state.loading !== this.getObjectId()) {
                bNeedsReload = true;
            }
        }

        if (this.state.loadLinkTime != null && this.props.changeLinkTime != null && this.state.loadLinkTime < this.props.changeLinkTime)
        {
            bNeedsReload = true;
        }

        if (this.state.loadTime != null && this.props.changeTime != null && this.state.loadTime < this.props.changeTime)
        {
            console.log("Object has changed, reloading link data");
            setTimeout(() => {
                try {
                    this.loadLinkObject(this.state.linkInfo.currentObject.object.object_id, 0);
                } catch (e){}
            });
        }

        if (bNeedsReload)
        {
            this.loadLinkInfo();
            this.state.loading = this.getObjectId();
        }

        return <DataLink loading={this.state.loading!=null}
                         currentObject={this.props.currentObject}
                         template={this.props.template}
                         addObject={this.props.addObject}
                         loadLinkObject={this.loadLinkObject}
                         linkInfo={this.state.linkInfo}
                         updateSavedStatus={this.props.updateSavedStatus}
                         updateLinkObject={this.updateLinkObject}
                         addLink={this.addLink}
                         removeLink={this.removeLink}
                         siteData={this.props.siteData}
                         height={this.props.height}
                         />
    }


}

export default DataLinkContainer



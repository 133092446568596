import jq from "../../utils/jq"

export function correctHeight() {

    var pageWrapper = jq.find('#page-wrapper');
    var navbarHeigh = jq.find('nav.navbar-default').height();
    var wrapperHeigh = pageWrapper.height();

    if (navbarHeigh > wrapperHeigh) {
        pageWrapper.css("min-height", navbarHeigh + "px");
    }

    if (navbarHeigh < wrapperHeigh) {
        if (navbarHeigh < jq.find(window).height()) {
            pageWrapper.css("min-height", jq.find(window).height() + "px");
        } else {
            pageWrapper.css("min-height", navbarHeigh + "px");
        }
    }

    if (jq.find('body').hasClass('fixed-nav')) {
        if (navbarHeigh > wrapperHeigh) {
            pageWrapper.css("min-height", navbarHeigh + "px");
        } else {
            pageWrapper.css("min-height", jq.find(window).height() - 60 + "px");
        }
    }
}

export function detectBody() {
    if (window.location.host.includes('lite') !== true) {
        if (jq.find(document).width() < 769) {
            jq.find('body').addClass('body-small')
        } else {
            jq.find('body').removeClass('body-small')
        }
    }
}

export function smoothlyMenu() {
    if (!jq.find('body').hasClass('mini-navbar') || jq.find('body').hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        jq.find('#side-menu').hide();
        // For smoothly turn on menu
        setTimeout(
            function () {
                jq.find('#side-menu').fadeIn(400);
            }, 200);
    } else if (jq.find('body').hasClass('fixed-sidebar')) {
        jq.find('#side-menu').hide();
        setTimeout(
            function () {
                jq.find('#side-menu').fadeIn(400);
            }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        jq.find('#side-menu').removeAttr('style');
    }
}

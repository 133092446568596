import React from 'react';
import sitedata from '../utils/sitedata'
import {Row,Col} from 'react-bootstrap'
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import StakeholderEventView from './StakeholderEventView'
import StakeholderEventListView from './StakeholderEventListView'
import StakeholderActionsListView from './StakeholderActionsListView'
import StakeholderObjectionsListView from './StakeholderObjectionsListView'
import StakeholderObjectionView from './StakeholderObjectionView'
import StakeholderFilter from "../utils/StakeholderFilter";
import CommsFilter from "../utils/CommsFilter";
import SessionManager from "../utils/SessionManager";
import {withTranslation} from "react-i18next";

class StakeholderCommsView extends React.Component {

    constructor()
    {
        super();

        this.selectTab = this.selectTab.bind(this);
        this.onEditObject = this.onEditObject.bind(this);
    }

    selectObject(object)
    {
        let object_id = "none";
        if (object != null)
        {
            object_id = object.object_id;
        }

        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let listType = params.listType;
        let filter = new CommsFilter(params.filter);
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/'+object_id);

    }

    selectTab(tabKey)
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let object_id = params.stakeholderId;
        let tabIndex = tabKey;

        let filter = new StakeholderFilter(params.filter);
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+filter.toString()+'/'+object_id+'/'+tabIndex);
    }

    newEvent()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        this.props.newEvent(project_id);

        // let listType = this.props.params.listType;
        // let filter = new CommsFilter(this.props.params.filter);
        // this.props.routerPush('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/loading");
    }

    saveFields()
    {
        this.props.saveEventFields(this.props.changes);
    }

    viewObjection(objection_id)
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let listType = "objections";
        let filter = new CommsFilter(params.filter);

        // reload all the evtns
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/'+objection_id);
    }


    gotoEvent(event_id)
    {
        console.log(" gotoEvent ",event_id);
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let listType = "events";
        let filter = new CommsFilter(params.filter);

        this.props.loadEvents(project_id, null);

        // reload all the evtns
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/'+event_id);
    }
    gotoAllCommunications()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let listType = "events";
        let filter = new CommsFilter(params.filter);

        this.props.loadEvents(project_id, null);

        // reload all the evtns
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/none');
    }

    gotoAllActions()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;

        this.props.loadActions(project_id);

        let listType = "actions";
        let filter = new CommsFilter(params.filter);
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/none');
    }

    gotoAllObjections()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;

        //this.props.loadObjections(project_id);

        let listType = "objections";
        let filter = new CommsFilter(params.filter);
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/none');
    }

    onEditObject(listType, object_id)
    {
        let params = sitedata.getParams(this.props);
        //console.log("StakeholderCommsView:: oneditObject: "+listType + " | "+object_id);
        let project_id = params.projectId;
        let filter = new CommsFilter(params.filter);
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/'+object_id);
    }

    onEdit(object_id)
    {
        let params = sitedata.getParams(this.props);
        let listType = params.listType;
        this.onEditObject(listType, object_id);
    }

    showAll()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let listType = "events";
        let filter = new CommsFilter();
        SessionManager.shared().goto('/stakeholder/'+project_id+'/comms/'+listType+'/'+filter.toString()+'/none');
    }

    searchComms(term){
        let params = sitedata.getParams(this.props);
        this.props.searchComms(term, params.projectId);
    }

    render() {

        const {t} = this.props;

        // console.log("StakeholderCommsView:: render",this.props);
        // console.log(" StakeholderCommsView:: loading - ",this.props.isLoading);
        // console.log(" StakeholderCommsView:: object loading - ",this.props.isObjectLoading);
        // console.log(" StakeholderCommsView:: object Lists - ",this.props.objectLists);
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        let listType = params.listType;
        let objectId = params.objectId;
        let filter = new CommsFilter(params.filter);

        // the filter gives us some idea of what to show


        let page_title = t("Stakeholder Communications");
        let crumbs = ['/', '/projects', '/projects/project/'+project_id+'/info', '/stakeholder/'+project_id+'/home/1', page_title];


        if (this.props.isLoading || this.props.isObjectLoading) {
            return <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)}
                                 crumbs={crumbs}
                                 dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <Spinner/>;
                </PageWrapper>
            </div>
        }

        let pane_style = {padding:20, overflow:"scroll"};
        pane_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height;
        pane_style.overflowY = "scroll";
        pane_style.overflowX = "hidden";
        pane_style.textOverflow = "ellipsis";

        let addCommButton = <button className="btn btn-block btn-primary compose-mail" onClick={this.newEvent.bind(this)}><i className="fa fa-plus"></i>&nbsp;{t('New Communication')}</button>;
        if (this.props.readOnly === true){
            addCommButton = [];
        }

        let filterMessage = null;
        if (!filter.showAll())
        {
            filterMessage = (
            <div className="alert alert-info" style={{marginBottom: 0}}>
                <Row style={{height: 15}}>
                    <Col md={10} style={{marginTop: -2}}>
                        <i className="fa"></i> <b>{filter.getDisplayMessage()}</b>
                    </Col>
                    <Col md={2} style={{textAlign: "right", marginTop: -7}}>
                        <EButton isTable={true} type="clear" onClick={this.showAll.bind(this)}>{t("Show All")}</EButton>
                    </Col>
                </Row>
            </div>)

        }

        /*
         * If there is an object loaded then we'll showthat on the right side. If
         * it isn't then we'll show whatever sort of list
         * is specified.
         */
        let right_side = null;
        if (objectId==="loading")
        {
            right_side = <Spinner/>
        }
        else if (objectId == null || objectId === "none")
        {
            if (listType === "events")
            {
                //All Communications
                right_side = <StakeholderEventListView events={this.props.events}
                                                       stakeholdersMap={this.props.stakeholdersMap}
                                                       onClick={this.onEdit.bind(this)}
                                                       search={this.searchComms.bind(this)}
                                                       clearSearch={this.props.clearCommsSearch}
                                                       isSearching={this.props.isSearching}
                                                       searchResults={this.props.searchResults}
                                                       searchTerm={this.props.searchTerm}
                />
            }
            else if(listType === "actions")
            {
                //All actions from all communications
                right_side = <StakeholderActionsListView
                    readOnly={this.props.readOnly}
                    actions={this.props.allActions}
                    objectLists={this.props.objectLists}
                    actionClick={this.onEditObject.bind(this, "events")}
                    updateField={this.props.updateEventField}
                    saveFields={this.saveFields.bind(this)}
                />
            }
            else if(listType === "objections")
            {
                //All actions from all communications
                right_side = <StakeholderObjectionsListView
                    readOnly={this.props.readOnly}
                    objections={this.props.allObjections}
                    objectLists={this.props.objectLists}
                    onClick={this.onEdit.bind(this)}
                    updateObjection={(objection_id,details) => this.props.updateObjection(objection_id, details,() => this.props.loadList('objections', project_id,'none'))}
                />
            }
            else
            {
                right_side = <div>{t("Unknown List Type")}</div>
            }
        }
        else
        {
            let selectedObject = this.props.selectedObject;
            if (selectedObject == null || this.props.isObjectLoading)
            {
                right_side = <Spinner/>
            }
            else if (selectedObject.type === "StakeholderEvent")
            {
                right_side = <StakeholderEventView event={this.props.selectedObject}
                                                   readOnly={this.props.readOnly}
                                                   deleteEvent={this.props.deleteEvent}
                                                   saveFields={this.saveFields.bind(this)}
                                                   stakeholdersMap={this.props.stakeholdersMap}
                                                   back={this.gotoAllCommunications.bind(this)}
                                                   updateField={this.props.updateEventField}
                                                   uploadAttachment={this.props.uploadAttachment}
                                                   deleteAttachment={this.props.deleteAttachment}

                    // actions
                                                   actions={this.props.actions}
                                                   selectedAction={this.props.selectedAction}
                                                   selectedActionValid={this.props.selectedActionValid}
                                                   objectLists={this.props.objectLists}
                                                   newAction={()=> this.props.newAction(objectId)}
                                                   removeAction={this.props.removeAction}
                                                   doneAction={this.props.doneAction}

                    // objections
                                                   newObjection={(details) => this.props.newObjection(objectId, details)}
                                                   linkObjection={(details) => this.props.linkObjection(objectId, details)}
                                                   objectionLinks={this.props.objectionLinks}
                                                   removeObjection={this.props.removeObjection}
                                                   viewObjection={this.viewObjection.bind(this)}
                                                   updateObjection={(objection_id,details) => this.props.updateObjection(objection_id, details)}


                />

            }
            else if (selectedObject.type === "StakeholderObjection")
            {
                right_side = <StakeholderObjectionView objection={this.props.selectedObject}
                                                       back={this.gotoAllObjections.bind(this)}
                                                       objectLists={this.props.objectLists}
                                                       objectionLinks={this.props.objectionLinks}
                                                       onClick={this.gotoEvent.bind(this)}

                />

            }
            else
            {
                right_side = <div>{t("Don't know how to display {object_type}", {object_type:selectedObject.type})}</div>
            }

        }

        return (
            <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)} crumbs={crumbs} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions} style={{padding:0, margin:0, position:"relative"}}>
                    {filterMessage}

                    <Row>
                        <Col lg={3}>
                            <div className="ibox float-e-margins">
                                <div className="ibox-content mailbox-content">
                                    <div className="file-manager">
                                        {addCommButton}
                                        <div className="space-25"></div>
                                        <h5>{t('Categories')}</h5>
                                        <ul className="folder-list m-b-md" style={{padding: 0}}>
                                            <li><a  onClick={this.gotoAllCommunications.bind(this)}><EnvidObjectIcon type="Communication"/> {t("Communications")}
                                                {/*<span className="label label-warning pull-right"></span>*/}
                                            </a></li>
                                            <li><a  onClick={this.gotoAllActions.bind(this)}><EnvidObjectIcon type="Action"/> {t("Actions")}</a></li>
                                            <li><a  onClick={this.gotoAllObjections.bind(this)}><EnvidObjectIcon type="Objection"/> {t("Objections")}</a></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={9} className="xanimated xfadeInRight">

                            {right_side}

                        </Col>
                    </Row>
            </PageWrapper>
            </div>
            );

    }

}

export default withTranslation()(StakeholderCommsView)

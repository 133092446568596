/**
 * Created by craig on 1/2/17.
 */
import React from 'react';
import taskUtil from '../../utils/taskUtil'
import {useTranslation} from "react-i18next";

export default function AnswerLabel(props)
{
    const { t } = useTranslation();

    if (props.risk != null)
    {
        return <span className="label" style={{color:"#FFF", background:taskUtil.matrix_colours[props.risk]}}>{t(props.riskName)}</span>
        //</span><i className="fa fa-check-circle" style={{color:taskUtil.matrix_colours[props.risk]}}/>
    }

    if (props.answer == null || props.answer === "") {
        return <span></span>;
    }

    if (props.answer === "Yes")
    {
        return <i className="fa fa-check-circle text-navy"></i>;
    }
    if (props.answer === "No")
    {
        return <i className="fa fa-times-circle text-default"></i>;
    }
    if (props.answer === "DontKnow")
    {
        return <i className="fa fa-question-circle text-warning"></i>;
    }

    return <i className="fa fa-circle" style={{color:"#a10288"}}></i>;
    // let label = (props.answer === "Yes")?"label-primary" : (props.answer === "No")?"label-warning" : "label-success";
    // return <span className={label} style={{paddingLeft: 5, paddingRight: 5}}>{t(props.answer)}</span>
}

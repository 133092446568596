/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientLibraryResult } from './ClientLibraryResult';
import {
    ClientLibraryResultFromJSON,
    ClientLibraryResultFromJSONTyped,
    ClientLibraryResultToJSON,
} from './ClientLibraryResult';
import type { ClientResult } from './ClientResult';
import {
    ClientResultFromJSON,
    ClientResultFromJSONTyped,
    ClientResultToJSON,
} from './ClientResult';
import type { UserResult } from './UserResult';
import {
    UserResultFromJSON,
    UserResultFromJSONTyped,
    UserResultToJSON,
} from './UserResult';
import type { DatasetResult } from './DatasetResult';
import {
    DatasetResultFromJSON,
    DatasetResultFromJSONTyped,
    DatasetResultToJSON,
} from './DatasetResult';
import type { Overlay } from './Overlay';
import {
    OverlayFromJSON,
    OverlayFromJSONTyped,
    OverlayToJSON,
} from './Overlay';
import type { TranslationResult } from './TranslationResult';
import {
    TranslationResultFromJSON,
    TranslationResultFromJSONTyped,
    TranslationResultToJSON,
} from './TranslationResult';

/**
 * 
 * @export
 * @interface SiteDataResult
 */
export interface SiteDataResult {
    /**
     * 
     * @type {UserResult}
     * @memberof SiteDataResult
     */
    user?: UserResult;
    /**
     * 
     * @type {ClientResult}
     * @memberof SiteDataResult
     */
    client?: ClientResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiteDataResult
     */
    access?: Array<string>;
    /**
     * 
     * @type {Overlay}
     * @memberof SiteDataResult
     */
    overlay?: Overlay;
    /**
     * 
     * @type {Array<ClientLibraryResult>}
     * @memberof SiteDataResult
     */
    clientLibraries?: Array<ClientLibraryResult>;
    /**
     * 
     * @type {DatasetResult}
     * @memberof SiteDataResult
     */
    dataset?: DatasetResult;
    /**
     * 
     * @type {Array<TranslationResult>}
     * @memberof SiteDataResult
     */
    languages?: Array<TranslationResult>;
    /**
     * 
     * @type {string}
     * @memberof SiteDataResult
     */
    serverVersion?: string;
}

/**
 * Check if a given object implements the SiteDataResult interface.
 */
export function instanceOfSiteDataResult(value: object): value is SiteDataResult {
    return true;
}

export function SiteDataResultFromJSON(json: any): SiteDataResult {
    return SiteDataResultFromJSONTyped(json, false);
}

export function SiteDataResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteDataResult {
    if (json == null) {
        return json;
    }
    return {
        
        'user': json['user'] == null ? undefined : UserResultFromJSON(json['user']),
        'client': json['client'] == null ? undefined : ClientResultFromJSON(json['client']),
        'access': json['access'] == null ? undefined : json['access'],
        'overlay': json['overlay'] == null ? undefined : OverlayFromJSON(json['overlay']),
        'clientLibraries': json['clientLibraries'] == null ? undefined : ((json['clientLibraries'] as Array<any>).map(ClientLibraryResultFromJSON)),
        'dataset': json['dataset'] == null ? undefined : DatasetResultFromJSON(json['dataset']),
        'languages': json['languages'] == null ? undefined : ((json['languages'] as Array<any>).map(TranslationResultFromJSON)),
        'serverVersion': json['serverVersion'] == null ? undefined : json['serverVersion'],
    };
}

export function SiteDataResultToJSON(value?: SiteDataResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user': UserResultToJSON(value['user']),
        'client': ClientResultToJSON(value['client']),
        'access': value['access'],
        'overlay': OverlayToJSON(value['overlay']),
        'clientLibraries': value['clientLibraries'] == null ? undefined : ((value['clientLibraries'] as Array<any>).map(ClientLibraryResultToJSON)),
        'dataset': DatasetResultToJSON(value['dataset']),
        'languages': value['languages'] == null ? undefined : ((value['languages'] as Array<any>).map(TranslationResultToJSON)),
        'serverVersion': value['serverVersion'],
    };
}


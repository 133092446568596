/**
 * Created by lewellyn on 14/12/16.
 */
import React from 'react'
import ebase from '../../utils/ajax'
import DataManage from '../../views/DataManage'
import SessionManager from "../../utils/SessionManager";

class DataManageContainer extends React.Component{


    constructor(){
        super();

        this.state = {
            checkInfo : null,
            loading  : null
        };

    }

    doDelete()
    {
        this.setState({updating:true});

        ebase.ajax({
           url:"/ebase/envid-admin/write-enviddata-delete",
            data:{object_id:this.props.currentObject.object.object_id},
            success: this.deleteSuccess.bind(this),
            error: this.deleteError.bind(this)

        });
    }

    deleteSuccess(result){
        // go back to the search screen
        if (result.success) {
            // this.context.router.push("/enviddata/objects");
            SessionManager.shared().goto("/enviddata");
        } else {
            this.setState({checkInfo: {canDelete:false, error:"Error deleting object - " + result.error}, loading: null})
        }
    }

    deleteError(result){
        console.log("update error: "+result);
        this.setState({checkInfo: {canDelete:false, error:"Error deleting object, try again later."}, loading: null})
    }


    getObjectId()
    {
        return this.props.currentObject.object.object_id;
    }

    loadDeleteCheckInfo()
    {
        var request = {
            object_id:this.getObjectId(),
        }

        ebase.ajax({
            url:"/ebase/envid-admin/read-enviddata-deletecheck",
            data:request,
            success: this.checkInfoSuccess.bind(this, request),
            error:   () => this.updateLinkInfo.setState({checkInfo: {canDelete:false, error:"Error checking object status"}, loading: null})
        });
    }

    reload(){
        this.setState({loading:true});
        this.loadDeleteCheckInfo();
    }

    checkInfoSuccess(request, result)
    {
        if (result.success) {
            this.setState({checkInfo: result.data, loading: null});
        }
    }

    render()
    {

        if (this.state.checkInfo == null)
        {
            // show the spinner, and request they be loaded
            if (this.state.loading == null || this.state.loading !== this.getObjectId()) {
                this.loadDeleteCheckInfo();
                this.state.loading = this.getObjectId();
            }
        }


        return (

                <DataManage currentObject={this.props.currentObject}
                            updating={this.state.updating}
                            loading={this.state.loading!=null}
                            deleteCheckInfo={this.state.checkInfo}
                            doDelete = {this.doDelete.bind(this)}
                            siteData={this.props.siteData}
                            height={this.props.height}
                            reload={this.reload.bind(this)}/>

        );
    }


}

// DataManageContainer.contextTypes = {
//     router: function contextType() {
//         return PropTypes.func.isRequired;
//     }
// };

export default DataManageContainer



/**
 *
 */
import React from 'react';
import {TemplatesAdminView} from '../../views/TemplatesAdminView';

const TemplatesAdminContainer = (props) => {
    return React.cloneElement(<TemplatesAdminView/>, props);
}

export {TemplatesAdminContainer}

import React from 'react';
import {useTranslation} from "react-i18next";

const getEventTypeIcon = function(type)
{
    let icon = "fa-envelope";
    switch (type)
    {
        case "EMAIL":   icon = "fa-envelope";       break;
        case "MAIL":    icon = "fa-envelope-open";  break;
        case "MEETING": icon = "fa-group";          break;
        case "PHONE":   icon = "fa-phone";          break;
        case "OTHER":   icon = "fa-clipboard";      break;
        default: icon = ""; break;
    }

    return icon;

}
const StakeholderEventType = function(props)
{
    const { t } = useTranslation();

    let icon = "fa-envelope";
    let name = "";
    switch (props.type)
    {
        case "EMAIL":   icon = "fa-envelope";       name = "Email"; break;
        case "MAIL":    icon = "fa-envelope-open";   name = "Mail"; break;
        case "MEETING": icon = "fa-group";          name = "Meeting"; break;
        case "PHONE":   icon = "fa-phone";          name = "Phone Call"; break;
        case "OTHER":   icon = "fa-clipboard";      name = "Other"; break;
        default: icon = ""; name = "?"; break;
    }

    let thename = "";
    if (props.showTitle)
    {
        thename = " " + t(name);
    }

    return <span><i className={"fa " + icon} style={props.style}></i>{thename}</span>
}

export {StakeholderEventType, getEventTypeIcon}

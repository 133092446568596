import {
    LOAD_PROJECTS_START, LOAD_PROJECTS_DONE, LOAD_PROJECT_DONE, LOAD_PROJECT_START,
    UPDATE_PROJECT_FIELD, SAVING_PROJECT_START, SAVING_PROJECT_SUCCESS, SAVING_PROJECT_FAILED,
    LOAD_PROJECT_STATUS_START, LOAD_PROJECT_STATUS_DONE, LOAD_PROJECT_COMPLIANCE_STATUS_START,
    LOAD_PROJECT_COMPLIANCE_STATUS_DONE
} from "../actions/actions"
import {LOAD_PROJECTOBJECTS_START, LOAD_PROJECTOBJECTS_DONE, LOAD_PROJECTOBJECTS_ERROR} from "../actions/actions"
import {NEW_PROJECTOBJECT_START, NEW_PROJECTOBJECT_DONE, NEW_PROJECTOBJECT_ERROR} from "../actions/actions"
import {EDIT_PROJECTOBJECT_START, UPDATE_PROJECTOBJECT_FIELD} from "../actions/actions"

import details from '../../utils/details'
import update from 'immutability-helper';

export default function projects(state = {list:null, isLoading:false, changes:{savedStatus:"unchanged", changedFields:{}}}, action) {

    switch (action.type)
    {
        case LOAD_PROJECTS_START:
            return {isLoading:true, changes:state.changes};

        case LOAD_PROJECTS_DONE:
            if (action.result.success)
            {
                return {isLoaded: true, list: action.result.data, changes:state.changes};
            }
            return {isLoaded:true, error:action.result.error, changes:state.changes};

        case LOAD_PROJECT_START:
            return Object.assign({}, state, {isLoading: true, currentObject:null, projectName:action.projectName});

        case LOAD_PROJECT_DONE:
            if (action.result.success)
            {
                return Object.assign({}, state, {isLoading: false, currentObject:action.result.data});
            }
            return Object.assign({}, state, {isLoading: false, error:action.result.error});

        case UPDATE_PROJECT_FIELD:
        {
            let fieldName = action.name;
            let value = action.value;
            let object_id = state.currentObject.object.object_id;

            // update the object
            let currentObject = update(state.currentObject, details.getUpdate(fieldName, value));


            // now update the changed list
            var changes = {savedStatus:"saving"};

            // now fix up the changes stuff
            var fchanges = {};
            if( Object.prototype.toString.call( value ) === '[object Array]' ) {
                fchanges[details.makeId(object_id, fieldName)] = {object_id:object_id, field:fieldName, list:value};
            } else {
                fchanges[details.makeId(object_id, fieldName)] = {object_id:object_id, field:fieldName, value:value};
            }
            changes.changedFields = update(state.changes.changedFields, {$merge: fchanges});

            let newState = Object.assign({}, state, {currentObject:currentObject, changes:changes});
            return newState;
        }

        case SAVING_PROJECT_START:
        {
            // clear the update fields stuff
            var changes = {savedStatus:"saving", changedFields:{}};
            let newState = Object.assign({}, state, {changes:changes});
            return newState;
        }

        case SAVING_PROJECT_SUCCESS:
        {
            // clear the update fields stuff
            var changes = {savedStatus:(details.isObjectEmpty(state.changes.changedFields))?"saved":"saving", changedFields:state.changes.changedFields};
            let newState = Object.assign({}, state, {changes:changes});
            return newState;
        }

        case SAVING_PROJECT_FAILED:
        {
            // we're going to have to show some sort of error, and put the
            // fields back on the changed field list
            break;
        }

        case LOAD_PROJECTOBJECTS_START:
        {
            let object_type = action.object_type;
            let changes = {};
            changes[object_type] = {isLoading:true};
            let newState = Object.assign({}, state, changes);
            return newState;
        }

        case LOAD_PROJECTOBJECTS_DONE:
        {
            let object_type = action.object_type;
            let changes = {};
            let objects = [];
            console.log("LOAD_PROJECTOBJECTS_DONE");
            if (action.data.objectLists[object_type] != null){
                objects = Object.values(action.data.objectLists[object_type]).slice();
                objects = objects.sort((a, b) => {return b.details.date - a.details.date;})
            }
            changes[object_type] = {isLoading:false, objects:objects, objectLists:action.data.objectLists};
            let newState = Object.assign({}, state, changes);
            return newState;
        }

        case LOAD_PROJECTOBJECTS_ERROR:
        {
            let object_type = action.object_type;
            changes[object_type] = {isLoading:false, error:action.error};
            return state;
        }

        case NEW_PROJECTOBJECT_START:
        {
            let changes = {};
            let newState = Object.assign({}, state, changes);
            return newState;
        }

        case NEW_PROJECTOBJECT_DONE:
        {
            let object_type = action.object_type;
            let new_object = action.data.object;

            let changes = {};
            let objects = state[object_type].objects.slice();
            objects.splice(0, 0, new_object);

            let objectLists = {};
            if (state[object_type] != null)
            {
                objectLists = Object.assign({}, state[object_type].objectLists);

            if (objectLists[object_type] == null)
                objectLists[object_type] = {};
            }

            objectLists[object_type][new_object.object_id] = new_object;

            changes[object_type] = {isLoading:false, objects:objects, objectLists:objectLists, editing_id:new_object.object_id};
            let newState = Object.assign({}, state, changes);
            return newState;
        }

        case NEW_PROJECTOBJECT_ERROR:
        {
            // let object_type = action.object_type;
            // changes[object_type] = {isLoading:false, error:action.error};
            //
            return state;
        }

        case EDIT_PROJECTOBJECT_START:
        {
            let object_type = action.object_type;
            let object_id = action.object_id;

            let objects = state[object_type].objects;
            if (object_id == null)
            {
                objects = objects.slice();
                objects = objects.sort((a, b) => {return b.details.date - a.details.date;})

            }
            let changes = {};
            changes[object_type] = {isLoading:false, objects:objects, objectLists:state[object_type].objectLists, editing_id:object_id};
            let newState = Object.assign({}, state, changes);
            return newState;
        }

        case UPDATE_PROJECTOBJECT_FIELD:
        {
            let fieldName = action.name;
            let value = action.value;
            let object_id = action.object_id;
            let object_type = action.object_type;

            console.log("Update Field for " + object_type);
            console.log(state[object_type]);


            // // update the object
            // let changedObject = update(state.currentObject, details.getUpdate(fieldName, value));
            //
            let objectLists = Object.assign({}, state[object_type].objectLists);

            let changedObject = update({object:objectLists[object_type][object_id]}, details.getUpdate(fieldName, value));

            objectLists[object_type][object_id] = changedObject.object;

            // find the object and replace it, because we don't want the order to change
            let objects = state[object_type].objects.slice();
            for (let i=0;i<objects.length; ++i)
            {
                if (objects[i].object_id === changedObject.object.object_id)
                {
                    objects[i] = changedObject.object;
                    break;
                }
            }
            //let objects = Object.values(objectLists[object_type]);


            // now update the changed list
            var changes = {savedStatus:"saving"};

            // now fix up the changes stuff
            var fchanges = {};
            if( Object.prototype.toString.call( value ) === '[object Array]' ) {
                fchanges[details.makeId(object_id, fieldName)] = {object_id:object_id, field:fieldName, list:value};
            } else {
                fchanges[details.makeId(object_id, fieldName)] = {object_id:object_id, field:fieldName, value:value};
            }
            changes.changedFields = update(state.changes.changedFields, {$merge: fchanges});

            let allchanges = {};
            allchanges[object_type] = {isLoading:false, objects:objects, objectLists:objectLists, editing_id:state[object_type].editing_id};
            allchanges.changes = changes;
            let newState = Object.assign({}, state, allchanges);

            return newState;

        }

        case LOAD_PROJECT_STATUS_START:
        {
            return Object.assign({}, state,{isStatusLoading:true});
        }

        case LOAD_PROJECT_STATUS_DONE:
        {
            return Object.assign({}, state, {isStatusLoading:false,project_status:action.result.data});
        }

        case LOAD_PROJECT_COMPLIANCE_STATUS_START:
        {
            return Object.assign({}, state,{isStatusLoading:true});
        }

        case LOAD_PROJECT_COMPLIANCE_STATUS_DONE:
        {
            return Object.assign({}, state, {isStatusLoading:false,compliance_status:action.result.data});
        }

        default:{}

    }
    return state;
}
